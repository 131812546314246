import { IApplicationState } from "@/app/types";

const sliceNames: Record<keyof IApplicationState, string> = {
  administrator: "administrator",
  article: "article",
  product: "product",
  order: "order",
  video: "video",
  chatBot: "chatBot",
  globalData: "globalData",
  customer: "customer",
  chat: "chat",
  stat: "stat",
  intent: "intent",
  professional: "professional",
  booking: "booking",
  articleCategory: "articleCategory",
  blogCategory: "blogCategory",
  blog: "blog",
  autoForm: "autoForm",
};

export default sliceNames;
