import React from "react";
// @mui
// routes
import { PATH_DASHBOARD, PATH_PAGE } from "@/app/routes/paths";
// components
import CustomBreadcrumbs from "@/core/components/custom-breadcrumbs";
// sections
import LoadingScreen from "@/core/components/loading-screen/LoadingScreen";
import Page from "@/core/components/page";
import useTranslation from "@/core/hooks/useTranslation";
import { BlogNewPostForm } from "@/presentation/sections/@dashboard/blog";
import articleEffects from "@/redux/slices/article/effects";
import { store, useSelector } from "@/redux/store";
import { useParams } from "react-router-dom";
import { APP_PERMISSIONS } from "@/app/config-global";

// ----------------------------------------------------------------------

export default function BlogNewPostPage() {
  // Hooks
  const { uuid } = useParams();
  const t = useTranslation();
  const dispatch = store.dispatch;

  // Store
  const {
    article: { currentItem: articleToEdit },
  } = useSelector((state) => state);

  // Effects
  React.useEffect(() => {
    if (uuid) {
      dispatch(
        articleEffects.get(
          uuid,
          () => {},
          (error: any) => {
            if (error.status === 404) {
              window.location.href = PATH_PAGE.page404;
            }
          }
        )
      );
    }
  }, [uuid]);

  return uuid && !articleToEdit ? (
    <LoadingScreen />
  ) : (
    <Page
      permissions={APP_PERMISSIONS.article.create?.concat(
        APP_PERMISSIONS.article.update
      )}
      noPermissionUrl={PATH_DASHBOARD.articles.root}
      title={t(`article.${articleToEdit ? "update" : "create"}`)}
    >
      {/* @ts-ignore */}
      <CustomBreadcrumbs
        heading={`article.${articleToEdit ? "update" : "create"}`}
        links={[
          {
            name: "dashboard.root",
            href: PATH_DASHBOARD.root,
          },
          {
            name: "article.root_plural",
            href: PATH_DASHBOARD.articles.root,
          },
          {
            name: `app.${articleToEdit ? "update" : "create"}`,
          },
        ]}
      />

      <BlogNewPostForm
        articleToEdit={articleToEdit}
        uuid={uuid}
      />
    </Page>
  );
}
