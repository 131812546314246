import { DEFAULT_NA_STRING } from "@/app/config-global";
import { IHairdressingSalon } from "@/app/types";
import { ITableRowColumn } from "@/core/components/table/AppTableRow";
import moment from "moment";

const hairdressingSalonColumns: (
  locale: string
) => ITableRowColumn<IHairdressingSalon>[] = (locale: string) => [
  {
    header: { id: "name", label: "administrator.name", align: "left" },
    type: "avatar",
    title: (row) => row?.name,
    avatarUrl: (row) => row?.file?.url ?? "",
  },
  {
    header: {
      id: "primary_address",
      label: "label.primary_address",
      align: "left",
    },
    title: (row) => row?.address?.address_one ?? "",
  },
  {
    header: { id: "town", label: "label.town", align: "left" },
    title: (row) => row?.address?.town ?? "",
  },
  {
    header: { id: "country", label: "label.country", align: "left" },
    title: (row) => row?.address?.country ?? "",
  },
  {
    header: { id: "phone_number", label: "label.phone_number", align: "left" },
    title: (row) => row?.phonenumber ?? "",
  },
  {
    header: { id: "formula", label: "label.formula", align: "left" },
    type: "badge",
    title: (row) => `label.${row?.formula?.code || "none_female"}`,
    badgeColor: (row) =>
      row?.formula?.code
        ? row?.formula?.code === "PREMIUM"
          ? "warning"
          : "secondary"
        : "default",
    sx: { textTransform: "capitalize" },
  },
  {
    header: {
      id: "register_date",
      label: "label.register_date",
      align: "left",
    },
    title: (row) =>
      row?.date_added ? moment(row?.date_added).format("ll") : "",
  },
  // {
  //   header: { id: "status", label: "administrator.status", align: "left" },
  //   type: "badge",
  //   title: (row) => `status.${row?.shop_onligne ? "online" : "offline"}`,
  //   badgeColor: (row) => (row?.shop_onligne ? "success" : "error"),
  //   sx: { textTransform: "capitalize" },
  // },
];

export default hairdressingSalonColumns;
