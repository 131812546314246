import { IBookingCreate, IBookingRange, IBookingUpdate } from "@/app/types";
import BaseService from "./BaseService";
import { BookingUrls } from "./urls";

class BookingService {
  static get = (uuid: string) => {
    return BaseService.getRequest(BookingUrls.GET(uuid), true);
  };
  static paginate = (query: IBookingRange) => {
    return BaseService.getRequest(BookingUrls.PAGINATE(query), true);
  };
  static create = (data: IBookingCreate) => {
    return BaseService.postRequest(BookingUrls.CREATE, data, true);
  };
  static update = ({ uuid, ...rest }: IBookingUpdate) => {
    return BaseService.putRequest(BookingUrls.UPDATE(uuid), rest, true);
  };
  static delete = (uuid: string) => {
    return BaseService.deleteRequest(BookingUrls.DELETE(uuid), {}, true);
  };
}

export default BookingService;
