import { IBooking } from "@/app/types";
import { IBaseCrudReducer } from "@/app/types/BaseReduxTypes";
import BaseCrudReducer from "@/redux/base/crud-slice-helpers/reducers";
import { resetStateKeys } from "@/redux/common/functions";
import { bookingInitialState, IBookingState } from ".";

export interface IBookingReducer
  extends IBaseCrudReducer<IBookingState, IBooking> {
  resetState: (state: IBookingState) => void;
}

class BookingReducer
  extends BaseCrudReducer<IBookingState, IBooking>
  implements IBookingReducer
{
  resetState = (state: IBookingState) =>
    resetStateKeys(state, bookingInitialState);
}

const reducers: IBookingReducer = new BookingReducer();

export default reducers;
