import { IArticleCreate, IArticleRange, IArticleUpdate } from "@/app/types";
import BaseService from "./BaseService";
import { ArticleUrls } from "./urls";

class ArticleService {
  static get = (articleId: string) => {
    return BaseService.getRequest(ArticleUrls.GET(articleId), true);
  };
  static paginate = (query: IArticleRange) => {
    return BaseService.getRequest(ArticleUrls.PAGINATE(query), true);
  };
  static create = (data: IArticleCreate) => {
    return BaseService.postRequest(ArticleUrls.CREATE, data, true);
  };
  static update = ({ uuid, ...rest }: IArticleUpdate) => {
    return BaseService.putRequest(ArticleUrls.UPDATE(uuid), rest, true);
  };
  static delete = (articleId: string) => {
    return BaseService.deleteRequest(ArticleUrls.DELETE(articleId), {}, true);
  };
  static publish = (articleId: string) => {
    return BaseService.putRequest(ArticleUrls.PUBLISH(articleId), {}, true);
  };
  static addOrRemoveFavorite = (articleId: string) => {
    return BaseService.postRequest(
      ArticleUrls.ADD_OR_REMOVE_FAVORITE(articleId),
      {},
      true
    );
  };
  static listHome = (query: IArticleRange) => {
    return BaseService.getRequest(ArticleUrls.LIST_HOME_ARTICLES(query), true);
  };
  static listByCategory = (query: IArticleRange) => {
    return BaseService.getRequest(ArticleUrls.LIST_BY_CATEGORY(query), true);
  };
  static listFavorites = (query: IArticleRange) => {
    return BaseService.getRequest(ArticleUrls.LIST_FAVORITES(query), true);
  };
}

export default ArticleService;
