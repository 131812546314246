import { IBlog } from "@/app/types";
import {
  baseCrudInitialState,
  IBaseCrudState,
} from "@/redux/base/crud-slice-helpers";
import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers, { IBlogReducer } from "./reducers";

export interface IBlogState extends IBaseCrudState<IBlog> {
  home: IBaseCrudState;
  favorites: IBaseCrudState;
  category: IBaseCrudState;
}

export const blogInitialState: IBlogState = {
  ...baseCrudInitialState,
  home: baseCrudInitialState,
  favorites: baseCrudInitialState,
  category: baseCrudInitialState,
  isLoading: false,
};

export const  slice = createSlice<
  IBlogState,
  IBlogReducer,
  typeof sliceNames.blog
>({
  name: sliceNames.blog,
  initialState: blogInitialState,
  reducers,
});

export default slice.reducer;
