import React, { useEffect } from "react";
// @ts-ignore
import { Link as RouterLink } from "react-router-dom";
// @mui
import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
// utils
// routes
import { PATH_DASHBOARD } from "@/app/routes/paths";
// components
import CustomBreadcrumbs from "@/core/components/custom-breadcrumbs";
import Iconify from "@/core/components/iconify";
import { useSettingsContext } from "@/core/components/settings";
import { SkeletonPostItem } from "@/core/components/skeleton";
// sections
import AccessGuard from "@/app/auth/AccessGuard";
import { APP_PERMISSIONS, DEFAULT_RANGE } from "@/app/config-global";
import { useLocales } from "@/app/locales";
import BlogCategoryService from "@/app/services/api/BlogCategoryService";
import {
  IApplicationState,
  IAppLocale,
  IBlog,
  IBlogCategory,
} from "@/app/types";
import EmptyContent from "@/core/components/empty-content";
import Image from "@/core/components/image";
import Markdown from "@/core/components/markdown";
import Page from "@/core/components/page";
import Scrollbar from "@/core/components/scrollbar";
import useFilters from "@/core/hooks/useFilters";
import useToast from "@/core/hooks/useToast";
import useTranslation from "@/core/hooks/useTranslation";
import AppModal from "@/presentation/components/AppModal";
import DataFilterer from "@/presentation/components/DataFilterer";
import {
  BlogPostsSort,
  NewBlogPostCard,
} from "@/presentation/sections/@dashboard/blog";
import sliceNames from "@/redux/names";
import blogEffects from "@/redux/slices/blog/effects";
import { store } from "@/redux/store";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: "desc", label: "app.desc" },
  { value: "asc", label: "app.asc" },
];

// ----------------------------------------------------------------------

export default function BlogsPage() {
  const { themeStretch } = useSettingsContext();

  // Store
  const dispatch = store.dispatch;
  const { blog } = useSelector((state: IApplicationState) => state);

  // State
  const [currentCategory, setCurrentCategory] =
    React.useState<IBlogCategory | null>(null);

    console.log('blog: ', blog)

  const posts = blog?.list?.data;

  // Hooks
  const {
    keyword,
    handleKeyword,
    order,
    handleOrder,
    handleCategory_uuid,
    category_uuid,
    getData,
  } = useFilters({
    effects: blogEffects,
    initialState: {
      category_uuid: "",
      keyword: "",
      order: "desc",
    },
  });

  const t = useTranslation();
  const toast = useToast();
  const { currentLang } = useLocales();
  const [blogToShow, setBlogToShow] = React.useState<IBlog | null>(
    null
  );
  const lang = currentLang.value as IAppLocale;

  const handleShowBlog = (blog: IBlog) => {
    setBlogToShow(blog);
  };

  const closeBlogPreviewModal = () => {
    setBlogToShow(null);
  };

  const handlePublish = (e: any, checked: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      blogEffects.publish(blogToShow?.uuid ?? "", (data) => {
        setBlogToShow(data);
        toast.success(t("blog.status_change_success"));
        setBlogToShow(null);
      })
    );
  };
  const handlePublishRecentArticle = (e: any, checked: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      blogEffects.addOrRemoveInRecentArticle(blogToShow?.uuid ?? "", (data) => {
        setBlogToShow(data);
        toast.success(t("blog.status_change_success"));
        setBlogToShow(null);
      })
    );
  };
  const handlePublishInNews = (e: any, checked: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      blogEffects.addOrRemoveInTheNews(blogToShow?.uuid ?? "", (data) => {
        setBlogToShow(data);
        toast.success(t("blog.status_change_success"));
        setBlogToShow(null);
      })
    );
  };
  return (
    <Page
      title={"blog.root_plural"}
      permissions={APP_PERMISSIONS[sliceNames.blog].list}
    >
      <CustomBreadcrumbs
        heading="blog.root_plural"
        links={[
          {
            name: "dashboard.root",
            href: PATH_DASHBOARD.root,
          },
          {
            name: "blog.root_plural",
          },
        ]}
        action={
          <AccessGuard roles={APP_PERMISSIONS[sliceNames.blog].create}>
            <Button
              component={RouterLink}
              to={PATH_DASHBOARD.blogs.new}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              {t("app.add")}
            </Button>
          </AccessGuard>
        }
      />

      <Stack
        mb={5}
        direction="row"
        gap={{ xs: 2 }}
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        {/* <BlogPostsSearch
            handleKeywordChange={handleKeywordChange}
            keyword={keyword}
          /> */}
        <Stack
          direction="row"
          gap={{ xs: 2 }}
          alignItems="center"
          flexWrap="wrap"
        >
          <TextField
            name={"search"}
            id={"search-input"}
            // label={t("label.search")}
            onChange={(e) => handleKeyword(e.target.value)}
            placeholder={t("placeholder.search")}
            value={keyword}
          />
          <DataFilterer
            options={[
              {
                name: "category_uuid",
                type: "autocomplete",
                placeholder: "placeholder.category",
                getOptionLabel: (value: IBlogCategory) =>
                  value?.[`title_${currentLang.value as IAppLocale}`] ?? "",
                getOptionValue: (value: IBlogCategory) => value?.uuid ?? "",
                getValue: (options: IBlogCategory[]) =>
                  options.find((opt) => opt.uuid === category_uuid) ?? null,
                loadOptions: async (keyword: string) => {
                  const res = await BlogCategoryService.paginate({
                    ...DEFAULT_RANGE,
                    keyword,
                  });
                  const data = await res.json();
                  return data.data;
                },
                onChange: (value: any) => handleCategory_uuid(value),
              },
            ]}
          />
        </Stack>
        <BlogPostsSort
          sortBy={order}
          sortOptions={SORT_OPTIONS}
          onSort={handleOrder}
        />
      </Stack>

      {!blog?.isLoading && (blog?.list.data.length === 0 || !blog?.list.data.length) && (
        <Stack>
          {/* @ts-ignore */}
          <EmptyContent title={t("app.no_data")} />
        </Stack>
      )}

      <Grid
        container
        spacing={3}
      >
        {(blog?.isLoading ? [...Array(12)] : posts)?.map((post, index) =>
          !blog?.isLoading ? (
            <Grid
              key={post.id}
              item
              xs={12}
              sm={6}
              md={(index === 0 && 6) || 3}
            >
              <NewBlogPostCard
                post={post}
                index={index}
                handleShowPost={handleShowBlog}
                getData={getData}
              />
            </Grid>
          ) : (
            <SkeletonPostItem key={index} />
          )
        )}
      </Grid>

      {blogToShow && (
        <AppModal
          maxWidth="md"
          onClose={closeBlogPreviewModal}
          actions={[
            <AccessGuard
              key={`change_status_action_modal_button`}
              roles={APP_PERMISSIONS[sliceNames.blog].change_status}
            >
              {blog?.isLoading ? (
                <CircularProgress />
              ) : (
                <Stack direction={'row'} columnGap={2}>
                  <FormControlLabel
                  label={t("label.publish")}
                  labelPlacement="end"
                  disabled={blog?.isLoading}
                  control={
                    <Switch
                      placeholder={t("placeholder.publish")}
                      title={t("title.change_status")}
                      checked={blogToShow?.is_online}
                      onChange={handlePublish}
                    />
                  }
                />
                <FormControlLabel
                  label={t("label.recent_blogs")}
                  labelPlacement="end"
                  disabled={blog?.isLoading}
                  control={
                    <Switch
                      placeholder={t("placeholder.recent_blogs")}
                      title={t("title.recent_blogs")}
                      checked={blogToShow?.recent_blogs}
                      onChange={handlePublishRecentArticle}
                    />
                  }
                />
                <FormControlLabel
                  label={t("label.in_the_news")}
                  labelPlacement="end"
                  disabled={blog?.isLoading}
                  control={
                    <Switch
                      placeholder={t("placeholder.in_the_news")}
                      title={t("title.in_the_news")}
                      checked={blogToShow?.in_the_news}
                      onChange={handlePublishInNews}
                    />
                  }
                />
                </Stack>
              )}
            </AccessGuard>,
          ]}
          breadCrumbLinks={[
            {
              name: "dashboard.root",
              href: PATH_DASHBOARD.root,
            },
            {
              name: "blog.root_plural",
            },
            {
              name: blogToShow?.[`title_${lang}`] ?? "",
            },
          ]}
        >
          {blogToShow?.[`title_${lang}`] ||
          blogToShow?.[`content_${lang}`] ? (
            <Scrollbar>
              {blogToShow?.cover_image && (
                <PreviewHero
                  title={blogToShow?.[`title_${lang}`]}
                  cover={blogToShow?.cover_image?.medium?.url}
                />
              )}
              <Container sx={{ mt: 5, mb: 10 }}>
                <Markdown
                  children={blogToShow?.[`content_${lang}`]}
                  sx={{}}
                />
              </Container>
            </Scrollbar>
          ) : (
            <>
              {/* @ts-ignore */}
              <EmptyContent title="app.no_data" />
            </>
          )}
        </AppModal>
      )}
    </Page>
  );
}

function PreviewHero({ title, cover }: any) {
  return (
    <Box sx={{ position: "relative" }}>
      <Container
        sx={{
          bottom: "24px",
          left: 0,
          right: 0,
          zIndex: 9,
          position: "absolute",
          color: "common.white",
        }}
      >
        <Typography
          variant="h2"
          component="h1"
        >
          {title}
        </Typography>
      </Container>

      <Box
        sx={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 8,
          position: "absolute",
          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
        }}
      />
      <Image
        //@ts-ignore
        alt="cover"
        src={cover}
        ratio="16/9"
      />
    </Box>
  );
}
