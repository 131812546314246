import { IAdministrator } from "@/app/types";
import { IBaseCrudReducer } from "@/app/types/BaseReduxTypes";
import BaseCrudReducer from "@/redux/base/crud-slice-helpers/reducers";
import { administratorInitialState, IAdministratorState } from ".";
import { resetStateKeys } from "@/redux/common/functions";

export interface IAdministratorReducer
  extends IBaseCrudReducer<IAdministratorState, IAdministrator> {
  resetState: (state: IAdministratorState) => void;
}

class AdministratorReducer
  extends BaseCrudReducer<IAdministratorState, IAdministrator>
  implements IAdministratorReducer
{
  resetState = (state: IAdministratorState) =>
    resetStateKeys(state, administratorInitialState);
}

const reducers: IAdministratorReducer = new AdministratorReducer();

export default reducers;
