import { DEFAULT_RANGE } from "@/app/config-global";
import CustomerService from "@/app/services/api/CustomerService";
import HairdressingSalonService from "@/app/services/api/HairdressingSalonService";
import { ICustomer, IHairdressingSalon } from "@/app/types";
import getFullName from "@/core/utils/getFullName";
import { IToolbarOption } from "@/presentation/components/TablePage";

export const orderColorByStatus = {
  PENDING: "default",
  BOOK: "info",
  IN_PROGRESS: "warning",
  ACCEPTED: "success",
  PAID: "success",
};

export const orderPaymentTypeIcons = {
  GOOGLE_PAY: "logos:google-pay",
  STRIPE: "logos:stripe",
  APPLE_PAY: "logos:apple-pay",
  PAYPAL: "logos:paypal",
};

export const orderTableTopOptions = [
  {
    label: "status.all",
    value: "",
  },
  {
    label: "status.PENDING",
    value: "PENDING",
  },
  {
    label: "status.BOOK",
    value: "BOOK",
  },
  {
    label: "status.IN_PROGRESS",
    value: "IN_PROGRESS",
  },
  {
    label: "status.ACCEPTED",
    value: "ACCEPTED",
  },
];
export const orderToolbarOptions = (
  keyword: {
    value: string;
    onChange: (value: string) => void;
  },
  role: {
    value: string;
    onChange: (value: string) => void;
  },
  salon: null | {
    value: string;
    onChange: (value: string) => void;
  },
  user: null | {
    value: string;
    onChange: (value: string) => void;
  },
  paymentTypes: any[]
): IToolbarOption[] => [
  {
    name: "admin_roles",
    type: "select",
    options: paymentTypes,
    placeholder: "order.payment_types",
    ...role,
  },
  //@ts-ignore
  ...(salon
    ? [
        {
          name: "salon",
          type: "autocomplete",
          placeholder: "placeholder.hairdressing_salon",
          getOptionLabel: (value: IHairdressingSalon) => value?.name ?? "",
          getOptionValue: (value: IHairdressingSalon) => value?.uuid ?? "",
          getValue: (options: IHairdressingSalon[]) =>
            options.find((opt) => opt.uuid === salon.value) ?? null,
          loadOptions: async (keyword: string) => {
            const res = await HairdressingSalonService.paginate({
              ...DEFAULT_RANGE,
              keyword,
            });
            const data = await res.json();
            return data.data;
          },
          onChange: salon.onChange,
        },
      ]
    : []),
  //@ts-ignore
  ...(user
    ? [
        {
          name: "user",
          type: "autocomplete",
          placeholder: "label.customer",
          getOptionLabel: (value: ICustomer) =>
            (() => {
              const profile = value?.profiles?.find((p) => !!p?.is_parent);
              return getFullName(
                profile?.firstname,
                profile?.lastname,
                profile?.email ?? ""
              );
            })(),
          getOptionValue: (value: ICustomer) => value?.public_id ?? "",
          getValue: (options: ICustomer[]) =>
            options.find((opt) => opt.public_id === user?.value) ?? null,
          loadOptions: async (keyword: string) => {
            const res = await CustomerService.paginate({
              ...DEFAULT_RANGE,
              keyword,
            });
            const data = await res.json();
            return data.data;
          },
          onChange: user?.onChange,
        },
      ]
    : []),
  {
    name: "admin_search",
    placeholder: "placeholder.search",
    // @ts-ignore
    type: "input",
    ...keyword,
  },
];
