import FullCalendar from "@fullcalendar/react"; // => request placed at the top
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import timelinePlugin from "@fullcalendar/timeline";
//
import { useState, useRef, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet-async";
// @mui
import {
  Card,
  Button,
  Container,
  DialogTitle,
  Dialog,
  Skeleton,
  LinearProgress,
} from "@mui/material";
// redux
import { useDispatch, useSelector } from "@/redux/store";
import {
  getEvents,
  createEvent,
  updateEvent,
  deleteEvent,
} from "@/redux/slices/calendar";
// routes
import { PATH_DASHBOARD } from "@/app/routes/paths";
// utils
import { fTimestamp } from "@/core/utils/formatTime";
// hooks
import useResponsive from "@/core/hooks/useResponsive";
// components
import Iconify from "@/core/components/iconify";
import { useSnackbar } from "@/core/components/snackbar";
import CustomBreadcrumbs from "@/core/components/custom-breadcrumbs";
import { useSettingsContext } from "@/core/components/settings";
import { useDateRangePicker } from "@/core/components/date-range-picker";
// sections
import {
  CalendarForm,
  StyledCalendar,
  CalendarToolbar,
  CalendarFilterDrawer,
} from "../../sections/@dashboard/calendar";
import useFilters from "@/core/hooks/useFilters";
import bookingEffects from "@/redux/slices/booking/effects";
import moment from "moment";
import { toApiDate } from "@/core/utils/toApiDate";

// ----------------------------------------------------------------------

const COLOR_OPTIONS = [
  "#00AB55", // theme.palette.primary.main,
  "#1890FF", // theme.palette.info.main,
  "#54D62C", // theme.palette.success.main,
  "#FFC107", // theme.palette.warning.main,
  "#FF4842", // theme.palette.error.main
  "#04297A", // theme.palette.info.darker
  "#7A0C2E", // theme.palette.error.darker
];

// ----------------------------------------------------------------------

export interface ICalendarPageEvent {
  title: string;
  date?: Date;
  end?: Date;
  start?: Date;
  textColor?: string;
  allDay?: boolean;
}

export interface ICalendarPageProps {
  events: ICalendarPageEvent[];
  loading?: boolean;
  additionalFilters?: Record<string, string | undefined>;
}

export default function CalendarPage({
  events,
  loading,
  additionalFilters,
}: ICalendarPageProps) {
  const { enqueueSnackbar } = useSnackbar();

  const { themeStretch } = useSettingsContext();

  const dispatch = useDispatch();

  const isDesktop = useResponsive("up", "sm");

  const calendarRef = useRef(null);

  const {
    handleStart_date: _handleStartDate,
    handleEnd_date: _handleEndDate,
    start_date,
    end_date,
  } = useFilters({
    effects: bookingEffects,
    initialState: {
      start_date: toApiDate(new Date()),
      end_date: toApiDate(new Date()),
      ...(additionalFilters || {}),
    },
  });

  const handleStart_date = (date: any) => {
    _handleStartDate(toApiDate(date));
  };

  const handleEnd_date = (date: any) => {
    _handleEndDate(toApiDate(date));
  };

  const [openForm, setOpenForm] = useState(false);

  const [selectedEventId, setSelectedEventId] = useState(null);

  const [selectedRange, setSelectedRange] = useState(null);

  const selectedEvent = useSelector(() => {
    if (selectedEventId) {
      return events.find((event: any) => event.id === selectedEventId);
    }

    return null;
  });

  const picker = useDateRangePicker(
    null,
    null,
    handleStart_date,
    handleEnd_date
  );

  const [date, setDate] = useState(new Date());

  const [openFilter, setOpenFilter] = useState(false);

  const [filterEventColor, setFilterEventColor] = useState([]);

  const [view, setView] = useState(isDesktop ? "dayGridMonth" : "listWeek");

  useEffect(() => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = (calendarEl as any).getApi();

      const newView = isDesktop ? "dayGridMonth" : "listWeek";
      calendarApi.changeView(newView);
      setView(newView);
    }
  }, [isDesktop]);

  const handleOpenModal = () => {
    setOpenForm(true);
  };

  const handleCloseModal = () => {
    setOpenForm(false);
    setSelectedRange(null);
    setSelectedEventId(null);
  };

  const handleClickToday = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = (calendarEl as any).getApi();

      calendarApi.today();
      handleStart_date(calendarApi.getDate());
      handleEnd_date(calendarApi.getDate());
    }
  };

  const handleChangeView = (newView: any) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = (calendarEl as any).getApi();

      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = (calendarEl as any).getApi();

      calendarApi.prev();
      handleStart_date(calendarApi.getDate());
      handleEnd_date(calendarApi.getDate());
    }
  };

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = (calendarEl as any).getApi();

      calendarApi.next();
      handleStart_date(calendarApi.getDate());
      handleEnd_date(calendarApi.getDate());
    }
  };

  const handleSelectRange = (arg: any) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = (calendarEl as any).getApi();

      calendarApi.unselect();
    }
    handleOpenModal();
    setSelectedRange({
      // @ts-ignore
      start: arg.start,
      end: arg.end,
    });
  };

  const handleSelectEvent = (arg: any) => {
    handleOpenModal();
    setSelectedEventId(arg.event.id);
  };

  const handleResizeEvent = ({ event }: any) => {
    try {
      dispatch(
        updateEvent(event.id, {
          allDay: event.allDay,
          start: event.start,
          end: event.end,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleDropEvent = ({ event }: any) => {
    try {
      dispatch(
        updateEvent(event.id, {
          allDay: event.allDay,
          start: event.start,
          end: event.end,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateUpdateEvent = (newEvent: any) => {
    if (selectedEventId) {
      dispatch(updateEvent(selectedEventId, newEvent));
      enqueueSnackbar("Update success!");
    } else {
      dispatch(createEvent(newEvent));
      enqueueSnackbar("Create success!");
    }
  };

  const handleDeleteEvent = () => {
    try {
      if (selectedEventId) {
        handleCloseModal();
        dispatch(deleteEvent(selectedEventId));
        enqueueSnackbar("Delete success!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilterEventColor = (eventColor: any) => {
    const checked: any = filterEventColor.includes(eventColor as never)
      ? filterEventColor.filter((value) => value !== eventColor)
      : [...filterEventColor, eventColor];

    setFilterEventColor(checked);
  };

  const handleResetFilter = () => {
    const { setStartDate, setEndDate } = picker;

    if (setStartDate && setEndDate) {
      setStartDate(null);
      setEndDate(null);
    }

    setFilterEventColor([]);

    handleStart_date(new Date());
    handleEnd_date(new Date());
  };

  const dataFiltered = applyFilter({
    inputData: events,
    filterEventColor,
    filterStartDate: picker.startDate,
    filterEndDate: picker.endDate,
    isError: !!picker.isError,
  });

  return (
    <>
      {/*
        <CustomBreadcrumbs
          heading="Calendar"
          links={[
            {
              name: "Dashboard",
              href: PATH_DASHBOARD.root,
            },
            {
              name: "Calendar",
            },
          ]}
          moreLink={["https://fullcalendar.io/docs/react"]}
          action={
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={handleOpenModal}
            >
              New Event
            </Button>
          }
        />
    */}

      <Card>
        <StyledCalendar>
          <CalendarToolbar
            // @ts-ignore
            date={start_date}
            view={view}
            onNextDate={handleClickDateNext}
            onPrevDate={handleClickDatePrev}
            onToday={handleClickToday}
            onChangeView={handleChangeView}
            onOpenFilter={() => setOpenFilter(true)}
          />
          {loading && <LinearProgress />}
          <FullCalendar
            weekends
            editable
            droppable
            selectable
            rerenderDelay={10}
            allDayMaintainDuration
            eventResizableFromStart
            ref={calendarRef}
            initialDate={date}
            initialView={view}
            dayMaxEventRows={3}
            eventDisplay="block"
            events={dataFiltered}
            headerToolbar={false}
            initialEvents={events}
            select={handleSelectRange}
            eventDrop={handleDropEvent}
            eventClick={handleSelectEvent}
            eventResize={handleResizeEvent}
            height={isDesktop ? 720 : "auto"}
            plugins={[
              listPlugin,
              dayGridPlugin,
              timelinePlugin,
              timeGridPlugin,
              interactionPlugin,
            ]}
          />
        </StyledCalendar>
      </Card>

      {/*
<Dialog
        fullWidth
        maxWidth="xs"
        open={openForm}
        onClose={handleCloseModal}
      >
        <DialogTitle>{selectedEvent ? "Edit Event" : "Add Event"}</DialogTitle>

        <CalendarForm
          event={selectedEvent}
          range={selectedRange}
          onCancel={handleCloseModal}
          onCreateUpdateEvent={handleCreateUpdateEvent}
          onDeleteEvent={handleDeleteEvent}
          colorOptions={COLOR_OPTIONS}
        />
      </Dialog>
*/}

      <CalendarFilterDrawer
        events={events}
        picker={picker}
        openFilter={openFilter}
        colorOptions={COLOR_OPTIONS}
        onResetFilter={handleResetFilter}
        filterEventColor={filterEventColor}
        onCloseFilter={() => setOpenFilter(false)}
        onFilterEventColor={handleFilterEventColor}
        onSelectEvent={(eventId) => {
          if (eventId) {
            handleOpenModal();
            setSelectedEventId(eventId);
          }
        }}
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  filterEventColor,
  filterStartDate,
  filterEndDate,
  isError,
}: any) {
  const stabilizedThis = inputData.map((el: any, index: number) => [el, index]);

  inputData = stabilizedThis.map((el: any) => el[0]);

  if (filterEventColor.length) {
    inputData = inputData.filter((event: any) =>
      filterEventColor.includes(event.color)
    );
  }

  if (filterStartDate && filterEndDate && !isError) {
    inputData = inputData.filter(
      (event: any) =>
        fTimestamp(event.start) >= fTimestamp(filterStartDate) &&
        fTimestamp(event.end) <= fTimestamp(filterEndDate)
    );
  }

  return inputData;
}
