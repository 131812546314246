import { IBlogCategory } from "@/app/types";
import {
  baseCrudInitialState,
  IBaseCrudState,
} from "@/redux/base/crud-slice-helpers";
import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers, { IBlogCategoryReducer } from "./reducers";

export interface IBlogCategoryState
  extends IBaseCrudState<IBlogCategory> {}

export const blogCategoryInitialState: IBlogCategoryState =
  baseCrudInitialState;

export const slice = createSlice<
  IBlogCategoryState,
  IBlogCategoryReducer,
  typeof sliceNames.blogCategory
>({
  name: sliceNames.blogCategory,
  initialState: blogCategoryInitialState,
  reducers,
});

export default slice.reducer;
