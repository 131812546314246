import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import {
  Avatar,
  Box,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
// components
import { DEFAULT_DEBOUNCE_DURATION } from "@/app/config-global";
import CustomerService from "@/app/services/api/CustomerService";
import HairdressingSalonService from "@/app/services/api/HairdressingSalonService";
import AppAsyncAutocomplete from "@/core/components/app-async-autocomplete";
import Iconify from "@/core/components/iconify";
import SearchNotFound from "@/core/components/search-not-found";
import useTranslation from "@/core/hooks/useTranslation";

// ----------------------------------------------------------------------

ChatHeaderCompose.propTypes = {
  sx: PropTypes.object,
  contacts: PropTypes.array,
  recipients: PropTypes.array,
  onAddRecipients: PropTypes.func,
  isSalon: PropTypes.bool,
  setIsSalon: PropTypes.func,
};

export default function ChatHeaderCompose({
  contacts,
  recipients,
  onAddRecipients,
  isSalon,
  setIsSalon,
  sx,
  ...other
}) {
  const [searchRecipients, setSearchRecipients] = useState("");

  const t = useTranslation();

  const handleAddRecipients = (selectedRecipients) => {
    setSearchRecipients("");
    onAddRecipients(selectedRecipients);
  };
  console.log("le statut de recherche du salon", { isSalon });
  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      sx={{
        py: 2,
        px: 2.5,
        ...sx,
      }}
      {...other}
    >
      <Typography
        variant="subtitle2"
        sx={{ color: "text.secondary" }}
      >
        {t("chat.to")}:
      </Typography>

      <AppAsyncAutocomplete
        sx={{ minWidth: 340 }}
        // multiple
        popupIcon={null}
        noOptionsText={<SearchNotFound query={searchRecipients} />}
        onChange={(event, value) =>
          handleAddRecipients(Array.isArray(value) ? value : [value])
        }
        onInputChange={(event, value) => {
          return setSearchRecipients(value);
        }}
        options={[]}
        // optionsResetDeps={[isSalon]}
        loadOptions={async (keyword) => {
          console.log("voici le mot envoyé", { keyword });
          try {
            const res = await (isSalon
              ? HairdressingSalonService
              : CustomerService
            ).paginate({
              ...DEFAULT_DEBOUNCE_DURATION,
              keyword,
            });
            const data = await res.json();
            console.log("voici les data de retours", data.data);
            return data.data;
          } catch (e) {
            console.log({ e });
            return [];
          }
        }}
        value={recipients[0] ?? null}
        // getOptionLabel={(recipient) =>
        //   recipient?.email ??
        //   `${recipient?.firstname ?? ""} ${recipient?.lastname ?? ""}`.trim()
        // }
        getOptionLabel={(recipient) =>
          isSalon
            ? recipient.name
            : `${recipient?.profiles?.[0]?.firstname} ${recipient?.profiles?.[0]?.lastname}` ||
              recipient?.email
        }
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            placeholder={""}
          />
        )}
        renderOption={(props, recipient, { inputValue, selected }) => {
          const { email, avatar, file, profiles, user, name } = recipient;

          console.log({ inputValue });
          const matchesEmail =
            email != undefined
              ? match(email ?? "", inputValue ?? "")
              : match(user.email ?? "", inputValue ?? "");

          const matchesName =
            profiles != undefined && profiles.length > 0
              ? match(
                  (
                    profiles[0]?.firstname +
                    " " +
                    profiles[0]?.lastname
                  ).trim() ?? "",
                  inputValue ?? ""
                )
              : match((name ?? "").trim() ?? "", inputValue ?? "");

          const partsEmail =
            email != undefined
              ? parse(email ?? "", matchesEmail)
              : parse(user.email ?? "", matchesEmail);
          const partsName =
            profiles != undefined && profiles.length > 0
              ? parse(
                  (
                    profiles[0]?.firstname +
                    " " +
                    profiles[0]?.lastname
                  ).trim() ?? "",
                  matchesName
                )
              : parse((name ?? "").trim() ?? "", matchesName);

          return (
            <Box
              component="li"
              sx={{
                p: "12px !important",
              }}
              {...props}
            >
              <Box
                sx={{
                  mr: 1.5,
                  width: 32,
                  height: 32,
                  overflow: "hidden",
                  borderRadius: "50%",
                  position: "relative",
                  flexShrink: 0,
                }}
              >
                <Avatar
                  alt={email}
                  src={(avatar ?? file)?.url}
                  sx={{ height: "100%", width: "100%" }}
                />
                <Box
                  sx={{
                    top: 0,
                    opacity: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    position: "absolute",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                    transition: (theme) =>
                      theme.transitions.create("opacity", {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shorter,
                      }),
                    ...(selected && {
                      opacity: 1,
                      color: "primary.main",
                    }),
                  }}
                >
                  <Iconify icon="eva:checkmark-fill" />
                </Box>
              </Box>

              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"start"}
              >
                <Box
                  display={"flex"}
                  justifyContent={"start"}
                >
                  {partsEmail.map((part, index) => (
                    <Typography
                      key={`email-${index}`}
                      variant="subtitle2"
                      color={part.highlight ? "primary" : "textPrimary"}
                    >
                      {part.text}
                    </Typography>
                  ))}
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"start"}
                >
                  {partsName.map((part, index) => (
                    <Typography
                      key={`name-${index}`}
                      variant="subtitle2"
                      color={part.highlight ? "primary" : "textPrimary"}
                    >
                      {part.text}
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Box>
          );
        }}

        // renderTags={(recipient, getTagProps) => (
        //   <Chip
        //     {...getTagProps({ index })}
        //     key={recipient.public_id}
        //     size="small"
        //     label={recipient.firstname}
        //     avatar={
        //       <Avatar
        //         alt={recipient.firstname}
        //         src={recipient.avatar?.url}
        //       />
        //     }
        //   />
        // )}
      />
      <FormControlLabel
        label={t("label.salon")}
        labelPlacement="end"
        control={
          <Switch
            placeholder={t("label.salon")}
            title={t("label.salon")}
            checked={isSalon}
            onChange={(e) => {
              setIsSalon((state) => !state);
              handleAddRecipients([]);
            }}
          />
        }
      />
    </Stack>
  );
}
