import {
  ACCEPTED_FILE_TYPES,
  ACCEPTED_IMAGES,
  ACCEPTED_VIDEOS,
} from "@/app/config-global";
import FileService from "@/app/services/api/FileService";
import AppAsyncAutocomplete from "@/core/components/app-async-autocomplete";
import Editor from "@/core/components/editor";
import ScrollTo from "@/core/components/scroll-to";
import SearchNotFound from "@/core/components/search-not-found/SearchNotFound";
import { Upload } from "@/core/components/upload";
import useToast from "@/core/hooks/useToast";
import useTranslation from "@/core/hooks/useTranslation";
import toBase64 from "@/core/utils/toBase64";
import { slice } from "@/redux/slices/autoForm";
import { useDispatch, useSelector } from "@/redux/store";
import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import _ from "lodash";
import React from "react";
import * as Yup from "yup";
import AppTextField from "../AppTextField";
import PlanningSelect from "../PlanningSelect";
import {
  formatValuesWithDoubleUnderscores,
  getInitialItemWithDoubleUnderscore,
} from "./utils";

interface IFieldData<T> {
  initialValue: string | ((initialItem: T) => any | undefined);
  initialImage?: string | ((initialItem: T) => any | undefined);
  label: string;
  placeholder: string;
  title?: string;
  props?: Record<string, any>;
  validation: any | ((initialItem: T) => any);
  type?: "autocomplete" | string;
  getOptionLabel?: (value: any) => any;
  getOptionValue?: (value: any) => any;
  checkFn?: (value: any) => any;
  ignoreItem?: (option: any, selectedItems: any[]) => boolean;
  getOptions?: (initialValue?: any) => any[];
  single?: boolean;
  collection?: string;
  multiple?: boolean;
  freeSolo?: boolean;
  loadOptions?: (value: any) => Promise<any>;
  renderOptions?: (props: any, opt: any, { inputValue, selected }: any) => any;
  initialOptions?: (row: T) => any;
  hide?: boolean;
  hidden?: (initialsValues: any, formikValues?: any) => boolean;
  onChangeCallback?: (formik: any, brutData?: any) => void;
  initialArray?: { label: string; value: string }[];
  switchValues?: [string | number | boolean, string | number | boolean];
  accept?: string[];
  fileType?:
    | typeof ACCEPTED_FILE_TYPES.image
    | typeof ACCEPTED_FILE_TYPES.video;
}

export type IField<T = any> = ((formik?: any) => IFieldData<T>) | IFieldData<T>;

export type IAutoFormFields<T = any> = Record<string, IField<T>>;

interface Props {
  formikRef?: any;
  ref?: any;
  hideButton?: boolean;
  initialItem?: any;
  fields: IAutoFormFields;
  onSubmit: (values: any) => void;
  setLoading?: (loading: boolean) => void;
  resetValuesDeps?: any[];
  noValuesHolder?: boolean;
  props?: {
    cols?: number;
    gapX?: number;
    gapY?: number;
  };
}

const AutoForm: React.FC<Props> = React.forwardRef(
  (
    {
      formikRef,
      initialItem,
      fields,
      props,
      onSubmit,
      hideButton,
      setLoading,
      noValuesHolder,
    },
    ref
  ) => {
    // Hooks
    const toast = useToast();
    const dispatch = useDispatch();

    // Store
    const autoFormState = useSelector((state) => state.autoForm);
    const valuesHolder = autoFormState.valuesToHold;

    // Functions
    const setValuesToHold = (values: any) =>
      dispatch(slice.actions.setValuesToHold(values));
    const initFormik = React.useCallback(() => {
      const initialValues: { [Property in keyof typeof fields]: any } = {};
      const validations: any = {};
      for (let x = 0; x < Object.keys(fields).length; x++) {
        const fieldName: string = Object.keys(fields)[x];
        const field =
          fields[fieldName] instanceof Function
            ? // @ts-ignore
              fields[fieldName]()
            : fields[fieldName];
        if (!(field.hidden && field.hidden(initialItem, null))) {
          if (typeof field?.validation !== typeof undefined) {
            initialValues[fieldName] =
              field.initialValue instanceof Function
                ? (field.initialValue as Function)(initialItem)
                : !fieldName.includes("__")
                ? field.initialValue ?? ""
                : initialItem
                ? getInitialItemWithDoubleUnderscore(initialItem, fieldName) ??
                  ""
                : "";
            if (field.initialOptions) {
              field.initialOptions =
                field.initialOptions instanceof Function
                  ? (field.initialOptions as Function)(initialItem)
                  : !fieldName.includes("__")
                  ? field.initialOptions
                  : initialItem
                  ? getInitialItemWithDoubleUnderscore(
                      initialItem,
                      fieldName
                    ) ?? ""
                  : "";
            }
            validations[fieldName] =
              field.validation instanceof Function
                ? field.validation(initialItem)
                : field.validation;
          }
        }
      }

      return { initialValues, validationSchema: Yup.object(validations) };
    }, []);

    const formikObj = initFormik();

    // Hooks
    const t = useTranslation();
    const formik = useFormik({
      initialValues: formikObj.initialValues,
      validationSchema: formikObj.validationSchema,
      enableReinitialize: true,
      onSubmit: async (values: any) => {
        setLoading && setLoading(true);
        if (!noValuesHolder) {
          setValuesToHold(_.cloneDeep(values));
        }

        // Store files
        for (let x = 0; x < Object.keys(fields).length; x++) {
          const fieldName = Object.keys(fields)[x];
          const field: any =
            fields[fieldName] instanceof Function
              ? (fields[fieldName] as Function)()
              : fields[fieldName];
          if (["uploader", "uploader-multiple"].includes(field.type)) {
            if (
              !values[fieldName] ||
              (typeof values[fieldName] === "string" &&
                values[fieldName].length <= 0) ||
              (Array.isArray(values[fieldName]) &&
                values[fieldName].length <= 0)
            ) {
              continue;
            }
            const b64List =
              field.type === "uploader"
                ? values[fieldName]
                  ? [values[fieldName]]
                  : []
                : values[fieldName];
            const uuidList: string[] = [];
            for (let y = 0; y < b64List.length; y++) {
              const b64 = b64List[y];
              // TODO: Improve this check
              if (b64?.includes(";base64,")) {
                try {
                  const res: any = await FileService.storeFile({
                    file_name:
                      String(new Date().getTime()) +
                      "." +
                      String(
                        b64.split(";")[0].split("/")[1]
                      ).toLocaleLowerCase(),
                    base_64: b64,
                  });
                  const data = await res.json();
                  uuidList.push(data.uuid);
                } catch (error) {
                  toast.error(
                    t("error.file_upload_error", { field: field.label ?? "" })
                  );
                }
              } else {
                uuidList.push(b64);
              }
            }
            values[fieldName] =
              field.type === "uploader" ? uuidList[0] ?? "" : uuidList;
          }
        }

        onSubmit(formatValuesWithDoubleUnderscores(values));
      },
    });

    // Functions
    const resolveFilePreview = (formik: any, field: any, fieldName: any) => {
      return !String((formik.values as any)[fieldName]).includes(";base64,")
        ? field.initialImage instanceof Function
          ? field.initialImage(initialItem)
          : field.initialImage
        : (formik.values as any)[fieldName];
    };

    const [file, setFile] = React.useState("");

    React.useEffect(() => {
      setFile;
    }, [formik.values]);

    // Effects
    React.useEffect(() => {
      if (formikRef) {
        formikRef.current = formik;
      }
      console.log(formik.values);
    }, [formik.values]);

    React.useEffect(() => {
      if (valuesHolder && !noValuesHolder) {
        formik.setValues(_.cloneDeep(valuesHolder));
        console.log({ valuesHolder });
      }
    }, [formik.isSubmitting]);
    return (
      <form
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}
      >
        <Stack>
          <Grid
            container
            columns={props?.cols}
            gap={2}
            sx={{ my: 2 }}
          >
            {Object.keys(fields)
              .filter((k) => !!fields[k])
              .map((fieldName) => {
                const field =
                  fields[fieldName] instanceof Function
                    ? // @ts-ignore
                      fields[fieldName](formik)
                    : fields[fieldName];
                const isInvalid =
                  !!(formik.errors as any)[fieldName] &&
                  !!(formik.touched as any)[fieldName];
                return (
                  field &&
                  !field.hide &&
                  !(
                    field.hidden && field.hidden(initialItem, formik?.values)
                  ) && (
                    <Grid
                      item
                      xs={12}
                      key={fieldName}
                    >
                      {field.type === "autocomplete" ? (
                        <AppAsyncAutocomplete
                          multiple={field.multiple}
                          freeSolo={field.freeSolo}
                          sx={{ minWidth: 240 }}
                          popupIcon={null}
                          noOptionsText={
                            <SearchNotFound
                              query={t(field.label)}
                              sx={{}}
                            />
                          }
                          onChange={(_event, value) => {
                            if (!field.multiple) {
                              field.initialOptions = Array.isArray(value)
                                ? value
                                : [value];
                            }
                            formik.setFieldValue(
                              fieldName,
                              Array.isArray(value)
                                ? value.map((val) => field.getOptionValue(val))
                                : field.getOptionValue(value)
                            );
                          }}
                          value={
                            field.multiple
                              ? !field.freeSolo
                                ? (
                                    field.getOptions(field.initialOptions) ?? []
                                  ).filter((option: any) =>
                                    Array.isArray(formik.values[fieldName])
                                      ? formik.values[fieldName].includes(
                                          field.getOptionValue(option)
                                        )
                                      : false
                                  )
                                : formik.values[fieldName] ?? []
                              : (Array.isArray(field.initialOptions)
                                  ? field.initialOptions
                                  : field.getOptions(field.initialOptions) ?? []
                                ).find(
                                  (option: any) =>
                                    field.getOptionValue(option) ===
                                    formik.values[fieldName]
                                ) ?? null
                          }
                          onInputChange={(_event: any, value: any) =>
                            field.getOptions
                              ? field
                                  .getOptions(field.initialOptions)
                                  .filter((opt: any) =>
                                    t(field.getOptionLabel(opt))
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  )
                              : []
                          }
                          options={
                            field.getOptions
                              ? field.getOptions(field.initialOptions)
                              : []
                          }
                          loadOptions={field.loadOptions}
                          getOptionLabel={field.getOptionLabel}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              placeholder={t(field.placeholder)}
                            />
                          )}
                          renderOption={
                            field.renderOptions ??
                            ((
                              props: any,
                              opt: any,
                              { inputValue, selected }: any
                            ) => (
                              // @ts-ignore
                              <Box
                                {...(props || {})}
                                sx={{ p: 1 }}
                              >
                                {field.getOptionLabel(opt)}
                              </Box>
                            ))
                          }
                          renderTags={(
                            selectedRecipients: any,
                            getTagProps: any
                          ) =>
                            selectedRecipients.map(
                              (recipient: any, index: number) => (
                                <Chip
                                  {...getTagProps({ index })}
                                  key={field.getOptionValue(recipient)}
                                  size="small"
                                  label={field.getOptionLabel(recipient)}
                                  avatar={<></>}
                                />
                              )
                            )
                          }
                        />
                      ) : field.type === "uploader-multiple" ? (
                        <Box>
                          <Upload
                            accept={
                              field.accept ??
                              (field.fileType
                                ? field.fileType === ACCEPTED_FILE_TYPES.video
                                  ? ACCEPTED_VIDEOS
                                  : field.fileType === ACCEPTED_FILE_TYPES.image
                                  ? ACCEPTED_IMAGES
                                  : undefined
                                : undefined)
                            }
                            thumbnail
                            files={(
                              (formik.values as any)[fieldName] ?? []
                            ).map((val: string) =>
                              val.includes(";base64,")
                                ? val
                                : (field.initialOptions ?? []).find(
                                    (opt: any) => opt.uuid === val
                                  )?.thumbnail?.url ??
                                  (field.initialOptions ?? []).find(
                                    (opt: any) => opt.uuid === val
                                  )?.url ??
                                  ""
                            )}
                            onRemoveAll={() =>
                              formik.setFieldValue(fieldName, [])
                            }
                            onRemove={(_: any, index: number) =>
                              formik.setFieldValue(
                                fieldName,
                                (
                                  (formik.values as any)[fieldName] ?? []
                                ).filter((_: any, i: number) => index !== i)
                              )
                            }
                            multiple
                            label={t(field.label)}
                            error={!!isInvalid}
                            onDrop={(data: any[]) => {
                              data.map((data) => {
                                toBase64(data).then((base64) => {
                                  formik.setFieldValue(fieldName, [
                                    ...(formik.values[fieldName] ?? []),
                                    base64,
                                  ]);
                                });
                              });
                            }}
                            {...(field.props || {})}
                          />
                        </Box>
                      ) : field.type === "uploader" ? (
                        <Upload
                          accept={
                            field.accept ??
                            (field.fileType
                              ? field.fileType === ACCEPTED_FILE_TYPES.video
                                ? ACCEPTED_VIDEOS
                                : field.fileType === ACCEPTED_FILE_TYPES.image
                                ? ACCEPTED_IMAGES
                                : undefined
                              : undefined)
                          }
                          // file={field.initialFile}
                          file={resolveFilePreview(formik, field, fieldName)}
                          label={t(field.label)}
                          error={!!isInvalid}
                          onDrop={(data: any) => {
                            toBase64(data[0]).then((base64) => {
                              formik.setFieldValue(fieldName, base64);
                              field.onChangeCallback &&
                                field.onChangeCallback(formik, data);
                            });
                          }}
                          {...(field.props || {})}
                          onDelete={() => {
                            field.initialImage = "";
                            formik.setFieldValue(fieldName, "");
                          }}
                        />
                      ) : field.type === "editor" ? (
                        <Editor
                          key={fieldName}
                          name={fieldName}
                          id={fieldName}
                          value={(formik.values as any)[fieldName]}
                          onChange={(value: string) => {
                            formik.setFieldValue(fieldName, value);
                          }}
                          // onBlur={formik.handleBlur}
                          placeholder={t(field.placeholder)}
                          label={t(field.label)}
                          error={!!isInvalid}
                          {...(field.props || {})}
                        />
                      ) : field.type === "switch" ? (
                        <FormControlLabel
                          label={t(field.label)}
                          labelPlacement="end"
                          control={
                            <Switch
                              key={fieldName}
                              name={fieldName}
                              placeholder={t(field.placeholder)}
                              title={t(field.title)}
                              checked={
                                field.checkFn &&
                                field.checkFn(formik.values[fieldName])
                              }
                              onChange={(e) =>
                                formik.setFieldValue(
                                  fieldName,
                                  (formik.values as any)[fieldName] ===
                                    field.switchValues[0]
                                    ? field.switchValues[1]
                                    : field.switchValues[0]
                                )
                              }
                            />
                          }
                        />
                      ) : field.type === "select" ? (
                        <TextField
                          key={fieldName}
                          name={fieldName}
                          id={fieldName}
                          select
                          fullWidth
                          label={t(field.label)}
                          placeholder={t(field.placeholder)}
                          title={t(field.title)}
                          value={(formik.values as any)[fieldName] ?? ""}
                          defaultValue={(formik.values as any)[fieldName] ?? ""}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                sx: {
                                  maxHeight: 260,
                                },
                              },
                            },
                          }}
                          sx={{
                            textTransform: "capitalize",
                          }}
                        >
                          {field.getOptions &&
                            field
                              .getOptions(field.initialOption)
                              ?.map((option: any) => (
                                <MenuItem
                                  key={fieldName + field.getOptionValue(option)}
                                  selected={
                                    (formik.values as any)[fieldName]
                                      ? String(
                                          (formik.values as any)[fieldName]
                                        ) ===
                                        String(field.getOptionValue(option))
                                      : false
                                  }
                                  value={field.getOptionValue(option)}
                                  sx={{
                                    mx: 1,
                                    borderRadius: 0.75,
                                    typography: "body2",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {field.getOptionLabel(option)}
                                </MenuItem>
                              ))}
                        </TextField>
                      ) : field.type === "planning" ? (
                        <PlanningSelect
                          label={t(field.label)}
                          // placeholder={t(field.placeholder)}
                          // title={t(field.title)}
                          onChange={(value) => {
                            formik.setFieldValue(fieldName, value);
                          }}
                          // onBlur={formik.handleBlur}
                          // invalid={isInvalid}
                          value={(formik.values as any)[fieldName]}
                          // type={field.type}
                          {...(field.props || {})}
                        />
                      ) : field.type === "textarea" ? (
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          maxRows={Infinity}
                          name={fieldName}
                          id={fieldName}
                          label={t(field.label)}
                          placeholder={t(field.placeholder)}
                          title={t(field.title)}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={isInvalid}
                          value={(formik.values as any)[fieldName]}
                          type={field.type}
                          {...(field.props || {})}
                        />
                      ) : (
                        <AppTextField
                          fullWidth
                          name={fieldName}
                          id={fieldName}
                          label={t(field.label)}
                          placeholder={t(field.placeholder)}
                          title={t(field.title)}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          // invalid={isInvalid}
                          value={(formik.values as any)[fieldName]}
                          type={field.type}
                        />
                      )}
                      {isInvalid && (
                        <ScrollTo>
                          <Typography
                            variant="caption"
                            color="error"
                          >
                            {t((formik.errors as any)[fieldName] as string)}
                          </Typography>
                        </ScrollTo>
                      )}
                    </Grid>
                  )
                );
              })}
          </Grid>
          {hideButton ? (
            <button
              type="submit"
              //@ts-ignore
              ref={ref}
              style={{ display: "none" }}
            />
          ) : (
            <Button
              type="submit"
              color="primary"
            >
              Soumettre
            </Button>
          )}
        </Stack>
      </form>
    );
  }
);

export default AutoForm;
