import {
  IBlogCategoryCreate,
  IBlogCategoryRange,
  IBlogCategoryUpdate,
} from "@/app/types";
import BaseService from "./BaseService";
import { BlogCategoryUrls } from "./urls";

class BlogCategoryService {
  static get = (public_id: string) => {
    return BaseService.getRequest(BlogCategoryUrls.GET(public_id), true);
  };
  static paginate = (query: IBlogCategoryRange) => {
    return BaseService.getRequest(BlogCategoryUrls.PAGINATE(query), true);
  };
  static create = (data: IBlogCategoryCreate) => {
    return BaseService.postRequest(BlogCategoryUrls.CREATE, data, true);
  };
  static update = ({ uuid, ...rest }: IBlogCategoryUpdate) => {
    return BaseService.putRequest(BlogCategoryUrls.UPDATE(uuid), rest, true);
  };
  static delete = (uuid: string) => {
    return BaseService.deleteRequest(
      BlogCategoryUrls.DELETE(uuid),
      {},
      true
    );
  };
}

export default BlogCategoryService;
