import { IChatBot } from "@/app/types";
import {
  baseCrudInitialState,
  IBaseCrudState,
} from "@/redux/base/crud-slice-helpers";
import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers, { IChatBotReducer } from "./reducers";

export interface IChatBotState extends IBaseCrudState<IChatBot> {}

export const chatBotInitialState: IChatBotState = baseCrudInitialState;

export const slice = createSlice<
  IChatBotState,
  IChatBotReducer,
  typeof sliceNames.chatBot
>({
  name: sliceNames.chatBot,
  initialState: chatBotInitialState,
  reducers,
});

export default slice.reducer;
