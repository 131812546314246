import PropTypes from "prop-types";
// @mui
import { Card, Divider, Stack, Typography } from "@mui/material";
// utils
import useTranslation from "@/core/hooks/useTranslation";
import { fNumber } from "@/core/utils/formatNumber";

// ----------------------------------------------------------------------

ProfileFollowInfo.propTypes = {
  follower: PropTypes.number,
  following: PropTypes.number,
};

export default function ProfileFollowInfo({ follower, following }) {
  const t = useTranslation();
  return (
    <Card sx={{ py: 3 }}>
      <Stack
        direction="row"
        divider={
          <Divider
            orientation="vertical"
            flexItem
          />
        }
      >
        <Stack
          width={1}
          textAlign="center"
        >
          <Typography variant="h4">{fNumber(follower)}</Typography>

          <Typography
            variant="body2"
            sx={{ color: "text.secondary" }}
          >
            {t("label.popularity_index")}
          </Typography>
        </Stack>

        {/* <Stack
          width={1}
          textAlign="center"
        >
          <Typography variant="h4">{fNumber(following)}</Typography>

          <Typography
            variant="body2"
            sx={{ color: "text.secondary" }}
          >
            Following
          </Typography>
        </Stack> */}
      </Stack>
    </Card>
  );
}
