import { IProductCreate, IProductRange, IProductUpdate } from "@/app/types";
import BaseService from "./BaseService";
import { ProductUrls } from "./urls";

class ProductService {
  static get = (uuid: string) => {
    return BaseService.getRequest(ProductUrls.GET(uuid), true);
  };
  static paginate = (query: IProductRange) => {
    return BaseService.getRequest(ProductUrls.PAGINATE(query), true);
  };
  static create = (data: IProductCreate) => {
    return BaseService.postRequest(ProductUrls.CREATE, data, true);
  };
  static update = ({ uuid, ...rest }: IProductUpdate) => {
    return BaseService.putRequest(ProductUrls.UPDATE(uuid), rest, true);
  };
  static delete = (uuid: string) => {
    return BaseService.deleteRequest(ProductUrls.DELETE, [uuid], true);
  };
}

export default ProductService;
