import { styled, Theme } from "@mui/material";
import { FC } from "react";
import { ReactSVG, Props as ReactSVGProps } from "react-svg";
// @ts-ignore
interface IconProps extends ReactSVGProps {
  fill?: string | ((theme: Theme) => string);
  stroke?: string | ((theme: Theme) => string);
  fillAndStroke?: string | ((theme: Theme) => string);
  noStroke?: Boolean;
  hoverStroke?: string | ((theme: Theme) => string);
  hoverFill?: string | ((theme: Theme) => string);
  hoverFillAndStroke?: string | ((theme: Theme) => string);
}
export const RootIcon = styled(ReactSVG)({});
const IconSvg: FC<IconProps> = ({
  fill,
  stroke,
  color,
  fillAndStroke,
  noStroke,
  hoverStroke,
  hoverFill,
  hoverFillAndStroke,
  ...props
}) => {
  return (
    <RootIcon
      {...props}
      sx={(theme: Theme) => ({
        ...(fill
          ? {
              fill: typeof fill === "function" ? fill?.(theme) : fill,
              path: {
                fill: typeof fill === "function" ? fill?.(theme) : fill,
              },
            }
          : {}),
        ...(stroke
          ? {
              stroke: typeof stroke === "function" ? stroke?.(theme) : stroke,
              path: {
                stroke: typeof stroke === "function" ? stroke?.(theme) : stroke,
              },
            }
          : {
              stroke:
                theme.palette.mode === "light"
                  ? noStroke
                    ? "white"
                    : "#001F27"
                  : "white",
              path: {
                stroke: theme.palette.mode === "light" ? "#001F27" : "white",
              },
            }),
        ...(fillAndStroke
          ? {
              stroke:
                typeof fillAndStroke === "function"
                  ? fillAndStroke?.(theme)
                  : fillAndStroke,
              fill:
                typeof fillAndStroke === "function"
                  ? fillAndStroke?.(theme)
                  : fillAndStroke,
              path: {
                stroke:
                  typeof fillAndStroke === "function"
                    ? fillAndStroke?.(theme)
                    : fillAndStroke,
                fill:
                  typeof fillAndStroke === "function"
                    ? fillAndStroke?.(theme)
                    : fillAndStroke,
              },
            }
          : {}),
        ...(hoverFill
          ? {
              "&:hover": {
                fill:
                  typeof hoverFill === "function"
                    ? hoverFill?.(theme)
                    : hoverFill,
                path: {
                  fill:
                    typeof hoverFill === "function"
                      ? hoverFill?.(theme)
                      : hoverFill,
                },
              },
            }
          : {}),
        ...(hoverStroke
          ? {
              "&:hover": {
                stroke:
                  typeof hoverStroke === "function"
                    ? hoverStroke?.(theme)
                    : hoverStroke,
                path: {
                  stroke:
                    typeof hoverStroke === "function"
                      ? hoverStroke?.(theme)
                      : hoverStroke,
                },
              },
            }
          : {}),
        ...(hoverFillAndStroke
          ? {
              "&:hover": {
                stroke:
                  typeof hoverFillAndStroke === "function"
                    ? hoverFillAndStroke?.(theme)
                    : hoverFillAndStroke,
                fill:
                  typeof hoverFillAndStroke === "function"
                    ? hoverFillAndStroke?.(theme)
                    : hoverFillAndStroke,
                path: {
                  stroke:
                    typeof hoverFillAndStroke === "function"
                      ? hoverFillAndStroke?.(theme)
                      : hoverFillAndStroke,
                  fill:
                    typeof hoverFillAndStroke === "function"
                      ? hoverFillAndStroke?.(theme)
                      : hoverFillAndStroke,
                },
              },
            }
          : {}),
        ...(color && color != "none"
          ? {
              "& path": {
                fill: color,
                stroke: color,
              },
            }
          : {}),
      })}
    />
  );
};
export default IconSvg;
