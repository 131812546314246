import BlogService from "@/app/services/api/BlogService";
import { IBlogCreate, IBlogRange, IBlogUpdate } from "@/app/types";
import {
  IReduxBaseCrudEffect,
  ISimpleReduxCallback,
} from "@/app/types/BaseReduxTypes";
import BaseReduxCrudEffects from "@/redux/base/crud-slice-helpers/effects";
import { execute } from "@/redux/common/functions";
import { ThunkAction } from "redux-thunk";
import { slice } from ".";

export interface IBlogEffects
  extends IReduxBaseCrudEffect<
    IBlogRange,
    IBlogCreate,
    IBlogUpdate,
    BlogService
  > {
  publish: (
    blogId: string,
    callback: ISimpleReduxCallback
  ) => ThunkAction<any, any, any, any>;
  addOrRemoveFavorite: (
    blogId: string,
    callback: ISimpleReduxCallback
  ) => ThunkAction<any, any, any, any>;
  addOrRemoveInTheNews: (
    blogId: string,
    callback: ISimpleReduxCallback
  ) => ThunkAction<any, any, any, any>;
  addOrRemoveInRecentArticle: (
    blogId: string,
    callback: ISimpleReduxCallback
  ) => ThunkAction<any, any, any, any>;
  listHome: (query: IBlogRange) => ThunkAction<any, any, any, any>;
  listByCategory: (query: IBlogRange) => ThunkAction<any, any, any, any>;
  listFavorites: (query: IBlogRange) => ThunkAction<any, any, any, any>;
}

class BlogEffects
  extends BaseReduxCrudEffects<IBlogRange, IBlogCreate, IBlogUpdate, typeof BlogService>
  implements IBlogEffects
{
  publish = (blogId: string, callback: ISimpleReduxCallback) =>
    execute({
      slice: this.slice,
      inputProps: blogId,
      serviceFn: this.ResourceService.publish,
      actionToDispatch: this.slice.actions.publish,
      callback,
    });
  addOrRemoveFavorite = (blogId: string, callback: ISimpleReduxCallback) =>
    execute({
      slice: this.slice,
      inputProps: blogId,
      serviceFn: this.ResourceService.get,
      actionToDispatch: this.slice.actions.get,
      callback,
    });
  addOrRemoveInTheNews = (blogId: string, callback: ISimpleReduxCallback) =>
    execute({
      slice: this.slice,
      inputProps: blogId,
      serviceFn: this.ResourceService.addOrRemoveInTheNews,
      actionToDispatch: this.slice.actions.addOrRemoveInRecentArticle,
      callback,
    });
  addOrRemoveInRecentArticle = (blogId: string, callback: ISimpleReduxCallback) =>
    execute({
      slice: this.slice,
      inputProps: blogId,
      serviceFn: this.ResourceService.addOrRemoveInRecentArticle,
      actionToDispatch: this.slice.actions.addOrRemoveInRecentArticle,
      callback,
    });
  listHome = (query: IBlogRange) =>
    execute({
      slice: this.slice,
      inputProps: query,
      serviceFn: this.ResourceService.get,
      actionToDispatch: this.slice.actions.get,
    });
  listByCategory = (query: IBlogRange) =>
    execute({
      slice: this.slice,
      inputProps: query,
      serviceFn: this.ResourceService.get,
      actionToDispatch: this.slice.actions.get,
    });
  listFavorites = (query: IBlogRange) =>
    execute({
      slice: this.slice,
      inputProps: query,
      serviceFn: this.ResourceService.get,
      actionToDispatch: this.slice.actions.get,
    });
}

const blogEffects = new BlogEffects(slice, BlogService);

export default blogEffects;
