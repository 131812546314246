import moment from "moment";
import PropTypes from "prop-types";
// @mui
import {
  Badge,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
// components
import { useAuthContext } from "@/app/auth/useAuthContext";
import BadgeStatus from "@/core/components/badge-status";
import {
  CustomAvatar,
  CustomAvatarGroup,
} from "@/core/components/custom-avatar";
import useTranslation from "@/core/hooks/useTranslation";
import { useSelector } from "@/redux/store";

// ----------------------------------------------------------------------

ChatNavItem.propTypes = {
  openNav: PropTypes.bool,
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool,
  conversation: PropTypes.object,
};

export default function ChatNavItem({
  conversation,
  openNav,
  isSelected,
  onSelect,
}) {
  const {
    chat: { messages },
  } = useSelector((state) => state);
  const t = useTranslation();
  const { user } = useAuthContext();
  const CURRENT_USER_ID = user?.public_id ?? "";

  const getDetails = (conversation, currentUserId, messages) => {
    const otherParticipants = [conversation.receiver];

    const displayNames = otherParticipants
      .map((participant) => participant.firstname + " " + participant.lastname)
      .join(", ");

    let displayText = "";

    const lastMessage = conversation?.last_message ?? "";
    if (lastMessage) {
      const sender =
        conversation.last_sender_id === currentUserId
          ? `${t("chat.you")}: `
          : "";

      const message = lastMessage;

      displayText = `${sender}${message}`;
    }
    return { otherParticipants, displayNames, displayText };
  };

  const details = getDetails(conversation, CURRENT_USER_ID, messages);

  const lastActivity = conversation.last_sending_date;

  const isGroup = details.otherParticipants.length > 1;

  const isUnread = conversation.unreadCount > 0;

  const hasOnlineInGroup =
    isGroup &&
    details.otherParticipants.map((item) => item.status).includes("online");

  return (
    <ListItemButton
      disableGutters
      onClick={onSelect}
      sx={{
        py: 1.5,
        px: 2.5,
        ...(isSelected && {
          bgcolor: "action.selected",
        }),
      }}
    >
      <ListItemAvatar>
        {isGroup ? (
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={hasOnlineInGroup && <BadgeStatus status="online" />}
          >
            <CustomAvatarGroup
              compact
              sx={{ width: 48, height: 48 }}
            >
              {details.otherParticipants.slice(0, 2).map((participant) => (
                <CustomAvatar
                  key={participant.id}
                  alt={participant.name}
                  src={participant.avatar}
                />
              ))}
            </CustomAvatarGroup>
          </Badge>
        ) : (
          <CustomAvatar
            key={details.otherParticipants[0].public_id}
            alt={details.otherParticipants[0].firstname}
            src={details.otherParticipants[0].avatar?.url}
            BadgeProps={{
              badgeContent: (
                <BadgeStatus status={details.otherParticipants[0].status} />
              ),
            }}
            sx={{ width: 48, height: 48 }}
          />
        )}
      </ListItemAvatar>

      {openNav && (
        <>
          <ListItemText
            primary={details.displayNames}
            primaryTypographyProps={{ noWrap: true, variant: "subtitle2" }}
            secondary={details.displayText}
            secondaryTypographyProps={{
              noWrap: true,
              variant: isUnread ? "subtitle2" : "body2",
              color: isUnread ? "text.primary" : "text.secondary",
            }}
          />

          <Stack
            alignItems="flex-end"
            sx={{ ml: 2, height: 44 }}
          >
            <Typography
              noWrap
              variant="body2"
              component="span"
              sx={{
                mb: 1.5,
                fontSize: 12,
                color: "text.disabled",
              }}
            >
              {lastActivity ? moment(lastActivity).fromNow() : ""}
            </Typography>

            {isUnread && (
              <BadgeStatus
                status="unread"
                size="small"
              />
            )}
          </Stack>
        </>
      )}
    </ListItemButton>
  );
}
