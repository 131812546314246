const placeholder = {
  title: "Titre",
  status_admin: "Activer/Désactiver",
  search: "Recherche...",
  first_name: "Prénom",
  last_name: "Nom",
  email: "Adresse email",
  phone_number: "Numéro de téléphone",
  role: "Role",
  job: "Travail",
  bio: "Bio",
  image: "Image",
  write_something: "Ecrivez quelque chose d'exceptionnel...",
  meta_title: "Meta titre",
  meta_description: "Meta description",
  meta_keywords: "Meta mots-clés",
  drop_or_select_file:
    "Déposez un fichier ou cliquez-ici et sélectionnez un fichier",
  category: "Catégorie",
  name: "Nom",
  name_en: "Nom en anglais",
  name_fr: "Nom en français",
  brand: "Marque",
  images: "Images",
  description: "Description",
  description_en: "Description en anglais",
  sku: "SKU",
  real_price: "Prix réel",
  current_price: "Prix actuel",
  qty: "Quantité",
  hair_style: "Style de cheveux",
  benefits: "Bénéfices",
  weight: "Poids",
  is_online: "Mettre en ligne",
  sub_category: "Sous-catégorie",
  video_link: "Lien de la vidéo",
  image_link: "Lien de l'image",
  prestation: "Prestation",
  prestations: "Prestations",
  begin_duration: "Début",
  content: "Ecrivez quelque chose de merveilleux...",
  video: "Vidéo",
  video_duration: "Durée de la vidéo",
  tags: "Tags",
  hairdressing_salon: "Salon",
  hairdresser_salon_name: "Nom du salon",
  country_code: "Code du pays",
  entreprise_name: "Nom de l'entreprise",
  entreprise_phonenumber: "Numéro de téléphone de l'entreprise",
  website: "Site web",
  read_duration: "Durée de lecture (minutes)",
  hair_types: "Types de cheuveux",
  people_type_ids: "Types de personne",
  shop_onligne: "Visible en ligne",
  headdress_at_home: "Coiffure à domicile",
  postal_code: "Code postal",
  province: "Région",
  town: "Ville",
  country: "Pays",
  address_one: "Adresse principale",
  address_two: "Adresse secondaire",
  accept_click_n_collect: "Click N Collect",
  manager_hairdressing_email: "Adresse email du gérant du salon",
  accepted_files: "Fichiers acceptés",
  browse: "Importer",
  password: "Mot de passe",
  publish: "Publié",
  title_fr: "Titre en français",
  title_en: "Title en anglais",
  description_fr: "Description en français",
  product_hot_point: "Points saillants",
  special_price: "Prix spécial",
  stock: "Stock",
  sold: "Vendu",
  hair_type: "Type de cheveux",
  price: "Prix",
  french: "Français",
  english: "Anglais",
  is_kid_video: "Est-ce un dessin animé ?",
  is_kid: "Est-ce une vidéo pour enfant ?",
  fonction_auteur: "Fonction de l'auteur",
  prenom_auteur: "Prénom de l'auteur",
  nom_auteur: "Nom de l'auteur",
  avatar_auteur: "Photo de profil de l'auteur",
};

export default placeholder;
