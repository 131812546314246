/* eslint-disable max-len */
import Label from "@/core/components/label";
import clsx from "clsx";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { IApplicationState, Intent } from "@/app/types";
import {
  Box,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";

import getInitials from "@/core/utils/getInitials";
import { OrderView } from "./OrderDetail";

import { DEFAULT_RANGE } from "@/app/config-global";
import { useLocales } from "@/app/locales";
import { CustomAvatar } from "@/core/components/custom-avatar";
import { TableNoData, TableSkeleton } from "@/core/components/table";
import useTranslation from "@/core/hooks/useTranslation";
import ErrorHandler from "@/presentation/components/ErrorHandler";

const sortOptionsFr = [
  {
    value: "Aucun filtre",
    label: "Aucun filtre",
  },
  {
    value: "oui",
    label: "Actif",
  },
  {
    value: "non",
    label: "Inactif",
  },
];
const sortOptionsEn = [
  {
    value: "Any filter",
    label: "Any filter",
  },
  {
    value: "oui",
    label: "Active",
  },
  {
    value: "non",
    label: "Unactive",
  },
];

const useStyles: any = makeStyles((theme: any) => ({
  root: {},
  queryField: {},
  bulkOperations: {
    position: "relative",
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: "absolute",
    width: "100%",
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  formControl: {},
}));

interface ResultsProps {
  className?: any;
  intents: Intent[];
  lang: string;
}

function Results(props: ResultsProps) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { className, ...rest } = props;
  const { currentLang } = useLocales();
  const {
    intent: { range, intents, isLoading },
  } = useSelector((state: IApplicationState) => state);
  const t = useTranslation();

  const [keyword, setKeyword] = React.useState<string>("");
  const [sort, setSort] = useState(
    currentLang.value === "fr" ? sortOptionsFr[0].value : sortOptionsEn[0].value
  );
  const [perPage, setPerpage] = React.useState<number>(10);

  function getStatusLabel(status: string) {
    const map: any = {
      active: {
        text: "Nouvelle entreprise",
        color: "success",
      },
      un_active: {
        text: "Mise a jour",
        color: "error",
      },
    };

    const { text, color } = map[status];

    // @ts-ignore
    return <Label color={color}>{text}</Label>;
  }

  // Usually query is done on backend with indexing solutions

  return (
    <Paper
      className={clsx(classes.root, className)}
      {...rest}
    >
      {/* <Box
        p={2}
        minHeight={56}
        display={matches ? 'flex' : 'block'}
        alignItems="center">
        <TextField
          className={classes.queryField}
          style={matches ? { width: '40%' } : { width: '100%' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            )
          }}
          onChange={handleQueryChange}
          placeholder={t('Search_users')}
          variant="outlined"
          color="primary"
        />
        {matches ? <Box flexGrow={1} /> : <br />}
        <FormControl
          style={matches ? { width: '30%' } : { marginTop: 6, width: '60%' }}
          variant="outlined"
          className={classes.formControl}
          color="primary">
          <InputLabel>{t('sort_by')}</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={sort}
            onChange={handleSortChange}
            label={t('sort_by')}>
            {i18n.language === 'fr'
              ? sortOptionsFr.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : sortOptionsEn.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </Box> */}

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("chatBot.question")}</TableCell>
            <TableCell align="center">{t("chatBot.question_plural")}</TableCell>
            <TableCell align="center">{t("chatBot.response_plural")}</TableCell>
            <TableCell align="right">{t("app.actions")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading
            ? Array(DEFAULT_RANGE.per_page)
                .fill("")
                .map((_, index) => (
                  <TableSkeleton
                    id={`chatbot-${index}`}
                    cols={4}
                    key={`loading-table-${index}`}
                  />
                ))
            : intents.map((intent) => {
                return (
                  // @ts-ignore
                  <ErrorHandler>
                    <TableRow
                      hover
                      key={intent.name}
                    >
                      <TableCell>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={2}
                        >
                          {/* @ts-ignore */}
                          <CustomAvatar
                            // @ts-ignore
                            name={getInitials(
                              (intent.trainingPhrases ?? []).length > 0
                                ? (intent.trainingPhrases ?? [])[0].parts[0]
                                    .text
                                : intent.displayName
                            )}
                          />
                          <Box>
                            <Box>
                              <Link
                                color="inherit"
                                component={RouterLink}
                                to="#"
                                variant="subtitle2"
                              >
                                {`${
                                  (intent.trainingPhrases ?? []).length > 0
                                    ? (intent.trainingPhrases ?? [])[0].parts[0]
                                        .text
                                    : intent.displayName
                                }`}
                              </Link>
                            </Box>
                            <Box>
                              <Typography variant="caption">
                                {intent.name}
                              </Typography>
                            </Box>
                          </Box>
                        </Stack>
                      </TableCell>

                      <TableCell align="center">
                        <div>
                          <Typography variant="subtitle2">
                            {(intent.trainingPhrases ?? []).length}
                          </Typography>
                        </div>
                      </TableCell>

                      <TableCell align="center">
                        <div>
                          <Typography variant="subtitle2">
                            {(intent.messages ?? []).length}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <OrderView
                          lang={props.lang}
                          info={intent}
                        />
                      </TableCell>
                    </TableRow>
                  </ErrorHandler>
                );
              })}
          {intents.length === 0 && !isLoading && (
            <TableNoData
              isNotFound={intents.length === 0 && !isLoading}
              colSpan={12}
            />
          )}
        </TableBody>
      </Table>
      {/* <TablePagination
        component="div"
        count={intents.le}
        page={intents.current_page - 1}
        onChangePage={handleChangePage}
        rowsPerPage={intents.per_page}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage={matches ? t('per_page') : ''}
        nextIconButtonText={t('next_page')}
        backIconButtonText={t('previous_page')}
      /> */}
    </Paper>
  );
}

export default Results;
