const administrator = {
  root: "administrateur",
  root_plural: "administrateurs",
  name: "Nom",
  email: "Email",
  role: "Role",
  verified: "Vérifié",
  status: "Statut",
};

export default administrator;
