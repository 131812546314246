import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers from "./reducers";

export interface IIntentState {
  isLoading: boolean;
  error: any;
  intents: any[];
  range: {
    page: number;
    per_page: number;
    keyword: string;
    order: string;
    is_active?: boolean;
  };
}

export const intentInitialState: IIntentState = {
  isLoading: false,
  error: null,
  intents: [],
  range: {
    page: 1,
    per_page: 10,
    keyword: "",
    order: "ASC",
    is_active: undefined,
  },
};

export const slice = createSlice<
  typeof intentInitialState,
  typeof reducers,
  typeof sliceNames.intent
>({
  name: sliceNames.intent,
  initialState: intentInitialState,
  reducers,
});

export default slice.reducer;
