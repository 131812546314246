import {
  IActionWithPayload,
  IBaseCrudError,
  IBaseCrudReducer,
} from "@/app/types/BaseReduxTypes";
import { resetStateKeys } from "@/redux/common/functions";

export default class BaseCrudReducer<IState, IReturnAction>
  implements IBaseCrudReducer<IState, IReturnAction>
{
  [key: string]: (state: IState, action: IActionWithPayload<any>) => void;
  startLoading = (state: any) => {
    state.isLoading = true;
  };
  hasError = (state: any, action: IActionWithPayload<any>) => {
    state.error = action.payload as IBaseCrudError;
  };
  stopLoading = (state: any) => {
    state.isLoading = false;
  };
  get = (state: any, action: IActionWithPayload<any>) => {
    state.currentItem = action.payload;
  };
  paginate = (state: any, action: IActionWithPayload<any>) => {
    state.list = action.payload;
  };
  create = (state: any, action: IActionWithPayload<any>) => {};
  update = (state: any, action: IActionWithPayload<any>) => {};
  delete = (state: any, action: IActionWithPayload<any>) => {};
  reset = (state: any, action: IActionWithPayload<any>) => {
    resetStateKeys(state, action.payload);
  };
}
