import {
  IAdministratorCreate,
  IAdministratorRange,
  IAdministratorUpdate,
} from "@/app/types";
import BaseService from "./BaseService";
import { AdministratorUrls } from "./urls";

class AdministratorService {
  static get = (public_id: string) => {
    return BaseService.getRequest(AdministratorUrls.GET(public_id), true);
  };
  static paginate = (query: IAdministratorRange) => {
    return BaseService.getRequest(AdministratorUrls.PAGINATE(query), true);
  };
  static create = (data: IAdministratorCreate) => {
    return BaseService.postRequest(AdministratorUrls.CREATE, data, true);
  };
  static update = ({ public_id, ...rest }: IAdministratorUpdate) => {
    return BaseService.putRequest(
      AdministratorUrls.UPDATE(public_id),
      rest,
      true
    );
  };
  static delete = (public_id: string) => {
    return BaseService.deleteRequest(
      AdministratorUrls.DELETE,
      [public_id],
      true
    );
  };
}

export default AdministratorService;
