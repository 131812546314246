import { IBaseCrudError } from "@/app/types/BaseReduxTypes";
import {
  IStatCustomerByLocation,
  IStatIncome,
  IStatProductProfit,
  IStatSalonByLocation,
  IStatSalonProfit,
} from "@/app/types/StatTypes";
import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers from "./reducers";

export interface IStatState {
  error: IBaseCrudError | null;
  isLoading: boolean;
  isLoadingIncome: boolean;
  isLoadingSalonIncome: boolean;
  isLoadingProductProfit: boolean;
  isLoadingSalonProductProfit: boolean;
  isLoadingSalonProfit: boolean;
  isLoadingCustomerByLocation: boolean;
  isLoadingSalonByLocation: boolean;
  isLoadingNumberOfUser: boolean;
  isLoadingSalonByCountry: boolean;
  productProfit: IStatProductProfit[] | null;
  salonProductProfit: IStatProductProfit[];
  salonProfit: IStatSalonProfit[] | null;
  income: IStatIncome | null;
  salonIncome: IStatIncome | null;
  customerByLocation: IStatCustomerByLocation[];
  salonByLocation: IStatSalonByLocation[];
  numberOfUser: { professionals: number[]; customers: number[] };
  salonByCountry: { town: string; total: number }[];
}

export const statInitialState: IStatState = {
  error: null,
  isLoading: true,
  isLoadingIncome: true,
  isLoadingSalonIncome: true,
  isLoadingProductProfit: true,
  isLoadingSalonProductProfit: true,
  isLoadingSalonProfit: true,
  isLoadingCustomerByLocation: true,
  isLoadingSalonByLocation: true,
  isLoadingNumberOfUser: true,
  isLoadingSalonByCountry: true,
  productProfit: null,
  salonProfit: null,
  income: null,
  salonIncome: null,
  salonProductProfit: [],
  customerByLocation: [],
  salonByLocation: [],
  numberOfUser: { professionals: [], customers: [] },
  salonByCountry: [],
};

export const slice = createSlice<
  typeof statInitialState,
  typeof reducers,
  typeof sliceNames.stat
>({
  name: sliceNames.stat,
  initialState: statInitialState,
  reducers,
});

export default slice.reducer;
