import VideoService from "@/app/services/api/VideoService";
import { IVideoCreate, IVideoRange, IVideoUpdate } from "@/app/types";
import { IReduxBaseCrudEffect } from "@/app/types/BaseReduxTypes";
import BaseReduxCrudEffects from "@/redux/base/crud-slice-helpers/effects";
import { slice } from ".";

export interface IVideoEffects
  extends IReduxBaseCrudEffect<
    IVideoRange,
    IVideoCreate,
    IVideoUpdate,
    VideoService
  > {}

class VideoEffects
  extends BaseReduxCrudEffects<IVideoRange, IVideoCreate, IVideoUpdate>
  implements IVideoEffects {}

const videoEffects = new VideoEffects(slice, VideoService);

export default videoEffects;
