import PropTypes from "prop-types";
// @mui
import { Box, Card, Grid, Stack, Typography, CardHeader } from "@mui/material";
//
import useTranslation from "@/core/hooks/useTranslation";
import { useNavigate } from "react-router-dom";
import ProfileAbout from "./ProfileAbout";
import ProfileFollowInfo from "./ProfileFollowInfo";
import { getValueOrNA } from "@/core/utils/getFullName";
import { DAYS_OF_THE_WEEK } from "@/app/settings/config";

// ----------------------------------------------------------------------

Profile.propTypes = {
  info: PropTypes.object,
  posts: PropTypes.array,
};

export default function Profile({ info, posts }) {
  const t = useTranslation();
  const navigate = useNavigate();
  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
        md={4}
      >
        <Stack spacing={3}>
          <ProfileFollowInfo
            follower={info.popularity_index}
            // following={info.following}
          />

          <ProfileAbout
            quote={info.quote}
            country={info.address.address_one}
            email={info.manager_hairdressing_email}
            phone={info.entreprise_phonenumber}
            formula={info.formula?.code}
            acceptClickNCollect={info.accept_click_and_collection}
            hairdressingAtHome={info.headdress_at_home}
            isOnline={info.shop_onligne}
            emailPrincipal={info.user.email}
            prestations={info.prestations}
            // company={info.company}
            // school={info.school}
          />

          {/* <ProfileSocialInfo socialLinks={info.socialLinks} /> */}
        </Stack>
      </Grid>

      <Grid
        item
        xs={12}
        md={8}
      >
        <Stack spacing={3}>
          <Card>
            <CardHeader title={t("label.planning")} />
            <Stack
              spacing={2}
              sx={{ p: 3 }}
            >
              {DAYS_OF_THE_WEEK.map((day) => {
                const planning = info?.planning?.find(
                  (p) => p.day === day
                )?.hours;
                return (
                  <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems="center"
                    key={`index-${day}`}
                  >
                    <Stack>
                      <Typography variant="subtitle2">
                        {t(`label.${day}`)}
                      </Typography>

                      <Typography
                        variant="caption"
                        sx={{ color: "text.secondary" }}
                      >
                        {planning
                          ? `${getValueOrNA(planning?.start_time).replace(
                              ":",
                              "h"
                            )} ${t("app.at")} ${getValueOrNA(
                              planning?.end_time
                            ).replace(":", "h")}`
                          : t("professional.not_open_this_day")}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={0.5}
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          width: 8,
                          height: 8,
                          flexShrink: 0,
                          borderRadius: 999,
                          backgroundColor: planning
                            ? "success.light"
                            : "error.light",
                        }}
                      />
                      <Typography
                        variant="caption"
                        sx={{
                          color: planning ? "success.light" : "error.light",
                          fontWeight: 700,
                        }}
                      >
                        {t(planning ? "app.open" : "app.close")}
                      </Typography>
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
          </Card>
          {/* <ProfilePostInput /> */}
        </Stack>
      </Grid>
    </Grid>
  );
}
