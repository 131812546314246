import PropTypes from "prop-types";
import { useRef, useState } from "react";
// @mui
import {
  Box,
  IconButton,
  InputAdornment,
  InputBase,
  Stack,
} from "@mui/material";
// utils
// components
import { useAuthContext } from "@/app/auth/useAuthContext";
import Iconify from "@/core/components/iconify";
import useTranslation from "@/core/hooks/useTranslation";
import toBase64 from "@/core/utils/toBase64";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

ChatMessageInput.propTypes = {
  sx: PropTypes.object,
  onSend: PropTypes.func,
  disabled: PropTypes.bool,
  conversationId: PropTypes.string,
};

export default function ChatMessageInput({
  disabled,
  conversationId,
  onSend,
  onSendFile,
  sx,
  ...other
}) {
  const fileInputRef = useRef(null);

  const [message, setMessage] = useState("");
  const { user } = useAuthContext();
  const t = useTranslation();

  const { chat } = useSelector((state) => state);

  const handleClickAttach = () => {
    fileInputRef.current?.click();
  };

  const handleSend = (event) => {
    if (event.key === "Enter") {
      if (onSend && message /* && conversationId */) {
        (chat?.recipients || []).forEach((recipient) => {
          onSend(
            {
              receiver_id: recipient?.public_id ?? "",
              content: message,
            },
            () => {
              setMessage("");
            }
          );
        });
      }
    }
  };

  const handleSendFile = (event) => {
    toBase64(event.target.files[0]).then((b64) => {
      (chat?.recipients || []).forEach((recipient) => {
        onSendFile({
          file_name:
            String(new Date().getTime()) +
            "." +
            String(b64.split(";")[0].split("/")[1]).toLocaleLowerCase(),
          base64: b64,
          receiver_id: recipient?.public_id ?? "",
        });
      });
    });
  };

  const loading = chat.isLoadingSendMessage;

  return (
    <Box sx={{ p: 2 }}>
      <InputBase
        fullWidth
        value={message}
        onKeyUp={handleSend}
        disabled={loading}
        onChange={(event) => setMessage(event.target.value)}
        placeholder={t("chat.type_your_message")}
        startAdornment={
          <InputAdornment position="start">
            <IconButton size="small">
              <Iconify icon="eva:smiling-face-fill" />
            </IconButton>
          </InputAdornment>
        }
        endAdornment={
          <Stack
            direction="row"
            spacing={1}
            sx={{ flexShrink: 0, mr: 1.5 }}
          >
            <IconButton
              disabled={disabled ?? loading}
              size="small"
              onClick={loading ? undefined : handleClickAttach}
            >
              <Iconify icon="ic:round-add-photo-alternate" />
            </IconButton>

            <IconButton
              disabled={disabled ?? loading}
              size="small"
              onClick={handleClickAttach}
            >
              <Iconify icon="eva:attach-2-fill" />
            </IconButton>

            {/* <IconButton
              disabled={disabled ?? loading}
              size="small"
            >
              <Iconify icon="eva:mic-fill" />
            </IconButton> */}
          </Stack>
        }
        sx={{
          pl: 1,
          height: 56,
          flexShrink: 0,
          borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
          ...sx,
        }}
        {...other}
      />

      <input
        type="file"
        disabled={loading}
        ref={fileInputRef}
        onChange={handleSendFile}
        style={{ display: "none" }}
      />
    </Box>
  );
}
