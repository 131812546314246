import { IIntentRequestRange } from "@/app/types/IntentTypes";
import googleAuth from "google-oauth-jwt";
import BaseService from "./BaseService";
// import { exchangeToken,getAccessToken, createOAuthHeaders } from "react-google-oauth2";

const key = {
  type: "service_account",
  project_id: "carla-plus-curls",
  private_key_id: "58dd9eab89ff5c353fad164c1b9650445e092a34",
  private_key:
    "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDLywD9fdkqVFb7\nKy7PbXH/me2RjFOWizGNkZwpZjFChLBRSfmosVRO1lnE0MW21iKZkjxUUN8OUdYn\nBsE14FeSMMaHrA8aEeRd2upKIBzdNZ6I6t2+xGihOlt2LMBXbcLMQ0pvAJoFL7CA\nsrLFKzpf5HIfL+EaqkCC03Liemo7DnptUMTUelBhxLY3IA3GpjG4ElJs/oa/ReIy\nYejTf4a3vwtjl1IQiOlorYP9zOOBjw8O88f87fyKO0P4ARznJaUv8d08+JrtAH/f\nsIL4AQcVtO3BXvJdlqI8cMNQ3N2Fh0hM3pbzthFdDTGY0iSaeUJnKRs6byy2Le7Q\nbVS0Rdk1AgMBAAECggEADSVmEo20HPqEnRgAbjnXKSMOCirAZNOx0wbZ/GmuzJjj\nbtSUAE4zUndm7wuwoiy9RD7Rf91Mcw+gZIAXcpkRzRWZMomzQUrdYMvAYh7tyojg\nIy250Pzs6vACxzErCMWR07wutWsw6rWHoz9KMCHe8CFvPMfJgGWljhe2leZKEZxH\ndUm4OXb5avGENlNvlIV+fECH1lVdsCwzN6MtfVqiL9XCLBCFUI8oZ6aUHx2IuHy5\nlWlsud1be0rV+VFp57LDzrkN9lY6UCMDKsOnZBlx4zkTPm3K1TgOGxHSoKQ/6sE9\nj1rmrPaSxOve3DaJTzxSDvX7y+yPBPlbBXpelmXOeQKBgQD9QZPc9KP3mruQWE7y\nhVVHxa3P+hSIFx1KmIGG6MwWdFV8CLA6YnhKY1VB1dhnAw85Xux+WkWRBALjhkJq\nIXLzKSPYJ3+GRVBWzyt6T9Oo73NtRzNdpaNw0hO5YsPNhAy4aqP68k6Ze60cZuim\nv3wIpLJF6+Pb+wRhu3zjN8ZKgwKBgQDOADyoH6M1qS+2MXub+OaLIkpL0yvUB76W\nD4vUI4SMekD2vo6+rBMIjf4xAFlbERlS5+tyzpdVtabGjdSwt/8SiG+cPYxrM5wZ\nNnlTRmRHgpRWGEzJBlm9kTgxC1pnPYC+ZuNP7t8dqR4qEa9pmHlt4UWRlptRODiL\nbsQpc+Zf5wKBgGrTSoedObyrrApK9aIe08YegB6GIjr1ZQmeIqTBdbTTBWv4Tj2S\nY6MdG01V4p4xEC7m92KkuUteIcnPTBxwV4hyBgibQJNmkWjMok5JdcKAnnOt4ODT\niYmBU4iTPHxoAuWXNewYYUckf/THA8OyaaNLtVcBFXApBcQjzZ4aXK35AoGBAMDY\np/Ab7gZGJExDdpxFHk4SA8uIHPeZaGnCk/w8Y9G+gSJ8sdT98bOIefYVhZEjKLnc\nMyZ/EF1EeKRYEkx9c2Q+JX32gIW7At73iAzVUPtA2uzeQsPOFiTI1fnTzwXnJacZ\nWqNx051IvNxVQglwm/ypbx8kBrK6FvUw9KJ5xajxAoGBAOBYc52369sUGYFYSym7\nHpH5NhYSbbnj3rNYXCnr54REtg9gmf161WTrpCim8fUtVSacwz8qTrhfcBY9gbz7\nV7rTPU5hE51ABs2yJbG/+IQ/64bl5HZyigSwoJwJnIqymb5TdFPPPqJyfvKgKL9i\nA2y5sIuF9ZmrCpNglOmZrYsu\n-----END PRIVATE KEY-----\n",
  client_email: "carla-dialog-flow@carla-plus-curls.iam.gserviceaccount.com",
  client_id: "108791116818025072951",
  auth_uri: "https://accounts.google.com/o/oauth2/auth",
  token_uri: "https://oauth2.googleapis.com/token",
  auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
  client_x509_cert_url:
    "https://www.googleapis.com/robot/v1/metadata/x509/carla-dialog-flow%40carla-plus-curls.iam.gserviceaccount.com",
};

class IntentsService {
  static login = (info: object) => {
    console.log({ info });
  };

  static getToken = async (): Promise<string> => {
    return new Promise((resolve) => {
      googleAuth.authenticate(
        {
          client_id: key.client_id,
          email: key.client_email,
          key: key.private_key,
          scopes: [
            "https://www.googleapis.com/auth/cloud-platform",
            "https://www.googleapis.com/auth/dialogflow",
          ],
        },
        (err: any, token: string) => {
          resolve(token);
        }
      );
    });
  };

  static list_intents = async ({ range, lang }: IIntentRequestRange) => {
    const token = await IntentsService.getToken();
    return BaseService.getRequest(
      `https://dialogflow.googleapis.com/v2/projects/carla-plus-curls/agent/intents?intentView=INTENT_VIEW_FULL&languageCode=${lang}`,
      true,
      token
    );
  };

  static translate_text = async (text: string) => {
    return fetch("https://api.deepl.com/v2/translate", {
      body: `auth_key=a87ee184-be84-fb1a-efca-4cedbd911fd8&text=${encodeURIComponent(
        text
      )}&target_lang=en`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "POST",
    });
  };
  static add_intent = async (body: object, lang: string) => {
    const token = await IntentsService.getToken();
    return BaseService.postRequest(
      "https://dialogflow.googleapis.com/v2/projects/carla-plus-curls/agent/intents?languageCode=" +
        lang,
      body,
      true,
      token
    );
  };

  static delete_intent = async (intentId: string) => {
    const token = await IntentsService.getToken();
    return BaseService.deleteRequest(
      `https://dialogflow.googleapis.com/v2/projects/carla-plus-curls/agent/intents/${intentId}`,
      {},
      true,
      token
    );
  };

  static update_intent = async (
    intentId: string,
    body: object,
    lang: string
  ) => {
    const token = await IntentsService.getToken();
    return BaseService.patchRequest(
      `https://dialogflow.googleapis.com/v2/projects/carla-plus-curls/agent/intents/${intentId}?languageCode=` +
        lang,
      body,
      true,
      token
    );
  };
}

export default IntentsService;
