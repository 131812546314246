import { IAppLocale, IHairdressingSalon } from "@/app/types";
import buildValidators from "@/core/utils/buildValidators";
import { IAutoFormFields } from "@/presentation/components/AutoForm";

const hairdressingSalonFields = (
  t: Function,
  prestations: any[],
  hairStyles: any[],
  peopleTypes: any[],
  lang: IAppLocale
): IAutoFormFields<IHairdressingSalon> => {
  const v = buildValidators(t);
  return {
    email: {
      initialValue: (row) => row?.user?.email,
      label: "label.email",
      placeholder: "placeholder.email",
      title: "title.email",
      props: {},
      validation: v.email,
    },
    password: {
      initialValue: (_row) => "",
      type: "password",
      label: "label.password",
      placeholder: "placeholder.password",
      title: "title.password",
      props: {},
      validation: (row: any) => (row ? v.password_nr : v.password),
    },
    hairdresser_salon_name: {
      initialValue: (row) => row?.name,
      label: "label.hairdresser_salon_name",
      placeholder: "placeholder.hairdresser_salon_name",
      title: "title.hairdresser_salon_name",
      props: {},
      validation: v.string,
    },
    country_code: {
      initialValue: (_row) => "",
      label: "label.country_code",
      placeholder: "placeholder.country_code",
      title: "title.country_code",
      hidden: (row) => row,
      props: {},
      validation: v.string_nr,
    },
    entreprise_name: {
      initialValue: (_row) => "",
      label: "label.entreprise_name",
      placeholder: "placeholder.entreprise_name",
      title: "title.entreprise_name",
      hidden: (row) => row,
      props: {},
      validation: v.string_nr,
    },
    entreprise_phonenumber: {
      initialValue: (_row) => "",
      label: "label.entreprise_phonenumber",
      placeholder: "placeholder.entreprise_phonenumber",
      title: "title.entreprise_phonenumber",
      hidden: (row) => row,
      props: {},
      validation: v.number_nr,
    },
    website: {
      initialValue: (row) => row?.website,
      label: "label.website",
      placeholder: "placeholder.website",
      title: "title.website",
      hidden: (row) => row,
      props: {},
      validation: v.string_nr,
    },
    // phonenumber: {
    //   initialValue: (row) => "",
    //   label: "label.phone_number",
    //   placeholder: "placeholder.phone_number",
    //   title: "title.phone_number",
    //   hidden: (row) => row,
    //   props: {},
    //   validation: v.number,
    // },
    address__address_one: {
      initialValue: (row) => row?.address?.address_one,
      label: "label.address_one",
      placeholder: "placeholder.address_one",
      title: "title.address_one",
      props: {},
      validation: v.string_nr,
    },
    address__address_two: {
      initialValue: (row) => row?.address?.address_two,
      label: "label.address_two",
      placeholder: "placeholder.address_two",
      title: "title.address_two",
      props: {},
      validation: v.string_nr,
    },
    address__town: {
      initialValue: (row) => row?.address?.town,
      label: "label.town",
      placeholder: "placeholder.town",
      title: "title.town",
      props: {},
      validation: v.string_nr,
    },
    address__postal_code: {
      initialValue: (row) => row?.address?.postal_code,
      label: "label.postal_code",
      placeholder: "placeholder.postal_code",
      title: "title.postal_code",
      props: {},
      validation: v.string_nr,
    },
    address__country: {
      initialValue: (row) => row?.address?.country,
      label: "label.country",
      placeholder: "placeholder.country",
      title: "title.country",
      props: {},
      validation: v.string_nr,
    },
    address__phonenumber: {
      initialValue: (_row) => "",
      label: "label.phone_number",
      placeholder: "placeholder.phone_number",
      title: "title.phone_number",
      hidden: (row) => row,
      props: {},
      validation: v.number_nr,
    },
    prestation_ids: {
      type: "autocomplete",
      multiple: true,
      getOptions: () => prestations,
      getOptionLabel: (option) => option?.[`title_${lang}`],
      getOptionValue: (option) => Number(option?.id),
      initialValue: (_row) => [],
      label: "label.prestations",
      placeholder: "placeholder.prestations",
      title: "title.prestations",
      hidden: (row) => row,
      props: {},
      validation: v.arrayOfString,
    },
    hair_style: {
      type: "autocomplete",
      multiple: true,
      getOptions: () => hairStyles,
      getOptionLabel: (option) => option?.[`title_${lang}`],
      getOptionValue: (option) => Number(option?.id),
      initialValue: (_row) => [],
      label: "label.hair_style",
      placeholder: "placeholder.hair_style",
      title: "title.hair_style",
      hidden: (row) => row,
      props: {},
      validation: v.arrayOfString,
    },
    shop_onligne: {
      initialValue: (row) => !!row?.shop_onligne,
      switchValues: [true, false],
      type: "switch",
      checkFn: (value) => !!value,
      label: "label.shop_onligne",
      placeholder: "placeholder.shop_onligne",
      title: "title.shop_onligne",
      hidden: (row) => row,
      props: {},
      validation: v.boolean,
    },
    headdress_at_home: {
      initialValue: (row) => !!row?.headdress_at_home,
      switchValues: [true, false],
      type: "switch",
      checkFn: (value) => !!value,
      label: "label.headdress_at_home",
      placeholder: "placeholder.headdress_at_home",
      title: "title.headdress_at_home",
      props: {},
      validation: v.boolean,
    },
    planning: {
      initialValue: (row) => row?.planning,
      type: "planning",
      hidden: (row) => !row,
      label: "label.planning",
      title: "label.planning",
      placeholder: "label.planning",
      props: {},
      validation: v.arrayOfObject_nr,
    },
    images: {
      type: "uploader-multiple",
      initialValue: (row) => row?.images?.map((img) => img.uuid) ?? [],
      initialOptions: (row) => row?.images?.map((img) => img),
      label: "label.images",
      placeholder: "placeholder.images",
      title: "title.images",
      hidden: (row) => !row,
      validation: v.arrayOfString,
    },
    file: {
      type: "uploader",
      initialValue: (row) => row?.file?.medium?.url,
      initialImage: (row) => row?.file?.medium?.url,
      label: "label.image",
      placeholder: "placeholder.image",
      title: "title.image",
      hidden: (row) => !row,
      validation: v.string,
    },
    manager_hairdressing_email: {
      initialValue: (row) => row?.manager_hairdressing_email,
      label: "label.manager_hairdressing_email",
      placeholder: "placeholder.manager_hairdressing_email",
      title: "title.manager_hairdressing_email",
      hidden: (row) => !row,
      props: {},
      validation: (row: unknown) => row ? v.email_nr : v.email,
    },
  };
};

export default hairdressingSalonFields;
