import IntentsService from "@/app/services/api/IntentsService";
import { IApplicationState } from "@/app/types";
import { Intent } from "@/app/types/IntentTypes";
import Iconify from "@/core/components/iconify/Iconify";
import useTranslation from "@/core/hooks/useTranslation";
import intentEffects from "@/redux/slices/intent/effects";
import { dispatch } from "@/redux/store";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  createStyles,
  FormControlLabel,
  IconButton,
  Stack,
  SvgIcon,
  Theme,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import { FieldArray, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { MessageCircle, ShoppingBag, Trash } from "react-feather";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import InputText from "./InputText";

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: "relative",
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    leftInput: {
      paddingRight: 8,
      [theme.breakpoints.down("xs")]: {
        paddingRight: 0,
      },
    },
    rightInput: {
      paddingLeft: 8,
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
      },
    },
  })
);

interface AddOrderFormProps {
  className?: string;
  onSubmitSuccess?: Function;
  handleClose: Function;
  info?: Intent;
  lang: string;
}

function AddOrderForm(props: AddOrderFormProps) {
  const classes = useStyles();
  const { className, onSubmitSuccess, lang, info, handleClose, ...rest } =
    props;
  const formRef = React.createRef<HTMLFormElement>();
  const t = useTranslation();

  const {
    intent: { isLoading, error, range, intents },
  } = useSelector((state: IApplicationState) => state);
  const validations: Object = {
    trainingPhrases:
      info &&
      info.name ===
        "projects/carla-plus-curls/agent/intents/7948228a-b941-4926-9fb2-9458fe4a5b68"
        ? Yup.array().of(
            Yup.object().shape({
              text: Yup.string().ensure().required("Veuillez remplir ce champ"),
            })
          )
        : Yup.array()
            .of(
              Yup.object().shape({
                text: Yup.string()
                  .ensure()
                  .required("Veuillez remplir ce champ"),
              })
            )
            .min(1, "Remplir au moins une question")
            .required("Remplir au moins une question"),
    messages: Yup.array()
      .of(
        Yup.object().shape({
          text: Yup.string()
            .ensure()
            // @ts-ignore
            .when("is_profile_base_value_false", {
              is: true, // alternatively: (val) => val == true
              then: (schema) => schema.required("Veuillez remplir ce champ"),
              otherwise: (schema) => schema.optional(),
            }),
          text1: Yup.string().when("is_profile_base_value_false", {
            is: true, // alternatively: (val) => val == true
            then: (schema) => schema.required("Veuillez remplir ce champ"),
            otherwise: (schema) => schema.optional(),
          }),
          text2: Yup.string().when("is_profile_base_value_false", {
            is: true, // alternatively: (val) => val == true
            then: (schema) => schema.required("Veuillez remplir ce champ"),
            otherwise: (schema) => schema.optional(),
          }),
          text3: Yup.string().when("is_profile_base_value_false", {
            is: true, // alternatively: (val) => val == true
            then: (schema) => schema.required("Veuillez remplir ce champ"),
            otherwise: (schema) => schema.optional(),
          }),
          text4: Yup.string().when("is_profile_base_value_false", {
            is: true, // alternatively: (val) => val == true
            then: (schema) => schema.required("Veuillez remplir ce champ"),
            otherwise: (schema) => schema.optional(),
          }),
        })
      )
      .min(1, "Remplir au moins une réponse")
      .required("Remplir au moins une réponse"),
  };

  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const initialValues = info
    ? {
        trainingPhrases: (info.trainingPhrases ?? []).map(
          (trainingPhrase: any) => ({
            text: trainingPhrase.parts.map((part: any) => part.text).join(" "),
          })
        ),
        messages: (info.messages ?? []).map((message: any) => ({
          text: message.text.text[0].includes("[@]")
            ? ""
            : message.text.text[0].split("(Voir plus)")[0],
          text_more: message.text.text[0].includes("[@]")
            ? ""
            : message.text.text[0].split("(Voir plus)")[1] ?? "",
          is_profile_base_value_false: message.text.text[0].includes("[@]"),

          text1: message.text.text[0].includes("[@]")
            ? message.text.text[0]
                .split("[@]")
                .find((t: any) => t.includes("1A/1B/1C[:]"))
                ?.replace("1A/1B/1C[:]", "")
                .split("(Voir plus)")[0]
            : "",
          text1_more: message.text.text[0].includes("[@]")
            ? message.text.text[0]
                .split("[@]")
                .find((t: any) => t.includes("1A/1B/1C[:]"))
                ?.replace("1A/1B/1C[:]", "")
                .split("(Voir plus)")[1]
            : "",

          text2: message.text.text[0].includes("[@]")
            ? message.text.text[0]
                .split("[@]")
                .find((t: any) => t.includes("2A/2B/2C[:]"))
                ?.replace("2A/2B/2C[:]", "")
                .split("(Voir plus)")[0]
            : "",
          text2_more: message.text.text[0].includes("[@]")
            ? message.text.text[0]
                .split("[@]")
                .find((t: any) => t.includes("2A/2B/2C[:]"))
                ?.replace("2A/2B/2C[:]", "")
                .split("(Voir plus)")[1]
            : "",

          text3: message.text.text[0].includes("[@]")
            ? message.text.text[0]
                .split("[@]")
                .find((t: any) => t.includes("3A/3B/3C[:]"))
                ?.replace("3A/3B/3C[:]", "")
                .split("(Voir plus)")[0]
            : "",
          text3_more: message.text.text[0].includes("[@]")
            ? message.text.text[0]
                .split("[@]")
                .find((t: any) => t.includes("3A/3B/3C[:]"))
                ?.replace("3A/3B/3C[:]", "")
                .split("(Voir plus)")[1]
            : "",

          text4: message.text.text[0].includes("[@]")
            ? message.text.text[0]
                .split("[@]")
                .find((t: any) => t.includes("4A/4B/4C[:]"))
                ?.replace("4A/4B/4C[:]", "")
                .split("(Voir plus)")[0]
            : "",
          text4_more: message.text.text[0].includes("[@]")
            ? message.text.text[0]
                .split("[@]")
                .find((t: any) => t.includes("4A/4B/4C[:]"))
                ?.replace("4A/4B/4C[:]", "")
                .split("(Voir plus)")[1]
            : "",
        })),
      }
    : {
        trainingPhrases: [
          {
            text: "",
          },
        ],
        messages: [
          {
            text: "",
            text_more: "",
            is_profile_base_value_false: false,
            text1: "",
            text1_more: "",
            text2: "",
            text2_more: "",
            text3: "",
            text3_more: "",
            text4: "",
            text4_more: "",
          },
        ],
      };

  return (
    <Box sx={{ p: 2 }}>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape(validations as any)}
        onSubmit={async (values) => {
          const body = {
            displayName:
              info &&
              info.name ===
                "projects/carla-plus-curls/agent/intents/7948228a-b941-4926-9fb2-9458fe4a5b68"
                ? "FallBack intent"
                : values.trainingPhrases[0].text,
            priority: 500000,
            trainingPhrases:
              info &&
              info.name ===
                "projects/carla-plus-curls/agent/intents/7948228a-b941-4926-9fb2-9458fe4a5b68"
                ? []
                : values.trainingPhrases.map((trainingPhrase: any) => ({
                    type: "EXAMPLE",
                    parts: [
                      {
                        text: trainingPhrase.text,
                      },
                    ],
                  })),
            // 'action': 'listRooms',
            messages: values.messages.map((message: any) => ({
              text: {
                text: [
                  !message.is_profile_base_value_false
                    ? `${message.text}${
                        message.text_more != "" ? "(Voir plus)" : ""
                      }${message.text_more}`
                    : `1A/1B/1C[:]${message.text1}${
                        message.text1_more != "" ? "(Voir plus)" : ""
                      }${message.text1_more}[@]2A/2B/2C[:]${message.text2}${
                        message.text2_more != "" ? "(Voir plus)" : ""
                      }${message.text2_more}[@]3A/3B/3C[:]${message.text3}${
                        message.text3_more != "" ? "(Voir plus)" : ""
                      }${message.text3_more}[@]4A/4B/4C[:]${message.text4}${
                        message.text4_more != "" ? "(Voir plus)" : ""
                      }${message.text4_more}`,
                ],
              },
            })),
          };
          setLoading(true);
          let request = info
            ? IntentsService.update_intent(
                info.name.split("/")[info.name.split("/").length - 1],
                body,
                lang
              )
            : IntentsService.add_intent(body, lang);
          request
            .then(async (response: any) => {
              const data = await response.json();
              if (response.status === 200 || response.status === 201) {
                dispatch(intentEffects.paginate({ range, lang }));
                enqueueSnackbar(
                  info
                    ? "Mise à jour de la question effectuée avec succès"
                    : "Ajout de la question effectué avec succès",
                  {
                    variant: "success",
                  }
                );
                handleClose();
              } else {
                console.log({ data });
                enqueueSnackbar(
                  info
                    ? "Echec de mise à jour de la question"
                    : "Echec d'ajout de la question",
                  {
                    variant: "error",
                  }
                );
              }
            })
            .catch(() => {
              enqueueSnackbar(t("shared.internet_connexion_error"), {
                variant: "error",
              });
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        {({
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          setFieldValue,
          setErrors,
        }) => (
          <Form>
            <Stack spacing={3}>
              <FieldArray name="trainingPhrases">
                {({ insert, remove, push }) => (
                  <Stack spacing={1}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => push({ text: "" })}
                      startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                      {t("chatBot.add_a_question")}
                    </Button>
                    {values.trainingPhrases.length > 0 &&
                      values.trainingPhrases.map(
                        (trainingPhrase: any, index: number) => (
                          <InputText
                            key={index}
                            multiline
                            endIcon={
                              <IconButton
                                color="error"
                                onClick={() => remove(index)}
                              >
                                <SvgIcon fontSize="small">
                                  <Trash />
                                </SvgIcon>
                              </IconButton>
                            }
                            error={Boolean(
                              (touched as any)[`trainingPhrases`] &&
                                (touched as any)[`trainingPhrases`][index] &&
                                (touched as any)[`trainingPhrases`][index][
                                  `text`
                                ] &&
                                (errors as any)[`trainingPhrases`] &&
                                (errors as any)[`trainingPhrases`][index] &&
                                (errors as any)[`trainingPhrases`][index][
                                  `text`
                                ]
                            )}
                            fullWidth
                            helperText={
                              (touched as any)[`trainingPhrases`] &&
                              (touched as any)[`trainingPhrases`][index] &&
                              (touched as any)[`trainingPhrases`][index][
                                `text`
                              ] &&
                              (errors as any)[`trainingPhrases`] &&
                              (errors as any)[`trainingPhrases`][index] &&
                              (errors as any)[`trainingPhrases`][index][`text`]
                            }
                            label={`${t("chatBot.question")} ${index + 1}`}
                            value={
                              (values as any)["trainingPhrases"][index][`text`]
                            }
                            name={`trainingPhrases.${index}.text`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type={"text"}
                          />
                        )
                      )}
                  </Stack>
                )}
              </FieldArray>

              <FieldArray name="messages">
                {({ insert, remove, push }) => (
                  <Stack spacing={1}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() =>
                        push({
                          text: "",
                          text_more: "",
                          is_profile_base_value_false: false,
                          text1: "",
                          text1_more: "",
                          text2: "",
                          text2_more: "",
                          text3: "",
                          text3_more: "",
                          text4: "",
                          text4_more: "",
                        })
                      }
                      startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                      {t("chatBot.add_a_response")}
                    </Button>
                    {values.messages.length > 0 &&
                      values.messages.map((message: any, index: number) => (
                        <Box
                          sx={{
                            p: 2,
                            border: "1px dashed",
                            borderColor: "gray",
                          }}
                          key={index}
                        >
                          <Box>
                            <Stack
                              direction={"row"}
                              justifyContent="space-between"
                              flexWrap={"wrap-reverse"}
                              sx={{ mb: 1 }}
                            >
                              <FormControlLabel
                                value={
                                  (values as any)[
                                    `message.${index}.is_profile_base_value_false`
                                  ]
                                }
                                control={
                                  <Checkbox
                                    checked={
                                      (values as any)[
                                        `message.${index}.is_profile_base_value_false`
                                      ]
                                    }
                                    onChange={handleChange}
                                    name={`messages.${index}.is_profile_base_value_false`}
                                    color="primary"
                                    value={
                                      (values as any)[
                                        `messages.${index}.is_profile_base_value_false`
                                      ]
                                    }
                                  />
                                }
                                label={t("chatBot.response_base_on_air_type")}
                              />

                              <Button
                                color="error"
                                onClick={() => remove(index)}
                              >
                                {t("chatBot.delete_this_response")}
                              </Button>
                            </Stack>
                            {!message.is_profile_base_value_false && (
                              <InputText
                                multiline
                                error={Boolean(
                                  (touched as any)[`messages`] &&
                                    (touched as any)[`messages`][index] &&
                                    (touched as any)[`messages`][index][
                                      `text`
                                    ] &&
                                    (errors as any)[`messages`] &&
                                    (errors as any)[`messages`][index] &&
                                    (errors as any)[`messages`][index][`text`]
                                )}
                                fullWidth
                                helperText={
                                  (touched as any)[`messages`] &&
                                  (touched as any)[`messages`][index] &&
                                  (touched as any)[`messages`][index][`text`] &&
                                  (errors as any)[`messages`] &&
                                  (errors as any)[`messages`][index] &&
                                  (errors as any)[`messages`][index][`text`]
                                }
                                label={`${t("chatBot.response")} ${index + 1}`}
                                name={`messages.${index}.text`}
                                id={`messages.${index}.text`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={
                                  (values as any)["messages"][index][`text`]
                                }
                                type={"text"}
                                endIcon={
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                  >
                                    <IconButton
                                      sx={{ flexShrink: 0 }}
                                      onClick={() => {
                                        // @ts-ignore
                                        setFieldValue(
                                          `messages.${index}.text`,
                                          (
                                            (values as any)["messages"][index][
                                              `text`
                                            ] || ""
                                          ).slice(
                                            0,
                                            document.getElementById(
                                              `messages.${index}.text`
                                              // @ts-ignore
                                            )?.selectionStart
                                          ) +
                                            "[%]" +
                                            (
                                              (values as any)["messages"][
                                                index
                                              ][`text`] || ""
                                            ).slice(
                                              document.getElementById(
                                                `messages.${index}.text`
                                                // @ts-ignore
                                              )?.selectionStart
                                            )
                                        );
                                      }}
                                    >
                                      <SvgIcon fontSize="small">
                                        <ShoppingBag />
                                      </SvgIcon>
                                    </IconButton>
                                    <IconButton
                                      sx={{ flexShrink: 0 }}
                                      onClick={() => {
                                        // @ts-ignore
                                        setFieldValue(
                                          `messages.${index}.text`,
                                          (
                                            (values as any)["messages"][index][
                                              `text`
                                            ] || ""
                                          ).slice(
                                            0,
                                            document.getElementById(
                                              `messages.${index}.text`
                                              // @ts-ignore
                                            )?.selectionStart
                                          ) +
                                            "[=]" +
                                            (
                                              (values as any)["messages"][
                                                index
                                              ][`text`] || ""
                                            ).slice(
                                              document.getElementById(
                                                `messages.${index}.text`
                                                // @ts-ignore
                                              )?.selectionStart
                                            )
                                        );
                                      }}
                                    >
                                      <SvgIcon fontSize="small">
                                        <MessageCircle />
                                      </SvgIcon>
                                    </IconButton>
                                  </Stack>
                                }
                              />
                            )}
                            {!message.is_profile_base_value_false && (
                              <InputText
                                multiline
                                endIcon={
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                  >
                                    <IconButton
                                      onClick={() => {
                                        // @ts-ignore
                                        setFieldValue(
                                          `messages.${index}.text_more`,
                                          (
                                            (values as any)["messages"][index][
                                              `text_more`
                                            ] || ""
                                          ).slice(
                                            0,
                                            document.getElementById(
                                              `messages.${index}.text_more`
                                              // @ts-ignore
                                            )?.selectionStart
                                          ) +
                                            "[%]" +
                                            (
                                              (values as any)["messages"][
                                                index
                                              ][`text_more`] || ""
                                            ).slice(
                                              document.getElementById(
                                                `messages.${index}.text_more`
                                                // @ts-ignore
                                              )?.selectionStart
                                            )
                                        );
                                      }}
                                    >
                                      <SvgIcon fontSize="small">
                                        <ShoppingBag />
                                      </SvgIcon>
                                    </IconButton>
                                    <IconButton
                                      onClick={() => {
                                        // @ts-ignore
                                        setFieldValue(
                                          `messages.${index}.text_more`,
                                          (
                                            (values as any)["messages"][index][
                                              `text_more`
                                            ] || ""
                                          ).slice(
                                            0,
                                            document.getElementById(
                                              `messages.${index}.text_more`
                                              // @ts-ignore
                                            )?.selectionStart
                                          ) +
                                            "[=]" +
                                            (
                                              (values as any)["messages"][
                                                index
                                              ][`text_more`] || ""
                                            ).slice(
                                              document.getElementById(
                                                `messages.${index}.text_more`
                                                // @ts-ignore
                                              )?.selectionStart
                                            )
                                        );
                                      }}
                                    >
                                      <SvgIcon fontSize="small">
                                        <MessageCircle />
                                      </SvgIcon>
                                    </IconButton>
                                  </Stack>
                                }
                                error={Boolean(
                                  (touched as any)[`messages`] &&
                                    (touched as any)[`messages`][index] &&
                                    (touched as any)[`messages`][index][
                                      `text_more`
                                    ] &&
                                    (errors as any)[`messages`] &&
                                    (errors as any)[`messages`][index] &&
                                    (errors as any)[`messages`][index][
                                      `text_more`
                                    ]
                                )}
                                fullWidth={true}
                                helperText={
                                  (touched as any)[`messages`] &&
                                  (touched as any)[`messages`][index] &&
                                  (touched as any)[`messages`][index][
                                    `text_more`
                                  ] &&
                                  (errors as any)[`messages`] &&
                                  (errors as any)[`messages`][index] &&
                                  (errors as any)[`messages`][index][
                                    `text_more`
                                  ]
                                }
                                label={`${t("chatBot.response")} (Voir plus) ${
                                  index + 1
                                }`}
                                name={`messages.${index}.text_more`}
                                id={`messages.${index}.text_more`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={
                                  (values as any)["messages"][index][
                                    `text_more`
                                  ]
                                }
                                type={"text"}
                              />
                            )}
                            {message.is_profile_base_value_false &&
                              [
                                "1A/1B/1C",
                                "2A/2B/2C",
                                "3A/3B/3C",
                                "4A/4B/4C",
                              ].map((item, index2) => (
                                <div
                                  style={{
                                    border: "1px dashed #14ACCD",
                                    margin: "5px  0",
                                    padding: 5,
                                  }}
                                >
                                  {message.is_profile_base_value_false && (
                                    <InputText
                                      multiline
                                      endIcon={
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                        >
                                          <IconButton
                                            onClick={() => {
                                              // @ts-ignore
                                              setFieldValue(
                                                `messages.${index}.text${
                                                  index2 + 1
                                                }`,
                                                (values as any)[`messages`][
                                                  index
                                                ][`text${index2 + 1}`].slice(
                                                  0,
                                                  document.getElementById(
                                                    `messages.${index}.text${
                                                      index2 + 1
                                                    }`
                                                    // @ts-ignore
                                                  )?.selectionStart
                                                ) +
                                                  "[%]" +
                                                  (values as any)[`messages`][
                                                    index
                                                  ][`text${index2 + 1}`].slice(
                                                    document.getElementById(
                                                      `messages.${index}.text${
                                                        index2 + 1
                                                      }`
                                                      // @ts-ignore
                                                    )?.selectionStart
                                                  )
                                              );
                                            }}
                                          >
                                            <SvgIcon fontSize="small">
                                              <ShoppingBag />
                                            </SvgIcon>
                                          </IconButton>
                                          <IconButton
                                            onClick={() => {
                                              // @ts-ignore
                                              setFieldValue(
                                                `messages.${index}.text${
                                                  index2 + 1
                                                }`,
                                                (values as any)[`messages`][
                                                  index
                                                ][`text${index2 + 1}`].slice(
                                                  0,
                                                  document.getElementById(
                                                    `messages.${index}.text${
                                                      index2 + 1
                                                    }`
                                                    // @ts-ignore
                                                  )?.selectionStart
                                                ) +
                                                  "[=]" +
                                                  (values as any)[`messages`][
                                                    index
                                                  ][`text${index2 + 1}`].slice(
                                                    document.getElementById(
                                                      `messages.${index}.text${
                                                        index2 + 1
                                                      }`
                                                      // @ts-ignore
                                                    )?.selectionStart
                                                  )
                                              );
                                            }}
                                          >
                                            <SvgIcon fontSize="small">
                                              <MessageCircle />
                                            </SvgIcon>
                                          </IconButton>
                                        </Stack>
                                      }
                                      error={Boolean(
                                        (touched as any)[`messages`] &&
                                          (touched as any)[`messages`][index] &&
                                          (touched as any)[`messages`][index][
                                            `text${index2 + 1}`
                                          ] &&
                                          (errors as any)[`messages`] &&
                                          (errors as any)[`messages`][index] &&
                                          (errors as any)[`messages`][index][
                                            `text${index2 + 1}`
                                          ]
                                      )}
                                      fullWidth={true}
                                      helperText={
                                        (touched as any)[`messages`] &&
                                        (touched as any)[`messages`][index] &&
                                        (touched as any)[`messages`][index][
                                          `text${index2 + 1}`
                                        ] &&
                                        (errors as any)[`messages`] &&
                                        (errors as any)[`messages`][index] &&
                                        (errors as any)[`messages`][index][
                                          `text${index2 + 1}`
                                        ]
                                      }
                                      label={`${t("chatBot.response")} ${
                                        index + 1
                                      }: ${item}`}
                                      name={`messages.${index}.text${
                                        index2 + 1
                                      }`}
                                      id={`messages.${index}.text${index2 + 1}`}
                                      onBlur={handleBlur}
                                      value={
                                        (values as any)[`messages`][index][
                                          `text${index2 + 1}`
                                        ]
                                      }
                                      onChange={handleChange}
                                      type={"text"}
                                    />
                                  )}
                                  {message.is_profile_base_value_false && (
                                    <InputText
                                      multiline
                                      endIcon={
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                        >
                                          <IconButton
                                            onClick={() => {
                                              // @ts-ignore
                                              setFieldValue(
                                                `messages.${index}.text${
                                                  index2 + 1
                                                }_more`,
                                                (values as any)[`messages`][
                                                  index
                                                ][
                                                  `text${index2 + 1}_more`
                                                ].slice(
                                                  0,
                                                  document.getElementById(
                                                    `messages.${index}.text${
                                                      index2 + 1
                                                    }_more`
                                                    // @ts-ignore
                                                  )?.selectionStart
                                                ) +
                                                  "[%]" +
                                                  (values as any)[`messages`][
                                                    index
                                                  ][
                                                    `text${index2 + 1}_more`
                                                  ].slice(
                                                    document.getElementById(
                                                      `messages.${index}.text${
                                                        index2 + 1
                                                      }_more`
                                                      // @ts-ignore
                                                    )?.selectionStart
                                                  )
                                              );
                                            }}
                                          >
                                            <SvgIcon fontSize="small">
                                              <ShoppingBag />
                                            </SvgIcon>
                                          </IconButton>
                                          <IconButton
                                            onClick={() => {
                                              // @ts-ignore
                                              setFieldValue(
                                                `messages.${index}.text${
                                                  index2 + 1
                                                }_more`,
                                                (values as any)[`messages`][
                                                  index
                                                ][
                                                  `text${index2 + 1}_more`
                                                ].slice(
                                                  0,
                                                  document.getElementById(
                                                    `messages.${index}.text${
                                                      index2 + 1
                                                    }_more`
                                                    // @ts-ignore
                                                  )?.selectionStart
                                                ) +
                                                  "[=]" +
                                                  (values as any)[`messages`][
                                                    index
                                                  ][
                                                    `text${index2 + 1}_more`
                                                  ].slice(
                                                    document.getElementById(
                                                      `messages.${index}.text${
                                                        index2 + 1
                                                      }_more`
                                                      // @ts-ignore
                                                    )?.selectionStart
                                                  )
                                              );
                                            }}
                                          >
                                            <SvgIcon fontSize="small">
                                              <MessageCircle />
                                            </SvgIcon>
                                          </IconButton>
                                        </Stack>
                                      }
                                      error={Boolean(
                                        (touched as any)[`messages`] &&
                                          (touched as any)[`messages`][index] &&
                                          (touched as any)[`messages`][index][
                                            `text${index2 + 1}_more`
                                          ] &&
                                          (errors as any)[`messages`] &&
                                          (errors as any)[`messages`][index] &&
                                          (errors as any)[`messages`][index][
                                            `text${index2 + 1}_more`
                                          ]
                                      )}
                                      fullWidth
                                      helperText={
                                        (touched as any)[`messages`] &&
                                        (touched as any)[`messages`][index] &&
                                        (touched as any)[`messages`][index][
                                          `text${index2 + 1}_more`
                                        ] &&
                                        (errors as any)[`messages`] &&
                                        (errors as any)[`messages`][index] &&
                                        (errors as any)[`messages`][index][
                                          `text${index2 + 1}_more`
                                        ]
                                      }
                                      label={`${t(
                                        "chatBot.response"
                                      )} (Voir plus) ${index + 1}: ${item}`}
                                      name={`messages.${index}.text${
                                        index2 + 1
                                      }_more`}
                                      id={`messages.${index}.text${
                                        index2 + 1
                                      }_more`}
                                      value={
                                        (values as any)[`messages`][index][
                                          `text${index2 + 1}_more`
                                        ]
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      type={"text"}
                                    />
                                  )}
                                </div>
                              ))}
                          </Box>
                        </Box>
                      ))}
                  </Stack>
                )}
              </FieldArray>
              <LoadingButton
                color="primary"
                fullWidth
                variant="contained"
                sx={{ my: 3 }}
                loading={loading}
                type="submit"
                startIcon={
                  info ? (
                    <Iconify icon="eva:checkmark-fill" />
                  ) : (
                    <Iconify icon="eva:plus-fill" />
                  )
                }
              >
                {t(`app.${info ? "update" : "create"}`)}
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default AddOrderForm;
