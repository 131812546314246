import { IProduct } from "@/app/types";
import {
  baseCrudInitialState,
  IBaseCrudState,
} from "@/redux/base/crud-slice-helpers";
import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers, { IProductReducer } from "./reducers";

export interface IProductState extends IBaseCrudState<IProduct> {}

export const productInitialState: IProductState = baseCrudInitialState;

export const slice = createSlice<
  IProductState,
  IProductReducer,
  typeof sliceNames.product
>({
  name: sliceNames.product,
  initialState: productInitialState,
  reducers,
});

export default slice.reducer;
