const status = {
  all: "Tous",
  active: "Actif",
  inactive: "Inactif",
  active_plural: "Actifs",
  inactive_plural: "Inactifs",
  ACTIVED: "Activé",
  UNACTIVED: "Désactivé",
  online: "En ligne",
  not_in_the_news: "Pas à la une",
  in_the_news: "A la une",
  offline: "Hors ligne",
  paid: "Payé",
  unpaid: "Non payé",
  overdue: "En retard",
  draft: "Brouillon",
  PENDING: "Initiée",
  BOOK: "Réservée",
  IN_PROGRESS: "En cours",
  ACCEPTED: "Acceptée",
  PAID: "Payée",
};

export default status;
