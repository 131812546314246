export default function getVideoImage(path, callback, secs = 1) {
  var me = this,
    video = document.createElement("video");
  video.onloadedmetadata = function () {
    if ("function" === typeof secs) {
      secs = secs(this.duration);
    }
    this.currentTime = Math.min(
      Math.max(0, (secs < 0 ? this.duration : 0) + secs),
      this.duration
    );
  };
  video.onseeked = function (e) {
    var canvas = document.createElement("canvas");
    canvas.height = video.videoHeight;
    canvas.width = video.videoWidth;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    const dataUrl = canvas.toDataURL();
    callback.call(me, dataUrl, this.currentTime, e);
  };
  video.onerror = function (e) {
    callback.call(me, undefined, undefined, e);
  };
  video.src = path;
}
