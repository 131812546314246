import { sentenceCase } from "change-case";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
// @mui
import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  MenuItem,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
// routes
// utils
import { fCurrency, fShortenNumber } from "@/core/utils/formatNumber";
// _mock
import { _socials } from "@/app/_mock/arrays";
// components
import { IncrementerButton } from "@/core/components/custom-input";
import FormProvider, { RHFSelect } from "@/core/components/hook-form";
import Iconify from "@/core/components/iconify";
import Label from "@/core/components/label";
import { IAppLocale, IProduct } from "@/app/types";
import useCurrency from "@/core/hooks/useCurrency";
import useTranslation from "@/core/hooks/useTranslation";
import { getValueOrNA } from "@/core/utils/getFullName";
import { useLocales } from "@/app/locales";

// ----------------------------------------------------------------------

ProductDetailsSummary.propTypes = {
  cart: PropTypes.array,
  onAddCart: PropTypes.func,
  product: PropTypes.object,
  onGotoStep: PropTypes.func,
};

export default function ProductDetailsSummary({
  cart,
  product: _product,
  onAddCart,
  onGotoStep,
  ...other
}: any) {
  const navigate = useNavigate();
  const product = _product as IProduct;
  const toCurrency = useCurrency();
  const t = useTranslation();
  const { currentLang } = useLocales();
  const lang = currentLang?.value as IAppLocale;

  return (
    <Stack
      spacing={3}
      sx={{
        p: (theme) => ({
          md: theme.spacing(5, 5, 0, 2),
        }),
      }}
      {...other}
    >
      <Stack spacing={2}>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary" }}
        >
          {product?.brand}
        </Typography>
        <Box>
          {/* @ts-ignore */}
          <Label
            variant="soft"
            color={(product?.qty ?? 0) > 0 ? "success" : "error"}
            sx={{ textTransform: "uppercase", mr: "auto" }}
          >
            {sentenceCase(
              t(
                (product?.qty ?? 0) > 0 ? "label.in_stock" : "label.unavailable"
              )
            )}
          </Label>
        </Box>

        <Typography
          variant="overline"
          component="div"
          sx={{
            color: !product.is_online ? "error.main" : "info.main",
          }}
        >
          {t(`status.${product.is_online ? "online" : "offline"}`)}
        </Typography>

        <Typography variant="h5">{product.name}</Typography>
        {/*
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Rating
            value={totalRating}
            precision={0.1}
            readOnly
          />

          <Typography
            variant="body2"
            sx={{ color: "text.secondary" }}
          >
            ({fShortenNumber(totalReview)}
            reviews)
          </Typography>
        </Stack>
          */}

        <Typography variant="h4">
          {product.current_price !== product.real_price && (
            <Box
              component="span"
              sx={{
                color: "text.disabled",
                textDecoration: "line-through",
                mr: 0.5,
              }}
            >
              {toCurrency(product?.real_price)}
            </Box>
          )}

          {toCurrency(product.current_price)}
        </Typography>
      </Stack>

      <Divider sx={{ borderStyle: "dashed" }} />
      {/*
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="subtitle2">Color</Typography>

         <Controller
            name="colors"
            control={control}
            render={({ field }) => (
              <ColorSinglePicker
                colors={colors}
                value={field.value}
                onChange={field.onChange}
                sx={{
                  ...(colors.length > 4 && {
                    maxWidth: 144,
                    justifyContent: "flex-end",
                  }),
                }}
              />
            )}
          /> 
      </Stack>
*/}
      <Stack
        gap={1}
        sx={{
          "& .MuiTypography-root span": {
            color: "text.primary",
          },
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ height: 36, lineHeight: "36px", color: "text.secondary" }}
        >
          {t("label.qty")} : <span>{product?.qty ?? 0}</span>
        </Typography>

        <Typography
          variant="subtitle2"
          sx={{ height: 36, lineHeight: "36px", color: "text.secondary" }}
        >
          {t("label.sku")} : <span>{getValueOrNA(product?.sku)}</span>
        </Typography>

        <Typography
          variant="subtitle2"
          sx={{ height: 36, lineHeight: "36px", color: "text.secondary" }}
        >
          {t("label.weight")} : <span>{getValueOrNA(product?.weight)} kg</span>
        </Typography>

        <Typography
          variant="subtitle2"
          sx={{ height: 36, lineHeight: "36px", color: "text.secondary" }}
        >
          {t("label.category")} :{" "}
          <span>
            {getValueOrNA(product?.sub_category?.category?.[`title_${lang}`])}
          </span>
        </Typography>

        <Typography
          variant="subtitle2"
          sx={{ height: 36, lineHeight: "36px", color: "text.secondary" }}
        >
          {t("label.sub_category")} :{" "}
          <span>{getValueOrNA(product?.sub_category?.[`title_${lang}`])}</span>
        </Typography>
      </Stack>

      <Divider sx={{ borderStyle: "dashed" }} />
      <Stack>
        <Typography
          variant="subtitle2"
          sx={{ height: 36, lineHeight: "36px", color: "text.secondary" }}
        >
          {t("label.benefits")}
        </Typography>
        <Stack
          flexWrap={"wrap"}
          direction="row"
          spacing={1}
        >
          {product.benefits?.length === 0 && (
            <Typography>{t("app.no_data")}</Typography>
          )}
          {product.benefits?.map((benef, index) => (
            <Box key={`${benef}${index}`}>
              {/* @ts-ignore */}
              <Label>{benef}</Label>
            </Box>
          ))}
        </Stack>
      </Stack>

      <Divider sx={{ borderStyle: "dashed" }} />
      <Stack>
        <Typography
          variant="subtitle2"
          sx={{ height: 36, lineHeight: "36px", color: "text.secondary" }}
        >
          {t("label.tags")}
        </Typography>
        <Stack
          flexWrap={"wrap"}
          direction="row"
          spacing={1}
        >
          {product.tags?.length === 0 && (
            <Typography>{t("app.no_data")}</Typography>
          )}
          {product.tags?.map((benef, index) => (
            <Box key={`${benef}${index}`}>
              {/* @ts-ignore */}
              <Label>{benef}</Label>
            </Box>
          ))}
        </Stack>
      </Stack>
      {/*

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        {_socials.map((social) => (
          <IconButton key={social.name}>
            <Iconify icon={social.icon} />
          </IconButton>
        ))}
      </Stack>
      */}
    </Stack>
  );
}
