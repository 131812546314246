import { DEFAULT_RANGE } from "@/app/config-global";
import HairdressingSalonService from "@/app/services/api/HairdressingSalonService";
import { IHairdressingSalon } from "@/app/types";
import { IToolbarOption } from "@/presentation/components/TablePage";

export const productTableTopOptions = [
  {
    label: "status.all",
    value: "",
  },
  {
    label: "status.online",
    value: "true",
  },
  {
    label: "status.offline",
    value: "false",
  },
];
export const productToolbarOptions = (
  keyword: {
    value: string;
    onChange: (value: string) => void;
  },
  sub_category: {
    value: string;
    onChange: (value: string) => void;
  },
  category: {
    value: string;
    onChange: (value: string) => void;
  },
  salon: null | {
    value: string;
    onChange: (value: string) => void;
  },
  categories: any[],
  subCategories: any[]
): IToolbarOption[] => [
  {
    name: "category",
    type: "select",
    options: categories,
    placeholder: "product.category",
    ...category,
  },
  {
    name: "sub_category",
    type: "select",
    options: subCategories,
    placeholder: "product.sub_category",
    ...sub_category,
  },
  //@ts-ignore
  ...(salon
    ? [
        {
          name: "salon",
          type: "autocomplete",
          placeholder: "placeholder.hairdressing_salon",
          getOptionLabel: (value: IHairdressingSalon) => value?.name ?? "",
          getOptionValue: (value: IHairdressingSalon) => value?.uuid ?? "",
          getValue: (options: IHairdressingSalon[]) =>
            options.find((opt) => opt.uuid === salon.value) ?? null,
          loadOptions: async (keyword: string) => {
            const res = await HairdressingSalonService.paginate({
              ...DEFAULT_RANGE,
              keyword,
            });
            const data = await res.json();
            return data.data;
          },
          onChange: salon.onChange,
        },
      ]
    : []),
  {
    name: "admin_search",
    placeholder: "placeholder.search",
    //@ts-ignore
    type: "input",
    ...keyword,
  },
];
