import { IChatBot } from "@/app/types";
import { IBaseCrudReducer } from "@/app/types/BaseReduxTypes";
import BaseCrudReducer from "@/redux/base/crud-slice-helpers/reducers";
import { resetStateKeys } from "@/redux/common/functions";
import { chatBotInitialState, IChatBotState } from ".";

export interface IChatBotReducer
  extends IBaseCrudReducer<IChatBotState, IChatBot> {
  resetState: (state: IChatBotState) => void;
}

class ChatBotReducer
  extends BaseCrudReducer<IChatBotState, IChatBot>
  implements IChatBotReducer
{
  resetState = (state: IChatBotState) =>
    resetStateKeys(state, chatBotInitialState);
}

const reducers: IChatBotReducer = new ChatBotReducer();

export default reducers;
