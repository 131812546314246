import React, { useEffect } from "react";
// @ts-ignore
import { Link as RouterLink } from "react-router-dom";
// @mui
import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
// utils
// routes
import { PATH_DASHBOARD } from "@/app/routes/paths";
// components
import CustomBreadcrumbs from "@/core/components/custom-breadcrumbs";
import Iconify from "@/core/components/iconify";
import { useSettingsContext } from "@/core/components/settings";
import { SkeletonPostItem } from "@/core/components/skeleton";
// sections
import AccessGuard from "@/app/auth/AccessGuard";
import { APP_PERMISSIONS, DEFAULT_RANGE } from "@/app/config-global";
import { useLocales } from "@/app/locales";
import ArticleCategoryService from "@/app/services/api/ArticleCategoryService";
import {
  IApplicationState,
  IAppLocale,
  IArticle,
  IArticleCategory,
} from "@/app/types";
import EmptyContent from "@/core/components/empty-content";
import Image from "@/core/components/image";
import Markdown from "@/core/components/markdown";
import Page from "@/core/components/page";
import Scrollbar from "@/core/components/scrollbar";
import useFilters from "@/core/hooks/useFilters";
import useToast from "@/core/hooks/useToast";
import useTranslation from "@/core/hooks/useTranslation";
import AppModal from "@/presentation/components/AppModal";
import DataFilterer from "@/presentation/components/DataFilterer";
import {
  BlogPostCard,
  BlogPostsSort,
} from "@/presentation/sections/@dashboard/blog";
import sliceNames from "@/redux/names";
import articleEffects from "@/redux/slices/article/effects";
import { store } from "@/redux/store";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: "desc", label: "app.desc" },
  { value: "asc", label: "app.asc" },
];

// ----------------------------------------------------------------------

export default function ArticlesPage() {
  const { themeStretch } = useSettingsContext();

  // Store
  const dispatch = store.dispatch;
  const { article } = useSelector((state: IApplicationState) => state);

  // State
  const [currentCategory, setCurrentCategory] =
    React.useState<IArticleCategory | null>(null);

  const posts = article.list.data;

  // Hooks
  const {
    keyword,
    handleKeyword,
    order,
    handleOrder,
    handleCategory_uuid,
    category_uuid,
    getData,
  } = useFilters({
    effects: articleEffects,
    initialState: {
      category_uuid: "",
      keyword: "",
      order: "desc",
    },
  });

  const t = useTranslation();
  const toast = useToast();
  const { currentLang } = useLocales();
  const [articleToShow, setArticleToShow] = React.useState<IArticle | null>(
    null
  );
  const lang = currentLang.value as IAppLocale;

  const handleShowArticle = (article: IArticle) => {
    setArticleToShow(article);
  };

  const closeArticlePreviewModal = () => {
    setArticleToShow(null);
  };

  const handlePublish = (e: any, checked: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      articleEffects.publish(articleToShow?.uuid ?? "", (data) => {
        setArticleToShow(data);
        toast.success(t("article.status_change_success"));
        setArticleToShow(null);
      })
    );
  };
  return (
    <Page
      title={"article.root_plural"}
      permissions={APP_PERMISSIONS[sliceNames.article].list}
    >
      <CustomBreadcrumbs
        heading="article.root_plural"
        links={[
          {
            name: "dashboard.root",
            href: PATH_DASHBOARD.root,
          },
          {
            name: "article.root_plural",
          },
        ]}
        action={
          <AccessGuard roles={APP_PERMISSIONS[sliceNames.article].create}>
            <Button
              component={RouterLink}
              to={PATH_DASHBOARD.articles.new}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              {t("app.add")}
            </Button>
          </AccessGuard>
        }
      />

      <Stack
        mb={5}
        direction="row"
        gap={{ xs: 2 }}
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        {/* <BlogPostsSearch
            handleKeywordChange={handleKeywordChange}
            keyword={keyword}
          /> */}
        <Stack
          direction="row"
          gap={{ xs: 2 }}
          alignItems="center"
          flexWrap="wrap"
        >
          <TextField
            name={"search"}
            id={"search-input"}
            // label={t("label.search")}
            onChange={(e) => handleKeyword(e.target.value)}
            placeholder={t("placeholder.search")}
            value={keyword}
          />
          <DataFilterer
            options={[
              {
                name: "category_uuid",
                type: "autocomplete",
                placeholder: "placeholder.category",
                getOptionLabel: (value: IArticleCategory) =>
                  value?.[`title_${currentLang.value as IAppLocale}`] ?? "",
                getOptionValue: (value: IArticleCategory) => value?.uuid ?? "",
                getValue: (options: IArticleCategory[]) =>
                  options.find((opt) => opt.uuid === category_uuid) ?? null,
                loadOptions: async (keyword: string) => {
                  const res = await ArticleCategoryService.paginate({
                    ...DEFAULT_RANGE,
                    keyword,
                  });
                  const data = await res.json();
                  return data.data;
                },
                onChange: (value: any) => handleCategory_uuid(value),
              },
            ]}
          />
        </Stack>
        <BlogPostsSort
          sortBy={order}
          sortOptions={SORT_OPTIONS}
          onSort={handleOrder}
        />
      </Stack>

      {!article.isLoading && article.list.data.length === 0 && (
        <Stack>
          {/* @ts-ignore */}
          <EmptyContent title={t("app.no_data")} />
        </Stack>
      )}

      <Grid
        container
        spacing={3}
      >
        {(article.isLoading ? [...Array(12)] : posts).map((post, index) =>
          !article.isLoading ? (
            <Grid
              key={post.id}
              item
              xs={12}
              sm={6}
              md={(index === 0 && 6) || 3}
            >
              <BlogPostCard
                post={post}
                index={index}
                handleShowPost={handleShowArticle}
                getData={getData}
              />
            </Grid>
          ) : (
            <SkeletonPostItem key={index} />
          )
        )}
      </Grid>

      {articleToShow && (
        <AppModal
          maxWidth="md"
          onClose={closeArticlePreviewModal}
          actions={[
            <AccessGuard
              key={`change_status_action_modal_button`}
              roles={APP_PERMISSIONS[sliceNames.article].change_status}
            >
              {article.isLoading ? (
                <CircularProgress />
              ) : (
                <FormControlLabel
                  label={t("label.publish")}
                  labelPlacement="end"
                  disabled={article.isLoading}
                  control={
                    <Switch
                      placeholder={t("placeholder.publish")}
                      title={t("title.change_status")}
                      checked={articleToShow?.is_online}
                      onChange={handlePublish}
                    />
                  }
                />
              )}
            </AccessGuard>,
          ]}
          breadCrumbLinks={[
            {
              name: "dashboard.root",
              href: PATH_DASHBOARD.root,
            },
            {
              name: "article.root_plural",
            },
            {
              name: articleToShow?.[`title_${lang}`] ?? "",
            },
          ]}
        >
          {articleToShow?.[`title_${lang}`] ||
          articleToShow?.[`content_${lang}`] ? (
            <Scrollbar>
              {articleToShow?.cover_image && (
                <PreviewHero
                  title={articleToShow?.[`title_${lang}`]}
                  cover={articleToShow?.cover_image?.medium?.url}
                />
              )}
              <Container sx={{ mt: 5, mb: 10 }}>
                <Markdown
                  children={articleToShow?.[`content_${lang}`]}
                  sx={{}}
                />
              </Container>
            </Scrollbar>
          ) : (
            <>
              {/* @ts-ignore */}
              <EmptyContent title="app.no_data" />
            </>
          )}
        </AppModal>
      )}
    </Page>
  );
}

function PreviewHero({ title, cover }: any) {
  return (
    <Box sx={{ position: "relative" }}>
      <Container
        sx={{
          bottom: "24px",
          left: 0,
          right: 0,
          zIndex: 9,
          position: "absolute",
          color: "common.white",
        }}
      >
        <Typography
          variant="h2"
          component="h1"
        >
          {title}
        </Typography>
      </Container>

      <Box
        sx={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 8,
          position: "absolute",
          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
        }}
      />
      <Image
        //@ts-ignore
        alt="cover"
        src={cover}
        ratio="16/9"
      />
    </Box>
  );
}
