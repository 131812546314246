const stat = {
  root: "statistique",
  root_plural: "statistiques",
  customer_pro_evolution: "Evolution du nombre d'utilisateurs (clients et pro)",
  salon_by_country: "Salon par localisation",
  salon_revenue_fn_time: "Evolution des revenues générées par un salon",
  app_revenue_fn_time: "Evolution des revenues de l'application",
  app_formula_revenue_fn_time: "Evolution des formules payées",
  app_booking_revenue_fn_time: "Evolution des réservations payées",
  app_order_revenue_fn_time: "Evolution des commandes payées",
  app_brut_revenue_fn_time: "Evolution du chiffre d'affaire de l'application",
  product_by_profitability_order: "Produits par ordre de rentabilité",
  salon_by_profitability_order: "Salons par ordre de rentabilité",
  salon_by_localisation: "Salons par localisation",
  salon_fn_town: "Salons en fontion de la ville",
  salon_fn_country: "Salons en fontion du pays",
  customer_by_localisation: "Clients par localisation",
  customer_fn_town: "Clients en fontion de la ville",
  customer_fn_country: "Clients en fontion du pays",
  app_users_fn_time: "Evolution des utilisateurs (client et salon)",
  app_salon_by_profit_fn_country: "Salon par ordre de rentabilité",
  formulas: "Formules",
  no_salon_selected: "Pas de salon sélectionné",
  no_salon_selected_text:
    "Tapez le nom du salon que vous recherchez dans le champs ci-dessus pour sélectionnez le salon dont vous voulez voir les statistiques",
  app_customer_by_profit_fn_country: "Client par localisation",
};

export default stat;
