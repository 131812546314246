import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
//
import Lightbox from "@/core/components/lightbox";
import Scrollbar from "@/core/components/scrollbar";
//
import EmptyContent from "@/core/components/empty-content";
import useTranslation from "@/core/hooks/useTranslation";
import { useSelector } from "@/redux/store";
import { Skeleton, Stack, Typography } from "@mui/material";
import moment from "moment";
import ChatMessageItem from "./ChatMessageItem";
// ----------------------------------------------------------------------

ChatMessageList.propTypes = {
  conversation: PropTypes.object,
};

export default function ChatMessageList({ conversation }) {
  const scrollRef = useRef(null);
  const t = useTranslation();

  const [selectedImage, setSelectedImage] = useState(-1);
  const {
    chat: { messages, isLoading, activeConversationId, recipients },
  } = useSelector((state) => state);

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [messages]);

  const imagesLightbox = messages
    .filter((messages) => messages.is_image)
    .map((messages) => ({ src: messages.content }));

  const handleOpenLightbox = (imageUrl) => {
    const imageIndex = imagesLightbox.findIndex(
      (image) => image.src === imageUrl
    );
    setSelectedImage(imageIndex);
  };

  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };

  return (
    <>
      <Scrollbar
        scrollableNodeProps={{
          ref: scrollRef,
        }}
        sx={{ p: 3, height: 1 }}
      >
        {activeConversationId && (
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            direction="row"
            sx={{
              mb: 1,
            }}
          >
            <Typography
              variant="caption"
              sx={{
                color: "text.disabled",
              }}
            >
              {t("chat.conversation_start")}
              {messages[0] && ": "}
              {messages[0] && moment(messages[0].sending_date).calendar()}
            </Typography>
          </Stack>
        )}
        {!activeConversationId && recipients.length === 0 && (
          <EmptyContent
            img={"/assets/illustrations/illustration_empty_content.svg"}
            title={t("chat.no_conversation")}
            description={t("chat.no_conversation_text")}
            sx={{ transform: "scale(0.7)", m: 0, p: 0 }}
          />
        )}
        {isLoading &&
          Array(4)
            .fill("")
            .map((message, index) => (
              <Stack
                key={`message-skeleton-${index}`}
                direction="row"
                justifyContent={index % 3 === 0 ? "start" : "end"}
              >
                <Skeleton
                  variant="rectangular"
                  width={100}
                  height={40}
                  sx={{ borderRadius: "10px", my: 1 }}
                />
              </Stack>
            ))}
        {messages.map((message, index) => (
          <React.Fragment key={message.id}>
            {messages[index - 1] &&
              moment(message.sending_date).format("l") !==
                moment(messages[index - 1].sending_date).format("l") && (
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  direction="row"
                  sx={{
                    mb: 1,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: "text.disabled",
                    }}
                  >
                    {moment(message.sending_date).calendar()}
                  </Typography>
                </Stack>
              )}
            <ChatMessageItem
              message={message}
              conversation={conversation}
              onOpenLightbox={() => handleOpenLightbox(message.content)}
            />
          </React.Fragment>
        ))}
      </Scrollbar>

      <Lightbox
        index={selectedImage}
        slides={imagesLightbox}
        open={selectedImage >= 0}
        close={handleCloseLightbox}
      />
    </>
  );
}
