import { useLocales } from "@/app/locales";
import { PATH_DASHBOARD } from "@/app/routes/paths";
import { IApplicationState, IAppLocale } from "@/app/types";
import useFilters from "@/core/hooks/useFilters";
import useTranslation from "@/core/hooks/useTranslation";
import TablePage from "@/presentation/components/TablePage";
import hairdressingSalonEffects from "@/redux/slices/hairdressingSalon/effects";
import React from "react";
import { useSelector } from "react-redux";
import hairdressingSalonColumns from "./data/columns";
import {
  hairdressingSalonTableTopOptions,
  hairdressingSalonToolbarOptions,
} from "./data/constants";
import hairdressingSalonFields from "./data/fields";

const ProfessionalsPage: React.FC = () => {
  // Store
  const { professional, globalData } = useSelector(
    (state: IApplicationState) => state
  );

  // Hooks
  const { currentLang } = useLocales();
  const t = useTranslation();
  const {
    getData,
    handleKeyword,
    handleRole,
    handleStatus,
    handleOrder,
    handleOrder_field,
    handlePage,
    handlePer_page,
    keyword,
    role,
    status,
    order,
    order_field,
  } = useFilters({
    effects: hairdressingSalonEffects,
    initialState: {
      page: professional.list.current_page,
      per_page: professional.list.per_page,
      keyword: "",
      role: "",
      status: "",
      order: "",
      order_field: "",
    },
  });

  // Constants
  const formattedRoles = globalData.data.roles
    .filter((role) => [2, 3].includes(role.id))
    .map((role) => ({
      label: role[`title_${currentLang.value as IAppLocale}`],
      value: role.id,
    }));

  return (
    <TablePage
      model="professional"
      noCheckBoxes
      // ** Genre **
      // female
      // ** Toolbar **
      tableTopOptions={hairdressingSalonTableTopOptions}
      toolbarOptionChange={handleStatus}
      currentToolbarOption={status}
      toolbarOptions={hairdressingSalonToolbarOptions(
        formattedRoles,
        {
          value: keyword,
          onChange: handleKeyword,
        }
        // {
        //   value: role,
        //   onChange: handleRole,
        // }
      )}
      // ** On Select rows **
      idProperty="uuid"
      // ** Table CRUD **
      fields={hairdressingSalonFields(
        t,
        globalData.data.prestations,
        globalData.data.current_hairstyles,
        globalData.data.people_types,
        currentLang.value as IAppLocale
      )}
      stateData={professional}
      columns={hairdressingSalonColumns(currentLang.value)}
      effects={hairdressingSalonEffects}
      getData={getData}
      // ** Filter options **
      isNotFound={(data) =>
        (!data.length && !!keyword) ||
        (!data.length && !!role) ||
        (!data.length && !!status)
      }
      isFiltered={(data) => keyword !== "" || role !== "" || status !== ""}
      onReset={() => {
        handleKeyword("");
        handleRole("");
        handleStatus("");
      }}
      // ** Sorting **
      handleFilterOrder={handleOrder}
      handleFilterOrderField={handleOrder_field}
      order={order}
      orderField={order_field}
      // ** Pagination **
      onChangePage={handlePage}
      onChangeRowsPerPage={handlePer_page}
      // ** Table Row Actions **
      showDetailsUrl={(row) =>
        PATH_DASHBOARD.hairdressingSalons.view(row?.uuid ?? "")
      }
    />
  );
};

export default ProfessionalsPage;
