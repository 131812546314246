import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices
import sliceNames from "./names";
import administratorReducer from "./slices/administrator";
import articleReducer from "./slices/article";
import blogReducer from "./slices/blog";
import bookingReducer from "./slices/booking";
import chatReducer from "./slices/chat";
import chatBotReducer from "./slices/chatBot";
import customerReducer from "./slices/customer";
import globalDataReducer from "./slices/globalData";
import hairdressingSalonReducer from "./slices/hairdressingSalon";
import intentReducer from "./slices/intent";
import orderReducer from "./slices/order";
import productReducer from "./slices/product";
import statReducer from "./slices/stat";
import videoReducer from "./slices/video";
import articleCategoryReducer from "./slices/articleCategory";
import blogCategoryReducer from "./slices/blogCategory";
import autoFormReducer from "./slices/autoForm";

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

export const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const rootReducer = combineReducers({
  [sliceNames.administrator]: administratorReducer,
  [sliceNames.article]: articleReducer,
  [sliceNames.product]: productReducer,
  [sliceNames.order]: orderReducer,
  [sliceNames.video]: videoReducer,
  [sliceNames.chatBot]: chatBotReducer,
  [sliceNames.globalData]: globalDataReducer,
  [sliceNames.customer]: customerReducer,
  [sliceNames.chat]: chatReducer,
  [sliceNames.stat]: statReducer,
  [sliceNames.intent]: intentReducer,
  [sliceNames.professional]: hairdressingSalonReducer,
  [sliceNames.booking]: bookingReducer,
  [sliceNames.articleCategory]: articleCategoryReducer,
  [sliceNames.blogCategory]: blogCategoryReducer,
  [sliceNames.autoForm]: autoFormReducer,
  [sliceNames.blog]: blogReducer,
  // product: persistReducer(productPersistConfig, productReducer),
});

export default rootReducer;
