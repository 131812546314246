import Iconify from "@/core/components/iconify";
import Label from "@/core/components/label";
import MenuPopover from "@/core/components/menu-popover";
import useTranslation from "@/core/hooks/useTranslation";
import { getValueOrNA } from "@/core/utils/getFullName";
import {
  Box,
  Checkbox,
  IconButton,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { instanceOf } from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import { CustomAvatar } from "../custom-avatar";

type IAlign = "left" | "center" | "right";

export interface ICustomAppRowAction {
  icon?: ((data: any) => any) | any;
  label?: ((data: any) => string) | string;
  onClick: (data: any) => void;
  render?: (data: any, onClick: () => void) => void;
}

export interface ITableRowColumn<T = any> {
  header: {
    id: string | number;
    label: string | number;
    align?: IAlign;
  };
  type?: "avatar" | "badge" | "normal" | "lightBox";
  align?: IAlign;
  avatarUrl?: (row: T) => string;
  videoThumbnail?: (row: T) => string;
  videoUrl?: (row: T) => string;
  title?: ((row: T) => any) | string;
  sx?: object;
  badgeColor?: ((data: any) => string) | string;
}

export interface ITableRowProps {
  allData?: any[];
  data: any;
  selected?: boolean;
  onSelectRow?: (row: any) => void;
  onDeleteRow?: (row: any) => void;
  onEditRow?: (row: any) => void;
  onShowDetails?: (row: any) => void;
  columns?: ITableRowColumn[];
  noActions?: boolean;
  rowIndex?: number;
  customActions?: ICustomAppRowAction[];
}

const AppTableRow: React.FC<ITableRowProps> = ({
  data,
  onDeleteRow,
  onEditRow,
  onSelectRow,
  onShowDetails,
  selected,
  columns,
  noActions,
  allData,
  rowIndex,
  customActions,
}) => {
  const t = useTranslation();

  const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);
  const [isLightBoxOpen, setIsLightBoxOpen] = React.useState<boolean>(false);

  const [openPopover, setOpenPopover] = React.useState(null);
  const [currentLightBoxIndex, setCurrentLightBoxIndex] =
    React.useState<number>(0);

  const closeLightBox = () => {
    setIsLightBoxOpen(false);
  };

  const openLightBox = () => {
    setCurrentLightBoxIndex(rowIndex ?? 0);
    setIsLightBoxOpen(true);
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: any) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const resolveColTitle = (col: ITableRowColumn) =>
    col?.title &&
    (col?.title instanceof Function
      ? typeof col?.title(data) === "string"
        ? t(col?.title(data))
        : col?.title(data)
      : typeof col.title === "string"
      ? t(col.title)
      : col.title);

  const getYoutubeVideoId = (link: string) => {
    const id = link.split("?v=")[1];
    if (id) {
      return id;
    }
    const linkArr = link.split("/");
    if (linkArr.length > 0) {
      return linkArr[linkArr.length - 1];
    } else return "";
  };

  return (
    <>
      <TableRow
        hover
        selected={selected}
      >
        {onSelectRow && (
          <TableCell padding="checkbox">
            <Checkbox
              checked={selected}
              onClick={() => {
                onSelectRow(data);
              }}
            />
          </TableCell>
        )}

        {columns?.map((col, index) =>
          col.type === "lightBox" ? (
            <TableCell
              align={col?.align}
              key={`${col.type}${index}`}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ cursor: "pointer" }}
                onClick={openLightBox}
              >
                <Box
                  sx={{
                    position: "relative",
                    height: 40,
                    width: 40 + 40 * (9 / 16),
                    overflow: "hidden",
                    backgroundImage: `url("${
                      col.videoUrl
                        ? col.videoUrl(data).startsWith("https://www.youtu") ||
                          col.videoUrl(data).startsWith("https://youtu")
                          ? `http://img.youtube.com/vi/${getYoutubeVideoId(
                              col.videoUrl(data)
                            )}/1.jpg`
                          : col.videoThumbnail
                          ? col.videoThumbnail(data)
                          : ""
                        : ""
                    }")`,
                    backgroundSize: "cover",
                    borderRadius: "5px",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "#0008",
                    }}
                  />
                  <Iconify
                    icon="material-symbols:play-arrow-rounded"
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                    }}
                  />
                </Box>
                <Typography
                  variant="subtitle2"
                  noWrap
                >
                  {getValueOrNA(resolveColTitle(col))}
                </Typography>
              </Stack>
            </TableCell>
          ) : col.type === "avatar" ? (
            <TableCell
              align={col?.align}
              key={`${col.type}${index}`}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
              >
                {/* @ts-ignore */}
                <CustomAvatar
                  //@ts-ignore
                  alt={
                    typeof getValueOrNA(resolveColTitle(col)) === "string"
                      ? getValueOrNA(resolveColTitle(col))
                      : ""
                  }
                  name={
                    typeof getValueOrNA(resolveColTitle(col)) === "string"
                      ? getValueOrNA(resolveColTitle(col))
                      : ""
                  }
                  src={col?.avatarUrl ? col?.avatarUrl(data) : ""}
                />
                <Typography
                  variant="subtitle2"
                  noWrap
                >
                  {getValueOrNA(resolveColTitle(col))}
                </Typography>
              </Stack>
            </TableCell>
          ) : col.type === "badge" ? (
            <TableCell
              align={col?.align}
              key={`${col.type}${index}`}
            >
              {/* @ts-ignore */}
              <Label
                variant="soft"
                color={(col?.badgeColor instanceof Function
                  ? col.badgeColor(data)
                  : col.badgeColor ?? ""
                ).toLowerCase()}
                sx={{ textTransform: "capitalize" }}
              >
                {getValueOrNA(resolveColTitle(col))}
              </Label>
            </TableCell>
          ) : (
            <TableCell
              align={col?.align}
              sx={col?.sx}
              key={`${col.type}${index}`}
            >
              {getValueOrNA(resolveColTitle(col))}
            </TableCell>
          )
        )}

        {!noActions && (
          <TableCell align="right">
            <IconButton
              color={openPopover ? "inherit" : "default"}
              onClick={handleOpenPopover}
            >
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </TableCell>
        )}
      </TableRow>

      {/* @ts-ignore */}
      <MenuPopover
        open={openPopover}
        // @ts-ignore
        onClose={() => {
          handleClosePopover();
        }}
        arrow="right-top"
      >
        {customActions
          ? customActions.map((action, index) => (
              <MenuItem
                key={
                  data["uuid"] ??
                  data["public_id"] ??
                  data["id"] ??
                  `tableAction${index}`
                }
                onClick={() => {
                  action.onClick && action.onClick(data);
                  handleClosePopover();
                }}
              >
                <>
                  {(() => {
                    const icon =
                      action.icon instanceof Function
                        ? action.icon(data)
                        : action.icon;
                    return typeof icon === "string" ? (
                      <Iconify icon={icon} />
                    ) : (
                      icon
                    );
                  })()}
                  {action.label
                    ? t(
                        action.label instanceof Function
                          ? action.label(data)
                          : action.label
                      )
                    : null}
                  {action.render &&
                    action.render(data, () => action.onClick(data))}
                </>
              </MenuItem>
            ))
          : null}

        {onShowDetails && (
          <MenuItem
            onClick={() => {
              onShowDetails(data);
              handleClosePopover();
            }}
          >
            <Iconify icon="ic:round-remove-red-eye" />
            {t("app.details")}
          </MenuItem>
        )}

        {onEditRow && (
          <MenuItem
            onClick={() => {
              onEditRow(data);
              handleClosePopover();
            }}
          >
            <Iconify icon="eva:edit-fill" />
            {t("app.edit")}
          </MenuItem>
        )}

        {onDeleteRow && (
          <MenuItem
            onClick={() => {
              onDeleteRow(data);
              handleClosePopover();
            }}
            sx={{ color: "error.main" }}
          >
            <Iconify icon="eva:trash-2-outline" />
            {t("app.delete")}
          </MenuItem>
        )}
      </MenuPopover>

      {isLightBoxOpen &&
        ReactDOM.createPortal(
          <Box sx={{ zIndex: 999999, position: "relative" }}>
            <ReactImageVideoLightbox
              data={allData?.map((data) => {
                const resolveUrl: (data: any) => string = columns
                  ? columns[0]?.videoUrl ?? ((data: any) => "")
                  : (data: any) => "";
                const title = columns ? resolveColTitle(columns[0]) : "";
                return {
                  url: `https://www.youtube.com/embed/${getYoutubeVideoId(
                    resolveUrl(data)
                  )}`,
                  type: "video",
                  title,
                };
              })}
              startIndex={currentLightBoxIndex}
              showResourceCount={true}
              onCloseCallback={closeLightBox}
              onNavigationCallback={(currentIndex: number) => null}
            />
          </Box>,
          document.body
        )}
    </>
  );
};

export default AppTableRow;
