import { IVideoCreate, IVideoRange, IVideoUpdate } from "@/app/types";
import BaseService from "./BaseService";
import { VideoUrls } from "./urls";

class VideoService {
  static get = (uuid: string) => {
    return BaseService.getRequest(VideoUrls.GET(uuid), true);
  };
  static paginate = (query: IVideoRange) => {
    return BaseService.getRequest(VideoUrls.PAGINATE(query), true);
  };
  static create = (data: IVideoCreate) => {
    return BaseService.postRequest(VideoUrls.CREATE, data, true);
  };
  static update = ({ uuid, ...rest }: IVideoUpdate) => {
    return BaseService.putRequest(VideoUrls.UPDATE(uuid), rest, true);
  };
  static delete = (uuid: string) => {
    return BaseService.deleteRequest(VideoUrls.DELETE(uuid), {}, true);
  };
}

export default VideoService;
