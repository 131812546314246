import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import {
  Avatar,
  Box,
  Card,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
// _mock
import { _socials } from "@/app/_mock/arrays";
// components
import { CustomTextField } from "@/core/components/custom-input";
import Iconify from "@/core/components/iconify";
import MenuPopover from "@/core/components/menu-popover";
import SearchNotFound from "@/core/components/search-not-found";
import useTranslation from "@/core/hooks/useTranslation";
import EmptyContent from "@/core/components/empty-content";

// ----------------------------------------------------------------------

ProfileFriends.propTypes = {
  friends: PropTypes.array,
  onSearchFriends: PropTypes.func,
  searchFriends: PropTypes.string,
};

export default function ProfileFriends({
  friends,
  searchFriends,
  onSearchFriends,
}) {
  const dataFiltered = applyFilter(friends, searchFriends);
  const t = useTranslation();

  const isNotFound = dataFiltered.length === 0;

  return (
    <>
      <Stack
        spacing={3}
        justifyContent="space-between"
        direction={{ xs: "column", sm: "row" }}
        sx={{ my: 5 }}
      >
        <Typography variant="h4">{t("label.hairdressers")}</Typography>

        <CustomTextField
          width={220}
          size="small"
          value={searchFriends}
          onChange={onSearchFriends}
          placeholder={t("placeholder.search")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: "text.disabled" }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {isNotFound ? (
        <EmptyContent title={t("professional.no_hairdresser")} />
      ) : (
        <Box
          gap={3}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
          }}
        >
          {dataFiltered.map((friend) => (
            <FriendCard
              key={friend.uuid}
              friend={friend}
            />
          ))}
        </Box>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

FriendCard.propTypes = {
  friend: PropTypes.object,
};

function FriendCard({ friend }) {
  const t = useTranslation();
  const { firstname: name, headdress_at_home, avatar } = friend;
  const avatarUrl = avatar?.url ?? "";

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleDelete = () => {
    handleClosePopover();
    console.log("DELETE", name);
  };

  const handleEdit = () => {
    handleClosePopover();
    console.log("EDIT", name);
  };

  return (
    <>
      <Card
        sx={{
          py: 5,
          display: "flex",
          position: "relative",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Avatar
          alt={name}
          src={avatarUrl}
          sx={{ width: 64, height: 64, mb: 3 }}
        />

        <Link
          variant="subtitle1"
          color="text.primary"
        >
          {name}
        </Link>

        <Typography
          variant="body2"
          sx={{
            color: headdress_at_home ? "text.secondary" : "error.main",
            mb: 1,
            mt: 0.5,
          }}
        >
          <Stack
            direction="row"
            spacing={3}
            alignItems={"center"}
          >
            <Iconify
              icon={
                headdress_at_home
                  ? "material-symbols:check-small-rounded"
                  : "ic:round-close"
              }
            />
            {t("label.headdress_at_home")}
          </Stack>
        </Typography>

        {/*
        <Stack
          alignItems="center"
          justifyContent="center"
          direction="row"
        >
          {_socials.map((social) => (
            <IconButton
              key={social.name}
              sx={{
                color: social.color,
                "&:hover": {
                  bgcolor: alpha(social.color, 0.08),
                },
              }}
            >
              <Iconify icon={social.icon} />
            </IconButton>
          ))}
        </Stack> */}

        {/*
        <IconButton
          color={openPopover ? "inherit" : "default"}
          onClick={handleOpenPopover}
          sx={{ top: 8, right: 8, position: "absolute" }}
        >
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
        */}
      </Card>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
      >
        <MenuItem
          onClick={handleDelete}
          sx={{ color: "error.main" }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Delete
        </MenuItem>

        <MenuItem onClick={handleEdit}>
          <Iconify icon="eva:edit-fill" />
          Edit
        </MenuItem>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter(inputData, query) {
  if (query) {
    return inputData.filter(
      (friend) => friend.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  return inputData;
}
