import HairdressingSalonService from "@/app/services/api/HairdressingSalonService";
import {
  IHairdressingSalonCreate,
  IHairdressingSalonRange,
  IHairdressingSalonUpdate,
} from "@/app/types";
import { IReduxBaseCrudEffect } from "@/app/types/BaseReduxTypes";
import BaseReduxCrudEffects from "@/redux/base/crud-slice-helpers/effects";
import { slice } from ".";

export interface IHairdressingSalonEffects
  extends IReduxBaseCrudEffect<
    IHairdressingSalonRange,
    IHairdressingSalonCreate,
    IHairdressingSalonUpdate,
    HairdressingSalonService
  > {}

class HairdressingSalonEffects
  extends BaseReduxCrudEffects<
    IHairdressingSalonRange,
    IHairdressingSalonCreate,
    IHairdressingSalonUpdate
  >
  implements IHairdressingSalonEffects {}

const hairdressingSalonEffects = new HairdressingSalonEffects(
  slice,
  HairdressingSalonService
);

export default hairdressingSalonEffects;
