import { PATH_DASHBOARD } from "@/app/routes/paths";
import { IApplicationState, IOrder } from "@/app/types";
import CustomBreadcrumbs from "@/core/components/custom-breadcrumbs";
import Iconify from "@/core/components/iconify";
import Image from "@/core/components/image";
import Label from "@/core/components/label";
import Scrollbar from "@/core/components/scrollbar/Scrollbar";
import useCurrency from "@/core/hooks/useCurrency";
import useFilters from "@/core/hooks/useFilters";
import useTranslation from "@/core/hooks/useTranslation";
import toSentence from "@/core/utils/toSentence";
import AppModal from "@/presentation/components/AppModal";
import TablePage, {
  ICustomBreadcrumbsLink,
} from "@/presentation/components/TablePage";
import InvoiceAnalytic from "@/presentation/sections/@dashboard/invoice/InvoiceAnalytic";
import orderEffects from "@/redux/slices/order/effects";
import {
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { Box } from "@mui/system";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import orderColumns from "./data/columns";
import {
  orderColorByStatus,
  orderPaymentTypeIcons,
  orderTableTopOptions,
  orderToolbarOptions,
} from "./data/constants";
import orderFields from "./data/fields";

export interface IOrdersPageProps {
  additionalFilters?: Record<string, string | undefined>;
  breadcrumbs?: null | ICustomBreadcrumbsLink[];
  noPage?: boolean;
  noUser?: boolean;
  noSalon?: boolean;
}

const OrdersPage: React.FC<IOrdersPageProps> = ({
  additionalFilters,
  breadcrumbs,
  noPage,
  noUser,
  noSalon,
}) => {
  // Store
  const { order, globalData } = useSelector(
    (state: IApplicationState) => state
  );

  // State
  const [orderToShow, setOrderToShow] = React.useState<IOrder | null>(null);

  // Functions
  const closeModal = () => {
    setOrderToShow(null);
  };

  // Hooks
  const t = useTranslation();
  const theme: any = useTheme();
  const {
    getData,
    handleKeyword,
    handlePayment_type,
    handleStatus,
    handleOrder,
    handleOrder_field,
    handlePage,
    handlePer_page,
    keyword,
    payment_type,
    status,
    order: _order,
    order_field,
    user_public_id,
    handleHairdressing_salon_uuid,
    hairdressing_salon_uuid,
    handleUser_public_id,
  } = useFilters({
    effects: orderEffects,
    initialState: {
      page: order.list.current_page,
      per_page: order.list.per_page,
      keyword: "",
      payment_type: "",
      status: "",
      order: "",
      order_field: "",
      hairdressing_salon_uuid: "",
      user_public_id: "",
      ...(additionalFilters ?? {}),
    },
  });
  const currency = useCurrency();

  const paymentTypes = globalData.data.payment_type
    .filter((p) => p !== "APPLE_PAY" && p !== "GOOGLE_PAY")
    .map((paymentType) => ({
      label: `app.${paymentType}`,
      value: paymentType,
    }));

  return (
    <>
      <TablePage
        model="order"
        noCreate
        noUpdate
        noDelete
        noDeleteBulk
        noAddButton
        noCheckBoxes
        onShowDetails={(row) => setOrderToShow(row)}
        noPage={noPage}
        // ** Breadcrumbs **
        breadcrumbLinks={breadcrumbs}
        // ** Genre **
        female
        // ** After Breadcrumb **
        afterBreadCrumbComponent={
          order.isLoading ? null : (
            <Card sx={{ mb: 5 }}>
              <Scrollbar>
                <Stack
                  direction="row"
                  divider={
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ borderStyle: "dashed" }}
                    />
                  }
                  sx={{ py: 2 }}
                >
                  <InvoiceAnalytic
                    title={t("app.total")}
                    total={order.list.total_orders}
                    percent={100}
                    price={order.list.total_cost_orders}
                    icon="ic:round-receipt"
                    color={theme.palette.primary.main}
                  />

                  <InvoiceAnalytic
                    title={t("status.PENDING")}
                    total={order.list.total_pending}
                    percent={
                      (order.list.total_pending * 100) / order.list.total_orders
                    }
                    price={order.list.total_cost_pending}
                    icon="eva:file-fill"
                    color={theme.palette.text.secondary}
                  />

                  <InvoiceAnalytic
                    title={t("status.BOOK")}
                    total={order.list.total_booked}
                    percent={
                      (order.list.total_booked * 100) / order.list.total_orders
                    }
                    price={order.list.total_cost_booked}
                    icon="material-symbols:save-rounded"
                    color={theme.palette.info.main}
                  />

                  <InvoiceAnalytic
                    title={t("status.IN_PROGRESS")}
                    total={order.list.total_in_progress}
                    percent={
                      (order.list.total_in_progress * 100) /
                      order.list.total_orders
                    }
                    price={order.list.total_cost_in_progress}
                    icon="eva:clock-fill"
                    color={theme.palette.warning.main}
                  />

                  <InvoiceAnalytic
                    title={t("status.ACCEPTED")}
                    total={order.list.total_accepted}
                    percent={
                      (order.list.total_accepted * 100) /
                      order.list.total_orders
                    }
                    price={order.list.total_cost_accepted}
                    icon="eva:checkmark-circle-2-fill"
                    color={theme.palette.success.main}
                  />
                </Stack>
              </Scrollbar>
            </Card>
          )
        }
        // ** Toolbar **
        tableTopOptions={orderTableTopOptions}
        toolbarOptionChange={handleStatus}
        currentToolbarOption={status}
        toolbarOptions={orderToolbarOptions(
          {
            value: keyword,
            onChange: handleKeyword,
          },
          {
            value: payment_type,
            onChange: handlePayment_type,
          },
          noSalon
            ? null
            : {
                value: hairdressing_salon_uuid,
                onChange: handleHairdressing_salon_uuid,
              },
          noUser
            ? null
            : {
                value: user_public_id,
                onChange: handleUser_public_id,
              },
          paymentTypes
        )}
        // ** On Select rows **
        idProperty="uuid"
        // ** Table CRUD **
        fields={orderFields(t)}
        stateData={order}
        columns={orderColumns(currency)}
        effects={orderEffects}
        getData={getData}
        // ** Filter options **
        isNotFound={
          (data) => data.length === 0
          // (!data.length && !!keyword) ||
          // (!data.length && !!role) ||
          // (!data.length && !!status)
        }
        isFiltered={(data) =>
          keyword !== "" || payment_type !== "" || status !== ""
        }
        onReset={() => {
          handleKeyword("");
          handlePayment_type("");
          handleStatus("");
        }}
        // ** Sorting **
        handleFilterOrder={handleOrder}
        handleFilterOrderField={handleOrder_field}
        order={_order}
        orderField={order_field}
        // ** Pagination **
        onChangePage={handlePage}
        onChangeRowsPerPage={handlePer_page}
      />

      {orderToShow && (
        <AppModal
          onClose={closeModal}
          maxWidth="md"
          breadCrumbLinks={[
            { name: "dashboard.root", href: PATH_DASHBOARD.root },
            { name: `order.root_plural` },
            { name: orderToShow?.uuid ?? "" },
          ]}
        >
          <Stack
            sx={{ py: 3, px: 1 }}
            divider={<Divider component="div" />}
            spacing={2}
          >
            <Stack spacing={1}>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="h5"
                color="text.primary"
              >
                {toSentence(t("order.root"))}: {orderToShow?.uuid}
              </Typography>
              <Stack
                justifyContent={"space-between"}
                flexWrap={"wrap"}
                direction="row"
                spacing={1}
              >
                <Typography
                  variant="caption"
                  sx={{ color: "text.secondary" }}
                >
                  <>
                    {t("label.date_added")}:{" "}
                    {moment(orderToShow?.date_added).format("LL")}
                  </>
                </Typography>
                {/* @ts-ignore */}
                <Label
                  // @ts-ignore
                  color={(orderColorByStatus as any)[orderToShow?.status ?? ""]}
                  title={orderToShow?.status}
                  sx={{ color: "text.secondary" }}
                >
                  {t(`status.${orderToShow?.status}`)}
                </Label>
              </Stack>
            </Stack>
            <Scrollbar sx={{ maxHeight: "480px" }}>
              <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                {orderToShow?.order_products?.map((p) => (
                  <ListItem
                    key={p.uuid}
                    alignItems="flex-start"
                  >
                    <ListItemAvatar>
                      <Image
                        // @ts-ignore
                        alt={p?.product?.name ?? ""}
                        src={p?.product?.images?.[0]?.url ?? ""}
                        style={{ width: 50, height: 50 }}
                        sx={{
                          border: "1px solid lightgray",
                          borderRadius: "5px",
                        }}
                      />
                    </ListItemAvatar>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent={"space-between"}
                      sx={{ width: "100%" }}
                    >
                      <ListItemText
                        primary={p?.product?.name}
                        primaryTypographyProps={{ mt: 1 }}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.secondary"
                            >
                              {t("label.qty")}: {p?.quantity}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <Typography
                        fontWeight="bold"
                        variant="body2"
                        sx={{ pt: 1 }}
                      >
                        {currency(p?.total_price ?? 0)}
                      </Typography>
                    </Stack>
                  </ListItem>
                ))}
              </List>
            </Scrollbar>
            <Grid
              container
              rowSpacing={1}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Typography fontWeight="bold">{t("app.payment")}</Typography>
                <Box>
                  <Iconify
                    width={36}
                    icon={
                      orderPaymentTypeIcons[
                        orderToShow?.payment_type as keyof typeof orderPaymentTypeIcons
                      ] ?? ""
                    }
                  />
                  <Typography
                    fontWeight="bold"
                    sx={{ color: "primary.dark" }}
                  >
                    {t("app.total")}: {currency(orderToShow?.total_price)}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Typography fontWeight="bold">{t("app.delivery")}</Typography>
                <Box>
                  {[
                    orderToShow?.delivery?.address_one,
                    orderToShow?.delivery?.town,
                    orderToShow?.delivery?.province,
                    orderToShow?.delivery?.country,
                  ].map((v) => (
                    <Typography
                      key={v}
                      sx={{ color: "text.secondary" }}
                      variant={"body2"}
                    >
                      {v}
                    </Typography>
                  ))}
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Typography
                fontWeight="bold"
                sx={{ mb: 1 }}
              >
                {t("app.history")}
              </Typography>
              <Stack
                justifyContent={"space-between"}
                flexWrap={"wrap"}
                direction="row"
                spacing={1}
              >
                <Typography
                  variant="caption"
                  sx={{ color: "text.secondary" }}
                >
                  <>{t("app.date")}</>
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ color: "text.secondary" }}
                >
                  <>{t(`app.status`)}</>
                </Typography>
              </Stack>
              {[
                orderToShow?.pending_date,
                orderToShow?.in_progress_date,
                orderToShow?.book_date,
                orderToShow?.accepted_date,
              ].map((v, index) =>
                !v ? null : (
                  <Stack
                    key={`${v}${index}`}
                    justifyContent={"space-between"}
                    flexWrap={"wrap"}
                    direction="row"
                    spacing={1}
                    divider={<Divider />}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      {v && <>{moment(v).format("LL")}</>}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "secondary.main" }}
                    >
                      <>
                        {t(
                          `status.${
                            ["PENDING", "IN_PROGRESS", "BOOK", "ACCEPTED"][
                              index
                            ]
                          }`
                        )}
                      </>
                    </Typography>
                  </Stack>
                )
              )}
            </Box>
          </Stack>
        </AppModal>
      )}
    </>
  );
};

export default OrdersPage;
