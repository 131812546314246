import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import { Box, Drawer, IconButton, Stack } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
// hooks
import useResponsive from "@/core/hooks/useResponsive";
// utils
// components
import Iconify from "@/core/components/iconify";
import Scrollbar from "@/core/components/scrollbar";
//
import { useAuthContext } from "@/app/auth/useAuthContext";
import { getContacts } from "@/redux/slices/chat";
import { dispatch } from "@/redux/store";
import ChatNavAccount from "./ChatNavAccount";
import ChatNavList from "./ChatNavList";

// ----------------------------------------------------------------------

const StyledToggleButton = styled((props) => (
  <IconButton
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  left: 0,
  zIndex: 9,
  width: 32,
  height: 32,
  position: "absolute",
  top: theme.spacing(13),
  borderRadius: `0 12px 12px 0`,
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.customShadows.primary,
  "&:hover": {
    backgroundColor: theme.palette.primary.darker,
  },
}));

// ----------------------------------------------------------------------

const NAV_WIDTH = 320;

const NAV_COLLAPSE_WIDTH = 96;

ChatNav.propTypes = {
  conversations: PropTypes.object,
  activeConversationId: PropTypes.string,
};

export default function ChatNav({ conversations, activeConversationId }) {
  const theme = useTheme();

  const navigate = useNavigate();

  const isDesktop = useResponsive("up", "md");

  const [openNav, setOpenNav] = useState(false);

  const [searchResults, setSearchResults] = useState([]);

  const [searchContacts, setSearchContacts] = useState("");

  const isCollapse = isDesktop && !openNav;

  const { user } = useAuthContext();

  useEffect(() => {
    if (!isDesktop) {
      handleCloseNav();
    } else {
      handleOpenNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  const handleToggleNav = () => {
    setOpenNav(!openNav);
  };

  const handleOpenNav = () => {
    setOpenNav(true);
  };

  const handleCloseNav = () => {
    setOpenNav(false);
  };

  // Effects
  React.useEffect(() => {
    dispatch(
      getContacts({
        keyword: searchContacts,
      })
    );
  }, [searchContacts]);

  const renderContent = (
    <>
      <Box sx={{ p: 2.5 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          {!isCollapse && (
            <>
              <ChatNavAccount />
              <Box sx={{ flexGrow: 1 }} />
            </>
          )}

          <IconButton onClick={handleToggleNav}>
            <Iconify
              icon={
                openNav
                  ? "eva:arrow-ios-back-fill"
                  : "eva:arrow-ios-forward-fill"
              }
            />
          </IconButton>

          {/* {!isCollapse && (
            <IconButton
              component={RouterLink}
              to={PATH_DASHBOARD.chat.new}
            >
              <Iconify icon="eva:edit-fill" />
            </IconButton>
          )} */}
        </Stack>

        {/* {!isCollapse && (
          <ChatNavSearch
            value={searchContacts}
            onChange={handleChangeSearch}
            onClickAway={() => setSearchContacts("")}
          />
        )} */}
      </Box>

      <Scrollbar>
        <ChatNavList
          openNav={openNav}
          onCloseNav={handleCloseNav}
          conversations={conversations}
          selected={(conversationId) => activeConversationId === conversationId}
        />
        {/* {!searchContacts ? (
        ) : (
          <ChatNavSearchResults
            searchContacts={searchContacts}
            searchResults={searchResults}
            onSelectContact={handleSelectContact}
          />
        )} */}
      </Scrollbar>
    </>
  );

  return (
    <>
      {!isDesktop && (
        <StyledToggleButton onClick={handleToggleNav}>
          <Iconify
            width={16}
            icon="eva:people-fill"
          />
        </StyledToggleButton>
      )}

      {isDesktop ? (
        <Drawer
          open={openNav}
          variant="persistent"
          PaperProps={{
            sx: {
              pb: 1,
              width: 1,
              position: "static",
              ...(isCollapse && {
                transform: "none !important",
                visibility: "visible !important",
              }),
            },
          }}
          sx={{
            width: NAV_WIDTH,
            transition: theme.transitions.create("width"),
            ...(isCollapse && {
              width: NAV_COLLAPSE_WIDTH,
            }),
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={handleCloseNav}
          ModalProps={{ keepMounted: true }}
          PaperProps={{
            sx: {
              pb: 1,
              width: NAV_WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </>
  );
}
