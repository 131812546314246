import { IAdministrator, IAppLocale } from "@/app/types";
import { ITableRowColumn } from "@/core/components/table/AppTableRow";

const administratorColumns: (
  locale: IAppLocale
) => ITableRowColumn<IAdministrator>[] = (locale: IAppLocale) => [
  {
    header: { id: "name", label: "administrator.name", align: "left" },
    type: "avatar",
    title: (row) => row?.firstname,
    avatarUrl: (row) => row?.avatar?.url ?? "",
  },
  {
    header: { id: "email", label: "administrator.email", align: "left" },
    title: (row) => row?.email,
    align: "left",
  },
  {
    header: { id: "role", label: "administrator.role", align: "left" },
    title: (row) => row?.role?.[`title_${locale as IAppLocale}`],
    align: "left",
  },
  {
    header: { id: "status", label: "administrator.status", align: "left" },
    type: "badge",
    title: (row) => `status.${row?.status ?? ""}`,
    badgeColor: (row) => (row?.status === "ACTIVED" ? "success" : "error"),
    sx: { textTransform: "capitalize" },
  },
];

export default administratorColumns;
