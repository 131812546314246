import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
// @mui
import {
  Box,
  Container,
  Divider,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
// routes
// utils
import axios from "@/core/utils/axios";
// components
import CustomBreadcrumbs from "@/core/components/custom-breadcrumbs";
import Markdown from "@/core/components/markdown";
import { useSettingsContext } from "@/core/components/settings";
import { SkeletonPostDetails } from "@/core/components/skeleton";
// sections
import { DEFAULT_RANGE } from "@/app/config-global";
import { PATH_DASHBOARD } from "@/app/routes/paths";
import useFilters from "@/core/hooks/useFilters";
import {
  BlogPostCard,
  BlogPostCommentForm,
  BlogPostCommentList,
  BlogPostHero,
  BlogPostTags,
} from "@/presentation/sections/@dashboard/blog";

// ----------------------------------------------------------------------

export default function BlogPostPage() {
  const { themeStretch } = useSettingsContext();

  const { title } = useParams();

  const [recentPosts, setRecentPosts] = useState<any[]>([]);

  const [post, setPost] = useState<any>(null);

  const [loadingPost, setLoadingPost] = useState<boolean>(true);

  const [errorMsg, setErrorMsg] = useState<any>(null);

  // Functions
  const getPost = useCallback(async () => {
    try {
      const response = await axios.get("/api/blog/post", {
        params: { title },
      });

      setPost(response.data.post);
      setLoadingPost(false);
    } catch (error) {
      console.error(error);
      setLoadingPost(false);
      setErrorMsg((error as any).message);
    }
  }, [title]);

  const getRecentPosts = useCallback(async () => {
    try {
      const response = await axios.get("/api/blog/posts/recent", {
        params: { title },
      });

      setRecentPosts(response.data.recentPosts);
    } catch (error) {
      console.error(error);
    }
  }, [title]);

  // Hooks
  const filters = useFilters({
    getData: getRecentPosts,
    initialState: {
      page: DEFAULT_RANGE.page,
      per_page: DEFAULT_RANGE.per_page,
    },
  });

  useEffect(() => {
    filters.getData();
  }, []);

  useEffect(() => {
    if (title) {
      getPost();
    }
  }, [getPost, title]);

  return (
    <>
      <Helmet>
        <title>{`Blog: ${post?.title || ""} | Minimal UI`}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        {/* @ts-ignore */}
        <CustomBreadcrumbs
          heading="Post Details"
          links={[
            {
              name: "dashboard.root",
              href: PATH_DASHBOARD.root,
            },
            {
              name: "article.root_plural",
              href: PATH_DASHBOARD.articles.root,
            },
            {
              name: post?.title,
            },
          ]}
        />

        {post && (
          <Stack
            sx={{
              borderRadius: 2,
              boxShadow: (theme: any) => ({
                md: theme.customShadows.card,
              }),
            }}
          >
            <BlogPostHero post={post} />

            <Typography
              variant="h6"
              sx={{
                py: 5,
                px: { md: 5 },
              }}
            >
              {post.description}
            </Typography>

            <Markdown
              children={post.body}
              sx={{
                px: { md: 5 },
              }}
            />

            <Stack
              spacing={3}
              sx={{
                py: 5,
                px: { md: 5 },
              }}
            >
              <Divider />

              <BlogPostTags post={post} />

              <Divider />
            </Stack>

            <Stack
              sx={{
                px: { md: 5 },
              }}
            >
              <Stack
                direction="row"
                sx={{ mb: 3 }}
              >
                <Typography variant="h4">Comments</Typography>

                <Typography
                  variant="subtitle2"
                  sx={{ color: "text.disabled" }}
                >
                  ({post.comments.length})
                </Typography>
              </Stack>

              <BlogPostCommentForm />

              <Divider sx={{ mt: 5, mb: 2 }} />
            </Stack>

            <Stack
              sx={{
                px: { md: 5 },
              }}
            >
              <BlogPostCommentList comments={post.comments} />

              <Pagination
                page={filters.page}
                onChange={(_, page) => filters.handlePage(page)}
                count={8}
                sx={{
                  my: 5,
                  ml: "auto",
                  mr: { xs: "auto", md: 0 },
                }}
              />
            </Stack>
          </Stack>
        )}

        {errorMsg && !loadingPost && (
          <Typography variant="h6">404 {errorMsg}</Typography>
        )}

        {loadingPost && <SkeletonPostDetails />}

        {!!recentPosts.length && (
          <>
            <Typography
              variant="h4"
              sx={{ my: 5 }}
            >
              Recent posts
            </Typography>

            <Box
              gap={3}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(4, 1fr)",
              }}
            >
              {recentPosts
                .slice(recentPosts.length - 4)
                .map((recentPost, index) => (
                  <BlogPostCard
                    key={recentPost.id}
                    post={recentPost}
                    index={index}
                    getData={filters.getData}
                  />
                ))}
            </Box>
          </>
        )}
      </Container>
    </>
  );
}
