import PropTypes from "prop-types";
// @mui
import { Box, Card, CardHeader } from "@mui/material";
// components
import Chart, { useChart } from "@/core/components/chart";

// ----------------------------------------------------------------------

AnalyticsWebsiteVisits.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function AnalyticsWebsiteVisits({
  title,
  subheader,
  chart,
  ...other
}) {
  const { labels, colors, series, options } = chart;

  const chartOptions = useChart({
    colors,
    plotOptions: {
      bar: {
        columnWidth: "16%",
      },
    },
    // fill: {
    //   type: series.map((i) => i.fill),
    // },
    labels,
    xaxis: {
      type: "datetime",
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value) => {
          if (typeof value !== "undefined") {
            return `${value.toFixed(0)}`;
          }
          return value;
        },
      },
    },
    ...options,
  });

  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
      />

      <Box
        sx={{ p: 3, pb: 1 }}
        dir="ltr"
      >
        <Chart
          type="line"
          series={series}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
