import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import {
  Avatar,
  Box,
  Button,
  Divider,
  ListItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
// utils
import { fDate } from "@/core/utils/formatTime";

// ----------------------------------------------------------------------

BlogPostCommentItem.propTypes = {
  name: PropTypes.string,
  hasReply: PropTypes.bool,
  message: PropTypes.string,
  tagUser: PropTypes.string,
  postedAt: PropTypes.string,
  avatarUrl: PropTypes.string,
};

export default function BlogPostCommentItem({
  name,
  avatarUrl,
  message,
  tagUser,
  postedAt,
  hasReply,
}) {
  const [openReply, setOpenReply] = useState(false);

  return (
    <>
      <ListItem
        disableGutters
        sx={{
          alignItems: "flex-start",
          py: 3,
          ...(hasReply && {
            ml: 8,
          }),
        }}
      >
        <Avatar
          alt={name}
          src={avatarUrl}
          sx={{ mr: 2, width: 48, height: 48 }}
        />

        <Stack>
          <Typography variant="subtitle1"> {name} </Typography>

          <Typography
            variant="caption"
            sx={{ color: "text.disabled" }}
          >
            {fDate(postedAt)}
          </Typography>

          <Typography
            variant="body2"
            sx={{ mt: 1 }}
          >
            {tagUser && (
              <Box
                component="strong"
                sx={{ mr: 0.5 }}
              >
                @{tagUser}
              </Box>
            )}
            {message}
          </Typography>
        </Stack>

        {!hasReply && (
          <Button
            size="small"
            onClick={() => setOpenReply(!openReply)}
            sx={{ right: 0, position: "absolute" }}
          >
            Reply
          </Button>
        )}
      </ListItem>

      {openReply && (
        <Box
          sx={{
            mb: 3,
            ml: "auto",
            width: (theme) => `calc(100% - ${theme.spacing(7)})`,
          }}
        >
          <TextField
            fullWidth
            size="small"
            placeholder="Write comment"
          />
        </Box>
      )}

      <Divider
        sx={{
          ...(hasReply && {
            ml: 7,
          }),
        }}
      />
    </>
  );
}
