import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { Box, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 137,
        height: 47,
        display: "inline-flex",
        ...sx,
      }}
      {...other}
    >
      <svg
        width="137"
        height="47"
        viewBox="0 0 137 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.5503 2.28558C9.92602 3.52634 7.70573 9.14237 7.70573 17.5338C7.70573 25.9252 9.92602 31.5412 13.5503 32.782C5.15892 32.1616 0 26.4149 0 17.5664C0 8.68525 5.15892 2.93861 13.5503 2.28558ZM26.4476 11.1668C24.913 8.06488 19.8847 3.26512 17.0767 2.28558C24.4885 2.28558 26.4476 3.46103 26.4476 11.1668ZM17.2399 32.8146C20.0153 31.8024 25.0436 27.0353 26.5782 23.9008C26.5782 31.5412 24.6518 32.8146 17.2399 32.8146Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M39.6708 21.2561C40.9442 21.2561 42.3156 21.3867 43.8176 21.5826C40.0627 21.7459 37.6465 23.8029 37.6465 26.9374C37.6465 29.1251 38.7893 30.3985 40.7157 30.3985C41.9238 30.3985 43.0013 29.9414 43.8829 29.2557C42.6421 31.476 40.0627 32.88 36.7649 32.88C32.7487 32.88 30.2346 30.7903 30.2346 27.5252C30.2019 23.509 33.8262 21.2561 39.6708 21.2561ZM31.508 15.6401C31.508 12.6688 34.4466 11.0036 39.7688 10.9056C38.2015 11.2974 37.1567 12.8973 37.1567 15.1176C37.1567 16.3584 37.4179 17.4685 37.8097 18.3501C37.124 18.6766 36.1118 18.9378 35.1323 18.9378C32.9447 18.9705 31.508 17.6318 31.508 15.6401ZM41.2381 10.9056C47.6378 11.0689 51.0009 13.2892 51.0009 17.24V28.6027C51.0009 29.6802 51.5886 30.3332 52.5355 30.3332C53.0906 30.3332 53.515 30.1046 54.0374 29.7781C53.1559 31.6392 51.2947 32.8147 48.8785 32.8147C45.9399 32.8147 44.0788 31.1495 44.0788 28.57V15.4768C44.0461 12.832 42.9686 11.1342 41.2381 10.9056Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M56.6172 32.4554V11.1667H63.5066V32.4554H56.6172ZM63.8005 15.4767C64.8127 12.5708 67.1962 10.7423 69.9716 10.7423C72.747 10.7423 74.5428 12.5381 74.5428 15.3135C74.5428 17.6644 73.2694 19.0684 71.0817 19.0684C70.2002 19.0684 69.1553 18.8072 68.437 18.4807C68.8288 17.5991 69.09 16.4236 69.09 15.5094C69.09 14.1054 68.437 13.2564 67.3595 13.2564C66.282 13.1911 64.78 14.0727 63.8005 15.4767Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M77.7422 1.01219L84.6316 0V32.4555H77.7422V1.01219Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M98.1162 21.2561C99.3896 21.2561 100.761 21.3867 102.263 21.5826C98.508 21.7459 96.0918 23.8029 96.0918 26.9374C96.0918 29.1251 97.2346 30.3985 99.161 30.3985C100.369 30.3985 101.447 29.9414 102.328 29.2557C101.087 31.476 98.508 32.88 95.2102 32.88C91.1941 32.88 88.6799 30.7903 88.6799 27.5252C88.6473 23.509 92.2716 21.2561 98.1162 21.2561ZM89.986 15.6401C89.986 12.6688 92.9246 11.0036 98.2468 10.9056C96.6795 11.2974 95.6346 12.8973 95.6346 15.1176C95.6346 16.3584 95.8959 17.4685 96.2877 18.3501C95.602 18.6766 94.5898 18.9378 93.6103 18.9378C91.39 18.9705 89.986 17.6318 89.986 15.6401ZM99.6834 10.9056C106.083 11.0689 109.446 13.2892 109.446 17.24V28.6027C109.446 29.6802 110.034 30.3332 110.981 30.3332C111.536 30.3332 111.96 30.1046 112.483 29.7781C111.601 31.6392 109.74 32.8147 107.324 32.8147C104.385 32.8147 102.524 31.1495 102.524 28.57V15.4768C102.491 12.832 101.447 11.1342 99.6834 10.9056Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M136.613 21.3867C130.376 22.5295 124.173 22.5295 117.969 21.3867C124.173 20.2439 130.376 20.2439 136.613 21.3867Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M127.275 11.5913C128.45 14.0728 128.45 16.5217 127.308 19.0032C126.099 16.5543 126.165 14.0728 127.275 11.5913Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M127.307 31.0188C126.132 28.57 126.132 26.0885 127.307 23.607C128.483 26.0558 128.418 28.5373 127.307 31.0188Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M99.0657 46.824C96.323 46.824 94.168 44.669 94.168 41.9263C94.168 39.1182 96.323 36.9959 99.131 36.9959C99.5554 36.9959 99.9473 37.0286 100.339 37.1265C100.927 37.2571 101.188 37.3877 101.808 37.8122V38.9876C101.678 38.857 101.547 38.7591 101.482 38.6938C100.861 38.204 99.9146 37.9101 99.0657 37.9101C96.9107 37.9101 95.1802 39.7386 95.1802 41.9589C95.1802 44.1466 96.9433 45.9424 99.0983 45.9424C99.5554 45.9424 100.013 45.8444 100.47 45.6812C101.057 45.4526 101.319 45.322 101.841 44.8322V46.0403C100.992 46.5301 100.078 46.824 99.0657 46.824Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M111.146 45.516C110.526 46.3323 109.481 46.8221 108.404 46.8221C107.293 46.8221 106.249 46.3323 105.628 45.516C105.171 44.9283 105.008 44.2753 105.008 43.2631V37.1246H106.02V42.9366C106.02 43.9488 106.085 44.3079 106.347 44.7977C106.706 45.4834 107.522 45.9079 108.404 45.9079C109.285 45.9079 110.101 45.4834 110.461 44.7977C110.689 44.3732 110.787 43.9488 110.787 42.9366V37.1246H111.799V43.2631C111.799 44.2753 111.603 44.8957 111.146 45.516Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M119.505 46.6262L116.631 42.4468H116.174V46.6262H115.162V37.092H116.664C117.807 37.092 118.427 37.2226 119.015 37.6144C119.668 38.0389 120.06 38.8552 120.06 39.7694C120.06 41.2061 119.178 42.153 117.644 42.3815L120.68 46.6262H119.505ZM118.427 38.3654C118.101 38.1369 117.644 38.0389 116.893 38.0389H116.174V41.6306H116.893C118.264 41.6306 119.08 40.9449 119.08 39.8021C119.113 39.2144 118.819 38.6593 118.427 38.3654Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M123.521 46.6262V37.092H124.534V45.712H127.276V46.6262H123.521Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M132.304 46.822C130.835 46.822 129.692 45.8751 129.398 44.3732L130.378 44.112C130.541 45.1895 131.292 45.8751 132.337 45.8751C133.415 45.8751 134.296 44.9935 134.296 43.9487C134.296 43.1651 133.806 42.61 132.664 42.1202L131.749 41.7284C130.476 41.1733 129.856 40.3897 129.823 39.3449C129.823 37.9735 130.966 36.9286 132.533 36.9286C133.545 36.9286 134.361 37.3858 134.917 38.3327L134.133 38.8224C133.676 38.1368 133.219 37.8429 132.533 37.8429C131.586 37.8429 130.835 38.4959 130.835 39.3122C130.835 39.9326 131.26 40.3897 132.174 40.7815L133.121 41.1733C134.623 41.8264 135.276 42.6426 135.276 43.8507C135.308 45.5486 134.002 46.822 132.304 46.822Z"
          fill={PRIMARY_MAIN}
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link
      component={RouterLink}
      to="/"
      sx={{ display: "contents" }}
    >
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
