import useTranslation from "@/core/hooks/useTranslation";
import "@/core/utils/highlight";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import ReactQuill, {Quill} from "react-quill";
//
import EditorToolbar, { formats } from "./EditorToolbar";
import { StyledEditor } from "./styles";
import ImageResize from 'quill-image-resize-module-react'

// ----------------------------------------------------------------------

Editor.propTypes = {
  id: PropTypes.string,
  sx: PropTypes.object,
  error: PropTypes.bool,
  simple: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.object,
};

export default function Editor({
  id = "minimal-quill",
  error,
  value,
  onChange,
  simple = false,
  helperText,
  sx,
  ...other
}) {
  const t = useTranslation();
  Quill.register('modules/imageResize', ImageResize);

  const modules = {
    toolbar: {
      container: `#${id}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    imageResize: {
      displayStyles: {
        backgroundColor: 'black',
        border: 'none',
        color: 'white'
      },
      toolbarStyles: {
        backgroundColor: 'black',
        border: 'none',
        color: 'white'
      },

      modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
    },
    syntax: true,
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <>
      {other.label && (
        <Typography
          color="gray"
          variant="subtitle2"
          gutterBottom
        >
          {t(other.label)}
        </Typography>
      )}
      <StyledEditor
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
          }),
          ...sx,
        }}
      >
        <EditorToolbar
          id={id}
          isSimple={simple}
        />

        <ReactQuill
          value={value}
          onChange={onChange}
          modules={modules}
          formats={formats}
          placeholder={t("placeholder.write_something")}
          {...other}
        />
      </StyledEditor>

      {helperText && helperText}
    </>
  );
}
