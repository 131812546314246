import { Intent } from "@/app/types/IntentTypes";
import Iconify from "@/core/components/iconify/Iconify";
import useTranslation from "@/core/hooks/useTranslation";
import AppModal from "@/presentation/components/AppModal";
import { MenuItem, useMediaQuery, useTheme } from "@mui/material";
import { DialogProps } from "@mui/material/Dialog";
import { makeStyles } from "@mui/styles";
import React from "react";
import AddOrderForm from "./addOrderForm";

const useStyles: any = makeStyles((theme: any) => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    width: 900,
  },
}));

interface EditOrderProps {
  intent: Intent;
  lang: string;
  handleClosePopover: Function;
}

export default function EditOrder(props: EditOrderProps) {
  const t = useTranslation();

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const handleClickOpen = (scrollType: DialogProps["scroll"]) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.handleClosePopover();
    // dispatch(submittingReset());
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);

  return (
    <>
      <MenuItem
        onClick={() => {
          handleClickOpen("paper");
        }}
      >
        <Iconify icon="eva:edit-fill" />
        {t("app.edit")}
      </MenuItem>
      {open && (
        <AppModal
          onClose={() => handleClose()}
          title={
            props.intent
              ? t(`app.update_model_title`, {
                  model: `chatBot.root`,
                })
              : t(`app.create_model_title`, {
                  model: `chatBot.root`,
                })
          }
          breadCrumbLinks={[
            {
              name: props.intent
                ? t(`app.update_model_description`, {
                    model: `chatBot.root`,
                  })
                : t(`app.create_model_description`, {
                    model: `chatBot.root`,
                  }),
            },
          ]}
        >
          <AddOrderForm
            handleClose={handleClose}
            lang={props.lang}
            info={props.intent}
          />
        </AppModal>
      )}
    </>
  );
}
