import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
// @mui
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  Chip,
  Grid,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
// auth
// _mock_
// components
// sections
import { countries } from "@/app/assets/data";
import {
  APP_PERMISSIONS,
  DEFAULT_RANGE,
  MIN_STAT_YEAR,
} from "@/app/config-global";
import { PATH_DASHBOARD } from "@/app/routes/paths";
import HairdressingSalonService from "@/app/services/api/HairdressingSalonService";
import { ICustomerRange, IHairdressingSalon } from "@/app/types";
import { IStatRangeRequest } from "@/app/types/StatTypes";
import { CustomSmallSelect } from "@/core/components/custom-input";
import Iconify from "@/core/components/iconify/Iconify";
import Page from "@/core/components/page";
import SearchNotFound from "@/core/components/search-not-found/SearchNotFound";
import useFilters from "@/core/hooks/useFilters";
import useToastError from "@/core/hooks/useToastError";
import useTranslation from "@/core/hooks/useTranslation";
import {
  AnalyticsCurrentVisits,
  AnalyticsWebsiteVisits,
} from "@/presentation/sections/@dashboard/general/analytics";
import {
  AppAreaInstalled,
  AppTopAuthors,
  AppTopRelated,
} from "@/presentation/sections/@dashboard/general/app";
import sliceNames from "@/redux/names";
import customerEffects from "@/redux/slices/customer/effects";
import statEffects from "@/redux/slices/stat/effects";
import { dispatch, useSelector } from "@/redux/store";
import React from "react";
import { Search } from "react-feather";
// assets

// ----------------------------------------------------------------------

export default function IndexPage() {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const types = [
    {
      label: "app.application",
      value: "",
    },
    {
      label: "customer.salon",
      value: "salon",
    },
  ];

  const { stat, customer } = useSelector((state) => state);

  const theme = useTheme();
  const t = useTranslation();
  useToastError(stat?.error);

  const { handleYear, year } = useFilters<IStatRangeRequest>({
    getData: (form) => dispatch(statEffects.getIncome(`?year=${form!.year}`)),
    initialState: {
      customer: "",
      hairdressingSalon: "",
      year: new Date().getFullYear(),
    },
  });

  const [salons, setSalons] = React.useState<IHairdressingSalon[]>([]);
  const [uuid, handleUuid] = React.useState<string>("");
  const [country, setCountry] = React.useState<string>(countries[0].label);

  const getSalons = (keyword?: string) => {
    HairdressingSalonService.paginate({ page: 1, per_page: 20, keyword })
      .then(async (res) => await res.json())
      .then(({ data }) => {
        setSalons(data);
      });
  };

  const getYears = () => {
    const years = [];
    let currentYear = new Date().getFullYear();
    while (currentYear >= MIN_STAT_YEAR) {
      years.push(currentYear);
      currentYear--;
    }
    return years;
  };

  const { keyword, role, handleKeyword, handleRole }: any =
    useFilters<ICustomerRange>({
      getData: (form) =>
        form?.role === "salon"
          ? getSalons()
          : form?.role === "customer"
          ? dispatch(customerEffects.paginate(form!))
          : null,
      initialState: {
        page: DEFAULT_RANGE.page,
        per_page: DEFAULT_RANGE.per_page,
        keyword: "",
        role: "",
      },
    });

  // Get data
  React.useEffect(() => {
    const blackListKeys = ["getIncome", "getNumberOfUser", "getSalonByCountry"]; // That should be call manuually;
    const whiteListKeys = ["getSalonProfit", "getSalonByLocation"]; // That will be call without automacally pass salon id;
    Object.keys(statEffects).map((key) => {
      if (
        (!key.toLowerCase().includes("salon") &&
          !blackListKeys.includes(key)) ||
        whiteListKeys.includes(key)
      ) {
        dispatch((statEffects as any)[key]());
      } else {
        if (!blackListKeys.includes(key) && uuid) {
          dispatch((statEffects as any)[key](`${uuid}?year=${year}`));
        }
      }
    });

    if (!uuid) {
      dispatch(statEffects.getNumberOfUser(`?year=${year}`));
    }
  }, [uuid, year]);

  React.useEffect(() => {
    dispatch(statEffects.getSalonByCountry(country));
  }, [country]);

  return (
    <Page
      title="dashboard.root"
      permissions={APP_PERMISSIONS[sliceNames.stat].list}
      noPermissionUrl={PATH_DASHBOARD.articles.root}
      // isLoading={stat.isLoading}
      // noPermissionUrl={PATH_DASHBOARD.articles.root}
      // isLoading={stat.isLoading}
    >
      <Grid
        container
        spacing={3}
      >
        {/* <Grid item xs={12} md={8}>
            <AppWelcome
            title={`Welcome back! \n ${(user as any)?.email}`}
            description="If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything."
            img={
              <SeoIllustration
              sx={{
                p: 3,
                width: 360,
                margin: { xs: 'auto', md: 'inherit' },
              }}
              />
            }
            action={<Button variant="contained">Go Now</Button>}
            />
            </Grid>
            
            <Grid item xs={12} md={4}>
            <AppFeatured list={_appFeatured} />
          </Grid> */}
        <Grid
          item
          xs={12}
        >
          <Card>
            <Tabs
              value={role}
              onChange={(_, value) => {
                handleUuid("");
                handleRole(value);
              }}
              sx={{
                px: 2,
                bgcolor: "background.neutral",
              }}
            >
              {types.map((type) => (
                <Tab
                  label={t(type.label)}
                  value={type.value}
                />
              ))}
            </Tabs>
          </Card>

          <Stack
            direction="row"
            justifyContent={"end"}
            alignItems="center"
            spacing={1}
            sx={{ mt: 2 }}
          >
            <span>{t("label.year")}</span>
            <CustomSmallSelect
              value={year}
              onChange={(event: any) => handleYear(event.target.value)}
            >
              {getYears()
                .map((year) => ({ year }))
                .map((option) => (
                  <option
                    key={option.year}
                    value={option.year}
                  >
                    {option.year}
                  </option>
                ))}
            </CustomSmallSelect>
          </Stack>

          {role !== "" && (
            <Box sx={{ py: 2, maxWidth: "400px" }}>{renderAutoComplete()}</Box>
          )}
        </Grid>
        {["salon", "customer"].includes(role) && !uuid ? (
          <Grid
            xs={12}
            sx={{ py: 4 }}
          >
            <Stack
              justifyContent={"center"}
              alignItems="center"
              sx={{ maxWidth: 500, mx: "auto", textAlign: "center" }}
            >
              <Search size={72} />
              <Typography
                sx={{ mt: 2 }}
                variant="subtitle1"
                gutterBottom
              >
                {t("stat.no_salon_selected")}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary" }}
              >
                {t("stat.no_salon_selected_text")}
              </Typography>
            </Stack>
          </Grid>
        ) : (
          <>
            {/* <Grid
          item
          xs={12}
          md={4}
        >
          <AppWidgetSummary
            title="Total Active Users"
            percent={2.6}
            total={18765}
            chart={{
              colors: [theme.palette.primary.main],
              series: [5, 18, 12, 51, 68, 11, 39, 37, 27, 20],
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
        >
          <AppWidgetSummary
            title="Total Installed"
            percent={0.2}
            total={4876}
            chart={{
              colors: [theme.palette.info.main],
              series: [20, 41, 63, 33, 28, 35, 50, 46, 11, 26],
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
        >
          <AppWidgetSummary
            title="Total Downloads"
            percent={-0.1}
            total={678}
            chart={{
              colors: [theme.palette.warning.main],
              series: [8, 9, 31, 8, 16, 37, 8, 33, 46, 31],
            }}
          />
        </Grid> */}

            {/* <Grid
          item
          xs={12}
          md={6}
          lg={4}
        >
          <AppCurrentDownload
            title="Current Download"
            chart={{
              colors: [
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.error.main,
                theme.palette.warning.main,
              ],
              series: [
                { label: "Mac", value: 12244 },
                { label: "Window", value: 53345 },
                { label: "iOS", value: 44313 },
                { label: "Android", value: 78343 },
              ],
            }}
          />
        </Grid> */}

            <Grid
              item
              xs={12}
              md={6}
              // lg={8}
            >
              {stat[
                role === "salon" ? "isLoadingSalonIncome" : "isLoadingIncome"
              ] ? (
                <CardSkeleton />
              ) : (
                <AppAreaInstalled
                  title={t("stat.app_order_revenue_fn_time")}
                  currentYear={year}
                  subheader=""
                  years={getYears()}
                  colors={[
                    theme.palette.primary.main,
                    theme.palette.secondary.main,
                  ]}
                  chart={{
                    categories: months,
                    series: [
                      {
                        year,
                        data: [
                          {
                            name: t("order.root_plural"),
                            data:
                              stat[
                                `${role === "salon" ? "salonIncome" : "income"}`
                              ]?.orders ?? [],
                          },
                        ],
                      },
                    ],
                  }}
                />
              )}
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              // lg={8}
            >
              {stat[
                role === "salon" ? "isLoadingSalonIncome" : "isLoadingIncome"
              ] ? (
                <CardSkeleton />
              ) : (
                <>
                  <AppAreaInstalled
                    title={t("stat.app_booking_revenue_fn_time")}
                    currentYear={year}
                    years={getYears()}
                    subheader=""
                    chart={{
                      categories: months,
                      series: [
                        {
                          year,
                          data: [
                            {
                              name: t("booking.root_plural"),
                              data:
                                stat[
                                  role === "salon" ? "salonIncome" : "income"
                                ]?.bookings ?? [],
                            },
                          ],
                        },
                      ],
                    }}
                  />
                </>
              )}
            </Grid>

            {role === "" && (
              <Grid
                item
                xs={12}
              >
                {stat.isLoadingNumberOfUser ? (
                  <CardSkeleton />
                ) : (
                  <AnalyticsWebsiteVisits
                    title={t("stat.customer_pro_evolution")}
                    subheader=""
                    chart={{
                      colors: [
                        theme.palette.primary.main,
                        theme.palette.secondary.main,
                      ],
                      labels: [
                        `01/01/${year}`,
                        `02/01/${year}`,
                        `03/01/${year}`,
                        `04/01/${year}`,
                        `05/01/${year}`,
                        `06/01/${year}`,
                        `07/01/${year}`,
                        `08/01/${year}`,
                        `09/01/${year}`,
                        `10/01/${year}`,
                        `11/01/${year}`,
                        `12/01/${year}`,
                      ],
                      series: [
                        {
                          name: t("professional.root_plural"),
                          type: "column",
                          fill: "solid",
                          data: stat.numberOfUser.professionals,
                        },
                        {
                          name: t("customer.root_plural"),
                          type: "column",
                          fill: "gradient",
                          data: stat.numberOfUser.customers,
                        },
                        // {
                        //   name: "Team C",
                        //   type: "line",
                        //   fill: "solid",
                        //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                        // },
                      ],
                    }}
                  />
                )}
              </Grid>
            )}

            {role === "" && (
              <Grid
                item
                xs={12}
                lg={7}
              >
                {/* @ts-ignore */}
                {/* <AppNewInvoice
            title="New Invoice"
            tableData={_appInvoices}
            tableLabels={[
              { id: "id", label: "Invoice ID" },
              { id: "category", label: "Category" },
              { id: "price", label: "Price" },
              { id: "status", label: "Status" },
              { id: "" },
            ]}
          /> */}
                {stat.isLoadingIncome ? (
                  <CardSkeleton />
                ) : (
                  <AppAreaInstalled
                    title={t("stat.app_formula_revenue_fn_time")}
                    currentYear={year}
                    years={getYears()}
                    subheader=""
                    chart={{
                      categories: months,
                      series: [
                        {
                          year,
                          data: [
                            {
                              name: t("stat.formulas"),
                              data:
                                stat[
                                  role === "salon" ? "salonIncome" : "income"
                                ]?.formulas ?? [],
                            },
                          ],
                        },
                      ],
                    }}
                  />
                )}
              </Grid>
            )}

            {role === "" && (
              <Grid
                item
                xs={12}
                md={6}
                lg={5}
              >
                {stat[
                  role === "salon"
                    ? "isLoadingSalonProductProfit"
                    : "isLoadingProductProfit"
                ] ? (
                  <CardSkeleton />
                ) : (
                  <>
                    {/* @ts-ignore */}
                    <AppTopRelated
                      title={t("stat.product_by_profitability_order")}
                      list={(
                        stat[
                          role === "salon"
                            ? "salonProductProfit"
                            : "productProfit"
                        ] ?? []
                      )?.map((p) => ({
                        id:
                          (p as any)[
                            role === "salon" ? "hairdressing_salon" : "product"
                          ]?.uuid ?? "",
                        name:
                          (p as any)[
                            role === "salon" ? "hairdressing_salon" : "product"
                          ]?.name ?? "",
                        // system: (index === 2 && "Windows") || (index === 4 && "Windows") || "Mac",
                        price: p?.total_amount ?? 0,
                        // rating: 5,
                        // review: 5,
                        shortcut: (p as any)[
                          role === "salon" ? "hairdressing_salon" : "product"
                        ]?.images?.[0]?.thumbnail?.url,
                      }))}
                    />
                  </>
                )}
              </Grid>
            )}

            {role === "" && (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
              >
                {stat.isLoadingSalonByCountry ? (
                  <CardSkeleton />
                ) : (
                  <>
                    {/* @ts-ignore */}
                    <AnalyticsCurrentVisits
                      country={country}
                      setCountry={setCountry}
                      title={t("stat.salon_by_country")}
                      chart={{
                        series: stat.salonByCountry.map((l) => ({
                          label: l.town ?? "",
                          value: l.total ?? 0,
                        })),
                      }}
                    />
                  </>
                )}
              </Grid>
            )}

            {role === "" && (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
              >
                {stat.isLoadingCustomerByLocation ? (
                  <CardSkeleton />
                ) : (
                  <>
                    {/* @ts-ignore */}
                    <AppTopAuthors
                      title={t("stat.app_customer_by_profit_fn_country")}
                      list={stat.customerByLocation.map((salon) => ({
                        id: salon.country_code,
                        name: salon.country_code,
                        avatar: `https://flagsapi.com/${
                          salon.country_code?.toUpperCase() ?? ""
                        }/flat/64.png`,
                        favourite: salon.total,
                      }))}
                    />
                  </>
                )}
              </Grid>
            )}

            {role === "" && (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
              >
                {stat.isLoadingSalonByLocation ? (
                  <CardSkeleton />
                ) : (
                  <>
                    {/* @ts-ignore */}
                    <AppTopAuthors
                      title={t("stat.app_salon_by_profit_fn_country")}
                      list={stat.salonByLocation.map((salon) => ({
                        id: salon.country,
                        name: salon.country,
                        avatar: `https://countryflagsapi.com/svg/${salon.country}`,
                        favourite: salon.total,
                      }))}
                    />
                  </>
                )}
                {/* <Stack spacing={3}>
                <AppWidget
                  title="Conversion"
                  total={38566}
                  icon="eva:person-fill"
                  chart={{
                    series: 48,
                  }}
                />

                <AppWidget
                  title="Applications"
                  total={55566}
                  icon="eva:email-fill"
                  color="info"
                  chart={{
                    series: 75,
                  }}
                />
              </Stack> */}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Page>
  );

  function renderAutoComplete() {
    return (
      <Autocomplete
        popupIcon={null}
        noOptionsText={
          <SearchNotFound
            sx={{}}
            query={keyword}
          />
        }
        onChange={(event, value) =>
          role === "salon"
            ? handleUuid((value as any)?.uuid ?? "")
            : handleUuid((value as any)?.public_id ?? "")
        }
        onInputChange={(event, value) =>
          role === "salon" ? getSalons(value) : handleKeyword(value)
        }
        options={role === "salon" ? salons ?? [] : customer.list.data ?? []}
        getOptionLabel={(value: any) => value.email ?? value?.name ?? ""}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            placeholder={t("placeholder.search")}
          />
        )}
        renderOption={(props, recipient, { inputValue, selected }) => {
          const { email, file, name, images } = recipient as any;
          const matches = match(email ?? name ?? "", inputValue ?? "");
          const parts = parse(email ?? name ?? "", matches);

          return (
            <Box
              component="li"
              sx={{
                p: "12px !important",
              }}
              {...props}
            >
              <Box
                sx={{
                  mr: 1.5,
                  overflow: "hidden",
                  borderRadius: "50%",
                  position: "relative",
                  flexShrink: 0,
                }}
              >
                <Avatar
                  alt={email ?? name}
                  src={file?.url ?? images?.[0]?.thumbnail?.url}
                />
                <Box
                  sx={{
                    top: 0,
                    opacity: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    position: "absolute",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                    transition: (theme) =>
                      theme.transitions.create("opacity", {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shorter,
                      }),
                    ...(selected && {
                      opacity: 1,
                      color: "primary.main",
                    }),
                  }}
                >
                  <Iconify icon="eva:checkmark-fill" />
                </Box>
              </Box>

              {parts.map((part, index) => (
                <Typography
                  key={index}
                  variant="subtitle2"
                  color={part.highlight ? "primary" : "textPrimary"}
                >
                  {part.text}
                </Typography>
              ))}
            </Box>
          );
        }}
        renderTags={(selectedRecipients, getTagProps) =>
          selectedRecipients.map((value: any, index) => (
            <Chip
              {...getTagProps({ index })}
              key={value.public_id ?? value?.uuid}
              size="small"
              label={value.email ?? value.name}
              avatar={
                <Avatar
                  alt={value.email ?? value.name}
                  src={value?.avatar?.url ?? value?.images?.[0]?.thumbnail?.url}
                />
              }
            />
          ))
        }
      />
    );
  }
}

// ---------------------------------------------------
function CardSkeleton() {
  return (
    <Skeleton
      sx={{ p: 0, m: 0, borderRadius: "10px" }}
      variant="rectangular"
      height={483}
      width={"100%"}
    />
  );
}
