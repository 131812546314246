import { IArticle } from "@/app/types";
import {
  baseCrudInitialState,
  IBaseCrudState,
} from "@/redux/base/crud-slice-helpers";
import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers, { IArticleReducer } from "./reducers";

export interface IArticleState extends IBaseCrudState<IArticle> {
  home: IBaseCrudState;
  favorites: IBaseCrudState;
  category: IBaseCrudState;
}

export const articleInitialState: IArticleState = {
  ...baseCrudInitialState,
  home: baseCrudInitialState,
  favorites: baseCrudInitialState,
  category: baseCrudInitialState,
  isLoading: false,
};

export const slice = createSlice<
  IArticleState,
  IArticleReducer,
  typeof sliceNames.article
>({
  name: sliceNames.article,
  initialState: articleInitialState,
  reducers,
});

export default slice.reducer;
