// routes
import sliceNames from "@/redux/names";
import { PATH_DASHBOARD } from "./routes/paths";

// API
// ----------------------------------------------------------------------

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || "";

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAP_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

export const DEFAULT_RANGE = {
  page: 1,
  per_page: 10,
  keyword: "",
  order_field: "",
  order: "desc",
};

export const DEFAULT_DEBOUNCE_DURATION = 500;

export const MIN_STAT_YEAR = 2020;

export const ACCEPTED_IMAGES = [".png", ".jpg", ".jpeg"];

export const ACCEPTED_VIDEOS = [".mp4"];

export const ACCEPTED_FILE_TYPES = {
  image: "image",
  video: "video",
};

export const DEFAULT_NA_STRING = "--/--";

export const AUTHORIZED_ROLES = {
  administrator: 1,
  edimaster: 5,
  editor: 6,
};

export const APP_PERMISSIONS = {
  [sliceNames.stat]: {
    list: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    read: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
  },
  [sliceNames.administrator]: {
    list: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    read: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    create: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    update: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    delete: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
  },
  [sliceNames.customer]: {
    list: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    read: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    create: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    update: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    delete: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
  },
  [sliceNames.professional]: {
    list: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    read: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    create: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    update: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    delete: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
  },
  [sliceNames.chat]: {
    list: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    read: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    create: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    update: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    delete: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
  },
  [sliceNames.product]: {
    list: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    read: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    create: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    update: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    delete: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
  },
  [sliceNames.order]: {
    list: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    read: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    create: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    update: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    delete: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
  },
  [sliceNames.article]: {
    list: [
      AUTHORIZED_ROLES.administrator,
      AUTHORIZED_ROLES.edimaster,
      AUTHORIZED_ROLES.editor,
    ],
    read: [
      AUTHORIZED_ROLES.administrator,
      AUTHORIZED_ROLES.edimaster,
      AUTHORIZED_ROLES.editor,
    ],
    change_status: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    create: [
      AUTHORIZED_ROLES.editor,
      AUTHORIZED_ROLES.administrator,
      AUTHORIZED_ROLES.edimaster,
    ],
    update: [
      AUTHORIZED_ROLES.editor,
      AUTHORIZED_ROLES.administrator,
      AUTHORIZED_ROLES.edimaster,
    ],
    delete: [
      AUTHORIZED_ROLES.editor,
      AUTHORIZED_ROLES.administrator,
      AUTHORIZED_ROLES.edimaster,
    ],
  },
  [sliceNames.articleCategory]: {
    list: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    read: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    create: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    update: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    delete: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
  },
  [sliceNames.blogCategory]: {
    list: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    read: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    create: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    update: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    delete: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
  },
  [sliceNames.blog]: {
    list: [
      AUTHORIZED_ROLES.administrator,
      AUTHORIZED_ROLES.edimaster,
      AUTHORIZED_ROLES.editor,
    ],
    read: [
      AUTHORIZED_ROLES.administrator,
      AUTHORIZED_ROLES.edimaster,
      AUTHORIZED_ROLES.editor,
    ],
    change_status: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    create: [
      AUTHORIZED_ROLES.editor,
      AUTHORIZED_ROLES.administrator,
      AUTHORIZED_ROLES.edimaster,
    ],
    update: [
      AUTHORIZED_ROLES.editor,
      AUTHORIZED_ROLES.administrator,
      AUTHORIZED_ROLES.edimaster,
    ],
    delete: [
      AUTHORIZED_ROLES.editor,
      AUTHORIZED_ROLES.administrator,
      AUTHORIZED_ROLES.edimaster,
    ],
  },
  [sliceNames.video]: {
    list: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    read: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    create: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    update: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    delete: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
  },
  [sliceNames.chatBot]: {
    list: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    read: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    create: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    update: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
    delete: [AUTHORIZED_ROLES.administrator, AUTHORIZED_ROLES.edimaster],
  },
};
