import moment from "moment";
import PropTypes from "prop-types";
// @mui
import { Avatar, Stack, Typography } from "@mui/material";
// components
import { useAuthContext } from "@/app/auth/useAuthContext";
import Image from "@/core/components/image";

// ----------------------------------------------------------------------

ChatMessageItem.propTypes = {
  message: PropTypes.object,
  conversation: PropTypes.object,
  onOpenLightbox: PropTypes.func,
};

export default function ChatMessageItem({
  message,
  conversation,
  onOpenLightbox,
}) {
  const { user } = useAuthContext();
  const CURRENT_USER_ID = user?.public_id ?? "";
  const sender = message?.sender;
  // const sender = conversation.participants.find(
  //   (participant) => participant.public_id === message.senderId
  // );

  const senderDetails =
    message.sender?.public_id === CURRENT_USER_ID
      ? {
          type: "me",
        }
      : {
          avatar: sender?.avatar?.thumbnail?.url ?? "",
          name: sender?.firstname,
        };

  const currentUser = senderDetails.type === "me";

  const isImage = message.is_image;

  const firstName = senderDetails.name && senderDetails.name.split(" ")[0];

  return (
    <Stack
      direction="row"
      justifyContent={currentUser ? "flex-end" : "unset"}
      sx={{ mb: 3 }}
    >
      {!currentUser && (
        <Avatar
          alt={senderDetails.name}
          src={senderDetails.avatar}
          sx={{ width: 32, height: 32, mr: 2 }}
        />
      )}

      <Stack
        spacing={1}
        alignItems="flex-end"
      >
        <Typography
          noWrap
          variant="caption"
          sx={{
            color: "text.disabled",
            ...(!currentUser && {
              mr: "auto",
            }),
          }}
        >
          {!currentUser && `${firstName},`} &nbsp;
          {moment(message.sending_date).fromNow()}
        </Typography>

        <Stack
          sx={{
            p: 1.5,
            minWidth: 48,
            maxWidth: 320,
            borderRadius: 1,
            overflow: "hidden",
            typography: "body2",
            bgcolor: "background.neutral",
            ...(currentUser && {
              color: "grey.800",
              bgcolor: "primary.lighter",
            }),
            ...(isImage && {
              p: 0,
            }),
          }}
        >
          {isImage ? (
            <Image
              alt="attachment"
              src={message.content}
              onClick={() => onOpenLightbox(message.content)}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  opacity: 0.9,
                },
              }}
            />
          ) : (
            message.content
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
