import { IIntentState } from ".";

const reducers = {
  paginate: (state: IIntentState, action: any) => {
    state.intents = action.payload.intents;
    if (action.payload.range) {
      state.range = action.payload.range;
    }
  },
  startLoading: (state: any, action?: any) => {
    state.isLoading = true;
  },
  stopLoading: (state: any, action?: any) => {
    state.isLoading = false;
  },
  hasError: (state: any, action: any) => {
    state.error = action.payload;
  },
};

export default reducers;
