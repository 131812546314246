import numeral from "numeral";

export default function useCurrency() {
  const currency = "$"; // TODO: update to user currency
  const isLeft = true; // TODO: update to user currency position
  function result(format: string, key = ".00") {
    const isInteger = format.includes(key);

    return isInteger ? format.replace(key, "") : format;
  }
  return (number?: number) => {
    const format = Number.isFinite(number)
      ? numeral(number?.toFixed(2)).format(
          `${isLeft ? currency : ""}0,0.00${isLeft ? "" : currency}`
        )
      : "";

    return result(format, ".00");
  };
}
