import { IVideo } from "@/app/types";
import { ITableRowColumn } from "@/core/components/table/AppTableRow";

const videoColumns: ITableRowColumn<IVideo>[] = [
  {
    header: { id: "name", label: "label.name", align: "left" },
    type: "lightBox",
    title: (row) => row?.name,
    videoUrl: (row) => row?.video_link ?? "",
  },
  {
    header: { id: "name_en", label: "label.name_en", align: "left" },
    title: (row) => row?.name_en,
    align: "left",
  },
  // {
  //   header: { id: "role", label: "video.role", align: "left" },
  //   title: (row) => row?.role?.title_fr,
  //   align: "left",
  // },
  {
    header: {
      id: "begin_duration",
      label: "label.begin_duration",
      align: "left",
    },
    type: "badge",
    title: (row) =>
      row?.begin_duration ? `${row?.begin_duration.replace(":", "min ")}s` : "",
    badgeColor: "info",
    sx: { textTransform: "capitalize" },
  },
];

export default videoColumns;
