import ArticleService from "@/app/services/api/ArticleService";
import { IArticleCreate, IArticleRange, IArticleUpdate } from "@/app/types";
import {
  IReduxBaseCrudEffect,
  ISimpleReduxCallback,
} from "@/app/types/BaseReduxTypes";
import BaseReduxCrudEffects from "@/redux/base/crud-slice-helpers/effects";
import { execute } from "@/redux/common/functions";
import { ThunkAction } from "redux-thunk";
import { slice } from ".";

export interface IArticleEffects
  extends IReduxBaseCrudEffect<
    IArticleRange,
    IArticleCreate,
    IArticleUpdate,
    ArticleService
  > {
  publish: (
    articleId: string,
    callback: ISimpleReduxCallback
  ) => ThunkAction<any, any, any, any>;
  addOrRemoveFavorite: (
    articleId: string,
    callback: ISimpleReduxCallback
  ) => ThunkAction<any, any, any, any>;
  listHome: (query: IArticleRange) => ThunkAction<any, any, any, any>;
  listByCategory: (query: IArticleRange) => ThunkAction<any, any, any, any>;
  listFavorites: (query: IArticleRange) => ThunkAction<any, any, any, any>;
}

class ArticleEffects
  extends BaseReduxCrudEffects<IArticleRange, IArticleCreate, IArticleUpdate>
  implements IArticleEffects
{
  publish = (articleId: string, callback: ISimpleReduxCallback) =>
    execute({
      slice: this.slice,
      inputProps: articleId,
      // @ts-ignore
      serviceFn: this.ResourceService.publish,
      actionToDispatch: this.slice.actions.publish,
      callback,
    });
  addOrRemoveFavorite = (articleId: string, callback: ISimpleReduxCallback) =>
    execute({
      slice: this.slice,
      inputProps: articleId,
      serviceFn: this.ResourceService.get,
      actionToDispatch: this.slice.actions.get,
      callback,
    });
  listHome = (query: IArticleRange) =>
    execute({
      slice: this.slice,
      inputProps: query,
      serviceFn: this.ResourceService.get,
      actionToDispatch: this.slice.actions.get,
    });
  listByCategory = (query: IArticleRange) =>
    execute({
      slice: this.slice,
      inputProps: query,
      serviceFn: this.ResourceService.get,
      actionToDispatch: this.slice.actions.get,
    });
  listFavorites = (query: IArticleRange) =>
    execute({
      slice: this.slice,
      inputProps: query,
      serviceFn: this.ResourceService.get,
      actionToDispatch: this.slice.actions.get,
    });
}

const articleEffects = new ArticleEffects(slice, ArticleService);

export default articleEffects;
