const GLOBAL_DATA_FALLBACK = {
  devises: [
    {
      code: "COP",
      symbol: "COP",
      can_be_float: true,
      is_default: false,
      convertion_from_eur: 0.000196,
      countries: ["CO"],
      uuid: "78a1acbb-0f9f-463a-85e2-f17d6b79d59f",
      symbol_place_left: true,
      convertion_from_cad: 0.000284,
    },
    {
      code: "CHF",
      symbol: "CHF",
      can_be_float: true,
      is_default: false,
      convertion_from_eur: 1.003605,
      countries: ["CH"],
      uuid: "0a08ae70-0f24-40cc-8aa5-3473b5ae1cb5",
      symbol_place_left: false,
      convertion_from_cad: 1.454224,
    },
    {
      code: "MAD",
      symbol: "د.م.",
      can_be_float: true,
      is_default: false,
      convertion_from_eur: 0.090511,
      countries: ["MA"],
      uuid: "2020f7ad-bd23-4335-b961-b44e92900415",
      symbol_place_left: true,
      convertion_from_cad: 0.131151,
    },
    {
      code: "GBP",
      symbol: "£",
      can_be_float: true,
      is_default: false,
      convertion_from_eur: 1.131139,
      countries: ["GB"],
      uuid: "f204ced5-5d2b-4d38-b429-e5a11575dfbc",
      symbol_place_left: false,
      convertion_from_cad: 1.639021,
    },
    {
      code: "USD",
      symbol: "$",
      can_be_float: true,
      is_default: false,
      convertion_from_eur: 0.939963,
      countries: ["USA"],
      uuid: "cffb2d8a-7d1d-4932-ab33-9591524cb52d",
      symbol_place_left: true,
      convertion_from_cad: 1.362006,
    },
    {
      code: "MXN",
      symbol: "$",
      can_be_float: true,
      is_default: false,
      convertion_from_eur: 0.052351,
      countries: ["MX"],
      uuid: "f01ac243-cf72-4510-bd51-81687d6ef8f4",
      symbol_place_left: false,
      convertion_from_cad: 0.075857,
    },
    {
      code: "CAD",
      symbol: "$",
      can_be_float: true,
      is_default: true,
      convertion_from_eur: 0.690131,
      countries: ["CA"],
      uuid: "cffb7d8b-7d1d-4932-ab33-9591524cb52d",
      symbol_place_left: true,
      convertion_from_cad: 1.0,
    },
    {
      code: "BRL",
      symbol: "BRL",
      can_be_float: true,
      is_default: false,
      convertion_from_eur: 0.180911,
      countries: ["BR"],
      uuid: "9475a91b-c7f8-4175-982d-99891dd82840",
      symbol_place_left: true,
      convertion_from_cad: 0.26214,
    },
    {
      code: "DOP",
      symbol: "$",
      can_be_float: true,
      is_default: false,
      convertion_from_eur: 0.017015,
      countries: ["MX"],
      uuid: "73a7171e-656e-4b20-a1ed-a1be37879e6f",
      symbol_place_left: false,
      convertion_from_cad: 0.024655,
    },
    {
      code: "EUR",
      symbol: "€",
      can_be_float: true,
      is_default: false,
      convertion_from_eur: 1.0,
      countries: [
        "AT",
        "BE",
        "FR",
        "DE",
        "IT",
        "CY",
        "EE",
        "FI",
        "GR",
        "IE",
        "LV",
        "LT",
        "LU",
        "MT",
        "MC",
        "NL",
        "PT",
        "SK",
        "SI",
        "ES",
      ],
      uuid: "7dc3c43b-b408-442c-bc10-a37ac5e43ff3",
      symbol_place_left: false,
      convertion_from_cad: 1.449001,
    },
    {
      code: "XAF",
      symbol: "F CFA",
      can_be_float: false,
      is_default: false,
      convertion_from_eur: 0.001525,
      countries: ["CM", "CF", "TD", "CG", "GA", "CD", "GQ"],
      uuid: "f514a853-9663-4288-a0b9-b5cdb0441340",
      symbol_place_left: false,
      convertion_from_cad: 0.00221,
    },
  ],
  times: [
    { fin: 12, debut: 8, id: 1, number_of_hour: 4 },
    { fin: 18, debut: 15, id: 3, number_of_hour: 3 },
    { fin: 22, debut: 18, id: 4, number_of_hour: 4 },
    { fin: 15, debut: 12, id: 2, number_of_hour: 3 },
  ],
  roles: [
    { title_en: "Administrator", id: 1, title_fr: "Administrateur" },
    { title_en: "Customer", id: 2, title_fr: "Client" },
    { title_en: "Professional", id: 3, title_fr: "Professionnel" },
    { title_en: "Guest", id: 4, title_fr: "Invité" },
    { title_en: "Edimaster", id: 5, title_fr: "Edimestre" },
    { title_en: "Editor", id: 6, title_fr: "Redacteur" },
  ],
  hair_types: [
    { id: 1, code: "1A", url: "https://files.carlaplus.com/production/1A.jpg" },
    { id: 2, code: "1B", url: "https://files.carlaplus.com/production/1B.jpg" },
    { id: 3, code: "1C", url: "https://files.carlaplus.com/production/1C.jpg" },
    { id: 4, code: "2A", url: "https://files.carlaplus.com/production/2A.jpg" },
    { id: 5, code: "2B", url: "https://files.carlaplus.com/production/2B.jpg" },
    { id: 6, code: "2C", url: "https://files.carlaplus.com/production/2C.jpg" },
    { id: 7, code: "3A", url: "https://files.carlaplus.com/production/3A.jpg" },
    { id: 8, code: "3B", url: "https://files.carlaplus.com/production/3B.jpg" },
    { id: 9, code: "3C", url: "https://files.carlaplus.com/production/3C.jpg" },
    {
      id: 10,
      code: "4A",
      url: "https://files.carlaplus.com/production/4A.jpg",
    },
    {
      id: 11,
      code: "4B",
      url: "https://files.carlaplus.com/production/4B.jpg",
    },
    {
      id: 12,
      code: "4C",
      url: "https://files.carlaplus.com/production/4C.jpg",
    },
  ],
  categories: [
    {
      subs: [
        {
          title_fr: "Crème nourrissante",
          title_en: "Nourishing cream",
          position: null,
          category_id: 6,
          id: 19,
        },
        {
          title_fr: "Pommade et Huile",
          title_en: "Ointment and Oil",
          position: null,
          category_id: 6,
          id: 20,
        },
        {
          title_fr: "Revitalisant",
          title_en: "Conditioner",
          position: null,
          category_id: 6,
          id: 21,
        },
        {
          title_fr: "Enfants",
          title_en: "Children",
          position: null,
          category_id: 6,
          id: 22,
        },
        {
          title_fr: "Shampooing",
          title_en: "Shampooing",
          position: null,
          category_id: 6,
          id: 23,
        },
        {
          title_fr: "Accessoires et Outils",
          title_en: "Accessories and Tools",
          position: null,
          category_id: 6,
          id: 24,
        },
        {
          title_fr: "Spray Vitaminé",
          title_en: "Vitamin Spray",
          position: null,
          category_id: 6,
          id: 25,
        },
      ],
      category: {
        position: 1,
        id: 6,
        title_en: "Nancy Falaise",
        title_fr: "Nancy Falaise",
      },
    },
    {
      subs: [
        {
          title_fr: "Soin de cheveux",
          title_en: "Hair care",
          position: null,
          category_id: 1,
          id: 1,
        },
        {
          title_fr: "Couleur",
          title_en: "Color",
          position: null,
          category_id: 1,
          id: 2,
        },
        {
          title_fr: "Entretien des perruques et tresses",
          title_en: "Maintenance of wigs and braids",
          position: null,
          category_id: 1,
          id: 3,
        },
      ],
      category: {
        position: 2,
        id: 1,
        title_en: "Hair care",
        title_fr: "Soin des cheveux",
      },
    },
    {
      subs: [
        {
          title_fr: "Tresser",
          title_en: "Braid",
          position: null,
          category_id: 2,
          id: 4,
        },
        {
          title_fr: "Tisser",
          title_en: "Weave",
          position: null,
          category_id: 2,
          id: 5,
        },
        {
          title_fr: "Pièce de cheveux",
          title_en: "Hair piece",
          position: null,
          category_id: 2,
          id: 6,
        },
      ],
      category: {
        position: 3,
        id: 2,
        title_en: "Hair extension",
        title_fr: "Extension des cheveux",
      },
    },
    {
      subs: [
        {
          title_fr: "OPHair",
          title_en: "OPHair",
          position: null,
          category_id: 3,
          id: 7,
        },
        {
          title_fr: "Demi Perruque",
          title_en: "Half Wig",
          position: null,
          category_id: 3,
          id: 8,
        },
        {
          title_fr: "Dentelle perruque",
          title_en: "Lace Wig",
          position: null,
          category_id: 3,
          id: 9,
        },
        {
          title_fr: "Perruque de cheveux naturels",
          title_en: "Natural hair wig",
          position: null,
          category_id: 3,
          id: 10,
        },
        {
          title_fr: "Pleine Perruque",
          title_en: "Full Wig",
          position: null,
          category_id: 3,
          id: 11,
        },
        {
          title_fr: "Pleine converti-cap",
          title_en: "Full converted-cap",
          position: null,
          category_id: 3,
          id: 12,
        },
        {
          title_fr: "Bandeau Perruque",
          title_en: "Headband Wig",
          position: null,
          category_id: 3,
          id: 13,
        },
      ],
      category: { position: 4, id: 3, title_en: "Wig", title_fr: "Perruque" },
    },
    {
      subs: [
        {
          title_fr: "Tresser",
          title_en: "Braid",
          position: null,
          category_id: 4,
          id: 14,
        },
        {
          title_fr: "Cuir chevelu",
          title_en: "Scalp",
          position: null,
          category_id: 4,
          id: 26,
        },
        {
          title_fr: "Coiffant",
          title_en: "Styling",
          position: null,
          category_id: 4,
          id: 27,
        },
        {
          title_fr: "Traitement",
          title_en: "Processing",
          position: null,
          category_id: 4,
          id: 28,
        },
        {
          title_fr: "Shampooing",
          title_en: "Shampooing",
          position: null,
          category_id: 4,
          id: 29,
        },
      ],
      category: {
        position: 5,
        id: 4,
        title_en: "Children & Babies",
        title_fr: "Enfants & Bébés",
      },
    },
    {
      subs: [
        {
          title_fr: "Accessoire",
          title_en: "Accessory",
          position: null,
          category_id: 5,
          id: 15,
        },
        {
          title_fr: "Brosse & Peigne",
          title_en: "Brush & Comb",
          position: null,
          category_id: 5,
          id: 16,
        },
        {
          title_fr: "Cap & Wrap",
          title_en: "Cap & Wrap",
          position: null,
          category_id: 5,
          id: 17,
        },
        {
          title_fr: "Outil",
          title_en: "Tool",
          position: null,
          category_id: 5,
          id: 18,
        },
      ],
      category: {
        position: 6,
        id: 5,
        title_en: "Tools & Accessories",
        title_fr: "Outils & Accessoires",
      },
    },
    {
      subs: [
        {
          title_fr: "Homme",
          title_en: "Male",
          position: null,
          category_id: 7,
          id: 30,
        },
      ],
      category: { position: 7, id: 7, title_en: "Male", title_fr: "Homme" },
    },
  ],
  prestations: [
    {
      title_fr: "Coupe femmes et enfants",
      title_en: "Coupe femmes et enfants",
      id: 4,
    },
    { title_fr: "Coupe Homme", title_en: "Coupe Homme", id: 5 },
    {
      title_fr: "Coupe et Definitions des boucles",
      title_en: "Coupe et Definitions des boucles",
      id: 6,
    },
    { title_fr: "Coupe et Blow Out", title_en: "Coupe et Blow Out", id: 7 },
    { title_fr: "Coupe et Silkpress", title_en: "Coupe et Silkpress", id: 8 },
    { title_fr: "Coupe et Twist", title_en: "Coupe et Twist", id: 9 },
    {
      title_fr: "Mise en Plis - Cheveux lisse",
      title_en: "Mise en Plis - Cheveux lisse",
      id: 18,
    },
    { title_fr: "Twist Updo", title_en: "Twist Updo", id: 19 },
    { title_fr: "Flat Twist", title_en: "Flat Twist", id: 20 },
    { title_fr: "Coiffure hautes", title_en: "Coiffure hautes", id: 21 },
    {
      title_fr: "Coiffures hautes + silkpress",
      title_en: "Coiffures hautes + silkpress",
      id: 22,
    },
    { title_fr: "Tresses et Tissage", title_en: "Tresses et Tissage", id: 24 },
    { title_fr: "Wash'n'go", title_en: "Wash'n'go", id: 25 },
    { title_fr: "Locs", title_en: "Locs", id: 26 },
    { title_fr: "Rouleau", title_en: "Roller", id: 1 },
    { title_fr: "Consultation", title_en: "Consultation ", id: 3 },
    {
      title_fr: "Coupe + Balayage",
      title_en: "Haircuts and Hairstyles",
      id: 10,
    },
    { title_fr: "Décoloration", title_en: "Bleaching", id: 12 },
    { title_fr: "Blow Dry + Styling / Brushing", title_en: "Blow Dry", id: 15 },
    { title_fr: "Twist Out", title_en: "Twist", id: 16 },
    { title_fr: "Silk Press", title_en: "Hair Straightening", id: 17 },
    { title_fr: "Démêlage", title_en: "Detangling", id: 23 },
    { title_fr: "Coiffure Laine", title_en: "Hairstyle w Wool", id: 27 },
    { title_fr: "Perruque", title_en: "Wig", id: 28 },
    { title_fr: "Pose", title_en: "Fitting", id: 29 },
    { title_fr: "Retouche", title_en: "Retouching", id: 30 },
    { title_fr: "Traitement", title_en: "Treatment", id: 2 },
    { title_fr: "Coloration", title_en: "Coloring", id: 11 },
    { title_fr: "Définition de boucles", title_en: "Curls Définition", id: 13 },
    { title_fr: "Blow Out", title_en: "Blow Dry", id: 14 },
  ],
  sub_prestations: [
    { id: 1638, title_fr: "Flexi rod - Court", title_en: "Flexi rod - Court" },
    { id: 1657, title_fr: "Tissage", title_en: "Weaving" },
    { id: 1644, title_fr: "Serrées - Long", title_en: "Tight Curls - Long" },
    { id: 1645, title_fr: "Frisé - Long", title_en: "Wavy - Long" },
    { id: 1639, title_fr: "Anti-Inflammatoire", title_en: "Anti-Inflammatory" },
    { id: 1640, title_fr: "Consultation ", title_en: "Consultation " },
    { id: 1646, title_fr: "Frisé - Long", title_en: "Wavy - Long" },
    {
      id: 1641,
      title_fr: "Coupe + Balayage - Serrées - Long",
      title_en: "Haircut + Balayage - Tight Curls - Long",
    },
    { id: 1647, title_fr: "Serrées - Long ", title_en: "Tight Curls - Long" },
    { id: 1643, title_fr: "cheveux long", title_en: "Long Hair" },
    {
      id: 1648,
      title_fr: "Silk Press - Serrées - Long ",
      title_en: "Silk Press - Tight Curls - Long",
    },
    { id: 1649, title_fr: "Serrées - Long ", title_en: "Tight Curls - Long" },
    { id: 1650, title_fr: "Serrées - Long ", title_en: "Tight Curls - Long" },
    { id: 1651, title_fr: "Serrées - Long ", title_en: "Tight Curls - Long" },
    { id: 1652, title_fr: "Lavage + Style ", title_en: "Wash + Style" },
    {
      id: 1642,
      title_fr: "Ombre avec couleur hi-lift  - cheveux long - Serrés",
      title_en: "Ombre with hi-lift color - Long hair - Tight Curls",
    },
    { id: 1653, title_fr: "Exfoliation ", title_en: "Scrub" },
    { id: 1654, title_fr: "Long", title_en: "Long" },
    { id: 1655, title_fr: "Lavage", title_en: "Washing" },
    {
      id: 1656,
      title_fr: "Crochet tresses/twists ",
      title_en: "Crochet braids/twists",
    },
  ],
  current_hairstyles: [
    { title_fr: "Définition de boucles", title_en: "Curl definition", id: 1 },
    {
      title_fr: "Coloration/Décoloration",
      title_en: "Hair dying/Bleaching",
      id: 2,
    },
    { title_fr: "Tissage/Extensions", title_en: "Weave/Extensions", id: 3 },
    { title_fr: "Tresses", title_en: "Braids", id: 4 },
    { title_fr: "Nattes Collées", title_en: "Cornrows", id: 5 },
    { title_fr: "Cheveux défrisés", title_en: "Relaxed hair", id: 6 },
    { title_fr: "Cheveux naturels", title_en: "Natural hair", id: 7 },
    { title_fr: "Locs", title_en: "Locs", id: 8 },
    {
      title_fr: "Lissage permanent",
      title_en: "Permanent straightening",
      id: 9,
    },
    { title_fr: "Twists", title_en: "Twists", id: 10 },
    { title_fr: "Perruque", title_en: "Wig", id: 11 },
  ],
  prestation_with_sub_prestations: [
    {
      subs: [{ id: 1646, title_fr: "Frisé - Long", title_en: "Wavy - Long" }],
      prestation: {
        title_fr: "Blow Dry + Styling / Brushing",
        title_en: "Blow Dry",
        id: 15,
      },
    },
    {
      subs: [{ id: 1645, title_fr: "Frisé - Long", title_en: "Wavy - Long" }],
      prestation: { title_fr: "Blow Out", title_en: "Blow Dry", id: 14 },
    },
    {
      subs: [],
      prestation: {
        title_fr: "Coiffure hautes",
        title_en: "Coiffure hautes",
        id: 21,
      },
    },
    {
      subs: [{ id: 1654, title_fr: "Long", title_en: "Long" }],
      prestation: {
        title_fr: "Coiffure Laine",
        title_en: "Hairstyle w Wool",
        id: 27,
      },
    },
    {
      subs: [],
      prestation: {
        title_fr: "Coiffures hautes + silkpress",
        title_en: "Coiffures hautes + silkpress",
        id: 22,
      },
    },
    {
      subs: [
        {
          id: 1642,
          title_fr: "Ombre avec couleur hi-lift  - cheveux long - Serrés",
          title_en: "Ombre with hi-lift color - Long hair - Tight Curls",
        },
      ],
      prestation: { title_fr: "Coloration", title_en: "Coloring", id: 11 },
    },
    {
      subs: [
        { id: 1640, title_fr: "Consultation ", title_en: "Consultation " },
      ],
      prestation: {
        title_fr: "Consultation",
        title_en: "Consultation ",
        id: 3,
      },
    },
    {
      subs: [
        {
          id: 1641,
          title_fr: "Coupe + Balayage - Serrées - Long",
          title_en: "Haircut + Balayage - Tight Curls - Long",
        },
      ],
      prestation: {
        title_fr: "Coupe + Balayage",
        title_en: "Haircuts and Hairstyles",
        id: 10,
      },
    },
    {
      subs: [],
      prestation: {
        title_fr: "Coupe et Blow Out",
        title_en: "Coupe et Blow Out",
        id: 7,
      },
    },
    {
      subs: [],
      prestation: {
        title_fr: "Coupe et Definitions des boucles",
        title_en: "Coupe et Definitions des boucles",
        id: 6,
      },
    },
    {
      subs: [],
      prestation: {
        title_fr: "Coupe et Silkpress",
        title_en: "Coupe et Silkpress",
        id: 8,
      },
    },
    {
      subs: [],
      prestation: {
        title_fr: "Coupe et Twist",
        title_en: "Coupe et Twist",
        id: 9,
      },
    },
    {
      subs: [],
      prestation: {
        title_fr: "Coupe femmes et enfants",
        title_en: "Coupe femmes et enfants",
        id: 4,
      },
    },
    {
      subs: [],
      prestation: { title_fr: "Coupe Homme", title_en: "Coupe Homme", id: 5 },
    },
    {
      subs: [{ id: 1643, title_fr: "cheveux long", title_en: "Long Hair" }],
      prestation: { title_fr: "Décoloration", title_en: "Bleaching", id: 12 },
    },
    {
      subs: [
        {
          id: 1644,
          title_fr: "Serrées - Long",
          title_en: "Tight Curls - Long",
        },
      ],
      prestation: {
        title_fr: "Définition de boucles",
        title_en: "Curls Définition",
        id: 13,
      },
    },
    {
      subs: [
        {
          id: 1651,
          title_fr: "Serrées - Long ",
          title_en: "Tight Curls - Long",
        },
      ],
      prestation: { title_fr: "Démêlage", title_en: "Detangling", id: 23 },
    },
    {
      subs: [
        {
          id: 1650,
          title_fr: "Serrées - Long ",
          title_en: "Tight Curls - Long",
        },
      ],
      prestation: { title_fr: "Flat Twist", title_en: "Flat Twist", id: 20 },
    },
    {
      subs: [{ id: 1653, title_fr: "Exfoliation ", title_en: "Scrub" }],
      prestation: { title_fr: "Locs", title_en: "Locs", id: 26 },
    },
    {
      subs: [],
      prestation: {
        title_fr: "Mise en Plis - Cheveux lisse",
        title_en: "Mise en Plis - Cheveux lisse",
        id: 18,
      },
    },
    {
      subs: [{ id: 1655, title_fr: "Lavage", title_en: "Washing" }],
      prestation: { title_fr: "Perruque", title_en: "Wig", id: 28 },
    },
    {
      subs: [
        {
          id: 1656,
          title_fr: "Crochet tresses/twists ",
          title_en: "Crochet braids/twists",
        },
      ],
      prestation: { title_fr: "Pose", title_en: "Fitting", id: 29 },
    },
    {
      subs: [{ id: 1657, title_fr: "Tissage", title_en: "Weaving" }],
      prestation: { title_fr: "Retouche", title_en: "Retouching", id: 30 },
    },
    {
      subs: [
        {
          id: 1638,
          title_fr: "Flexi rod - Court",
          title_en: "Flexi rod - Court",
        },
      ],
      prestation: { title_fr: "Rouleau", title_en: "Roller", id: 1 },
    },
    {
      subs: [
        {
          id: 1648,
          title_fr: "Silk Press - Serrées - Long ",
          title_en: "Silk Press - Tight Curls - Long",
        },
      ],
      prestation: {
        title_fr: "Silk Press",
        title_en: "Hair Straightening",
        id: 17,
      },
    },
    {
      subs: [
        {
          id: 1639,
          title_fr: "Anti-Inflammatoire",
          title_en: "Anti-Inflammatory",
        },
      ],
      prestation: { title_fr: "Traitement", title_en: "Treatment", id: 2 },
    },
    {
      subs: [],
      prestation: {
        title_fr: "Tresses et Tissage",
        title_en: "Tresses et Tissage",
        id: 24,
      },
    },
    {
      subs: [
        {
          id: 1647,
          title_fr: "Serrées - Long ",
          title_en: "Tight Curls - Long",
        },
      ],
      prestation: { title_fr: "Twist Out", title_en: "Twist", id: 16 },
    },
    {
      subs: [
        {
          id: 1649,
          title_fr: "Serrées - Long ",
          title_en: "Tight Curls - Long",
        },
      ],
      prestation: { title_fr: "Twist Updo", title_en: "Twist Updo", id: 19 },
    },
    {
      subs: [
        { id: 1652, title_fr: "Lavage + Style ", title_en: "Wash + Style" },
      ],
      prestation: { title_fr: "Wash'n'go", title_en: "Wash'n'go", id: 25 },
    },
  ],
  countries_currencies: [
    {
      uuid: "25ba57d1-a208-475a-a8ea-0679d3b6bce2",
      country_code: "CA",
      currency_name_en: "Canadian dollar",
      fixed_tax: 0.0,
      country_name_fr: "Canada",
      country_name_en: "Canada",
      currency_name_fr: "Dollar canadien",
      currency_code: "CAD",
      percentage_tax: 14.975,
    },
    {
      uuid: "19546577-5e37-44e7-bf63-ed93575345ab",
      country_code: "FR",
      currency_name_en: "Euro",
      fixed_tax: 0.0,
      country_name_fr: "France",
      country_name_en: "France",
      currency_name_fr: "Euro",
      currency_code: "EUR",
      percentage_tax: 20.0,
    },
  ],
  people_types: [
    { title_fr: "Homme", id: 1, title_en: "Man" },
    { title_fr: "Femme", id: 2, title_en: "Woman" },
    { title_fr: "Autre", id: 3, title_en: "Other" },
  ],
  status: ["UNVALIDATED", "ACTIVED", "UNACTIVED", "DELETED"],
  delivery_type: ["CANADAPOST", "FEDEX", "STORE"],
  payment_type: ["STRIPE", "APPLE_PAY", "GOOGLE_PAY", "PAYPAL"],
  order_status: ["PENDING", "BOOK", "IN_PROGRESS", "ACCEPTED"],
};

export default GLOBAL_DATA_FALLBACK;
