import { ICustomer } from "@/app/types";
import buildValidators from "@/core/utils/buildValidators";
import { IAutoFormFields } from "@/presentation/components/AutoForm";

const customerFields = (
  t: Function,
  roles: any[]
): IAutoFormFields<ICustomer> => {
  const v = buildValidators(t);
  return {
    status: {
      initialValue: (row) => row?.status ?? "UNACTIVED",
      switchValues: ["ACTIVED", "UNACTIVED"],
      type: "switch",
      checkFn: (value) => value === "ACTIVED",
      label: "label.status_admin",
      placeholder: "placeholder.status_admin",
      title: "title.status_admin",
      props: {},
      validation: v.string,
    },
    firstname: {
      initialValue: (row) => {
        const profile = row?.profiles?.find((p) => p.is_parent);
        return profile?.firstname ?? "";
      },
      label: "label.first_name",
      placeholder: "placeholder.first_name",
      title: "title.first_name",
      props: {},
      validation: v.string,
    },
    lastname: {
      initialValue: (row) => {
        const profile = row?.profiles?.find((p) => p.is_parent);
        return profile?.lastname ?? "";
      },
      label: "label.last_name",
      placeholder: "placeholder.last_name",
      title: "title.last_name",
      props: {},
      validation: v.string,
    },
    email: {
      initialValue: (row) => {
        const profile = row?.profiles?.find((p) => p.is_parent);
        return profile?.email ?? "";
      },
      label: "label.email",
      placeholder: "placeholder.email",
      title: "title.email",
      props: {},
      validation: v.email,
    },
    password: {
      initialValue: (row) => "",
      type: "password",
      label: "label.password",
      placeholder: "placeholder.password",
      title: "title.password",
      // hidden: (row) => row,
      props: {},
      validation: (row: any) => (row ? v.password_nr : v.password),
    },
    phonenumber: {
      initialValue: (row) => row?.phonenumber,
      label: "label.phone_number",
      placeholder: "placeholder.phone_number",
      title: "title.phone_number",
      props: {},
      validation: v.number,
    },
    job: {
      initialValue: (row) => "",
      label: "label.job",
      placeholder: "placeholder.job",
      hidden: (row) => row,
      title: "title.job",
      props: {},
      validation: v.string_nr,
    },
    bio: {
      type: "textarea",
      initialValue: (row) => "",
      label: "label.bio",
      placeholder: "placeholder.bio",
      title: "title.bio",
      hidden: (row) => row,
      props: {},
      validation: v.string_nr,
    },
    role_id: {
      initialValue: (row) => row?.role?.id,
      type: "select",
      getOptions: () => roles,
      getOptionLabel: (option) => option.label,
      getOptionValue: (option) => option.value,
      label: "label.role",
      placeholder: "placeholder.role",
      title: "title.role",
      hidden: () => true,
      props: {},
      validation: v.string,
    },
  };
};

export default customerFields;
