import { IVideo } from "@/app/types";
import { IBaseCrudReducer } from "@/app/types/BaseReduxTypes";
import BaseCrudReducer from "@/redux/base/crud-slice-helpers/reducers";
import { resetStateKeys } from "@/redux/common/functions";
import { IVideoState, videoInitialState } from ".";

export interface IVideoReducer extends IBaseCrudReducer<IVideoState, IVideo> {
  resetState: (state: IVideoState) => void;
}

class VideoReducer
  extends BaseCrudReducer<IVideoState, IVideo>
  implements IVideoReducer
{
  resetState = (state: IVideoState) => resetStateKeys(state, videoInitialState);
}

const reducers: IVideoReducer = new VideoReducer();

export default reducers;
