import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers from "./reducers";

export interface IAutoFormState {
  valuesToHold: any | null;
}

export const statInitialState: IAutoFormState = {
  valuesToHold: null,
};

export const slice = createSlice<
  typeof statInitialState,
  typeof reducers,
  typeof sliceNames.autoForm
>({
  name: sliceNames.autoForm,
  initialState: statInitialState,
  reducers,
});

export default slice.reducer;
