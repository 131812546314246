import AppAsyncAutocomplete from "@/core/components/app-async-autocomplete";
import Iconify from "@/core/components/iconify";
import SearchNotFound from "@/core/components/search-not-found";
import useTranslation from "@/core/hooks/useTranslation";
import {
  Button,
  Chip,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";

interface IOption {
  label: string;
  value: string | number;
}

export interface IDataFiltererOption {
  name: string;
  type?: "select" | "input" | "autocomplete";
  placeholder?: string;
  value?: string;
  options?: IOption[];
  onChange: (value: string) => void;
  getValue?: (options: any[]) => any;
  getOptionLabel?: (value: any) => string;
  getOptionValue?: (value: any) => string;
  loadOptions?: (value: any) => Promise<any>;
  renderOption?: any;
  multiple?: boolean;
  freeSolo?: boolean;
}

export interface IDataFiltererProps {
  options: IDataFiltererOption[];
  isFiltered?: boolean;
  onReset?: any;
}

const DataFilterer: React.FC<IDataFiltererProps> = ({
  options,
  isFiltered,
  onReset,
}) => {
  // Hooks
  const t = useTranslation();

  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: "column",
        sm: "row",
      }}
    >
      {options.map((option, index) => {
        switch (option.type) {
          case "autocomplete": {
            return (
              <AppAsyncAutocomplete
                key={`${option.name}-toolbar-${index}`}
                fullWidth
                multiple={option.multiple}
                freeSolo={option.freeSolo}
                sx={{ minWidth: 240 }}
                popupIcon={null}
                noOptionsText={
                  <SearchNotFound
                    query={t(option.placeholder)}
                    sx={{}}
                  />
                }
                options={[]}
                getValue={option.getValue}
                onChange={(event, value) =>
                  option.onChange(
                    option.getOptionValue ? option.getOptionValue(value) : ""
                  )
                }
                loadOptions={option.loadOptions}
                getOptionLabel={option.getOptionLabel && option.getOptionLabel}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder={t(option.placeholder)}
                  />
                )}
                renderOption={
                  option.renderOption ??
                  ((props: any, opt: any, { inputValue, selected }: any) => (
                    // @ts-ignore
                    <Box
                      {...(props || {})}
                      sx={{ p: 1 }}
                    >
                      {option.getOptionLabel && option.getOptionLabel(opt)}
                    </Box>
                  ))
                }
                renderTags={(selectedRecipients: any, getTagProps: any) =>
                  selectedRecipients.map((recipient: any, index: number) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={
                        option.getOptionValue &&
                        option.getOptionValue(recipient)
                      }
                      size="small"
                      label={
                        option.getOptionLabel &&
                        option.getOptionLabel(recipient)
                      }
                      avatar={<></>}
                    />
                  ))
                }
              />
            );
          }
          case "select": {
            return (
              <TextField
                key={`${option.name}-toolbar-${index}`}
                fullWidth
                select
                label={t(option.placeholder)}
                value={option.value}
                onChange={(e) => option.onChange(e.target.value)}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        maxHeight: 260,
                      },
                    },
                  },
                }}
                sx={{
                  maxWidth: { sm: 240 },
                  textTransform: "capitalize",
                }}
              >
                {option.options?.map((_option) => (
                  <MenuItem
                    key={_option.value}
                    value={_option.value}
                    selected={_option.value === option.value}
                    sx={{
                      mx: 1,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                    }}
                  >
                    {t(_option.label)}
                  </MenuItem>
                ))}
              </TextField>
            );
          }
          default: {
            return (
              <TextField
                key={`${option.name}-toolbar-${index}`}
                fullWidth
                value={option.value}
                onChange={(e) => option.onChange(e.target.value)}
                placeholder={t(option.placeholder)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon="eva:search-fill"
                        sx={{ color: "text.disabled" }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            );
          }
        }
      })}
      {isFiltered && (
        <Button
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={onReset}
          startIcon={<Iconify icon="eva:trash-2-outline" />}
        >
          {t("app.clear")}
        </Button>
      )}
    </Stack>
  );
};

export default DataFilterer;
