import { ICustomerCreate, ICustomerRange, ICustomerUpdate } from "@/app/types";
import BaseService from "./BaseService";
import { CustomerUrls } from "./urls";

class CustomerService {
  static get = (public_id: string) => {
    return BaseService.getRequest(CustomerUrls.GET(public_id), true);
  };
  static paginate = (query: ICustomerRange) => {
    return BaseService.getRequest(CustomerUrls.PAGINATE(query), true);
  };
  static create = (data: ICustomerCreate) => {
    return BaseService.postRequest(CustomerUrls.CREATE, data, true);
  };
  static update = ({ public_id, ...rest }: ICustomerUpdate) => {
    return BaseService.putRequest(CustomerUrls.UPDATE(public_id), rest, true);
  };
  static delete = (public_id: string) => {
    return BaseService.deleteRequest(CustomerUrls.DELETE, [public_id], true);
  };
}

export default CustomerService;
