import {
  IAdministratorRange,
  IArticleCategoryRange,
  IArticleRange,
  IBlogCategoryRange,
  IBlogRange,
  IChatBotRange,
  IChatRangeMessage,
  ICustomerRange,
  IHairdressingSalonRange,
  IOrderRange,
  IProductRange,
  IVideoRange,
} from "@/app/types";
import { IBookingRange } from "@/app/types/BookingTypes";
import { buildCrudUrls, buildUrl } from "./utils";

export const fileUrls = {
  STORE_FILE: buildUrl(`/storages/file/upload`),
};

export const AuthUrls = {
  LOGIN_USER: buildUrl(`/auth/login/administrator`),
  UPDATE_USER_INFO: buildUrl(`/update`),
  UPDATE_PASSWORD: buildUrl(`/auth/update-password`),
  GET_CONNECTED_INFO: buildUrl(`/auth/me`),
  SEND_EMAIL_RESET_PWD: buildUrl(`/auth/password-recovery`),
  SEND_NEW_PWD: buildUrl(`/auth/reset-password`),
};

export const GlobalUrls = {
  GET_GLOBAL_DATA: buildUrl(`/users/default/data`),
};

export const AdministratorUrls = {
  ...buildCrudUrls<IAdministratorRange>("administrations"),
  DELETE: buildUrl(`/administrations/users`),
};

export const ArticleUrls = {
  ...buildCrudUrls<IArticleRange>("administrations/article"),
  PUBLISH: (articleId: string) =>
    buildUrl(`/administrations/article/toggle-online/${articleId}`),
  ADD_OR_REMOVE_FAVORITE: (articleId: string) =>
    buildUrl(`/administrations/article/list/favoris/${articleId}`),
  LIST_HOME_ARTICLES: ({ category_uuid, ...query }: IArticleRange) =>
    buildUrl(`/administrations/article/list/home`, { query }),
  LIST_BY_CATEGORY: ({ category_uuid, ...query }: IArticleRange) =>
    buildUrl(`/administrations/article/category/${category_uuid ?? ""}`, {
      query,
    }),
  LIST_FAVORITES: ({ category_uuid, ...query }: IArticleRange) =>
    buildUrl(`/administrations/article/list/favoris`, { query }),
};

export const BlogUrls = {
  ...buildCrudUrls<IBlogRange>("administrations/blog"),
  PUBLISH: (blogId: string) =>
    buildUrl(`/administrations/blog/toggle-online/${blogId}`),
  ADD_OR_REMOVE_FAVORITE: (blogId: string) =>
    buildUrl(`/administrations/blog/list/favoris/${blogId}`),
  ADD_OR_REMOVE_IN_THE_NEWS: (blogId: string) =>
    buildUrl(`/administrations/blog/toggle-in-the-news/${blogId}`),
  ADD_OR_REMOVE_IN_RECENT_ARTICLE: (blogId: string) =>
    buildUrl(`/administrations/blog/toggle-recent-blogs/${blogId}`),
  LIST_HOME_BLOGS: ({ category_uuid, ...query }: IBlogRange) =>
    buildUrl(`/administrations/blog/list/home`, { query }),
  LIST_BY_CATEGORY: ({ category_uuid, ...query }: IBlogRange) =>
    buildUrl(`/administrations/blog/category/${category_uuid ?? ""}`, {
      query,
    }),
  LIST_FAVORITES: ({ category_uuid, ...query }: IBlogRange) =>
    buildUrl(`/administrations/blog/list/favoris`, { query }),
};

export const ProductUrls = {
  ...buildCrudUrls<IProductRange>("administrations/products"),
  DELETE: buildUrl("/administrations/products"),
};

export const OrderUrls = {
  ...buildCrudUrls<IOrderRange>("orders"),
};

export const VideoUrls = {
  ...buildCrudUrls<IVideoRange>("hairdressing-salons/hairstyle-inspiration"),
  DELETE: (uuid: string) =>
    buildUrl(`/administrations/hairstyle-inspiration/${uuid ?? ""}`),
  PAGINATE: (query: IVideoRange) =>
    buildUrl("/administrations/hairstyle-inspiration", { query }),
};

export const ChatBotUrls = {
  ...buildCrudUrls<IChatBotRange>("chat-bot"),
};

export const CustomerUrls = {
  ...buildCrudUrls<ICustomerRange>("administrations/customer"),
  DELETE: buildUrl(`/administrations/users`),
};

export const StatUrls = {
  GET: (resource?: string) =>
    buildUrl(`/statistics/administration${resource ?? ""}`),
};

export const ChatUrls = {
  UNREAD_COUNT: buildUrl(`/messages/unread/count`),
  LAST_RECEIVED: buildUrl(`/messages/last-received`),
  LIST_CONVERSATION_MESSAGES: (id: string) => buildUrl(`/messages/list/${id}`),
  LIST_CONVERSATIONS: (query: IChatRangeMessage) =>
    buildUrl(`/messages/conversations`, { query }),
  LIST_USER_CONVERSATIONS: (public_id?: string) =>
    buildUrl(`/messages/conversations${public_id ? `/${public_id}` : ""}`),
  LIST_USER_CONVERSATION_MESSAGES: (public_id: string) =>
    buildUrl(`/messages/${public_id}`),
  CREATE_MESSAGE: buildUrl(`/messages`),
  CREATE_MESSAGE_FILE: buildUrl(`/messages/file`),
};

export const HairdressingSalonUrls = {
  ...buildCrudUrls<IHairdressingSalonRange>(
    "administrations/hairdressing-salon"
  ),
  GET: (uuid: string) => buildUrl(`/hairdressing-salons/${uuid}`),
};

export const BookingUrls = {
  ...buildCrudUrls<IBookingRange>("bookings"),
};

export const ArticleCategoryUrls = {
  ...buildCrudUrls<IArticleCategoryRange>("administrations/article/category"),
};

export const BlogCategoryUrls = {
  ...buildCrudUrls<IBlogCategoryRange>("administrations/blog/category"),
};
