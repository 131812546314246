const product = {
  root: "produit",
  root_plural: "produits",
  name: "Nom",
  name_en: "Nom en anglais",
  salon: "Salon",
  category: "Catégorie",
  createdAt: "Créé le",
  price: "Prix",
  details: "Details d'un produit",
  sub_category: "Sous-categorie",
};

export default product;
