import { IApplicationState } from "@/app/types";
import ConfirmDialog from "@/core/components/confirm-dialog/ConfirmDialog";
import articleEffects from "@/redux/slices/article/effects";
import { store } from "@/redux/store";
import PropTypes from "prop-types";
import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// @mui
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
// routes
import { PATH_DASHBOARD } from "@/app/routes/paths";
// hooks
import useResponsive from "@/core/hooks/useResponsive";
// utils
import { fDate } from "@/core/utils/formatTime";
// components
import { useAuthContext } from "@/app/auth/useAuthContext";
import { IArticle } from "@/app/types";
import Iconify from "@/core/components/iconify";
import Image from "@/core/components/image";
import SvgColor from "@/core/components/svg-color";
import TextMaxLine from "@/core/components/text-max-line";
import useToast from "@/core/hooks/useToast";
import useTranslation from "@/core/hooks/useTranslation";
import { useSelector } from "@/redux/store";

// ----------------------------------------------------------------------

const StyledOverlay = styled("div")(({ theme }) => ({
  top: 0,
  zIndex: 1,
  width: "100%",
  height: "100%",
  position: "absolute",
  backgroundColor: alpha(theme.palette.grey[900], 0.64),
}));

// ----------------------------------------------------------------------

export interface IBlogPostCardProps {
  index: number;
  post: IArticle;
  handleShowPost?: (article: IArticle) => void;
  getData: () => void;
}

const BlogPostCard: React.FC<IBlogPostCardProps> = ({
  post,
  index,
  handleShowPost,
  getData,
}) => {
  const isDesktop = useResponsive("up", "md");
  const latestPost = index === 0 || index === 1 || index === 2;
  const t = useTranslation();
  const toast = useToast();

  // Store
  const { article } = useSelector((state) => state);

  if (isDesktop && latestPost) {
    return (
      <Card
      // sx={{ cursor: handleShowPost ? "pointer" : undefined }}
      // onClick={handleShowPost ? () => handleShowPost(post) : undefined}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            top: 24,
            left: 24,
            right: 24,
            zIndex: 9,
            position: "absolute",
          }}
        >
          <Avatar
            alt={post?.owner_firstname ?? post?.author?.firstname ?? ""}
            src={
              post?.owner_image?.medium?.url ??
              post?.author?.avatar?.thumbnail?.url ??
              ""
            }
          />
          <Typography
            variant="subtitle2"
            sx={{ color: post.is_online ? "success.lighter" : "error.lighter" }}
          >
            {t(post?.is_online ? "status.online" : "status.offline")}
          </Typography>
        </Stack>

        <PostContent
          uuid={post.uuid}
          title={post.title_fr}
          view={null}
          comment={null}
          share={null}
          createdAt={null}
          index={index}
          post={post}
          handleShowPost={handleShowPost}
          getData={getData}
        />

        <StyledOverlay />

        <Image
          // @ts-ignore
          alt="cover"
          src={post.cover_image?.medium?.url ?? ""}
          sx={{ height: 360 }}
        />
        {/* {
          post.cover_image === null && post.cover_image_en === null ? (
            <video src={post.video?.url } height={'360px'} width={'100%'} />
          ) : (
            <Image
              // @ts-ignore 
              alt="cover"
              src={post.cover_image?.medium?.url }
              sx={{ height: 360 }}
            />
          )
        } */}
      </Card>
    );
  }

  return (
    <Card
    // sx={{ cursor: handleShowPost ? "pointer" : undefined }}
    // onClick={handleShowPost ? () => handleShowPost(post) : undefined}
    >
      <Box sx={{ position: "relative" }}>
        <SvgColor
          /* @ts-ignore */
          src="/assets/shape_avatar.svg"
          sx={{
            width: 80,
            height: 36,
            zIndex: 9,
            bottom: -15,
            position: "absolute",
            color: "background.paper",
          }}
        />

        <Avatar
          alt={post?.owner_firstname ?? post?.author?.firstname ?? ""}
          src={
            post?.owner_image?.medium?.url ??
            post?.author?.avatar?.thumbnail?.url ??
            ""
          }
          sx={{
            left: 24,
            zIndex: 9,
            width: 32,
            height: 32,
            bottom: -16,
            position: "absolute",
          }}
        />
        {/* {
          // <video src={post.video?.url } height={'200px'} />
          post.cover_image === null && post.cover_image_en === null ? (
            <video src={post.video?.url } height={'200px'} />
          ) : (
            <Image
              // @ts-ignore 
              alt="cover"
              src={post.cover_image?.medium?.url ?? post.cover_image_en?.medium?.url }
              ratio="4/3"
            />
          )
        } */}
        <Image
          // @ts-ignore
          alt="cover"
          src={post.cover_image?.medium?.url ?? ""}
          ratio="4/3"
        />
      </Box>
      <PostContent
        uuid={post.uuid}
        title={post.title_fr}
        view={null}
        comment={null}
        share={null}
        createdAt={null}
        post={post}
        handleShowPost={handleShowPost}
        getData={getData}
      />

      <Typography
        variant="subtitle2"
        sx={{
          px: 3,
          pb: 2,
          color: post.is_online ? "success.main" : "error.main",
        }}
      >
        {t(post?.is_online ? "status.online" : "status.offline")}
      </Typography>
    </Card>
  );
};

// ----------------------------------------------------------------------

PostContent.propTypes = {
  uuid: PropTypes.string,
  view: PropTypes.number,
  index: PropTypes.number,
  share: PropTypes.number,
  title: PropTypes.string,
  comment: PropTypes.number,
  createdAt: PropTypes.string,
  handleShowPost: PropTypes.func,
  post: PropTypes.object,
  getData: PropTypes.func,
};

export function PostContent({
  uuid,
  title,
  view,
  comment,
  share,
  createdAt,
  index,
  handleShowPost,
  post,
  getData,
}: any) {
  const isDesktop = useResponsive("up", "md");
  const { user } = useAuthContext();
  const [showDeleteMOdal, setShowDeleteModal] = React.useState<boolean>(false);
  const t = useTranslation();
  const toast = useToast();
  const dispatch = store.dispatch;
  const navigate = useNavigate();
  const {
    article: { isLoading },
  } = useSelector((state: IApplicationState) => state);

  // const linkTo = PATH_DASHBOARD.articles.view(paramCase(title ?? ""));
  const linkTo = PATH_DASHBOARD.articles.edit(uuid);
  const deleteArticle = () => {
    dispatch(
      articleEffects.delete(uuid, () => {
        toast.success(t(`models.delete_success`, { model: `article.root` }));
        setShowDeleteModal(false);
        getData();
      })
    );
  };
  const latestPostLarge = index === 0;

  const latestPostSmall = index === 1 || index === 2;

  const POST_INFO = [
    { id: "view", value: view, icon: "eva:eye-fill" },
    { id: "edit", value: comment, icon: "eva:edit-fill" },
    { id: "delete", value: share, icon: "fluent:delete-16-regular" },
  ];

  return (
    <CardContent
      sx={{
        pt: 4.5,
        width: 1,
        ...((latestPostLarge || latestPostSmall) && {
          pt: 0,
          zIndex: 9,
          bottom: 0,
          position: "absolute",
          color: "common.white",
        }),
      }}
    >
      <Typography
        gutterBottom
        variant="caption"
        component="div"
        sx={{
          color: "text.disabled",
          ...((latestPostLarge || latestPostSmall) && {
            opacity: 0.64,
            color: "common.white",
          }),
        }}
      >
        {fDate(createdAt)}
      </Typography>

      {/* <Link
        component={RouterLink}
        to={linkTo}
        color="inherit"
      > */}
      {/* @ts-ignore */}
      <TextMaxLine
        variant={isDesktop && latestPostLarge ? "h5" : "subtitle2"}
        line={2}
        persistent
      >
        {title}
      </TextMaxLine>
      {/* </Link> */}

      <Stack
        flexWrap="wrap"
        direction="row"
        justifyContent="flex-end"
        sx={{
          mt: 3,
          color: "text.disabled",
          ...((latestPostLarge || latestPostSmall) && {
            opacity: 0.64,
            color: "common.white",
          }),
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{ typography: "caption", ml: 1.5 }}
        >
          <Box
            width={16}
            sx={{ mr: 1.5, cursor: "pointer" }}
            onClick={handleShowPost ? () => handleShowPost(post) : undefined}
          >
            <Iconify
              icon={"eva:eye-fill"}
              width={16}
              sx={{ mr: 1.5 }}
            />
          </Box>
          <Box
            width={16}
            sx={{ mr: 1.5 }}
          >
            <Link
              component={RouterLink}
              to={linkTo}
              color="inherit"
            >
              <Iconify
                icon={"eva:edit-fill"}
                width={16}
                sx={{ mr: 0.5 }}
              />
            </Link>
          </Box>
          <Box
            width={16}
            sx={{ mr: 1.5, cursor: "pointer" }}
            onClick={() => setShowDeleteModal(true)}
          >
            <Iconify
              icon={"fluent:delete-16-regular"}
              width={16}
              sx={{ mr: 0.5 }}
            />
          </Box>
        </Stack>
      </Stack>

      {showDeleteMOdal && (
        <ConfirmDialog
          open={true}
          onClose={() => setShowDeleteModal(false)}
          disabled={isLoading}
          title={t("app.delete")}
          content={t(`app.delete_model_confirm_vowel`, {
            model: `article.root`,
          })}
          onConfirm={() => {
            deleteArticle();
          }}
          isConfirmLoading={isLoading}
          confirmLabel={t("app.delete")}
          action={null}
        />
      )}
    </CardContent>
  );
}

export default BlogPostCard;
