import AdministratorService from "@/app/services/api/AdministratorService";
import {
  IAdministratorCreate,
  IAdministratorRange,
  IAdministratorUpdate,
} from "@/app/types";
import { IReduxBaseCrudEffect } from "@/app/types/BaseReduxTypes";
import BaseReduxCrudEffects from "@/redux/base/crud-slice-helpers/effects";
import { slice } from ".";

export interface IAdministratorEffects
  extends IReduxBaseCrudEffect<
    IAdministratorRange,
    IAdministratorCreate,
    IAdministratorUpdate,
    AdministratorService
  > {}

class AdministratorEffects
  extends BaseReduxCrudEffects<
    IAdministratorRange,
    IAdministratorCreate,
    IAdministratorUpdate
  >
  implements IAdministratorEffects {}

const administratorEffects = new AdministratorEffects(
  slice,
  AdministratorService
);

export default administratorEffects;
