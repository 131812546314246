import { Autocomplete, AutocompleteProps } from "@mui/material";
import React from "react";

export interface IAppAsyncAutocompleteProps
  extends AutocompleteProps<any, any, any, any, any> {
  loadOptions?: (inputValue: string) => Promise<any>;
  options: any[];
  optionsResetDeps?: any[];
  getValue?: (option: any[]) => any;
}

const AppAsyncAutocomplete: React.FC<IAppAsyncAutocompleteProps> = ({
  loadOptions,
  value: _value,
  getValue,
  optionsResetDeps,
  ...props
}) => {
  // State
  const [hasFocus, setHasFocus] = React.useState<boolean>(false);
  console.log({ _value });
  const [value, setValue] = React.useState<any>(_value);
  const [optionsData, setOptionsData] = React.useState<any[]>(
    props.options ?? []
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  // Functions
  const getOptions = async (inputValue: string) => {
    if (loadOptions) {
      setLoading(true);
      // setOptionsData([]);
      try {
        const _values = await loadOptions(inputValue);
        console.log("_values: ", _values);
        setOptionsData(_values);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    } else {
      setOptionsData(props.options ?? []);
    }
  };

  React.useEffect(() => {
    if (_value) {
      setValue(_value);
    }
  }, [_value]);

  React.useEffect(() => {
    if (optionsResetDeps) {
      setOptionsData([]);
    }
  }, [...(optionsResetDeps ?? [])]);

  console.log("options: ", optionsData);

  return (
    <Autocomplete
      {...props}
      value={value}
      loading={loadOptions && loading}
      options={optionsData}
      onBlur={(e) => {
        props.onBlur && props.onBlur(e);
        setHasFocus(false);
      }}
      onFocus={(e) => {
        setHasFocus(true);
        optionsData.length === 0 &&
          loadOptions &&
          getOptions((e.target as any).value ?? "");
        props.onFocus && props.onFocus(e);
      }}
      onChange={(_, v, ...rest) => {
        setValue(v);
        props.onChange && props.onChange(_, v, ...rest);
      }}
      onInputChange={(event, value) => {
        getOptions(value);
        props.onInputChange && props.onInputChange(event, value, "input");
      }}
    />
  );
};

export default AppAsyncAutocomplete;
