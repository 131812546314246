import {
  IArticleCategoryCreate,
  IArticleCategoryRange,
  IArticleCategoryUpdate,
} from "@/app/types";
import BaseService from "./BaseService";
import { ArticleCategoryUrls } from "./urls";

class ArticleCategoryService {
  static get = (public_id: string) => {
    return BaseService.getRequest(ArticleCategoryUrls.GET(public_id), true);
  };
  static paginate = (query: IArticleCategoryRange) => {
    return BaseService.getRequest(ArticleCategoryUrls.PAGINATE(query), true);
  };
  static create = (data: IArticleCategoryCreate) => {
    return BaseService.postRequest(ArticleCategoryUrls.CREATE, data, true);
  };
  static update = ({ uuid, ...rest }: IArticleCategoryUpdate) => {
    return BaseService.putRequest(ArticleCategoryUrls.UPDATE(uuid), rest, true);
  };
  static delete = (uuid: string) => {
    return BaseService.deleteRequest(
      ArticleCategoryUrls.DELETE(uuid),
      {},
      true
    );
  };
}

export default ArticleCategoryService;
