import { DEFAULT_RANGE } from "@/app/config-global";
import { useLocales } from "@/app/locales";
import { PATH_DASHBOARD } from "@/app/routes/paths";
import { IApplicationState, IAppLocale, ICustomer } from "@/app/types";
import ConfirmDialog from "@/core/components/confirm-dialog";
import useFilters from "@/core/hooks/useFilters";
import useTranslation from "@/core/hooks/useTranslation";
import TablePage from "@/presentation/components/TablePage";
import customerEffects from "@/redux/slices/customer/effects";
import { dispatch } from "@/redux/store";
import React from "react";
import { useSelector } from "react-redux";
import customerColumns from "./data/columns";
import {
  customerTableTopOptions,
  customerToolbarOptions,
} from "./data/constants";
import customerFields from "./data/fields";

const CustomersPage: React.FC = () => {
  // Store
  const { customer, globalData } = useSelector(
    (state: IApplicationState) => state
  );

  // Hooks
  const { currentLang } = useLocales();
  const t = useTranslation();
  const {
    getData,
    handleKeyword,
    handleRole,
    handleStatus,
    handleOrder,
    handleOrder_field,
    handlePage,
    handlePer_page,
    keyword,
    role,
    status,
    order,
    order_field,
  } = useFilters({
    effects: customerEffects,
    initialState: {
      page: customer.list.current_page,
      per_page: customer.list.per_page,
      keyword: "",
      role: "",
      status: "",
      order: "",
      order_field: "",
    },
  });

  // Constants
  const formattedRoles = globalData.data.roles
    .filter((role) => [2, 3].includes(role.id))
    .map((role) => ({
      label: role[`title_${currentLang.value as IAppLocale}`],
      value: role.id,
    }));

  // State
  const [rowToChangeStatus, setRowToChangeStatus] =
    React.useState<ICustomer | null>(null);

  return (
    <>
      <TablePage
        model="customer"
        noUpdate
        noCheckBoxes
        // ** Genre **
        // female
        // ** Custom row actions **
        customRowActions={[
          {
            onClick: (row: ICustomer) => setRowToChangeStatus(row),
            icon: "mdi:list-status",
            label: "app.enable_disable",
          },
        ]}
        // ** Toolbar **
        tableTopOptions={customerTableTopOptions}
        toolbarOptionChange={handleStatus}
        currentToolbarOption={status}
        toolbarOptions={customerToolbarOptions(
          formattedRoles,
          {
            value: keyword,
            onChange: handleKeyword,
          }
          // {
          //   value: role,
          //   onChange: handleRole,
          // }
        )}
        // ** On Select rows **
        idProperty="public_id"
        // ** Table CRUD **
        fields={customerFields(t, formattedRoles)}
        stateData={customer}
        columns={customerColumns(currentLang.value)}
        effects={customerEffects}
        getData={getData}
        // ** Filter options **
        isNotFound={(data) =>
          (!data.length && !!keyword) ||
          (!data.length && !!role) ||
          (!data.length && !!status)
        }
        isFiltered={(data) => keyword !== "" || role !== "" || status !== ""}
        onReset={() => {
          handleKeyword("");
          handleRole("");
          handleStatus("");
        }}
        // ** Sorting **
        handleFilterOrder={handleOrder}
        handleFilterOrderField={handleOrder_field}
        order={order}
        orderField={order_field}
        // ** Pagination **
        onChangePage={handlePage}
        onChangeRowsPerPage={handlePer_page}
        // ** Table Row Actions **
        showDetailsUrl={(row) =>
          PATH_DASHBOARD.customers.view(row?.public_id ?? "")
        }
      />

      {rowToChangeStatus && (
        <ConfirmDialog
          open={true}
          onClose={() => setRowToChangeStatus(null)}
          disabled={customer?.isLoading}
          title={t("app.update")}
          content={t(`customer.really_want_to_change_user_status`, {
            status: `status.${
              rowToChangeStatus?.status === "ACTIVED" ? "UNACTIVED" : "ACTIVED"
            }`,
          })}
          onConfirm={() => {
            dispatch(
              customerEffects.update(
                {
                  public_id: rowToChangeStatus?.public_id ?? "",
                  status:
                    rowToChangeStatus?.status === "ACTIVED"
                      ? "UNACTIVED"
                      : "ACTIVED",
                },
                () => {
                  setRowToChangeStatus(null);
                  dispatch(customerEffects.paginate(DEFAULT_RANGE));
                }
              )
            );
          }}
          isConfirmLoading={customer?.isLoading}
          confirmLabel={t("app.confirm")}
          action={null}
        />
      )}
    </>
  );
};

export default CustomersPage;
