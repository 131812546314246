const label = {
  title: "Titre",
  status_admin: "Activer/Désactiver",
  search: "Recherche",
  first_name: "Prénom",
  last_name: "Nom",
  email: "Adresse email",
  phone_number: "Numéro de téléphone",
  role: "Role",
  job: "Travail",
  bio: "Bio",
  tags: "Tags",
  content: "Contenu",
  read_duration: "Durée de lecture (minutes)",
  meta_title: "Meta titre",
  meta_description: "Meta description",
  cover: "Couverture",
  image: "Image",
  drop_or_select_file:
    "Déposez un fichier ou cliquez-ici et sélectionnez un fichier",
  category: "Catégorie",
  name: "Nom",
  name_en: "Nom en anglais",
  name_fr: "Nom en français",
  brand: "Marque",
  images: "Images",
  description: "Description",
  description_en: "Description en anglais",
  sku: "SKU",
  real_price: "Prix réel",
  current_price: "Prix actuel",
  qty: "Quantité",
  hair_style: "Style de cheveux",
  benefits: "Bénéfices",
  weight: "Poids",
  is_online: "Mettre en ligne",
  sub_category: "Sous-catégorie",
  video_link: "Lien de la vidéo",
  image_link: "Lien de l'image",
  prestation: "Prestation",
  prestations: "Prestations",
  begin_duration: "Début",
  video: "Vidéo",
  video_duration: "Durée de la vidéo",
  hairdressing_salon: "Salon",
  password: "Mot de passe",
  primary_address: "Adresse principale",
  town: "Ville",
  country: "Pays",
  year: "Année",
  hairdresser_salon_name: "Nom du salon",
  country_code: "Code du pays",
  entreprise_name: "Nom de l'entreprise",
  entreprise_phonenumber: "Numéro de téléphone de l'entreprise",
  website: "Site web",
  hair_types: "Types de cheuveux",
  people_type_ids: "Types de personne",
  shop_onligne: "Visible en ligne",
  headdress_at_home: "Coiffure à domicile",
  postal_code: "Code postal",
  province: "Région",
  address_one: "Adresse principale",
  address_two: "Adresse secondaire",
  accept_click_n_collect: "Click N Collect",
  manager_hairdressing_email: "Adresse email du gérant du salon",
  parent_profile: "Profil parent",
  profile: "Profil",
  profiles: "Profils",
  orders_an_bookings: "Commandes et réservations",
  profile_count: "Nombre de profils",
  formula: "Formule",
  formulas: "Formules",
  BASIC: "Basique",
  PREMIUM: "Prémium",
  register_date: "Date d'inscription",
  contact_via_chat: "Contacter via le chat",
  monday: "Lundi",
  tuesday: "Mardi",
  wednesday: "Mercredi",
  thursday: "Jeudi",
  friday: "Vendredi",
  saturday: "Samedi",
  sunday: "Dimanche",
  week_day_1: "Lundi",
  week_day_2: "Mardi",
  week_day_3: "Mercredi",
  week_day_4: "Jeudi",
  week_day_5: "Vendredi",
  week_day_6: "Samedi",
  week_day_7: "Dimanche",
  planning: "Planning",
  start: "Début",
  end: "Fin",
  about: "À propos",
  popularity_index: "Index de popularité",
  hairdresser: "Coiffeur",
  hairdressers: "Coiffeurs",
  orders: "Commandes",
  bookings: "Réservations",
  client_email: "Email du client",
  salon: "Salon",
  date_added: "Date d'ajout",
  child_profile: "Profil enfant",
  people_types: "Types de personne",
  preferences: "Préférences",
  objective: "Objectif",
  last_time_hairdresser: "Dernière fois chez un coiffeur",
  hair_conditions: "Conditions de cheveux",
  current_hair_style: "Style de cheveux actuelle",
  hair_type: "Type de cheveux",
  in_stock: "En stock",
  unavailable: "Indisponible",
  position: "Position",
  publish: "Publié",
  in_the_news: "A la une",
  recent_blogs: "Article récent",
  total_with_feed_and_tva: "Total + Frais + TVA",
  total: "Total",
  order_date: "Date de la commande",
  title_fr: "Titre en français",
  title_en: "Title en anglais",
  description_fr: "Description en français",
  product_hot_point: "Points saillants",
  special_price: "Prix spécial",
  stock: "Stock",
  sold: "Vendu",
  price: "Prix",
  customer: "Client",
  none_female: "Aucune",
  french: "Français",
  english: "Anglais",
  is_kid_video: "Est-ce un dessin animé ?",
  is_kid: "Est-ce une vidéo pour enfant ?",
  fonction_auteur: "Fonction de l'auteur",
  prenom_auteur: "Prénom de l'auteur",
  nom_auteur: "Nom de l'auteur",
  avatar_auteur: "Photo de profil de l'auteur",
};

export default label;
