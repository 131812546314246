import { IToolbarOption } from "@/presentation/components/TablePage";

export const videoTableTopOptions = [
  {
    label: "status.all",
    value: "",
  },
  // {
  //   label: "status.ACTIVED",
  //   value: "active",
  // },
  // {
  //   label: "status.UNACTIVED",
  //   value: "inactive",
  // },
];
export const videoToolbarOptions = (
  keyword: {
    value: string;
    onChange: (value: string) => void;
  },
  prestation: {
    value: string;
    onChange: (value: string) => void;
  },
  prestations: any[]
): IToolbarOption[] => [
  {
    name: "admin_roles",
    type: "select",
    options: prestations,
    placeholder: "video.prestation",
    ...prestation,
  },
  {
    name: "admin_search",
    placeholder: "placeholder.search",
    type: "input",
    ...keyword,
  },
];
