import React, { useState } from "react";
// @mui
import { Box, Tab, Tabs } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "@/app/routes/paths";
// _mock_
// components
import Iconify from "@/core/components/iconify";
// sections
import Page from "@/core/components/page";
import useTranslation from "@/core/hooks/useTranslation";
import BookingAndOrders from "@/presentation/components/BookingsAndOrders";
import { AccountGeneral } from "@/presentation/sections/@dashboard/user/account";
import { useNavigate, useParams } from "react-router-dom";
import { dispatch, useSelector } from "@/redux/store";
import getFullName from "@/core/utils/getFullName";
import customerEffects from "@/redux/slices/customer/effects";
import { APP_PERMISSIONS } from "@/app/config-global";
import sliceNames from "@/redux/names";

// ----------------------------------------------------------------------

const CustomerDetailsPage = () => {
  // Store
  const {
    customer: { currentItem: user, isLoading },
  } = useSelector((state) => state);

  // State
  const [currentTab, setCurrentTab] = useState("general");

  // Hooks
  const t = useTranslation();
  const navigate = useNavigate();
  const { public_id } = useParams();

  // Constants
  const TABS = [
    {
      value: "general",
      label: "label.profiles",
      icon: <Iconify icon="ic:round-account-box" />,
      component: <AccountGeneral profiles={user?.profiles} />,
    },
    {
      value: "orders",
      label: "label.orders",
      icon: <Iconify icon="ic:round-receipt" />,
      component: <BookingAndOrders order />,
    },
    {
      value: "bookings",
      label: "label.bookings",
      icon: <Iconify icon="maki:hairdresser" />,
      component: <BookingAndOrders />,
    },
    // {
    //   value: 'notifications',
    //   label: 'Notifications',
    //   icon: <Iconify icon="eva:bell-fill" />,
    //   component: <AccountNotifications />,
    // },
    // {
    //   value: 'social_links',
    //   label: 'Social links',
    //   icon: <Iconify icon="eva:share-fill" />,
    //   component: <AccountSocialLinks socialLinks={_userAbout.socialLinks} />,
    // },
    // {
    //   value: 'change_password',
    //   label: 'Change password',
    //   icon: <Iconify icon="ic:round-vpn-key" />,
    //   component: <AccountChangePassword />,
    // },
  ];

  const parentProfile = user?.profiles
    ?.slice()
    .sort((a) => (!!a?.is_parent ? -1 : 1))?.[0];

  React.useEffect(() => {
    dispatch(customerEffects.get(public_id ?? ""));
    return () => {
      dispatch(customerEffects.reset());
    };
  }, []);

  return (
    <Page
      title="customer.client_detail"
      isLoading={isLoading}
      breadcrumbs={[
        { name: "dashboard.root", href: PATH_DASHBOARD.root },
        { name: "customer.root_plural", href: PATH_DASHBOARD.customers.root },
        {
          name: getFullName(
            parentProfile?.firstname,
            parentProfile?.lastname,
            parentProfile?.email
          ),
        },
      ]}
      permissions={APP_PERMISSIONS[sliceNames.customer].read}
      defaultActionButton={{
        type: "icon",
        title: "label.contact_via_chat",
        icon: "tabler:message-circle-2-filled",
        onClick: () =>
          navigate(`${PATH_DASHBOARD.chat.root}/?user=${public_id ?? ""}`),
      }}
    >
      <Tabs
        value={currentTab}
        onChange={(event, newValue) => setCurrentTab(newValue)}
      >
        {TABS.map((tab) => (
          <Tab
            key={tab.value}
            label={t(tab.label)}
            icon={tab.icon}
            value={tab.value}
          />
        ))}
      </Tabs>

      {TABS.map(
        (tab) =>
          tab.value === currentTab && (
            <Box
              key={tab.value}
              sx={{ mt: 5 }}
            >
              {tab.component}
            </Box>
          )
      )}
    </Page>
  );
};

export default CustomerDetailsPage;
