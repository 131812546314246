import { IAdministrator } from "@/app/types";
import {
  baseCrudInitialState,
  IBaseCrudState,
} from "@/redux/base/crud-slice-helpers";
import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers, { IAdministratorReducer } from "./reducers";

export interface IAdministratorState extends IBaseCrudState<IAdministrator> {}

export const administratorInitialState: IAdministratorState =
  baseCrudInitialState;

export const slice = createSlice<
  IAdministratorState,
  IAdministratorReducer,
  typeof sliceNames.administrator
>({
  name: sliceNames.administrator,
  initialState: administratorInitialState,
  reducers,
});

export default slice.reducer;
