import CustomerService from "@/app/services/api/CustomerService";
import { ICustomerCreate, ICustomerRange, ICustomerUpdate } from "@/app/types";
import { IReduxBaseCrudEffect } from "@/app/types/BaseReduxTypes";
import BaseReduxCrudEffects from "@/redux/base/crud-slice-helpers/effects";
import { slice } from ".";

export interface ICustomerEffects
  extends IReduxBaseCrudEffect<
    ICustomerRange,
    ICustomerCreate,
    ICustomerUpdate,
    CustomerService
  > {}

class CustomerEffects
  extends BaseReduxCrudEffects<ICustomerRange, ICustomerCreate, ICustomerUpdate>
  implements ICustomerEffects {}

const customerEffects = new CustomerEffects(slice, CustomerService);

export default customerEffects;
