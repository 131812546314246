import BookingService from "@/app/services/api/BookingService";
import { IBookingCreate, IBookingRange, IBookingUpdate } from "@/app/types";
import { IReduxBaseCrudEffect } from "@/app/types/BaseReduxTypes";
import BaseReduxCrudEffects from "@/redux/base/crud-slice-helpers/effects";
import { slice } from ".";

export interface IBookingEffects
  extends IReduxBaseCrudEffect<
    IBookingRange,
    IBookingCreate,
    IBookingUpdate,
    BookingService
  > {}

class BookingEffects
  extends BaseReduxCrudEffects<IBookingRange, IBookingCreate, IBookingUpdate>
  implements IBookingEffects {}

const bookingEffects = new BookingEffects(slice, BookingService);

export default bookingEffects;
