import { Intent } from "@/app/types/IntentTypes";
import Iconify from "@/core/components/iconify/Iconify";
import useTranslation from "@/core/hooks/useTranslation";
import AppModal from "@/presentation/components/AppModal";
import { Box, Theme, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { DialogProps } from "@mui/material/Dialog";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useDispatch } from "react-redux";
import AddOrderForm from "./addOrderForm";

const useStyles: any = makeStyles((theme: Theme) => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    width: 900,
  },
}));

interface AddOrderProps {
  intent?: Intent;
  lang: string;
}

export default function AddOrder(props: AddOrderProps) {
  const t = useTranslation();
  const dispatch = useDispatch();

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);

  return (
    <Box>
      <Button
        color="primary"
        variant="contained"
        className={classes.action}
        onClick={handleClickOpen("paper")}
        startIcon={<Iconify icon="eva:plus-fill" />}
      >
        {t("app.add")}
      </Button>
      {open && (
        <AppModal
          onClose={() => handleClose()}
          title={
            props.intent
              ? t(`app.update_model_title_female`, {
                  model: `chatBot.question`,
                })
              : t(`app.create_model_title_female`, {
                  model: `chatBot.question`,
                })
          }
          breadCrumbLinks={[
            {
              name: props.intent
                ? t(`app.update_model_description_female`, {
                    model: `chatBot.question`,
                  })
                : t(`app.create_model_description_female`, {
                    model: `chatBot.question`,
                  }),
            },
          ]}
          // actions={[
          // @ts-ignore
          // <LoadingButton
          //   key={`chatBot-save`}
          //   loading={props.isLoading}
          //   // disabled={stateData?.isLoading}
          //   variant="contained"
          //   color="primary"
          //   onClick={() => formRef.current?.click()}
          //   startIcon={
          //     props.intent ? (
          //       <Iconify icon="eva:checkmark-fill" />
          //     ) : (
          //       <Iconify icon="eva:plus-fill" />
          //     )
          //   }
          // >
          //   {t(`app.${props.intent ? "update" : "create"}`)}
          // </LoadingButton>,
          // <Button
          //   key={`${model}-cancel`}
          //   disabled={stateData?.isLoading}
          //   variant="contained"
          //   color="error"
          //   onClick={onClose}
          //   startIcon={<Iconify icon="eva:close-fill" />}
          // >
          //   {t("app.cancel")}
          // </Button>,
          // ]}
        >
          <AddOrderForm
            handleClose={handleClose}
            lang={props.lang}
            info={props.intent}
          />
        </AppModal>
      )}
    </Box>
  );
}
