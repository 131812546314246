const app = {
  total: "Total",
  clear: "Effacer",
  status: "Statut",
  accounts: "Comptes",
  messages: "Messages",
  media: "media",
  content: "Contenu",
  sales: "Ventes",
  add: "Ajouter",
  create: "Créer",
  update: "Sauvegarder",
  edit: "Modifier",
  delete: "Supprimer",
  role: "role",
  list: "Liste",
  no_data: "Pas de donnée",
  save: "Enregistrer",
  cancel: "Annuler",
  desc: "Descendant",
  asc: "Ascendant",
  actions: "Actions",
  details: "Détails",
  see: "Voir",
  create_model_title: "Créer un {model}",
  update_model_title: "Modifier un {model}",
  delete_model_title: "Suprrimer un {model}",
  create_model_title_female: "Créer une {model}",
  update_model_title_female: "Modifier une {model}",
  delete_model_title_female: "Supprimer une {model}",
  delete_model_confirm: "Voulez-vous vraiment supprimer ce {model}?",
  delete_model_confirm_plural:
    "Voulez-vous vraiment supprimer ces {count} {model}?",
  delete_model_confirm_vowel_plural:
    "Voulez-vous vraiment supprimer ces {count} {model}?",
  delete_model_confirm_vowel: "Voulez-vous vraiment supprimer cet {model}?",
  delete_model_confirm_female: "Voulez-vous vraiment supprimer cette {model}?",
  delete_model_confirm_vowel_female:
    "Voulez-vous vraiment supprimer cette {model}?",
  update_model_description:
    "Modifer un {model} en modifiant les champs ci-dessous et cliquez sur le boutton 'Modifier' pour confirmer les modifications.",
  create_model_description:
    "Créez un {model} en remplissant les champs ci-dessous et cliquez sur le boutton 'Créer' pour le sauvegarder.",
  update_model_description_female:
    "Modifer une {model} en modifiant les champs ci-dessous et cliquez sur le boutton 'Modifier' pour confirmer les modifications.",
  create_model_description_female:
    "Créez une {model} en remplissant les champs ci-dessous et cliquez sur le boutton 'Créer' pour la sauvegarder.",
  french: "Français",
  english: "Anglais",
  application: "Application",
  selected: "Sélectionné",
  selected_female: "Sélectionée",
  selected_plural: "Sélectionnés",
  selected_female_plural: "Sélectionées",
  selected_plural_female: "Sélectionées",
  STRIPE: "Stripe",
  APPLE_PAY: "Apple Pay",
  GOOGLE_PAY: "Google Pay",
  PAYPAL: "PayPal",
  remove_all: "Tout supprimer",
  infos: "Informations",
  table: "Tableau",
  calendar: "Calendrier",
  date: "Date",
  address: "Adresse",
  payment: "Paiement",
  delivery: "Livraison",
  history: "Historique",
  today: "Aujourd'hui",
  month: "Mois",
  day: "Jour",
  year: "Année",
  week: "Semaine",
  agenda: "Agenda",
  no_events: "Pas d'évènement",
  filters: "Filtres",
  range: "Interval",
  colors: "Couleurs",
  color: "Couleur",
  events: "Évènements",
  event: "Évènement",
  reset: "Réinitialiser",
  confirm: "Confirmer",
  booy: "Masculin",
  girl: "Feminin",
  page_not_found: "Page pas trouvée",
  page_not_found_title: "Désolé nous ne trouvons pas cette page!",
  page_not_found_text:
    "Désolé nous ne parvenons pas à trouver la page que vous recherchez, il s'agit peut être d'une erreur d'orthographe.",
  go_to_home: "Aller à l'accueil",
  enable_disable: "Activer / Désactiver",
  not_found: "Pas trouvé(e)",
  no_result_found_in_the_list:
    "Nous n'avons pas trouvé de résultat dans la liste",
  check_word_spelling: "Veuillez vérifier l'orthographe",
  enter_a_keyword: "Entrez un mot clé",
  at: "à",
  open: "Ouvert",
  close: "Fermé",
};

export default app;
