import { PATH_DASHBOARD } from "@/app/routes/paths";
import CustomBreadcrumbs from "@/core/components/custom-breadcrumbs/CustomBreadcrumbs";
import React from "react";
import AddOrder from "./addOrder";

interface HeaderProps {
  className?: object;
  value: string;
  handleChange: (event: React.ChangeEvent<{}>, value: any) => void;
}
const Header = (props: HeaderProps) => {
  const { value } = props;

  return (
    <CustomBreadcrumbs
      heading="chatBot.root_plural"
      links={[
        {
          name: "dashboard.root",
          href: PATH_DASHBOARD.root,
        },
        {
          name: "chatBot.root_plural",
          href: PATH_DASHBOARD.chatBots.root,
        },
        {
          name: "app.list",
        },
      ]}
      action={<AddOrder lang={value} />}
    />
  );
};

export default Header;
