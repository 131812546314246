// routes
import { PATH_DASHBOARD } from "@/app/routes/paths";
// components
import SvgColor from "@/core/components/svg-color";
import sliceNames from "@/redux/names";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  blog: icon("ic_blog"),
  cart: icon("ic_cart"),
  chat: icon("ic_chat"),
  mail: icon("ic_mail"),
  user: icon("ic_user"),
  file: icon("ic_file"),
  lock: icon("ic_lock"),
  label: icon("ic_label"),
  blank: icon("ic_blank"),
  kanban: icon("ic_kanban"),
  folder: icon("ic_folder"),
  banking: icon("ic_banking"),
  booking: icon("ic_booking"),
  invoice: icon("ic_invoice"),
  calendar: icon("ic_calendar"),
  disabled: icon("ic_disabled"),
  external: icon("ic_external"),
  menuItem: icon("ic_menu_item"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "dashboard.root",
    items: [
      {
        title: "dashboard.root",
        permissionProp: sliceNames.stat,
        path: PATH_DASHBOARD.app,
        icon: ICONS.dashboard,
      },
    ],
  },
  {
    subheader: "app.accounts",
    items: [
      {
        title: "administrator.root_plural",
        path: PATH_DASHBOARD.administrators.root,
        icon: ICONS.user,
      },
      {
        title: "customer.root_plural",
        path: PATH_DASHBOARD.customers.root,
        icon: ICONS.booking,
      },
      {
        title: "professional.root_plural",
        path: PATH_DASHBOARD.hairdressingSalons.root,
        icon: ICONS.ecommerce,
      },
    ],
  },
  {
    subheader: "app.messages",
    items: [
      {
        title: "chat.root_plural",
        path: PATH_DASHBOARD.chat.root,
        icon: ICONS.chat,
      },
    ],
  },
  {
    subheader: "app.sales",
    items: [
      {
        title: "product.root_plural",
        path: PATH_DASHBOARD.products.root,
        icon: ICONS.cart,
      },
      {
        title: "order.root_plural",
        path: PATH_DASHBOARD.orders.root,
        icon: ICONS.invoice,
      },
    ],
  },
  {
    subheader: "app.content",
    items: [
      {
        title: "article.root_plural",
        path: PATH_DASHBOARD.articles.root,
        icon: ICONS.blog,
      },
      {
        title: "articleCategory.root_plural",
        path: PATH_DASHBOARD.articleCatigories.root,
        icon: ICONS.folder,
      },
      {
        title: "blogCategory.root_plural",
        path: PATH_DASHBOARD.blogCategories.root,
        icon: ICONS.folder,
      },
      {
        title: "blog.root_plural",
        path: PATH_DASHBOARD.blogs.root,
        icon: ICONS.blog,
      },
    ],
  },
  {
    subheader: "app.media",
    items: [
      {
        title: "video.root_plural",
        path: PATH_DASHBOARD.videos.root,
        icon: ICONS.file,
      },
    ],
  },
  {
    subheader: "chatBot.chat_bot",
    items: [
      {
        title: "chatBot.root_plural",
        path: PATH_DASHBOARD.chatBots.root,
        icon: ICONS.chat,
      },
    ],
  },
];

export default navConfig;
