const blog = {
  root: "blog",
  root_plural: "blogs",
  create: "Créer un blog",
  publish: "Publier",
  enable_comments: "Activer les commentaires",
  preview: "Aperçu",
  post: "Poster",
  update: "Modifier un blog",
  delete_model_confirm_vowel:
  "Voulez-vous vraiment supprimer ce {model}?",
  read_duration: "Durée de lecture",
  status_change_success: "Statut du blog changé avec succès",
  info_on_article_crud:
    "N'oubliez pas d'écrire le blog dans les langues **Français** et **Anglais**.",
};

export default blog;
