import {
  IHairdressingSalonCreate,
  IHairdressingSalonRange,
  IHairdressingSalonUpdate,
} from "@/app/types";
import BaseService from "./BaseService";
import { HairdressingSalonUrls } from "./urls";

class HairdressingSalonService {
  static get = (public_id: string) => {
    return BaseService.getRequest(HairdressingSalonUrls.GET(public_id), true);
  };
  static paginate = (query: IHairdressingSalonRange): Promise<any> => {
    return BaseService.getRequest(HairdressingSalonUrls.PAGINATE(query), true);
  };
  static create = (data: IHairdressingSalonCreate) => {
    return BaseService.postRequest(HairdressingSalonUrls.CREATE, data, true);
  };
  static update = ({ uuid, ...rest }: IHairdressingSalonUpdate) => {
    return BaseService.putRequest(
      HairdressingSalonUrls.UPDATE(uuid),
      rest,
      true
    );
  };
  static delete = (public_id: string) => {
    return BaseService.deleteRequest(
      HairdressingSalonUrls.DELETE(public_id),
      {},
      true
    );
  };
}

export default HairdressingSalonService;
