import { Intent } from "@/app/types/IntentTypes";
import Iconify from "@/core/components/iconify/Iconify";
import MenuPopover from "@/core/components/menu-popover/MenuPopover";
import useTranslation from "@/core/hooks/useTranslation";
import AppModal from "@/presentation/components/AppModal";
import { IconButton, MenuItem } from "@mui/material";
import React from "react";
import { Eye } from "react-feather";
import AlertDialogDeleteIntent from "./DeleteIntent";
import EditOrder from "./editOrder";
import { OrderDetailsView } from "./OrderDetailView";
interface OrderViewProps {
  info: Intent;
  lang: string;
}

export function OrderView(props: OrderViewProps) {
  const { info } = props;
  const t = useTranslation();

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const [openPopover, setOpenPopover] = React.useState(null);

  const handleOpenPopover = (event: any) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };
  const handleClose = () => {
    setOpen(false);
    handleClosePopover();
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);

  return (
    <>
      <IconButton
        color={openPopover ? "inherit" : "default"}
        onClick={handleOpenPopover}
      >
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>

      {/* @ts-ignore */}
      <MenuPopover
        open={openPopover}
        onClose={() => {
          handleClosePopover();
        }}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            handleClickOpen();
          }}
        >
          <Eye size={13} />
          {t("app.details")}
        </MenuItem>
        <EditOrder
          lang={props.lang}
          intent={info}
          handleClosePopover={handleClosePopover}
        />
        <AlertDialogDeleteIntent
          lang={props.lang}
          info={info}
          handleClosePopover={handleClosePopover}
        />
      </MenuPopover>
      {open && (
        <AppModal
          onClose={() => handleClose()}
          title={t("chatBot.question")}
          breadCrumbLinks={[]}
        >
          <OrderDetailsView
            handleClose={handleClose}
            info={info}
          />
        </AppModal>
      )}
    </>
  );
}
