const title = {
  title: "Entrez le titre",
  status_admin: "Activer/Désactiver",
  search: "Recherche...",
  first_name: "Entrez le prénom",
  last_name: "Entrez le nom",
  email: "Entrez le adresse email",
  phone_number: "Entrez le  numéro de téléphone",
  role: "Sélectionnez le role",
  job: "Entrez le travail",
  bio: "Entrez la Bio",
  tags: "Entrez les tags",
  image: "Sélectionnez une image",
  category: "Catégorie",
  name: "Entrez le nom",
  name_en: "Entrez le nom en anglais",
  name_fr: "Entrez le nom en français",
  brand: "Entrez la marque",
  images: "Sélectionnez les images",
  description: "Entrez la description",
  description_en: "Entrez la description en anglais",
  sku: "Entrez le SKU",
  real_price: "Entrez le prix réel",
  current_price: "Entrez le prix actuel",
  qty: "Entrez la quantité",
  hair_style: "Sélectionnez le style de cheveux",
  benefits: "Sélectionnez les bénéfices",
  weight: "Entrez le poids",
  is_online: "Rendre le produit visible en ligne ou non",
  sub_category: "Sélectionnez une sous-catégorie",
  video_link: "Entrez le lien de la vidéo",
  image_link: "Entrez le lien de l'image",
  prestation: "Sélectionnez la prestation",
  prestations: "Sélectionnez les prestations",
  begin_duration: "Choisissez le début de la video",
  content: "Écrivez le contenu",
  video: "Sélectionnez une vidéo",
  video_duration: "Entrez la durée de la vidéo",
  hairdressing_salon: "Sélectionnez un salon",
  hairdresser_salon_name: "Entrez le nom du salon",
  country_code: "Entrez le code du pays",
  entreprise_name: "Entrez le nom de l'entreprise",
  entreprise_phonenumber: "Entrez le numéro de téléphone de l'entreprise",
  website: "Entrez le site web",
  hair_types: "Sélectionnez les types de cheuveux",
  people_type_ids: "Sélectionnez les types de personne",
  shop_onligne: "La boutique est-elle visible en ligne?",
  headdress_at_home: "La coiffure à domicile est-elle acceptée?",
  postal_code: "Entrez le code postal",
  province: "Entrez la région",
  town: "Entrez la ville",
  address_one: "Entrez l'adresse principale",
  address_two: "Entrez l'adresse secondaire",
  accept_click_n_collect: "Le click N Collect est-il accepté?",
  manager_hairdressing_email: "Entrez l'adresse email du gérant du salon",
  password: "Entrez le mot de passe",
  change_status: "Changer le status",
  recent_blogs: "Marquer comme Article récent",
  in_the_news: "Marquer comme Article À la une",
  title_fr: "Entrez le titre en français",
  title_en: "Entrez le title en anglais",
  description_fr: "Entrez la description en français",
  product_hot_point: "Entrez les points saillants",
  special_price: "Entrez le prix spécial",
  stock: "Entrez le stock",
  meta_title: "Entrez le titre de la Meta",
  meta_description: "Entrez la description de la Meta",
  sold: "Vendu",
  price: "Entrez le prix",
  hair_type: "Sélectionnez le type de cheveux",
  french: "Français",
  english: "Anglais",
  is_kid_video: "Est-ce un dessin animé ?",
  is_kid: "Est-ce une vidéo pour enfant ?",
  fonction_auteur: "Fonction de l'auteur",
  prenom_auteur: "Prénom de l'auteur",
  nom_auteur: "Nom de l'auteur",
  avatar_auteur: "Photo de profil de l'auteur",
};

export default title;
