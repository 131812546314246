import Iconify from "@/core/components/iconify";
import useTranslation from "@/core/hooks/useTranslation";
import AppModal from "@/presentation/components/AppModal";
import AutoForm, { IAutoFormFields } from "@/presentation/components/AutoForm";
import { slice as autoFormslice } from "@/redux/slices/autoForm";
import { useDispatch } from "@/redux/store";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import React from "react";

export interface ICreateEditModalProps {
  fields: IAutoFormFields;
  onClose: () => void;
  onSave: (values: any) => void;
  model: string;
  rowToEdit: any;
  female?: boolean;
  stateData?: any;
  idProp?: string;
  slice?: any;
  open: boolean;
}

const CreateEditModal: React.FC<ICreateEditModalProps> = ({
  fields,
  onClose,
  model,
  onSave,
  rowToEdit,
  female,
  stateData,
  idProp,
  slice,
  open,
}) => {
  // Refs
  const formRef = React.useRef<HTMLButtonElement>(null);

  // Hooks
  const t = useTranslation();
  const dispatch = useDispatch();

  // Functions
  const submitForm = (values: any) =>
    onSave({
      ...values,
      ...(rowToEdit && idProp ? { [idProp]: rowToEdit[idProp] } : {}),
    });

  React.useEffect(
    () => () => {
      // @ts-ignore
      dispatch(autoFormslice.actions.setValuesToHold(null));
    },
    [open]
  );

  return (
    <AppModal
      open={open}
      onClose={() => onClose()}
      title={
        rowToEdit
          ? t(`app.update_model_title${female ? "_female" : ""}`, {
              model: `${model}.root`,
            })
          : t(`app.create_model_title${female ? "_female" : ""}`, {
              model: `${model}.root`,
            })
      }
      breadCrumbLinks={[
        {
          name: rowToEdit
            ? t(`app.update_model_description${female ? "_female" : ""}`, {
                model: `${model}.root`,
              })
            : t(`app.create_model_description${female ? "_female" : ""}`, {
                model: `${model}.root`,
              }),
        },
      ]}
      actions={[
        // @ts-ignore
        <LoadingButton
          key={`${model}-save`}
          loading={stateData?.isLoading}
          // disabled={stateData?.isLoading}
          variant="contained"
          color="primary"
          onClick={() => formRef.current?.click()}
          startIcon={
            rowToEdit ? (
              <Iconify icon="eva:checkmark-fill" />
            ) : (
              <Iconify icon="eva:plus-fill" />
            )
          }
        >
          {t(`app.${rowToEdit ? "update" : "create"}`)}
        </LoadingButton>,
        <Button
          key={`${model}-cancel`}
          disabled={stateData?.isLoading}
          variant="contained"
          color="error"
          onClick={onClose}
          startIcon={<Iconify icon="eva:close-fill" />}
        >
          {t("app.cancel")}
        </Button>,
      ]}
    >
      <AutoForm
        initialItem={rowToEdit}
        setLoading={
          slice ? () => dispatch(slice.actions.startLoading()) : undefined
        }
        ref={formRef}
        fields={fields}
        onSubmit={submitForm}
        hideButton
      />
    </AppModal>
  );
};

export default CreateEditModal;
