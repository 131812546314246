import PropTypes from "prop-types";
// @mui
import {
  Box,
  Card,
  CardHeader,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
// utils
import { fCurrency, fShortenNumber } from "@/core/utils/formatNumber";
// components
import EmptyContent from "@/core/components/empty-content/EmptyContent";
import Iconify from "@/core/components/iconify";
import Label from "@/core/components/label";
import Scrollbar from "@/core/components/scrollbar";
import useTranslation from "@/core/hooks/useTranslation";

// ----------------------------------------------------------------------

AppTopRelated.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function AppTopRelated({ title, subheader, list, ...other }) {
  const t = useTranslation();
  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
      />

      <Scrollbar sx={{ maxHeight: 404 }}>
        <Stack
          spacing={3}
          sx={{ p: 3, pr: 0 }}
        >
          {list.length === 0 && (
            <EmptyContent
              title={t("app.no_data")}
              sx={{
                "& span.MuiBox-root": { height: 160 },
              }}
            />
          )}
          {list.map((app) => (
            <ApplicationItem
              key={app.id}
              app={app}
            />
          ))}
        </Stack>
      </Scrollbar>
    </Card>
  );
}

// ----------------------------------------------------------------------

ApplicationItem.propTypes = {
  app: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.number,
    rating: PropTypes.number,
    review: PropTypes.number,
    system: PropTypes.string,
    shortcut: PropTypes.string,
  }),
};

function ApplicationItem({ app }) {
  const { shortcut, system, price, rating, review, name } = app;

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
    >
      <Box
        sx={{
          width: 48,
          height: 48,
          flexShrink: 0,
          display: "flex",
          borderRadius: 1.5,
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "background.neutral",
        }}
      >
        <Box
          component="img"
          src={shortcut}
          sx={{ width: 24, height: 24 }}
        />
      </Box>

      <Box sx={{ flexGrow: 1, minWidth: 160 }}>
        <Typography variant="subtitle2">{name}</Typography>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ mt: 0.5, color: "text.secondary" }}
        >
          {system && (
            <Iconify
              width={16}
              icon={
                system === "Mac"
                  ? "ant-design:apple-filled"
                  : "ant-design:windows-filled"
              }
            />
          )}

          {system && (
            <Typography
              variant="caption"
              sx={{ ml: 0.5, mr: 1 }}
            >
              {system}
            </Typography>
          )}

          <Label
            variant="soft"
            color={price === 0 ? "success" : "error"}
          >
            {price === 0 ? "Free" : fCurrency(price)}
          </Label>
        </Stack>
      </Box>

      <Stack
        alignItems="flex-end"
        sx={{ pr: 3 }}
      >
        {rating && (
          <Rating
            readOnly
            size="small"
            precision={0.5}
            name="reviews"
            value={rating}
          />
        )}
        {review && (
          <Typography
            variant="caption"
            sx={{ mt: 0.5, color: "text.secondary" }}
          >
            {fShortenNumber(review)} reviews
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
