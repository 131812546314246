import { IBlogCategory } from "@/app/types";
import { ITableRowColumn } from "@/core/components/table/AppTableRow";

const blogCategoryColumns: ITableRowColumn<IBlogCategory>[] = [
  {
    header: { id: "title_fr", label: "label.name_fr", align: "left" },
    type: "avatar",
    title: (row) => row.title_fr,
    avatarUrl: (row) => row?.title_fr ?? "",
  },
  {
    header: {
      id: "title_en",
      label: "label.name_en",
      align: "left",
    },
    title: (row) => row?.title_en ?? "",
    align: "left",
  },
  {
    header: {
      id: "position",
      label: "label.position",
      align: "center",
    },
    title: (row) => row?.position ?? "",
    align: "center",
  },
];

export default blogCategoryColumns;
