const article = {
  root: "article",
  root_plural: "articles",
  create: "Créer un article",
  publish: "Publier",
  enable_comments: "Activer les commentaires",
  preview: "Aperçu",
  post: "Poster",
  update: "Modifier un article",
  status_change_success: "Status de l'article changé avec succès",
  info_on_article_crud:
    "N'oubliez d'écrire l'article dans les langues **Français** et **Anglais**.",
};

export default article;
