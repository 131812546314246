import OrderService from "@/app/services/api/OrderService";
import { IOrderCreate, IOrderRange, IOrderUpdate } from "@/app/types";
import { IReduxBaseCrudEffect } from "@/app/types/BaseReduxTypes";
import BaseReduxCrudEffects from "@/redux/base/crud-slice-helpers/effects";
import { slice } from ".";

export interface IOrderEffects
  extends IReduxBaseCrudEffect<
    IOrderRange,
    IOrderCreate,
    IOrderUpdate,
    OrderService
  > {}

class OrderEffects
  extends BaseReduxCrudEffects<IOrderRange, IOrderCreate, IOrderUpdate>
  implements IOrderEffects {}

const orderEffects = new OrderEffects(slice, OrderService);

export default orderEffects;
