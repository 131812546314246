import {
  IconButton,
  InputAdornment,
  StandardTextFieldProps,
  SvgIconProps,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { useState } from "react";
import { Eye, EyeOff, Trash } from "react-feather";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 6,
    marginBottom: 16,
    borderRadius: 5,
  },
  textFieldSuffixContainer: {
    display: "flex",
    alignItems: "stretch",
  },
  textFieldSuffix: {
    flexGrow: 1,
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px 0 0 5px",
    },
  },
  textFieldPrefix: {
    flexGrow: 1,
    "& .MuiOutlinedInput-root": {
      borderRadius: "0 5px 5px 0",
    },
  },
  overflowLabel: {
    backgroundColor: "#DDDDDD",
    display: "flex",
    height: 45,
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.grey["700"],
    padding: theme.spacing(0, 1.5),
  },
  suffix: {
    borderRadius: "0 5px 5px 0",
  },
  prefix: {
    borderRadius: "5px 0 0 5px",
  },
  container: {
    position: "relative",
  },
  overflow: {
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 100,
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
}));

export interface InputTextProps extends StandardTextFieldProps {
  label?: string;
  labelPrefix?: string;
  passwordIsVisible?: boolean;
  disabled?: boolean;
  required?: boolean;
  labelSuffix?: string;
  type?: string;
  value?: any;
  clearContent?: () => void;
  startIcon?: React.ReactElement<SvgIconProps>;
  endIcon?: React.ReactElement<SvgIconProps>;
  onInputOverflowClick?: () => void;
}

const InputText = (props: InputTextProps) => {
  const {
    label,
    labelPrefix,
    labelSuffix,
    onInputOverflowClick,
    startIcon,
    endIcon,
    clearContent,
    ...rest
  } = props;
  const styles = useStyles(props);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const opacity = label === " " ? 0 : 1;
  const rootOpacity = props.disabled ? 0.5 : 1;
  const [passwordIsVisible, setPasswordIsVisible] = useState(
    props.passwordIsVisible || false
  );
  return (
    <div
      style={{
        opacity: rootOpacity,
      }}
    >
      {label && (
        <Typography
          color="gray"
          variant="subtitle2"
        >
          {label}
          {label === " " && !mobile && "-"}
          {rest.required ? "*" : ""}
        </Typography>
      )}
      <div
        className={clsx(
          styles.container,
          (!!labelSuffix || !!labelPrefix) && styles.textFieldSuffixContainer
        )}
      >
        {onInputOverflowClick && (
          <div
            onClick={onInputOverflowClick}
            className={styles.overflow}
          />
        )}
        {!!labelPrefix && (
          <Typography
            variant={"h5"}
            className={clsx(styles.prefix, styles.overflowLabel, styles.root)}
          >
            {labelPrefix}
          </Typography>
        )}
        <TextField
          classes={{
            root: clsx(
              styles.root,
              !!labelSuffix && styles.textFieldSuffix,
              !!labelPrefix && styles.textFieldPrefix
            ),
          }}
          {...rest}
          sx={{ ...(rest.sx || {}), m: 0 }}
          type={
            props.type !== "password"
              ? props.type
              : passwordIsVisible
              ? "text"
              : "password"
          }
          InputProps={{
            endAdornment:
              !!clearContent && rest.value ? (
                <IconButton
                  style={{
                    padding: 0,
                  }}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    clearContent();
                  }}
                >
                  <Trash />
                </IconButton>
              ) : props.type === "password" ? (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setPasswordIsVisible(!passwordIsVisible);
                    }}
                  >
                    {passwordIsVisible ? <EyeOff /> : <Eye />}
                  </IconButton>
                </InputAdornment>
              ) : endIcon ? (
                <InputAdornment position="end">{endIcon}</InputAdornment>
              ) : undefined,
            startAdornment: startIcon ? (
              <InputAdornment position="start">{startIcon}</InputAdornment>
            ) : undefined,
          }}
          variant={"outlined"}
        />
        {!!labelSuffix && (
          <Typography
            variant={"h5"}
            className={clsx(styles.suffix, styles.overflowLabel, styles.root)}
          >
            {labelSuffix}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default InputText;
