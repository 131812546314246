import React, { useState } from "react";
// @mui
import {
  Box,
  Button,
  Card,
  Divider,
  Skeleton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableContainer,
  Tabs,
} from "@mui/material";
// routes
// _mock_
// components
import ConfirmDialog from "@/core/components/confirm-dialog";
import Scrollbar from "@/core/components/scrollbar";
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  TableSkeleton,
  useTable,
} from "@/core/components/table";
// sections
import { useAuthContext } from "@/app/auth/useAuthContext";
import { APP_PERMISSIONS, DEFAULT_RANGE } from "@/app/config-global";
import { PATH_DASHBOARD } from "@/app/routes/paths";
import { IReduxBaseCrudEffect } from "@/app/types/BaseReduxTypes";
import Page from "@/core/components/page";
import AppTableRow, {
  ICustomAppRowAction,
  ITableRowColumn,
} from "@/core/components/table/AppTableRow";
import useToast from "@/core/hooks/useToast";
import useToastError from "@/core/hooks/useToastError";
import useTranslation from "@/core/hooks/useTranslation";
import toSentence from "@/core/utils/toSentence";
import { store } from "@/redux/store";
import { useNavigate } from "react-router-dom";
import { IAutoFormFields } from "../AutoForm";
import DataFilterer, { IDataFiltererOption } from "../DataFilterer";
import CreateEditModal from "./components/CreateEditModal";

// ----------------------------------------------------------------------

export interface ICustomBreadcrumbsLink {
  name: string;
  href?: string;
}

export interface ITablePageOptionListItem {
  label: string;
  value: string | number;
}

export interface ITablePageOptionFunction {
  label: string;
  value: (_value: string) => string | number;
}

export type IToolbarOption = IDataFiltererOption;

export interface ITablePageProps {
  showDetailsUrl?: (_data: any) => string;
  onShowDetails?: (_data: any) => void;
  afterBreadCrumbComponent?: any;
  model: string;
  stateData: any;
  breadcrumbLinks?: null | ICustomBreadcrumbsLink[];
  tableTopOptions: ITablePageOptionListItem[];
  columns: ITableRowColumn[];
  columnsBaseFunctions?: {
    onEdit: (_values: any, _callback: (_data: any) => void) => void;
    onCreate: (_values: any, _callback: (_data: any) => void) => void;
    onDelete: (_rowData: any, _callback: (_data: any) => void) => void;
    onDeleteSelected?: (
      _rowData: any[],
      _callback: (_data: any) => void
    ) => void;
  };
  columnsCustomFunctions?: {
    label: string;
    icon?: any;
    function: (_rowData: any, _rowIndex: number) => void;
  }[];
  toolbarOptions: IDataFiltererOption[];
  fields: IAutoFormFields;
  idProperty: string;
  isNotFound: (_data: any[]) => boolean;
  isFiltered: (_data: any[]) => boolean;
  toolbarOptionChange: (_value: string) => void;
  currentToolbarOption: string | number;
  onReset: () => void;
  onChangePage: (_value: number) => void;
  onChangeRowsPerPage: (_value: number) => void;
  handleFilterOrder: (_value: string) => void;
  handleFilterOrderField: (_value: string) => void;
  female?: boolean;
  noCrud?: boolean;
  getData: () => any;
  effects: IReduxBaseCrudEffect<any, any, any, any>;
  slice?: any;
  orderField?: string;
  order?: string;
  noPage?: boolean;
  noCreate?: boolean;
  noUpdate?: boolean;
  noDelete?: boolean;
  noDeleteBulk?: boolean;
  noAddButton?: boolean;
  noCheckBoxes?: boolean;
  customRowActions?: ICustomAppRowAction[];
}

const TablePage: React.FC<ITablePageProps> = ({
  model,
  stateData,
  breadcrumbLinks,
  tableTopOptions,
  toolbarOptions,
  columns,
  columnsCustomFunctions,
  idProperty,
  fields,
  toolbarOptionChange,
  currentToolbarOption,
  onReset,
  isNotFound: isNotFoundFn,
  isFiltered: isFilteredFn,
  onChangePage,
  onChangeRowsPerPage,
  handleFilterOrder,
  handleFilterOrderField,
  female,
  noCrud,
  getData,
  effects,
  columnsBaseFunctions: customCrudFunctions,
  slice,
  order,
  orderField,
  afterBreadCrumbComponent,
  onShowDetails,
  showDetailsUrl,
  noPage,
  noCreate,
  noUpdate,
  noDeleteBulk,
  noDelete,
  noAddButton,
  noCheckBoxes,
  customRowActions,
}) => {
  // Permissions
  const { user } = useAuthContext();
  const permissions = APP_PERMISSIONS[model];
  const canViewDetails = !!permissions?.read?.includes(user?.role?.id);
  const canEdit =
    !noCrud && !noUpdate && !!permissions?.update?.includes(user?.role?.id);
  const canCreate =
    !noCrud &&
    !noAddButton &&
    !noCreate &&
    !!permissions?.create?.includes(user?.role?.id);
  const canDelete =
    !noCrud && !noDelete && !!permissions?.delete?.includes(user?.role?.id);
  const canDeleteBulk =
    !noCrud && !noDeleteBulk && !!permissions?.delete?.includes(user?.role?.id);
  const canPerformAnAction =
    canViewDetails || canCreate || canEdit || canDelete;

  // Hooks
  const {
    dense,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    // onSort,
    onChangeDense,
  } = useTable({
    defaultCurrentPage: stateData?.page ?? DEFAULT_RANGE.page,
    defaultRowsPerPage: stateData?.per_page ?? DEFAULT_RANGE.per_page,
    defaultOrder: stateData?.order ?? DEFAULT_RANGE.order,
    defaultOrderBy: stateData?.order_field ?? DEFAULT_RANGE.order_field,
    selectProperty: idProperty,
    onChangePage,
    onChangeRowsPerPage,
    onSort: (oder_field: string, order: string) => {
      handleFilterOrder(order);
      handleFilterOrderField(oder_field);
    },
  });
  const t = useTranslation();
  const toast = useToast();
  const dispatch = store.dispatch;
  const navigate = useNavigate();
  useToastError(stateData?.error);

  // State
  const [openConfirm, setOpenConfirm] = useState(false);
  const [rowToEdit, setRowToEdit] = useState<any>(null);
  const [rowToDelete, setRowToDelete] = useState<any>(null);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  // Memo
  const memoFields = React.useMemo(() => fields, []);

  // Data
  const baseCrudFunctions = {
    onCreate: !canCreate
      ? null
      : (values: any, callback: any) => {
          dispatch(
            effects.create(values, () => {
              callback && callback();
              toast.success(
                toSentence(
                  t(`models.create_success${female ? "_female" : ""}`, {
                    model: `${model}.root`,
                  })
                )
              );
              getData();
            })
          );
        },
    onEdit: !canEdit
      ? null
      : (values: any, callback: any) => {
          dispatch(
            effects.update(values, () => {
              callback && callback();
              toast.success(
                toSentence(
                  t(`models.update_success${female ? "_female" : ""}`, {
                    model: `${model}.root`,
                  })
                )
              );
              getData();
            })
          );
        },
    onDelete: !canDelete
      ? null
      : (row: any, callback: any) => {
          dispatch(
            effects.delete(row?.[idProperty] ?? "", () => {
              callback && callback();
              toast.success(
                toSentence(
                  t(`models.delete_success${female ? "_female" : ""}`, {
                    model: `${model}.root`,
                  })
                )
              );
              getData();
            })
          );
        },
    onDeleteSelected: !canDeleteBulk
      ? null
      : (row: any, callback: any) => {
          dispatch(
            effects.delete(row?.public_id ?? "", () => {
              callback && callback();
              toast.success(
                toSentence(
                  t(`models.delete_bulk_success${female ? "_female" : ""}`, {
                    model: `${model}.root${
                      selected.length > 1 ? "_plural" : ""
                    }`,
                  })
                )
              );
              getData();
            })
          );
        },
  };
  const columnsBaseFunctions = {
    ...baseCrudFunctions,
    ...(customCrudFunctions ?? {}),
  };
  const tableData = stateData?.list?.data ?? [];
  const TABLE_HEAD = [
    ...columns.map((col) => col.header),
    ...((columnsBaseFunctions || columnsCustomFunctions) && canPerformAnAction
      ? [{ id: "tablePage__actions" }]
      : []),
  ];
  const isFiltered = isFilteredFn(tableData);
  const isNotFound = isNotFoundFn(tableData);
  const defaultBreadcrumbLinks = [
    { name: "dashboard.root", href: PATH_DASHBOARD.root },
    { name: `${model}.root_plural` },
    { name: "app.list" },
  ];
  const isModelStartsWithVowel = ["a", "e", "o", "i", "u", "y"].includes(
    model.charAt(0).toLowerCase()
  );

  // Functions
  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleDeleteRows = (selectedRows: any[]) => {
    const deleteRows = tableData.filter(
      (row: any) => !selectedRows.includes(row.id)
    );
    setSelected([]);
  };

  const closeCreateEditModal = () => {
    setShowCreateModal(false);
    setRowToEdit(null);
  };

  const content = (
    <>
      {afterBreadCrumbComponent}

      <Card>
        {tableTopOptions && (
          <Tabs
            value={currentToolbarOption}
            onChange={(_, value) => toolbarOptionChange(value)}
            sx={{
              px: 2,
              bgcolor: "background.neutral",
            }}
          >
            {tableTopOptions.map((tab) => (
              <Tab
                key={tab.value}
                label={t(tab.label)}
                value={tab.value}
              />
            ))}
          </Tabs>
        )}

        {tableTopOptions && <Divider />}

        {toolbarOptions && (
          <Box sx={{ px: 3, py: 2.5 }}>
            <DataFilterer
              options={toolbarOptions}
              isFiltered={isFiltered}
              onReset={onReset}
            />
          </Box>
        )}

        <TableContainer sx={{ position: "relative", overflow: "unset" }}>
          {!noCrud && (
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={tableData.length}
              female={female}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData.map((row: any) => row)
                )
              }
              action={
                null
                // <Tooltip title="Delete">
                //   <IconButton
                //     color="primary"
                //     onClick={handleOpenConfirm}
                //   >
                //     <Iconify icon="eva:trash-2-outline" />
                //   </IconButton>
                // </Tooltip>
              }
            />
          )}

          <Scrollbar>
            <Table
              size={dense ? "small" : "medium"}
              sx={{ minWidth: 800 }}
            >
              <TableHeadCustom
                order={order}
                orderBy={orderField}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={selected.length}
                onSort={(cellId: string, order: string) => {
                  handleFilterOrderField(cellId);
                  handleFilterOrder(order);
                }}
                onSelectAllRows={
                  noCheckBoxes
                    ? undefined
                    : (checked) => onSelectAllRows(checked, tableData)
                }
              />

              <TableBody>
                {stateData?.isLoading
                  ? Array(DEFAULT_RANGE.per_page)
                      .fill("")
                      .map((_, index) => (
                        <TableSkeleton
                          hasCheckboxes={!noCheckBoxes}
                          id={`row-${index}`}
                          cols={columns.length}
                          key={`loading-table-${index}`}
                        />
                      ))
                  : stateData?.list?.data?.map((row: any, index: number) => (
                      <AppTableRow
                        key={`app-table-page-row-${index}${row[idProperty]}`}
                        rowIndex={index}
                        allData={stateData?.list?.data ?? []}
                        data={row}
                        columns={columns}
                        onShowDetails={
                          canViewDetails && (onShowDetails || showDetailsUrl)
                            ? (data) => {
                                onShowDetails && onShowDetails(data);
                                showDetailsUrl &&
                                  navigate(showDetailsUrl(data));
                              }
                            : undefined
                        }
                        onEditRow={
                          !columnsBaseFunctions.onEdit
                            ? undefined
                            : () => setRowToEdit(row)
                        }
                        onDeleteRow={
                          !columnsBaseFunctions.onDelete
                            ? undefined
                            : () => setRowToDelete(row)
                        }
                        onSelectRow={noCheckBoxes ? undefined : onSelectRow}
                        noActions={!canPerformAnAction}
                        selected={
                          selected
                            .map((row: any) => row[idProperty])
                            .indexOf(row[idProperty]) !== -1
                        }
                        customActions={customRowActions}
                      />
                    ))}

                {/* <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                    /> */}

                <TableNoData
                  isNotFound={isNotFound}
                  colSpan={12}
                />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        {stateData.isLoading ? (
          <Stack
            direction={"row"}
            gap={2}
            justifyContent="space-between"
            sx={{ p: 2 }}
          >
            <Skeleton
              variant="text"
              width={70}
              height={20}
            />
            <Stack
              direction={"row"}
              gap={2}
            >
              <Skeleton
                variant="text"
                width={200}
                height={20}
              />
              <Skeleton
                variant="text"
                width={70}
                height={20}
              />
            </Stack>
          </Stack>
        ) : (
          <TablePaginationCustom
            count={stateData?.list?.total ?? 0}
            page={(stateData?.list?.current_page ?? DEFAULT_RANGE.page) - 1}
            rowsPerPage={stateData?.list?.per_page ?? DEFAULT_RANGE.per_page}
            onPageChange={(_: any, value: number) => onChangePage(value + 1)}
            onRowsPerPageChange={(_: any, { props: { value } }: any) => {
              onChangePage(DEFAULT_RANGE.page);
              onChangeRowsPerPage(value);
            }}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        )}
      </Card>

      {/* Delete multiples items */}
      {columnsBaseFunctions.onDeleteSelected && (
        <ConfirmDialog
          open={openConfirm}
          onClose={handleCloseConfirm}
          title={t("app.delete")}
          content={t(
            `app.delete_model_confirm${isModelStartsWithVowel ? "_vowel" : ""}${
              selected.length > 1 ? "_plural" : ""
            }`,
            {
              count: selected.length,
              model: `${model}.root${selected.length > 1 ? "_plural" : ""}`,
            }
          )}
          action={
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDeleteRows(selected)}
            >
              {t("app.delete")}
            </Button>
          }
        />
      )}

      {/* Create Update modal */}
      <CreateEditModal
        open={
          !!(!rowToEdit && columnsBaseFunctions.onCreate && showCreateModal) ||
          !!(rowToEdit && columnsBaseFunctions.onEdit)
        }
        fields={memoFields}
        onClose={closeCreateEditModal}
        onSave={(values) =>
          columnsBaseFunctions?.[rowToEdit ? "onEdit" : "onCreate"] &&
          columnsBaseFunctions[rowToEdit ? "onEdit" : "onCreate"]!(
            values,
            closeCreateEditModal
          )
        }
        model={model}
        rowToEdit={rowToEdit}
        stateData={stateData}
        female={female}
        idProp={idProperty}
        slice={slice}
      />

      {/* Delete modal */}
      {columnsBaseFunctions.onDelete && rowToDelete && (
        <ConfirmDialog
          open={true}
          onClose={() => setRowToDelete(null)}
          disabled={stateData?.isLoading}
          title={t("app.delete")}
          content={t(
            `app.delete_model_confirm${isModelStartsWithVowel ? "_vowel" : ""}${
              female ? "_female" : ""
            }`,
            { model: `${model}.root` }
          )}
          onConfirm={() => {
            columnsBaseFunctions?.onDelete &&
              columnsBaseFunctions?.onDelete(rowToDelete, setRowToDelete);
          }}
          isConfirmLoading={stateData?.isLoading}
          confirmLabel={t("app.delete")}
          action={null}
        />
      )}
    </>
  );

  return noPage ? (
    <>{content}</>
  ) : (
    <Page
      title={t(`${model}.root_plural`)}
      breadcrumbs={
        breadcrumbLinks === null
          ? undefined
          : breadcrumbLinks ?? defaultBreadcrumbLinks
      }
      defaultActionButton={
        columnsBaseFunctions.onCreate
          ? {
              icon: "eva:plus-fill",
              title: "app.add",
              onClick: () => setShowCreateModal(true),
            }
          : undefined
      }
      permissions={permissions?.list}
    >
      {content}
    </Page>
  );
};

export default TablePage;
