import { IBlogCreate, IBlogRange, IBlogUpdate } from "@/app/types";
import BaseService from "./BaseService";
import { BlogUrls } from "./urls";

class BlogService {
  static get = (blogId: string) => {
    return BaseService.getRequest(BlogUrls.GET(blogId), true);
  };
  static paginate = (query: IBlogRange) => {
    return BaseService.getRequest(BlogUrls.PAGINATE(query), true);
  };
  static create = (data: IBlogCreate) => {
    return BaseService.postRequest(BlogUrls.CREATE, data, true);
  };
  static update = ({ uuid, ...rest }: IBlogUpdate) => {
    return BaseService.putRequest(BlogUrls.UPDATE(uuid), rest, true);
  };
  static delete = (blogId: string) => {
    return BaseService.deleteRequest(BlogUrls.DELETE(blogId), {}, true);
  };
  static publish = (blogId: string) => {
    return BaseService.putRequest(BlogUrls.PUBLISH(blogId), {}, true);
  };
  static addOrRemoveInTheNews = (blogId: string) => {
    return BaseService.putRequest(BlogUrls.ADD_OR_REMOVE_IN_THE_NEWS(blogId), {}, true);
  };
  static addOrRemoveInRecentArticle = (blogId: string) => {
    return BaseService.putRequest(BlogUrls.ADD_OR_REMOVE_IN_RECENT_ARTICLE(blogId), {}, true);
  };
  static addOrRemoveFavorite = (blogId: string) => {
    return BaseService.postRequest(
      BlogUrls.ADD_OR_REMOVE_FAVORITE(blogId),
      {},
      true
    );
  };

  static listHome = (query: IBlogRange) => {
    return BaseService.getRequest(BlogUrls.LIST_HOME_BLOGS(query), true);
  };
  static listByCategory = (query: IBlogRange) => {
    return BaseService.getRequest(BlogUrls.LIST_BY_CATEGORY(query), true);
  };
  static listFavorites = (query: IBlogRange) => {
    return BaseService.getRequest(BlogUrls.LIST_FAVORITES(query), true);
  };
}

export default BlogService;
