import { ICustomer } from "@/app/types";
import { IBaseCrudReducer } from "@/app/types/BaseReduxTypes";
import BaseCrudReducer from "@/redux/base/crud-slice-helpers/reducers";
import { resetStateKeys } from "@/redux/common/functions";
import { customerInitialState, ICustomerState } from ".";

export interface ICustomerReducer
  extends IBaseCrudReducer<ICustomerState, ICustomer> {
  resetState: (state: ICustomerState) => void;
}

class CustomerReducer
  extends BaseCrudReducer<ICustomerState, ICustomer>
  implements ICustomerReducer
{
  resetState = (state: ICustomerState) =>
    resetStateKeys(state, customerInitialState);
}

const reducers: ICustomerReducer = new CustomerReducer();

export default reducers;
