import { DEFAULT_RANGE } from "@/app/config-global";
import { IApplicationState } from "@/app/types";
import Iconify from "@/core/components/iconify";
import useFilters from "@/core/hooks/useFilters";
import useTranslation from "@/core/hooks/useTranslation";
import getFullName from "@/core/utils/getFullName";
import BookingsPage from "@/presentation/pages/dashboard/BookingsPage";
import OrdersPage from "@/presentation/pages/dashboard/OrdersPage";
import { CalendarForm } from "@/presentation/sections/@dashboard/calendar";
import { AccountBilling } from "@/presentation/sections/@dashboard/user/account";
import bookingEffects from "@/redux/slices/booking/effects";
import orderEffects from "@/redux/slices/order/effects";
import { dispatch, useSelector } from "@/redux/store";
import { Tab, Tabs, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useParams } from "react-router-dom";
import CalendarPage from "../Calendar";

export interface IBookingAndOrderProps {
  order?: boolean;
}

const BookingAndOrders: React.FC<IBookingAndOrderProps> = ({
  order: isOrder,
}) => {
  // Hooks
  const { uuid, public_id } = useParams();

  const { booking } = useSelector((state) => state);

  const events = booking.list?.data.map((booking) => {
    const user = booking?.user?.profiles?.find((p) => !!p?.is_parent);
    return {
      title: uuid
        ? booking?.hairdressing_salon?.name ?? ""
        : getFullName(user?.firstname, user?.lastname, user?.email ?? ""),
      date: booking.date,
      start: booking.date,
      end: booking.date,
      textColor: "green",
    };
  });
  return isOrder ? (
    <OrdersPage
      noPage
      noSalon
      noUser
      breadcrumbs={null}
      additionalFilters={{
        hairdressing_salon_uuid: uuid,
        user_public_id: public_id,
      }}
    />
  ) : (
    <CalendarPage
      loading={booking.isLoading}
      events={events}
      additionalFilters={{
        hairdressing_salon_uuid: uuid,
        user_public_id: public_id,
      }}
    />
  );
};
export default BookingAndOrders;
