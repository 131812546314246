import PropTypes from "prop-types";
import React from "react";
// @mui
import {
  Card,
  CardHeader,
  Collapse,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// components
import { useLocales } from "@/app/locales";
import Iconify from "@/core/components/iconify";
import useTranslation from "@/core/hooks/useTranslation";
import { ChevronDown, ChevronUp } from "react-feather";

// ----------------------------------------------------------------------

const StyledIcon = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------

ProfileAbout.propTypes = {
  company: PropTypes.string,
  country: PropTypes.string,
  email: PropTypes.string,
  emailPrincipal: PropTypes.string,
  quote: PropTypes.string,
  phone: PropTypes.string,
  school: PropTypes.string,
  acceptClickNCollect: PropTypes.bool,
  isOnline: PropTypes.bool,
  hairdressingAtHome: PropTypes.bool,
  formula: PropTypes.string,
  prestations: PropTypes.arrayOf(PropTypes.string),
};

export default function ProfileAbout({
  quote,
  country,
  email,
  emailPrincipal,
  phone,
  company,
  acceptClickNCollect,
  isOnline,
  hairdressingAtHome,
  formula,
  school,
  prestations,
}) {
  const t = useTranslation();
  const { currentLang } = useLocales();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <Card>
      <CardHeader title={t("label.about")} />

      <Stack
        spacing={2}
        sx={{ p: 3 }}
      >
        {/* <Typography variant="body2">{quote}</Typography> */}

        {country && (
          <Stack direction="row">
            <StyledIcon icon="eva:pin-fill" />

            <Typography variant="body2">
              <Link
                component="span"
                variant="subtitle2"
                color="text.primary"
              >
                {country}
              </Link>
            </Typography>
          </Stack>
        )}
        {emailPrincipal && (
          <Stack
            direction="row"
            alignItems={"center"}
          >
            <StyledIcon icon="eva:email-fill" />
            <Stack>
              <Typography variant="body2">Email du professionel</Typography>
              <Typography
                variant="caption"
                sx={{ color: "text.secondary" }}
              >
                {emailPrincipal}
              </Typography>
            </Stack>
          </Stack>
        )}
        {email && (
          <Stack
            direction="row"
            alignItems={"center"}
          >
            <StyledIcon icon="eva:email-fill" />
            <Stack>
              <Typography variant="body2">Email du manager</Typography>
              <Typography
                variant="caption"
                sx={{ color: "text.secondary" }}
              >
                {email}
              </Typography>
            </Stack>
          </Stack>
        )}

        {phone && (
          <Stack direction="row">
            <StyledIcon icon="material-symbols:phone-enabled" />

            <Typography variant="body2">{phone}</Typography>
          </Stack>
        )}

        {formula && (
          <Stack direction="row">
            <StyledIcon icon="icon-park-solid:weixin-cards-offers" />
            <Typography variant="body2">{t(`label.${formula}`)}</Typography>
          </Stack>
        )}
        {[
          {
            label: "accept_click_n_collect",
            checked: acceptClickNCollect,
          },
          {
            label: "headdress_at_home",
            checked: hairdressingAtHome,
          },
          {
            label: "shop_onligne",
            checked: isOnline,
          },
        ].map(({ label, checked }) => (
          <Stack
            direction="row"
            key={`item-${label}`}
          >
            <StyledIcon
              icon={
                checked
                  ? "material-symbols:check-small-rounded"
                  : "material-symbols:close-rounded"
              }
              color={checked ? "success.main" : "error.main"}
            />
            <Typography variant="body2">{t(`label.${label}`)}</Typography>
          </Stack>
        ))}

        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton onClick={handleClick}>
            {/*<ListItemIcon>
          <InboxIcon />
        </ListItemIcon> */}
            <ListItemText primary={t("label.prestations")} />
            {open ? <ChevronUp /> : <ChevronDown />}
          </ListItemButton>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <List
              component="div"
              disablePadding
            >
              {(!prestations || prestations?.length === 0) && (
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t("app.no_data")} />
                </ListItemButton>
              )}
              {prestations?.map((p) => (
                <ListItemButton
                  key={p.uuid}
                  sx={{ pl: 4 }}
                >
                  <ListItemText
                    primary={p?.prestation?.[`title_${currentLang.value}`]}
                  />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </List>

        {/* <Stack direction="row">
          <StyledIcon icon="ic:round-business-center" />

          <Typography variant="body2">
            Studied at &nbsp;
            <Link
              component="span"
              variant="subtitle2"
              color="text.primary"
            >
              {school}
            </Link>
          </Typography>
        </Stack> */}
      </Stack>
    </Card>
  );
}
