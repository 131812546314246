import * as Yup from "yup";
// form
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// @mui
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
// components
import FormProvider, { RHFTextField } from "@/core/components/hook-form";

// ----------------------------------------------------------------------

export default function BlogPostCommentForm() {
  const CommentSchema = Yup.object().shape({
    comment: Yup.string().required("Comment is required"),
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
  });

  const defaultValues = {
    comment: "",
    name: "",
    email: "",
  };

  const methods = useForm({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      console.log("DATA", data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack
        spacing={3}
        alignItems="flex-end"
      >
        <RHFTextField
          name="comment"
          placeholder="Write some of your comments..."
          multiline
          rows={3}
        />

        <LoadingButton
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Post comment
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
