import { IBooking } from "@/app/types";
import {
  baseCrudInitialState,
  IBaseCrudState,
} from "@/redux/base/crud-slice-helpers";
import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers, { IBookingReducer } from "./reducers";

export interface IBookingState extends IBaseCrudState<IBooking> {}

export const bookingInitialState: IBookingState = baseCrudInitialState;

export const slice = createSlice<
  IBookingState,
  IBookingReducer,
  typeof sliceNames.booking
>({
  name: sliceNames.booking,
  initialState: bookingInitialState,
  reducers,
});

export default slice.reducer;
