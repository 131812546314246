import { ACCEPTED_FILE_TYPES, DEFAULT_RANGE } from "@/app/config-global";
import HairdressingSalonService from "@/app/services/api/HairdressingSalonService";
import { IGlobalDataState, IHairdressingSalon, IProduct } from "@/app/types";
import buildValidators from "@/core/utils/buildValidators";
import { IAutoFormFields } from "@/presentation/components/AutoForm";
import { Avatar, Box, Stack, Typography } from "@mui/material";

const productFields = (
  t: Function,
  lang: string,
  categories: any[],
  hairStyles: IGlobalDataState["data"]["hair_types"]
): IAutoFormFields<IProduct> => {
  const v = buildValidators(t);
  return {
    name: {
      initialValue: (row) => row?.name,
      label: "label.title_fr",
      placeholder: "placeholder.title_fr",
      title: "title.title_fr",
      props: {},
      validation: v.string,
    },
    name_en: {
      initialValue: (row) => row?.name_en,
      label: "label.title_en",
      placeholder: "placeholder.title_en",
      title: "title.title_en",
      props: {},
      validation: v.string,
    },
    brand: {
      initialValue: (row) => row?.brand,
      label: "label.brand",
      placeholder: "placeholder.brand",
      title: "title.brand",
      props: {},
      validation: v.string,
    },
    image_uuids: {
      type: "uploader-multiple",
      fileType: ACCEPTED_FILE_TYPES.image,
      initialValue: (row) => row?.images?.map((img) => img.uuid),
      initialOptions: (row) => row?.images?.map((img) => img),
      label: "label.images",
      placeholder: "placeholder.images",
      title: "title.images",
      validation: v.arrayOfString,
    },
    description: {
      type: `textarea`,
      initialValue: (row) => row?.description,
      label: "label.description_fr",
      placeholder: "placeholder.description_fr",
      title: "title.description_fr",
      props: {},
      validation: v.string,
    },
    description_en: {
      type: `textarea`,
      initialValue: (row) => row?.description_en,
      label: "label.description_en",
      placeholder: "placeholder.description_en",
      title: "title.description_en",
      props: {},
      validation: v.string,
    },
    sku: {
      initialValue: (row) => row?.sku,
      label: "label.sku",
      placeholder: "placeholder.sku",
      title: "title.sku",
      props: {},
      validation: v.string,
    },
    real_price: {
      initialValue: (row) => row?.real_price,
      label: "label.price",
      placeholder: "placeholder.price",
      title: "title.price",
      props: {},
      validation: v.number,
    },
    current_price: {
      initialValue: (row) => row?.current_price,
      label: "label.special_price",
      placeholder: "placeholder.special_price",
      title: "title.special_price",
      props: {},
      validation: v.number,
    },
    qty: {
      initialValue: (row) => row?.qty,
      label: "label.stock",
      placeholder: "placeholder.stock",
      title: "title.stock",
      props: {},
      validation: v.number,
    },
    haidressing_salon_uuid: {
      type: "autocomplete",
      initialOptions: (row) => row?.hairdressing_salon,
      getOptions: (initialOption) => (initialOption ? [initialOption] : []),
      loadOptions: async (inputValue) => {
        const res = await HairdressingSalonService.paginate({
          ...DEFAULT_RANGE,
          keyword: inputValue,
        });
        const data = await res.json();
        return data.data;
      },
      getOptionLabel: (option: IHairdressingSalon) => option?.name,
      getOptionValue: (option: IHairdressingSalon) => option?.uuid,
      initialValue: (row) => row?.hairdressing_salon?.uuid,
      label: "label.hairdressing_salon",
      placeholder: "placeholder.hairdressing_salon",
      title: "title.hairdressing_salon",
      props: {},
      renderOptions: (
        props,
        opt: IHairdressingSalon,
        { inputValue: _, selected: __ }
      ) => (
        <Box
          sx={{ p: 2 }}
          {...(props || {})}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ overflow: "hidden" }}
          >
            <Avatar
              src={opt?.file?.thumbnail?.url ?? ""}
              alt={opt.name}
            />
            <Typography
              variant="subtitle2"
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
            >
              {opt.name}
            </Typography>
          </Stack>
        </Box>
      ),
      validation: v.string,
    },
    hair_style: {
      type: "autocomplete",
      multiple: true,
      getOptions: () => hairStyles,
      getOptionLabel: (option) => option?.code,
      getOptionValue: (option) => Number(option?.id),
      initialValue: (row) => row?.hair_style?.map((hs) => Number(hs.id)),
      label: "label.hair_types",
      placeholder: "placeholder.hair_types",
      title: "title.hair_types",
      renderOptions: (props, opt, { inputValue: _, selected: __ }) => (
        <Box
          sx={{ p: 2 }}
          {...(props || {})}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ overflow: "hidden" }}
          >
            <Avatar
              src={opt?.url ?? ""}
              alt={opt.code}
            />
            <Typography
              variant="subtitle2"
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
            >
              {opt.code}
            </Typography>
          </Stack>
        </Box>
      ),
      props: {},
      validation: v.arrayOfString,
    },
    // benefits: {
    //   type: "autocomplete",
    //   multiple: true,
    //   freeSolo: true,
    //   getOptions: () => [],
    //   getOptionLabel: (option) => option,
    //   getOptionValue: (option) => option,
    //   initialValue: (row) => row?.benefits,
    //   label: "label.benefits",
    //   placeholder: "placeholder.benefits",
    //   title: "title.benefits",
    //   props: {},
    //   validation: v.arrayOfString,
    // },
    // weight: {
    //   initialValue: (row) => row?.weight,
    //   label: "label.weight",
    //   placeholder: "placeholder.weight",
    //   title: "title.weight",
    //   props: {},
    //   validation: v.number,
    // },
    sub_category_id: {
      initialValue: (row) => row?.sub_category?.id,
      type: "select",
      getOptions: () => categories,
      getOptionLabel: (option) => option?.label,
      getOptionValue: (option) => option?.value,
      label: "label.sub_category",
      placeholder: "placeholder.sub_category",
      title: "title.sub_category",
      props: {},
      validation: v.number,
    },
    tags: {
      type: "autocomplete",
      getOptions: () => [],
      getOptionLabel: (option) => option,
      getOptionValue: (option) => option,
      initialValue: (row) => row?.tags,
      label: "label.product_hot_point",
      placeholder: "placeholder.product_hot_point",
      title: "title.product_hot_point",
      multiple: true,
      freeSolo: true,
      props: {},
      validation: v.arrayOfString,
    },
    is_online: {
      initialValue: (row) => !!row?.is_online,
      switchValues: [true, false],
      type: "switch",
      checkFn: (value) => !!value,
      label: "label.is_online",
      placeholder: "placeholder.is_online",
      title: "title.is_online",
      props: {},
      validation: v.boolean,
    },
    // sold_out: {
    //   initialValue: (row) => !!row?.sold_out,
    //   switchValues: [true, false],
    //   type: "switch",
    //   checkFn: (value) => !!value,
    //   label: "label.sold",
    //   placeholder: "placeholder.sold",
    //   title: "title.sold",
    //   props: {},
    //   validation: v.boolean,
    // },
  };
};

export default productFields;
