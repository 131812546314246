import PropTypes from "prop-types";
// @mui
import { Alert, Box, List } from "@mui/material";
// routes
// hooks
import useResponsive from "@/core/hooks/useResponsive";
// components
import { SkeletonConversationItem } from "@/core/components/skeleton";
//
import { useAuthContext } from "@/app/auth/useAuthContext";
import useTranslation from "@/core/hooks/useTranslation";
import { setActiveConversation } from "@/redux/slices/chat";
import { dispatch } from "@/redux/store";
import { useSelector } from "react-redux";
import ChatNavItem from "./ChatNavItem";

// ----------------------------------------------------------------------

ChatNavList.propTypes = {
  sx: PropTypes.object,
  openNav: PropTypes.bool,
  selected: PropTypes.func,
  onCloseNav: PropTypes.func,
  conversations: PropTypes.object,
};

export default function ChatNavList({
  conversations,
  openNav,
  onCloseNav,
  selected,
  sx,
  ...other
}) {
  const t = useTranslation();
  const { chat } = useSelector((state) => state);
  const { user } = useAuthContext();

  const isDesktop = useResponsive("up", "md");

  const handleSelectConversation = (conversationId) => {
    dispatch(setActiveConversation(conversationId));
    // let conversationKey = "";

    // const conversation = conversations.byId[conversationId];

    // if (conversation.type === "GROUP") {
    //   conversationKey = conversation.id;
    // } else {
    //   const otherParticipant = conversation.participants.find(
    //     (participant) => participant.id !== CURRENT_USER_ID
    //   );

    //   if (otherParticipant?.username) {
    //     conversationKey = otherParticipant?.username;
    //   }
    // }

    // navigate(PATH_DASHBOARD.chat.view(conversationKey));
  };

  const loading = chat.isLoadingConversations;

  return (
    <List
      disablePadding
      sx={sx}
      {...other}
    >
      {!loading && conversations.allIds.length === 0 ? (
        <Box sx={{ p: 2 }}>
          <Alert severity="info">{t("chat.no_data")}</Alert>
        </Box>
      ) : (
        (loading ? [...Array(12)] : conversations.allIds).map(
          (conversationId, index) =>
            conversationId ? (
              <ChatNavItem
                key={conversationId}
                openNav={openNav}
                conversation={conversations.byId[conversationId]}
                isSelected={selected(conversationId)}
                onSelect={() => {
                  if (!isDesktop) {
                    onCloseNav();
                  }
                  handleSelectConversation(conversationId);
                }}
              />
            ) : (
              <SkeletonConversationItem key={index} />
            )
        )
      )}
    </List>
  );
}
