const customer = {
  root: "client",
  root_plural: "clients",
  salon: "Salon",
  client_detail: "Détails du client",
  salon_detail: "Détails du salon",
  no_profile: "Pas de profile",
  hair_objective_1: "Force",
  hair_objective_2: "Longueur",
  hair_objective_3: "Nourir",
  hair_objective_4: "Définition",
  hair_state_client_current_1: "En bonnne santé",
  hair_state_client_current_2: "Cassant",
  hair_state_client_current_3: "Sécheresse du cuir chevelu",
  hair_state_client_current_4: "Problme de pellicules",
  hair_state_client_current_5: "Cheveux gras",
  hair_state_client_current_6: "Perte de cheveux",
  hair_state_client_current_7: "Autre",
  hair_last_client_current_1: "Moins de 3 semaines",
  hair_last_client_current_2: "Moins de 3 mois",
  hair_last_client_current_3: "Plus de 3 mois",
  really_want_to_change_user_status:
    "Voulez-vous vraiment changer le statut de cette utilisatetur en {status}?",
};

export default customer;
