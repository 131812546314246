import { IBlog } from "@/app/types";
import {
  IActionWithPayload,
  IBaseCrudReducer,
} from "@/app/types/BaseReduxTypes";
import BaseCrudReducer from "@/redux/base/crud-slice-helpers/reducers";
import { resetStateKeys } from "@/redux/common/functions";
import { blogInitialState, IBlogState } from ".";

export interface IBlogReducer
  extends IBaseCrudReducer<IBlogState, IBlog> {
  resetState: (state: IBlogState) => void;
  publish: (state: IBlogState, action: IActionWithPayload<any>) => void;
  addOrRemoveInRecentArticle: (state: IBlogState, action: IActionWithPayload<any>) => void;
  addOrRemoveInTheNews: (state: IBlogState, action: IActionWithPayload<any>) => void;
}

class BlogReducer
  extends BaseCrudReducer<IBlogState, IBlog>
  implements IBlogReducer
{
  publish = (state: IBlogState, action: IActionWithPayload<any>) => {
    state.list.data = state.list.data.map((d) =>
      d.uuid === action.payload?.uuid ? action.payload : d
    );
  };
  addOrRemoveInTheNews = (state: IBlogState, action: IActionWithPayload<any>) => {
    state.list.data = state.list.data.map((d) =>
      d.uuid === action.payload?.uuid ? action.payload : d
    );
  };
  addOrRemoveInRecentArticle = (state: IBlogState, action: IActionWithPayload<any>) => {
    state.list.data = state.list.data.map((d) =>
      d.uuid === action.payload?.uuid ? action.payload : d
    );
  };
  resetState = (state: IBlogState) =>
    resetStateKeys(state, blogInitialState);
  listHome = (state: IBlogState, action: IActionWithPayload<any>) => {
    state.home.list = action.payload;
  };
  listByCategory = (state: IBlogState, action: IActionWithPayload<any>) => {
    state.category.list = action.payload;
  };
  listFavorites = (state: IBlogState, action: IActionWithPayload<any>) => {
    state.favorites.list = action.payload;
  };
  addOrRemoveFavorite = (
    state: IBlogState,
    action: IActionWithPayload<any>
  ) => {};
}

const reducers: IBlogReducer = new BlogReducer();

export default reducers;
