import { IVideo } from "@/app/types";
import {
  baseCrudInitialState,
  IBaseCrudState,
} from "@/redux/base/crud-slice-helpers";
import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers, { IVideoReducer } from "./reducers";

export interface IVideoState extends IBaseCrudState<IVideo> {}

export const videoInitialState: IVideoState = baseCrudInitialState;

export const slice = createSlice<
  IVideoState,
  IVideoReducer,
  typeof sliceNames.video
>({
  name: sliceNames.video,
  initialState: videoInitialState,
  reducers,
});

export default slice.reducer;
