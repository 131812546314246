import React, { useState } from "react";
// form
// @mui
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Tab,
  Tabs,
} from "@mui/material";
// routes
//
import { PATH_DASHBOARD } from "@/app/routes/paths";
import { IApplicationState, IAppLocale } from "@/app/types";
import ConfirmDialog from "@/core/components/confirm-dialog/ConfirmDialog";
import Iconify from "@/core/components/iconify";
import useToast from "@/core/hooks/useToast";
import useTranslation from "@/core/hooks/useTranslation";
import AutoForm from "@/presentation/components/AutoForm";
import { ITablePageOptionListItem } from "@/presentation/components/TablePage";
import { articleFields } from "@/presentation/pages/dashboard/ArticlesPage/data/fields";
import { slice } from "@/redux/slices/article";
import { slice as autoFormSlice } from "@/redux/slices/autoForm";
import articleEffects from "@/redux/slices/article/effects";
import { store } from "@/redux/store";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { current } from "@reduxjs/toolkit";
import { useLocales } from "@/app/locales";
import { useLocation } from "react-router";

// ----------------------------------------------------------------------

const TAGS_OPTION = [
  "Toy Story 3",
  "Logan",
  "Full Metal Jacket",
  "Dangal",
  "The Sting",
  "2001: A Space Odyssey",
  "Singin' in the Rain",
  "Toy Story",
  "Bicycle Thieves",
  "The Kid",
  "Inglourious Basterds",
  "Snatch",
  "3 Idiots",
];

// ----------------------------------------------------------------------

export default function BlogNewPostForm({ articleToEdit, uuid }: any) {
  // Refs
  const formRef = React.createRef<HTMLDivElement>();
  const formikRef = React.useRef<any>();

  // Hooks
  const t = useTranslation();
  const toast = useToast();
  const dispatch = store.dispatch;
  const navigate = useNavigate();
  // Store
  const {
    article: { isLoading },
  } = useSelector((state: IApplicationState) => state);

  // State
  const [showDeleteMOdal, setShowDeleteModal] = React.useState<boolean>(false);
  const [currentLocaleTab, setCurrentLocaleTab] = useState<string>("fr");
  const { currentLang } = useLocales();
  const localeTabs: ITablePageOptionListItem[] = [
    {
      label: "FR",
      value: "fr",
    },
    {
      label: "EN",
      value: "en",
    },
  ];
  const fields = articleFields(
    t,
    currentLocaleTab,
    currentLang.value as IAppLocale
  );
  const changeLocaleTab = (locale: string) => setCurrentLocaleTab(locale);

  const saveArticle = (values: any) => {
    dispatch(
      articleEffects[articleToEdit ? "update" : "create"](
        { uuid, ...values },
        () => {
          toast.success(
            t(`models.${articleToEdit ? "update" : "create"}_success`, {
              model: `article.root`,
            })
          );
          navigate(PATH_DASHBOARD.articles.root);
        }
      )
    );
  };

  const deleteArticle = () => {
    dispatch(
      articleEffects.delete(uuid, () => {
        toast.success(t(`models.delete_success`, { model: `article.root` }));
        setShowDeleteModal(false);
        navigate(PATH_DASHBOARD.articles.root);
      })
    );
  };

  React.useEffect(() => () => {
    // @ts-ignore
    dispatch(autoFormSlice.actions.setValuesToHold(null));
  });

  return (
    <Box>
      <Box sx={{ borderRadius: 2, overflow: "hidden", mb: 2 }}>
        <Tabs
          value={currentLocaleTab}
          onChange={(_, value) => changeLocaleTab(value)}
          sx={{
            px: 2,
            bgcolor: "background.neutral",
          }}
        >
          {localeTabs.map((tab) => (
            <Tab
              key={tab.value}
              label={t(tab.label)}
              value={tab.value}
              icon={
                Object.keys(formikRef.current?.errors ?? {}).find(
                  (key) =>
                    key.endsWith(`_${tab.value}`) &&
                    formikRef.current?.errors[key]
                ) ? (
                  <Iconify
                    sx={{ color: "error.main" }}
                    width={16}
                    icon="material-symbols:error-circle-rounded"
                  />
                ) : undefined
              }
            />
          ))}
        </Tabs>
      </Box>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={8}
        >
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <AutoForm
                ref={formRef}
                formikRef={formikRef}
                setLoading={() => dispatch(slice.actions.startLoading())}
                initialItem={articleToEdit}
                hideButton
                fields={fields}
                onSubmit={(values: any) => saveArticle(values)}
              />
            </Stack>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
        >
          {/* <Stack spacing={3}>
            <Alert severity="info">
              <ReactMarkdown>{t("article.info_on_article_crud")}</ReactMarkdown>
            </Alert>
          </Stack> */}

          <Stack
            direction="row"
            spacing={1.5}
            // sx={{ mt: 3 }}
          >
            {/* <Button
              fullWidth
              color="inherit"
              variant="outlined"
              size="large"
              onClick={() => ""}
            >
              {t("article.preview")}
            </Button> */}

            {/* {articleToEdit && (
              <Button
                fullWidth
                color="error"
                variant="outlined"
                size="large"
                disabled={isLoading || formikRef.current?.isSubmitting}
                onClick={() => setShowDeleteModal(true)}
              >
                {t("app.delete")}
              </Button>
            )} */}

            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              loading={isLoading || formikRef.current?.isSubmitting}
              onClick={() => formRef.current?.click()}
            >
              {t(articleToEdit ? "app.update" : "article.post")}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>

      {/* <BlogNewPostPreview
        values={values}
        open={openPreview}
        isValid={isValid}
        isSubmitting={isSubmitting}
        onClose={handleClosePreview}
        onSubmit={handleSubmit(onSubmit)}
      /> */}

      {showDeleteMOdal && (
        <ConfirmDialog
          open={true}
          onClose={() => setShowDeleteModal(false)}
          disabled={isLoading}
          title={t("app.delete")}
          content={t(`app.delete_model_confirm_vowel`, {
            model: `article.root`,
          })}
          onConfirm={() => {
            deleteArticle();
          }}
          isConfirmLoading={isLoading}
          confirmLabel={t("app.delete")}
          action={null}
        />
      )}
    </Box>
  );
}
