import { IStatState } from ".";

type IStatReducer = (state: IStatState, action: any) => void;

const reducers: Record<string, IStatReducer> = {
  getIncome: (state, action) => {
    state.income = action.payload;
  },
  getSalonIncome: (state, action) => {
    state.salonIncome = action.payload;
  },
  getProductProfit: (state, action) => {
    state.productProfit = action.payload;
  },
  getSalonProductProfit: (state, action) => {
    state.salonProductProfit = action.payload.data;
  },
  getSalonProfit: (state, action) => {
    state.salonProfit = action.payload;
  },
  getCustomerByLocation: (state, action) => {
    state.customerByLocation = action.payload;
  },
  getSalonByLocation: (state, action) => {
    state.salonByLocation = action.payload;
  },
  getNumberOfUser: (state, action) => {
    state.numberOfUser = action.payload;
  },
  getSalonByCountry: (state, action) => {
    state.salonByCountry = action.payload;
  },
  startLoadingIncome: (state, action) => {
    state.isLoadingIncome = true;
  },
  stopLoadingIncome: (state, action) => {
    state.isLoadingIncome = false;
  },
  startLoadingSalonIncome: (state, action) => {
    state.isLoadingSalonIncome = true;
  },
  stopLoadingSalonIncome: (state, action) => {
    state.isLoadingSalonIncome = false;
  },
  startLoadingProductProfit: (state, action) => {
    state.isLoadingProductProfit = true;
  },
  stopLoadingProductProfit: (state, action) => {
    state.isLoadingProductProfit = false;
  },
  startLoadingSalonProductProfit: (state, action) => {
    state.isLoadingSalonProductProfit = true;
  },
  stopLoadingSalonProductProfit: (state, action) => {
    state.isLoadingSalonProductProfit = false;
  },
  startLoadingSalonProfit: (state, action) => {
    state.isLoadingSalonProfit = true;
  },
  stopLoadingSalonProfit: (state, action) => {
    state.isLoadingSalonProfit = false;
  },
  startLoading: (state, action) => {
    state.isLoading = true;
  },
  stopLoading: (state, action) => {
    state.isLoading = false;
  },
  startLoadingCustomerByLocation: (state, action) => {
    state.isLoadingCustomerByLocation = true;
  },
  stopLoadingCustomerByLocation: (state, action) => {
    state.isLoadingCustomerByLocation = false;
  },
  startLoadingSalonByLocation: (state, action) => {
    state.isLoadingSalonByLocation = true;
  },
  stopLoadingSalonByLocation: (state, action) => {
    state.isLoadingSalonByLocation = false;
  },
  startLoadingNumberOfUser: (state, action) => {
    state.isLoadingNumberOfUser = true;
  },
  stopLoadingNumberOfUser: (state, action) => {
    state.isLoadingNumberOfUser = false;
  },
  startLoadingSalonByCountry: (state, action) => {
    state.isLoadingSalonByCountry = true;
  },
  stopLoadingSalonByCountry: (state, action) => {
    state.isLoadingSalonByCountry = false;
  },
  hasError: (state, action) => {
    state.error = action.payload;
  },
};

export default reducers;
