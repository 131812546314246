import StatService from "@/app/services/api/StatService";
import { execute } from "@/redux/common/functions";
import { slice } from ".";

const statEffects = {
  getIncome: (resource?: string) =>
    execute({
      slice,
      startLoadingAction: slice.actions.startLoadingIncome,
      stopLoadingAction: slice.actions.stopLoadingIncome,
      actionToDispatch: slice.actions.getIncome,
      serviceFn: StatService.get,
      inputProps: `/income${resource ?? ""}`,
    }),
  getSalonIncome: (resource?: string) =>
    execute({
      slice,
      startLoadingAction: slice.actions.startLoadingSalonIncome,
      stopLoadingAction: slice.actions.stopLoadingSalonIncome,
      actionToDispatch: slice.actions.getSalonIncome,
      serviceFn: StatService.get,
      inputProps: `/income/hairdressing-salon/${resource ?? ""}`,
    }),
  getProductProfit: () =>
    execute({
      slice,
      startLoadingAction: slice.actions.startLoadingProductProfit,
      stopLoadingAction: slice.actions.stopLoadingProductProfit,
      actionToDispatch: slice.actions.getProductProfit,
      serviceFn: StatService.get,
      inputProps: `/products/profitability`,
    }),
  getSalonProductProfit: (resource?: string) =>
    execute({
      slice,
      startLoadingAction: slice.actions.startLoadingSalonProductProfit,
      stopLoadingAction: slice.actions.stopLoadingSalonProductProfit,
      actionToDispatch: slice.actions.getSalonProductProfit,
      serviceFn: StatService.get,
      inputProps: `/products/profitability/hairdressing-salon/${
        resource ?? ""
      }`,
    }),
  getSalonProfit: () =>
    execute({
      slice,
      startLoadingAction: slice.actions.startLoadingSalonProfit,
      stopLoadingAction: slice.actions.stopLoadingSalonProfit,
      actionToDispatch: slice.actions.getSalonProfit,
      serviceFn: StatService.get,
      inputProps: `/hairdressing-salons/profitability`,
    }),
  getCustomerByLocation: () =>
    execute({
      slice,
      startLoadingAction: slice.actions.startLoadingCustomerByLocation,
      stopLoadingAction: slice.actions.stopLoadingCustomerByLocation,
      actionToDispatch: slice.actions.getCustomerByLocation,
      serviceFn: StatService.get,
      inputProps: `/customer/location`,
    }),
  getSalonByLocation: () =>
    execute({
      slice,
      startLoadingAction: slice.actions.startLoadingSalonByLocation,
      stopLoadingAction: slice.actions.stopLoadingSalonByLocation,
      actionToDispatch: slice.actions.getSalonByLocation,
      serviceFn: StatService.get,
      inputProps: `/hairdressing-salons/location`,
    }),
  getNumberOfUser: (resource?: string) =>
    execute({
      slice,
      startLoadingAction: slice.actions.startLoadingNumberOfUser,
      stopLoadingAction: slice.actions.stopLoadingNumberOfUser,
      actionToDispatch: slice.actions.getNumberOfUser,
      serviceFn: StatService.get,
      inputProps: `/users/evolutuion${resource ?? ""}`,
    }),
  getSalonByCountry: (country: string) =>
    execute({
      slice,
      startLoadingAction: slice.actions.startLoadingSalonByCountry,
      stopLoadingAction: slice.actions.stopLoadingSalonByCountry,
      actionToDispatch: slice.actions.getSalonByCountry,
      serviceFn: StatService.get,
      inputProps: `/hairdressing-salons/location/${country ?? ""}`,
    }),
};

export default statEffects;
