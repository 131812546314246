import IntentsService from "@/app/services/api/IntentsService";
import { IIntentRequestRange } from "@/app/types/IntentTypes";
import { execute } from "@/redux/common/functions";
import { slice } from ".";

const intentEffects = {
  paginate: (range?: IIntentRequestRange) =>
    execute({
      slice,
      actionToDispatch: slice.actions.paginate,
      serviceFn: IntentsService.list_intents,
      inputProps: range,
    }),
};

export default intentEffects;
