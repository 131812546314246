import { IChatMessageCreate, IChatMessageFileCreate } from "@/app/types";
import BaseService from "./BaseService";
import { ChatUrls } from "./urls";

export class ChatService {
  static unreadCount = () => {
    return BaseService.getRequest(ChatUrls.UNREAD_COUNT, true);
  };
  static lastReceived = () => {
    return BaseService.getRequest(ChatUrls.LAST_RECEIVED, true);
  };
  static listConversationMessages = (id: string) => {
    return BaseService.getRequest(
      ChatUrls.LIST_CONVERSATION_MESSAGES(id),
      true
    );
  };
  static listConversations = (range?: any) => {
    return BaseService.getRequest(ChatUrls.LIST_CONVERSATIONS(range), true);
  };
  static listUserConversations = (public_id: string) => {
    return BaseService.getRequest(
      ChatUrls.LIST_USER_CONVERSATIONS(public_id),
      true
    );
  };
  static listUserConversationMessages = (public_id: string) => {
    return BaseService.getRequest(
      ChatUrls.LIST_USER_CONVERSATION_MESSAGES(public_id),
      true
    );
  };
  static createMessage = (message: IChatMessageCreate) => {
    return BaseService.postRequest(ChatUrls.CREATE_MESSAGE, message, true);
  };
  static createMessageFile = (message: IChatMessageFileCreate) => {
    return BaseService.postRequest(ChatUrls.CREATE_MESSAGE_FILE, message, true);
  };
}
