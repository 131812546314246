import clsx from "clsx";

import Label from "@/core/components/label";
import useTranslation from "@/core/hooks/useTranslation";
import getInitials from "@/core/utils/getInitials";
import {
  Avatar,
  Card,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";

import { Intent } from "@/app/types/IntentTypes";

interface OrderDetailsView {
  info: Intent;
  handleClose: Function;
}

function getStatusLabel(status: string) {
  const map: any = {
    active: {
      text: "Nouvelle entreprise",
      color: "success",
    },
    un_active: {
      text: "Mise a jour",
      color: "error",
    },
  };
  const { text, color } = map[status];

  // @ts-ignore
  return <Label color={color}>{text}</Label>;
}

const useStyles: any = makeStyles((theme: any) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}));

export function OrderDetailsView(props: OrderDetailsView) {
  const { info, handleClose, ...rest } = props;

  const classes = useStyles();
  const t = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const formatMessageOption = (text: string) => {
    return text
      .replace("[%]", " (Lien boutique) ")
      .split("[=]")
      .map((t) => (
        <div
          style={{
            padding: 5,
            marginBottom: 5,
            backgroundColor: "#14ACCD91",
            color: "white",
            border: "1px dashed #14ACCD",
          }}
        >
          {t}
        </div>
      ));
  };

  const formatRestMessage = (text: string) => {
    return text.split("(Voir plus)").length > 1 ? (
      <div>
        <div
          style={{
            paddingBottom: 5,
            marginBottom: 5,
            borderBottom: "1px dashed #f2a900",
          }}
        >
          {formatMessageOption(text.split("(Voir plus)")[0])}
        </div>
        <div>{formatMessageOption(text.split("(Voir plus)")[1])}</div>
      </div>
    ) : text.split("(See more)").length > 1 ? (
      <div>
        <div
          style={{
            paddingBottom: 5,
            marginBottom: 5,
            borderBottom: "1px dashed #f2a900",
          }}
        >
          {formatMessageOption(text.split("(See more)")[0])}
        </div>
        <div>{formatMessageOption(text.split("(See more)")[1])}</div>
      </div>
    ) : (
      formatMessageOption(text)
    );
  };

  const formatMessage = (text: string) => {
    return (
      <div>
        {text.split("[@]").map((t, index) => (
          <div
            key={t + index}
            style={{
              border: "1px dashed #14ACCD",
              margin: "5px  0",
              padding: 5,
            }}
          >
            {t.split("[:]").length > 1 ? (
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <span
                  style={{
                    color: "white",
                    borderRadius: 5,
                    display: "block",
                    padding: "2px 5px",
                    marginRight: 5,
                    backgroundColor: "#F2A900",
                  }}
                >
                  {t.split("[:]")[0]}
                </span>{" "}
                {formatRestMessage(t.split("[:]")[1])}
              </div>
            ) : (
              <span>{formatRestMessage(t)}</span>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{ py: 3 }}
    >
      <Grid
        item
        sm={12}
        xs={12}
      >
        <Card
          className={clsx(classes.root)}
          {...rest}
        >
          <CardHeader
            title={
              (info.trainingPhrases ?? []).length > 0
                ? (info.trainingPhrases ?? [])[0].parts[0].text
                : info.displayName
            }
            avatar={
              <Avatar aria-label="recipe">
                {getInitials(
                  (info.trainingPhrases ?? []).length > 0
                    ? (info.trainingPhrases ?? [])[0].parts[0].text
                    : info.displayName
                )}
              </Avatar>
            }
          />
          <Divider sx={{ mt: 2 }} />
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.fontWeightMedium}>
                  {t("Question(s)")}
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    <ul>
                      {(info.trainingPhrases || []).map((training) => (
                        <li>
                          {" "}
                          {training.parts.map((part) => part.text + " ")}{" "}
                        </li>
                      ))}
                    </ul>
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.fontWeightMedium}>
                  {t("Réponse(s)")}
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    <ul>
                      {(info.messages || []).map((message) =>
                        message.text.text.map((text) => formatMessage(text))
                      )}
                    </ul>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
        {/* @ts-ignore */}
        <Grid
          container
          style={{ marginTop: 10 }}
          justify="space-between"
        >
          {/* <Grid item>
            <AlertDialogDelete info={info} />
          </Grid> */}
          {/* <Grid item>
            <Button
              variant="contained"
              type="submit"
              style={
                info.status !== 'ACTIVED'
                  ? {
                      background: fade(theme.palette.error.main, 0.08),
                      color: theme.palette.error.main
                    }
                  : {
                      background: fade(theme.palette.success.main, 0.08),
                      color: theme.palette.success.main
                    }
              }
              onClick={() => {
                handleSubmit(info);
              }}>
              {info.status === 'ACTIVED' ? t('deactivate') : t('activate')}
            </Button>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
}
