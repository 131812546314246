import { useLocales } from "@/app/locales";
import { IApplicationState, IAppLocale } from "@/app/types";
import useFilters from "@/core/hooks/useFilters";
import useTranslation from "@/core/hooks/useTranslation";
import TablePage from "@/presentation/components/TablePage";
import videoEffects from "@/redux/slices/video/effects";
import React from "react";
import { useSelector } from "react-redux";
import videoColumns from "./data/columns";
import { videoTableTopOptions, videoToolbarOptions } from "./data/constants";
import videoFields from "./data/fields";

const VideosPage: React.FC = () => {
  // Store
  const { video, globalData } = useSelector(
    (state: IApplicationState) => state
  );

  // Hooks
  const t = useTranslation();
  const { currentLang } = useLocales();
  const {
    getData,
    handleKeyword,
    handlePrestation_id,
    handleStatus,
    handleOrder,
    handleOrder_field,
    handlePage,
    handlePer_page,
    keyword,
    prestation_id,
    status,
    order,
    order_field,
  } = useFilters({
    effects: videoEffects,
    initialState: {
      page: video.list.current_page,
      per_page: video.list.per_page,
      keyword: "",
      prestation_id: "",
      status: "",
      order: "",
      order_field: "",
    },
  });

  const prestations = globalData.data.prestations.map((p) => ({
    label: p[`title_${currentLang.value as IAppLocale}`],
    value: p.id,
  }));
  const langue = [
    { label: t("label.french"), value: "fr" },
    { label: t("label.english"), value: "en" },
  ].map((langue) => ({ label: langue.label, value: langue.value }));
  console.log(langue);
  return (
    <TablePage
      model="video"
      noCheckBoxes
      // ** Genre **
      female
      // ** Toolbar **
      tableTopOptions={videoTableTopOptions}
      toolbarOptionChange={handleStatus}
      currentToolbarOption={status}
      toolbarOptions={videoToolbarOptions(
        {
          value: keyword,
          onChange: handleKeyword,
        },
        {
          value: prestation_id,
          onChange: handlePrestation_id,
        },
        prestations
      )}
      // ** On Select rows **
      idProperty="uuid"
      // ** Table CRUD **
      fields={videoFields(t, prestations, langue)}
      stateData={video}
      columns={videoColumns}
      effects={videoEffects}
      getData={getData}
      // ** Filter options **
      isNotFound={(data) =>
        (!data.length && !!keyword) ||
        (!data.length && !!prestation_id) ||
        (!data.length && !!status)
      }
      isFiltered={() => keyword !== "" || prestation_id !== "" || status !== ""}
      onReset={() => {
        handleKeyword("");
        handlePrestation_id("");
        handleStatus("");
      }}
      // ** Sorting **
      handleFilterOrder={handleOrder}
      handleFilterOrderField={handleOrder_field}
      // ** Pagination **
      onChangePage={handlePage}
      onChangeRowsPerPage={handlePer_page}
      order={order}
      orderField={order_field}
    />
  );
};

export default VideosPage;
