import useFilters from "@/core/hooks/useFilters";
import useTranslation from "@/core/hooks/useTranslation";
import TablePage, {
  ICustomBreadcrumbsLink,
} from "@/presentation/components/TablePage";
import sliceNames from "@/redux/names";
import blogCategoryEffects from "@/redux/slices/blogCategory/effects";
import { useSelector } from "@/redux/store";
import blogCategoryColumns from "./data/columns";
import {
  blogCategoryTableTopOptions,
  blogCategoryToolbarOptions,
} from "./data/constants";
import blogCategoryFields from "./data/fields";

interface IBlogCategoryProps {
  additionalFilters?: Record<string, string | undefined>;
  breadcrumbs?: null | ICustomBreadcrumbsLink[];
  noPage?: boolean;
}
export default function BlogCategorysPage({
  additionalFilters,
  breadcrumbs,
  noPage,
}: IBlogCategoryProps) {
  // Store
  const blogCategory = useSelector((state) => state.blogCategory);

  // Hooks
  const t = useTranslation();
  const {
    getData,
    handleKeyword,
    handlePaymentType,
    handleStatus,
    handleOrder,
    handleOrder_field,
    handlePage,
    handlePer_page,
    keyword,
    paymentType,
    status,
    order: _order,
    order_field,
  } = useFilters({
    effects: blogCategoryEffects,
    initialState: {
      page: blogCategory.list.current_page,
      per_page: blogCategory.list.per_page,
      keyword: "",
      paymentType: "",
      status: "",
      order: "",
      order_field: "",
      ...(additionalFilters ?? {}),
    },
  });

  return (
    <TablePage
      model={sliceNames.blogCategory}
      noCheckBoxes
      // ** Breadcrumbs **
      breadcrumbLinks={breadcrumbs}
      // ** Genre **
      female
      // ** Toolbar **
      
      tableTopOptions={blogCategoryTableTopOptions}
      toolbarOptionChange={handleStatus}
      currentToolbarOption={status}
      toolbarOptions={blogCategoryToolbarOptions({
        value: keyword,
        onChange: handleKeyword,
      })}
      // ** On Select rows **
      idProperty="uuid"
      // ** Table CRUD **
      fields={blogCategoryFields(t, [])}
      stateData={blogCategory}
      columns={blogCategoryColumns}
      effects={blogCategoryEffects}
      getData={getData}
      // ** Filter options **
      isNotFound={
        (data) => data.length === 0
        // (!data.length && !!keyword) ||
        // (!data.length && !!role) ||
        // (!data.length && !!status)
      }
      isFiltered={(data) =>
        keyword !== "" || paymentType !== "" || status !== ""
      }
      onReset={() => {
        handleKeyword("");
        handlePaymentType("");
        handleStatus("");
      }}
      // ** Sorting **
      handleFilterOrder={handleOrder}
      handleFilterOrderField={handleOrder_field}
      order={_order}
      orderField={order_field}
      // ** Pagination **
      onChangePage={handlePage}
      onChangeRowsPerPage={handlePer_page}
      noPage={noPage}
    />
  );
}
