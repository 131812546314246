import { IUploadFiledCreate } from "@/app/types";
import BaseService from "./BaseService";
import { fileUrls } from "./urls";

class FileService {
  static storeFile = (file: IUploadFiledCreate) => {
    return BaseService.postRequest(fileUrls.STORE_FILE, file, true);
  };
}

export default FileService;
