import { IHairdressingSalon } from "@/app/types";
import {
  baseCrudInitialState,
  IBaseCrudState,
} from "@/redux/base/crud-slice-helpers";
import sliceNames from "@/redux/names";
import { createSlice } from "@reduxjs/toolkit";
import reducers, { IHairdressingSalonReducer } from "./reducers";

export interface IHairdressingSalonState
  extends IBaseCrudState<IHairdressingSalon> {}

export const hairdressingSalonInitialState: IHairdressingSalonState =
  baseCrudInitialState;

export const slice = createSlice<
  IHairdressingSalonState,
  IHairdressingSalonReducer,
  typeof sliceNames.professional
>({
  name: sliceNames.professional,
  initialState: hairdressingSalonInitialState,
  reducers,
});

export default slice.reducer;
