import { IBlogCategory } from "@/app/types";
import buildValidators from "@/core/utils/buildValidators";
import { IAutoFormFields } from "@/presentation/components/AutoForm";

const blogCategoryFields = (
  t: Function,
  roles: any[]
): IAutoFormFields<IBlogCategory> => {
  const v = buildValidators(t);
  return {
    title_fr: {
      initialValue: (row) => row?.title_fr,
      label: "label.name_fr",
      placeholder: "placeholder.name_fr",
      title: "title.name_fr",
      props: {},
      validation: v.string,
    },
    title_en: {
      initialValue: (row) => row?.title_en,
      label: "label.name_en",
      placeholder: "placeholder.name_en",
      title: "title.name_en",
      props: {},
      validation: v.string,
    },
  };
};

export default blogCategoryFields;
