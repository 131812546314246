import PropTypes from "prop-types";
import { useRef } from "react";
// @mui
import { Box, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
// utils
import { bgBlur } from "@/core/utils/cssStyles";
// auth
// components
import { CustomAvatar } from "@/core/components/custom-avatar";
import Image from "@/core/components/image";
import Carousel, {
  CarouselArrows,
  CarouselDots,
} from "@/core/components/carousel";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  "&:before": {
    // ...bgBlur({
    //   color: theme.palette.primary.darker,
    // }),
    backgroundColor: theme.palette.primary.darker,
    top: 0,
    content: "''",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
}));

const StyledInfo = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: "absolute",
  marginTop: theme.spacing(5),
  [theme.breakpoints.up("md")]: {
    right: "auto",
    display: "flex",
    alignItems: "center",
    left: theme.spacing(3),
    bottom: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

ProfileCover.propTypes = {
  covers: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  role: PropTypes.string,
  photoURL: PropTypes.string,
};

export default function ProfileCover({ name, role, covers, photoURL }) {
  const theme = useTheme();
  const carouselRef = useRef(null);

  const carouselSettings = {
    dots: false,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === "rtl"),
    ...CarouselDots({
      rounded: true,
      sx: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: "absolute",
      },
    }),
  };

  const handlePrev = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <StyledRoot>
      <StyledInfo>
        <CustomAvatar
          src={photoURL}
          alt={name}
          name={name}
          sx={{
            mx: "auto",
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "common.white",
            width: { xs: 80, md: 128 },
            height: { xs: 80, md: 128 },
          }}
        />

        <Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            color: "common.white",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography variant="h4">{name}</Typography>

          <Typography sx={{ opacity: 0.72 }}>{role}</Typography>
        </Box>
      </StyledInfo>

      <CarouselArrows
        filled
        shape="rounded"
        onNext={handleNext}
        onPrevious={handlePrev}
        leftButtonProps={{
          display: (covers ?? []).length > 0 ? "initial" : "none",
        }}
        rightButtonProps={{
          display: (covers ?? []).length > 0 ? "initial" : "none",
        }}
      >
        <Carousel
          ref={carouselRef}
          {...carouselSettings}
        >
          {(covers ?? []).map((cover) => (
            <Image
              alt="cover"
              src={cover}
              ratio="21/9"
            />
          ))}
        </Carousel>
      </CarouselArrows>
    </StyledRoot>
  );
}
