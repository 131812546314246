import GlobalService from "@/app/services/api/GlobalService";
import { slice } from ".";

const globalDataEffects = {
  get: () => async (dispatch: any) => {
    try {
      const res = await GlobalService.load_global_data();
      const result = await res.json();
      if (res.ok) {
        dispatch(slice.actions.get(result));
      } else {
      }
    } catch (error) {}
  },
};

export default globalDataEffects;
