import { IArticle } from "@/app/types";
import {
  IActionWithPayload,
  IBaseCrudReducer,
} from "@/app/types/BaseReduxTypes";
import BaseCrudReducer from "@/redux/base/crud-slice-helpers/reducers";
import { resetStateKeys } from "@/redux/common/functions";
import { articleInitialState, IArticleState } from ".";

export interface IArticleReducer
  extends IBaseCrudReducer<IArticleState, IArticle> {
  resetState: (state: IArticleState) => void;
  publish: (state: IArticleState, action: IActionWithPayload<any>) => void;
}

class ArticleReducer
  extends BaseCrudReducer<IArticleState, IArticle>
  implements IArticleReducer
{
  publish = (state: IArticleState, action: IActionWithPayload<any>) => {
    state.list.data = state.list.data.map((d) =>
      d.uuid === action.payload?.uuid ? action.payload : d
    );
  };
  resetState = (state: IArticleState) =>
    resetStateKeys(state, articleInitialState);
  listHome = (state: IArticleState, action: IActionWithPayload<any>) => {
    state.home.list = action.payload;
  };
  listByCategory = (state: IArticleState, action: IActionWithPayload<any>) => {
    state.category.list = action.payload;
  };
  listFavorites = (state: IArticleState, action: IActionWithPayload<any>) => {
    state.favorites.list = action.payload;
  };
  addOrRemoveFavorite = (
    state: IArticleState,
    action: IActionWithPayload<any>
  ) => {};
}

const reducers: IArticleReducer = new ArticleReducer();

export default reducers;
