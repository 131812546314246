import { IProduct } from "@/app/types";
import Image from "@/core/components/image/Image";
import { ITableRowColumn } from "@/core/components/table/AppTableRow";
import { Link, Stack } from "@mui/material";
import moment from "moment";

const productColumns: (
  fCurrency: (number: number) => string
) => ITableRowColumn<IProduct>[] = (fCurrency) => [
  {
    header: { id: "name", label: "product.name", align: "left" },
    title: (row) => (
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
      >
        <Image
          // @ts-ignore
          disabledEffect
          visibleByDefault
          alt={row?.name}
          src={row?.images?.[0]?.url ?? ""}
          sx={{ borderRadius: 1.5, width: 48, height: 48 }}
        />

        <Link
          noWrap
          color="inherit"
          variant="subtitle2"
          onClick={() => null}
          sx={{ cursor: "pointer" }}
        >
          {row?.name}
        </Link>
      </Stack>
    ),
  },
  {
    header: { id: "createdAt", label: "product.createdAt", align: "left" },
    title: (row) =>
      row?.date_added ? moment(row?.date_added).format("ll") : "",
    align: "left",
  },
  {
    header: { id: "status", label: "administrator.status", align: "left" },
    type: "badge",
    title: (row) => `status.${row?.is_online ? "online" : "offline"}`,
    badgeColor: (row) => (row?.is_online ? "SUCCESS" : "ERROR"),
    sx: { textTransform: "capitalize" },
  },
  {
    header: { id: "salon", label: "product.salon", align: "left" },
    title: (row) => row?.hairdressing_salon?.name ?? "",
    align: "left",
  },
  {
    header: { id: "price", label: "product.price", align: "left" },
    title: (row) => fCurrency(row?.current_price ?? 0),
    align: "left",
  },
];

export default productColumns;
