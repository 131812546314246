import {
  Card,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

import { DEFAULT_RANGE } from "@/app/config-global";
import IntentsService from "@/app/services/api/IntentsService";
import { IApplicationState, IIntentRequestRange } from "@/app/types";
import Iconify from "@/core/components/iconify/Iconify";
import Page from "@/core/components/page";
import useFilters from "@/core/hooks/useFilters";
import useToast from "@/core/hooks/useToast";
import useTranslation from "@/core/hooks/useTranslation";
import intentEffects from "@/redux/slices/intent/effects";
import { dispatch } from "@/redux/store";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Header, Results } from "./components";

const useStyles: any = makeStyles((theme: any) => ({
  root: {
    backgroundColor: "#FFFF",
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function IntentsListView() {
  const classes = useStyles();
  const t = useTranslation();
  const { handleRange, handleLang, lang, range } =
    useFilters<IIntentRequestRange>({
      initialState: {
        range: {
          page: DEFAULT_RANGE.page,
          per_page: DEFAULT_RANGE.per_page,
          keyword: "",
        },
        lang: "fr",
      },
      getData: (form) => dispatch(intentEffects.paginate(form)),
    });
  const {
    intent: { isLoading, error, range: stateRange, intents },
  } = useSelector((state: IApplicationState) => state);

  const toast = useToast();

  const { enqueueSnackbar } = useSnackbar();

  const fetchData = () => {
    dispatch(intentEffects.paginate({ range: stateRange, lang: lang }));
  };

  const handleChange = (event: any, newValue: string) => {
    handleLang(newValue);
  };

  const getTranslatedText = async (text: string) => {
    const response = await IntentsService.translate_text(text);
    if (response.status === 200 || response.status === 201) {
      const data = await response.json();
      if (data.translations.length > 0) {
        return data.translations[0].text;
      }
    }
    return null;
  };

  const translateIntents = async () => {
    for (let index = 0; index < intents.length; index++) {
      const intent = intents[index];
      let values = {
        trainingPhrases: (intent.trainingPhrases ?? []).map(
          (trainingPhrase: any) => ({
            text: trainingPhrase.parts.map((part: any) => part.text).join(" "),
          })
        ),
        messages: (intent.messages ?? []).map((message: any) => ({
          text: message.text.text[0].includes("[@]")
            ? ""
            : message.text.text[0].split("(Voir plus)")[0],
          text_more: message.text.text[0].includes("[@]")
            ? ""
            : message.text.text[0].split("(Voir plus)")[1] ?? "",
          is_profile_base_value_false: message.text.text[0].includes("[@]"),

          text1: message.text.text[0].includes("[@]")
            ? message.text.text[0]
                .split("[@]")
                .find((t: any) => t.includes("1A/1B/1C[:]"))
                ?.replace("1A/1B/1C[:]", "")
                .split("(Voir plus)")[0]
            : "",
          text1_more: message.text.text[0].includes("[@]")
            ? message.text.text[0]
                .split("[@]")
                .find((t: any) => t.includes("1A/1B/1C[:]"))
                ?.replace("1A/1B/1C[:]", "")
                .split("(Voir plus)")[1]
            : "",

          text2: message.text.text[0].includes("[@]")
            ? message.text.text[0]
                .split("[@]")
                .find((t: any) => t.includes("2A/2B/2C[:]"))
                ?.replace("2A/2B/2C[:]", "")
                .split("(Voir plus)")[0]
            : "",
          text2_more: message.text.text[0].includes("[@]")
            ? message.text.text[0]
                .split("[@]")
                .find((t: any) => t.includes("2A/2B/2C[:]"))
                ?.replace("2A/2B/2C[:]", "")
                .split("(Voir plus)")[1]
            : "",

          text3: message.text.text[0].includes("[@]")
            ? message.text.text[0]
                .split("[@]")
                .find((t: any) => t.includes("3A/3B/3C[:]"))
                ?.replace("3A/3B/3C[:]", "")
                .split("(Voir plus)")[0]
            : "",
          text3_more: message.text.text[0].includes("[@]")
            ? message.text.text[0]
                .split("[@]")
                .find((t: any) => t.includes("3A/3B/3C[:]"))
                ?.replace("3A/3B/3C[:]", "")
                .split("(Voir plus)")[1]
            : "",

          text4: message.text.text[0].includes("[@]")
            ? message.text.text[0]
                .split("[@]")
                .find((t: any) => t.includes("4A/4B/4C[:]"))
                ?.replace("4A/4B/4C[:]", "")
                .split("(Voir plus)")[0]
            : "",
          text4_more: message.text.text[0].includes("[@]")
            ? message.text.text[0]
                .split("[@]")
                .find((t: any) => t.includes("4A/4B/4C[:]"))
                ?.replace("4A/4B/4C[:]", "")
                .split("(Voir plus)")[1]
            : "",
        })),
      };
      for (let i = 0; i < values.trainingPhrases.length; i++) {
        const element = values.trainingPhrases[i];
        values.trainingPhrases[i] = {
          text: (await getTranslatedText(element.text)) || element.text,
        };
      }
      for (let i = 0; i < values.messages.length; i++) {
        let element = values.messages[i];
        for (let j = 0; j < Object.keys(element).length; j++) {
          const e = Object.keys(element)[j];
          if ((element as any)[e] != "") {
            (element as any)[e] =
              (await getTranslatedText((element as any)[e])) ||
              (element as any)[e];
          }
        }

        values.messages[i] = element;
      }
      const body = {
        displayName:
          (await getTranslatedText(intent.displayName)) ?? intent.displayName,
        priority: 500000,
        trainingPhrases: values.trainingPhrases.map((trainingPhrase: any) => ({
          type: "EXAMPLE",
          parts: [
            {
              text: trainingPhrase.text,
            },
          ],
        })),
        // 'action': 'listRooms',
        messages: values.messages.map((message: any) => ({
          text: {
            text: [
              !message.is_profile_base_value_false
                ? `${message.text}${
                    message.text_more != "" ? "(See more)" : ""
                  }${message.text_more}`
                : `1A/1B/1C[:]${message.text1}${
                    message.text1_more != "" ? "(See more)" : ""
                  }${message.text1_more}[@]2A/2B/2C[:]${message.text2}${
                    message.text2_more != "" ? "(See more)" : ""
                  }${message.text2_more}[@]3A/3B/3C[:]${message.text3}${
                    message.text3_more != "" ? "(See more)" : ""
                  }${message.text3_more}[@]4A/4B/4C[:]${message.text4}${
                    message.text4_more != "" ? "(See more)" : ""
                  }${message.text4_more}`,
            ],
          },
        })),
      };
      IntentsService.update_intent(
        intent.name.split("/")[intent.name.split("/").length - 1],
        body,
        "en"
      )
        .then(async (response: any) => {
          const data = await response.json();
          if (response.status === 200 || response.status === 201) {
            enqueueSnackbar(
              "Mise à jour de la question effectuée avec succès",
              { variant: "success" }
            );
          } else {
            console.log({ data });
            enqueueSnackbar("Echec de mise à jour de la question", {
              variant: "error",
            });
          }
        })
        .catch(() => {
          enqueueSnackbar(t("shared.internet_connexion_error"), {
            variant: "error",
          });
        });
    }
  };

  /*React.useEffect(() => {
     translateIntents()
  }, [intents]);*/

  React.useEffect(() => {
    if (error?.message) {
      toast.error(t(error?.message));
    }
  }, [error?.message]);

  return (
    <Page title={t("chatBot.root_plural")}>
      <Header
        value={lang}
        handleChange={handleChange}
      />
      <Card>
        <Tabs
          value={lang}
          onChange={handleChange}
          sx={{
            px: 2,
            bgcolor: "background.neutral",
          }}
        >
          <Tab
            label={t("app.french")}
            value={"fr"}
          />
          <Tab
            label={t("app.english")}
            value={"en"}
          />
        </Tabs>
        <Stack
          spacing={2}
          alignItems="center"
          direction={{
            xs: "column",
            sm: "row",
          }}
          sx={{ px: 2.5, py: 3 }}
        >
          <TextField
            key={"search-chatBot"}
            value={range.keyword}
            onChange={(e) => handleRange({ ...range, keyword: e.target.value })}
            placeholder={t("placeholder.search")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: "text.disabled" }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Results
          intents={intents}
          lang={lang}
        />
      </Card>
    </Page>
  );
}

export default IntentsListView;
