import { useEffect, useState } from "react";
// @mui
import { Box, Card, Tab, Tabs } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "@/app/routes/paths";
// auth
// components
import Iconify from "@/core/components/iconify";
// sections
import Page from "@/core/components/page";
import useTranslation from "@/core/hooks/useTranslation";
import BookingAndOrders from "@/presentation/components/BookingsAndOrders";
import {
  Profile,
  ProfileCover,
  ProfileFriends,
} from "@/presentation/sections/@dashboard/user/profile";
import hairdressingSalonEffects from "@/redux/slices/hairdressingSalon/effects";
import { dispatch, useSelector } from "@/redux/store";
import { useNavigate, useParams } from "react-router-dom";
import ProductsPage from "../../ProductsPage";
import { APP_PERMISSIONS } from "@/app/config-global";
import sliceNames from "@/redux/names";

// ----------------------------------------------------------------------

export default function ProfessionalDetailsPage() {
  // Hooks
  const { uuid } = useParams();
  const t = useTranslation();
  const navigate = useNavigate();

  const [searchFriends, setSearchFriends] = useState("");

  // Store
  const { professional: hairdressingSalon } = useSelector((state) => state);
  const salon = hairdressingSalon.currentItem;
  const displayName = salon?.name ?? "";

  const [currentTab, setCurrentTab] = useState("profile");

  const TABS = [
    {
      value: "profile",
      label: t("app.infos"),
      icon: <Iconify icon="ic:round-account-box" />,
      component: salon ? (
        <Profile
          info={salon}
          posts={[]}
        />
      ) : null,
    },
    {
      value: "products",
      label: t("product.root_plural"),
      icon: <Iconify icon="fluent-mdl2:product-variant" />,
      component: <ProductsTab />,
    },
    {
      value: "orders",
      label: t("label.orders"),
      icon: <Iconify icon="ic:round-receipt" />,
      component: <BookingAndOrders order />,
    },
    {
      value: "bookings",
      label: t("label.bookings"),
      icon: <Iconify icon="maki:hairdresser" />,
      component: <BookingAndOrders />,
    },
    {
      value: "hairdressers",
      label: t("label.hairdressers"),
      icon: <Iconify icon="eva:people-fill" />,
      component: (
        <ProfileFriends
          friends={salon?.hairdressers}
          searchFriends={searchFriends}
          onSearchFriends={(event) => setSearchFriends(event.target.value)}
        />
      ),
    },
    // {
    //   value: "gallery",
    //   label: "Gallery",
    //   icon: <Iconify icon="ic:round-perm-media" />,
    //   component: <ProfileGallery gallery={_userGallery} />,
    // },
  ];

  useEffect(() => {
    if (uuid) {
      dispatch(hairdressingSalonEffects.get(uuid));
    }
  }, []);

  return (
    <Page
      isLoading={hairdressingSalon.isLoading}
      title="professional.details"
      breadcrumbs={[
        { name: "dashboard.root", href: PATH_DASHBOARD.root },
        { name: "professional.root_plural", href: PATH_DASHBOARD.user.root },
        { name: displayName },
      ]}
      permissions={APP_PERMISSIONS[sliceNames.professional].read}
      defaultActionButton={{
        type: "icon",
        title: "label.contact_via_chat",
        icon: "tabler:message-circle-2-filled",
        onClick: () =>
          navigate(`${PATH_DASHBOARD.chat.root}/?salon=${uuid ?? ""}`),
      }}
    >
      <Card
        sx={{
          mb: 3,
          width: "100%",
          aspectRatio: "21/9",
          position: "relative",
        }}
      >
        <ProfileCover
          name={displayName}
          role={salon?.entreprise_name ?? ""}
          covers={salon?.images?.map((img) => img?.medium?.url)}
          photoURL={salon?.file?.medium?.url}
        />

        <Tabs
          value={currentTab}
          onChange={(event, newValue) => setCurrentTab(newValue)}
          sx={{
            width: 1,
            bottom: 0,
            zIndex: 9,
            position: "absolute",
            bgcolor: "background.paper",
            "& .MuiTabs-flexContainer": {
              pr: { md: 3 },
              justifyContent: {
                sm: "center",
                md: "flex-end",
              },
            },
          }}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              icon={tab.icon}
              label={tab.label}
            />
          ))}
        </Tabs>
      </Card>
      {TABS.map(
        (tab) =>
          tab.value === currentTab && (
            <Box key={tab.value}> {tab.component} </Box>
          )
      )}
    </Page>
  );
}

// ----------------------------------------------------------------
function ProductsTab() {
  // Hooks
  const { uuid, public_id } = useParams();

  return (
    <ProductsPage
      noPage
      noSalonFiler
      breadcrumbs={null}
      additionalFilters={{
        hairdressing_salon_uuid: uuid,
        user_public_id: public_id,
      }}
    />
  );
}
