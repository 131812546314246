import { DAYS_OF_THE_WEEK } from "@/app/settings/config";
import { IHairdressingSalonPlanning } from "@/app/types";
import useTranslation from "@/core/hooks/useTranslation";
import {
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

export interface IPlanningSelectProps {
  onChange?: (value: IHairdressingSalonPlanning[]) => void;
  value?: IHairdressingSalonPlanning[];
  name?: string;
  label?: string;
  disabled?: boolean;
}

const PlanningSelect: React.FC<IPlanningSelectProps> = ({
  onChange,
  value,
  label,
  disabled,
}) => {
  // Hooks
  const t = useTranslation();

  // State
  const [planning, setPlanning] = React.useState<IHairdressingSalonPlanning[]>(
    value ?? []
  );

  // Function
  const handleChange = (
    value: IHairdressingSalonPlanning,
    checked: boolean | null
  ) => {
    setPlanning((state) => {
      const newState = checked
        ? !state.find((p) => p.day === value.day)
          ? [...state, value]
          : state
        : checked === null
        ? state.map((p) => (p.day === value.day ? value : p))
        : state.filter((p) => p.day !== value.day);
      onChange && onChange(newState);
      return newState;
    });
  };

  React.useEffect(() => {
    setPlanning(value ?? []);
  }, [value]);

  return (
    <Stack rowGap={1}>
      {label && (
        <Typography
          color="gray"
          variant="subtitle2"
          gutterBottom
        >
          {t(label)}
        </Typography>
      )}
      <Stack rowGap={2}>
        {DAYS_OF_THE_WEEK.map((day) => (
          <Planning
            key={day}
            disabled={disabled}
            active={!!planning.find((p) => p.day === day)}
            handleChange={handleChange}
            name={day}
            initialValue={planning.find((p) => p.day === day)?.hours}
          />
        ))}
      </Stack>
    </Stack>
  );
};

function Planning({
  active,
  handleChange,
  name,
  initialValue,
  disabled,
}: {
  active: boolean;
  disabled?: boolean;
  handleChange: (
    value: IHairdressingSalonPlanning,
    checked: boolean | null
  ) => void;
  name: string;
  initialValue?: {
    start_time?: string;
    end_time?: string;
  } | null;
}) {
  // Hooks
  const t = useTranslation();

  // State
  const [form, setForm] = React.useState({
    start_time: initialValue?.start_time ?? "00:00",
    end_time: initialValue?.end_time ?? "00:00",
  });

  // Handle form changes
  const handleFormChange = (day: string, checked: boolean | null, e?: any) => {
    const hours = !e
      ? form
      : {
          ...form,
          [e.target.name]: e.target.value,
        };
    setForm(hours);
    handleChange(
      {
        day,
        hours,
      },
      checked
    );
  };

  return (
    <Grid
      container
      columnSpacing={2}
    >
      <Grid
        item
        xs={6}
      >
        <FormControlLabel
          label={t(`label.${name}`)}
          labelPlacement="end"
          disabled={disabled}
          control={
            <Switch
              name={name}
              title={t(`label.${name}`)}
              checked={active}
              disabled={disabled}
              onChange={(_, checked) => handleFormChange(name, checked)}
            />
          }
        />
      </Grid>
      <Grid
        item
        xs={3}
      >
        <TextField
          fullWidth
          type="time"
          disabled={disabled ?? !active}
          aria-disabled={disabled ?? !active}
          name="start_time"
          label={t("label.start")}
          title={t("label.start")}
          onChange={(e) => handleFormChange(name, null, e)}
          value={form.start_time}
        />
      </Grid>
      <Grid
        item
        xs={3}
      >
        <TextField
          fullWidth
          type="time"
          name="end_time"
          disabled={disabled ?? !active}
          aria-disabled={disabled ?? !active}
          label={t("label.end")}
          title={t("label.end")}
          onChange={(e) => handleFormChange(name, null, e)}
          value={form.end_time}
        />
      </Grid>
    </Grid>
  );
}

export default PlanningSelect;
