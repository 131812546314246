import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
// @mui
import { Card, CircularProgress, Stack } from "@mui/material";
// redux
import {
  addRecipients,
  createMessage,
  createMessageFile,
  getConversationMessages,
  getConversations,
  setActiveConversation,
} from "@/redux/slices/chat";
import { useDispatch, useSelector } from "@/redux/store";
// routes
import { PATH_DASHBOARD } from "@/app/routes/paths";
// components
import CustomBreadcrumbs from "@/core/components/custom-breadcrumbs";
// sections
import { useAuthContext } from "@/app/auth/useAuthContext";
import CustomerService from "@/app/services/api/CustomerService";
import HairdressingSalonService from "@/app/services/api/HairdressingSalonService";
import { IChatMessageCreate, IChatMessageFileCreate } from "@/app/types";
import Page from "@/core/components/page";
import useToastError from "@/core/hooks/useToastError";
import ChatHeaderCompose from "./header/ChatHeaderCompose";
import ChatMessageInput from "./message/ChatMessageInput";
import ChatMessageList from "./message/ChatMessageList";
import ChatNav from "./nav/ChatNav";

// ----------------------------------------------------------------------

export default function ChatPage() {
  // Context
  const { user } = useAuthContext();
  const CURRENT_USER_ID = user?.public_id ?? "";

  // Query params
  const [searchParams] = useSearchParams();

  // State
  const [loading, setLoading] = useState<boolean>(false);
  const [isSalon, setIsSalon] = useState<boolean>(!!searchParams.get("salon"));

  // Store
  const { chat } = useSelector((state) => state);
  const { contacts, recipients, activeConversationId, conversations } =
    useSelector((state) => state.chat);

  const selectedConversation = useSelector(() => {
    if (activeConversationId) {
      return conversations.byId[activeConversationId];
    }

    return {
      id: "",
      messages: [],
      participants: [],
      unreadCount: 0,
      type: "",
    };
  });

  // Hooks
  const dispatch = useDispatch();
  useToastError(chat?.error);

  // Functions
  const handleAddRecipients = (selectedRecipients: any) => {
    dispatch(addRecipients(selectedRecipients));
  };

  const handleSendMessage = async (
    value: IChatMessageCreate,
    callback: any
  ) => {
    dispatch(
      createMessage(value, () => {
        callback && callback();
        activeConversationId
          ? dispatch(getConversationMessages(activeConversationId))
          : dispatch(
              getConversations("", (data: any) => {
                dispatch(setActiveConversation(data[0]?.id ?? null));
              })
            );
      })
    );
  };

  const handleSendMessageFile = async (value: IChatMessageFileCreate) => {
    dispatch(
      createMessageFile(value, () => {
        activeConversationId
          ? dispatch(getConversationMessages(activeConversationId))
          : dispatch(
              getConversations("", (data: any) => {
                dispatch(setActiveConversation(data[0]?.id ?? null));
              })
            );
      })
    );
  };

  // -- Get conversations
  useEffect(() => {
    if (!(searchParams.get("user") || searchParams.get("salon"))) {
      dispatch(getConversations());
    }
  }, []);

  // -- Get messages when changing conversation
  useEffect(() => {
    if (activeConversationId) {
      dispatch(getConversationMessages(activeConversationId));
    }
  }, [dispatch, activeConversationId]);

  // -- Recall messages after 2 minutes
  useEffect(() => {
    const id = setInterval(() => {
      if (activeConversationId) {
        dispatch(getConversationMessages(activeConversationId));
      }
      dispatch(getConversations());
    }, 2 * 60 * 1000);
    return () => {
      clearInterval(id);
    };
  }, [activeConversationId]);

  useEffect(() => {
    const param = searchParams.get("user") ?? searchParams.get("salon");
    const isCustomer = !!searchParams.get("user");
    if (param) {
      setLoading(true);
      (isCustomer ? CustomerService : HairdressingSalonService)
        .get(param!)
        .then(async (res) => await res.json())
        .then((data) => {
          dispatch(addRecipients([data]));
        })
        .finally(() => {
          setLoading(false);
          dispatch(getConversations());
        });
    }
  }, [dispatch]);

  return (
    <Page title="chat.root_plural">
      {/* @ts-ignore */}
      <CustomBreadcrumbs
        heading="chat.root_plural"
        links={[
          {
            name: "dashboard.root",
            href: PATH_DASHBOARD.root,
          },
          { name: "chat.root_plural" },
        ]}
      />

      {loading ? (
        <Stack
          justifyContent={"center"}
          alignItems="center"
          sx={{ p: 3 }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Card sx={{ height: "72vh", display: "flex" }}>
          <ChatNav
            conversations={conversations}
            activeConversationId={activeConversationId}
          />

          <Stack
            flexGrow={1}
            sx={{ overflow: "hidden" }}
          >
            {/* {detailView ? (
            <ChatHeaderDetail participants={displayParticipants} />
          ) : (
            <>
              <ChatHeaderCompose
                recipients={recipients}
                contacts={Object.values(contacts.byId)}
                onAddRecipients={handleAddRecipients}
              />
            </>
          )} */}

            {/* @ts-ignore */}
            <ChatHeaderCompose
              recipients={recipients}
              contacts={Object.values(contacts.byId)}
              onAddRecipients={handleAddRecipients}
              isSalon={isSalon}
              setIsSalon={setIsSalon}
            />
            <Stack
              direction="row"
              flexGrow={1}
              sx={{
                overflow: "hidden",
                borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
              }}
            >
              <Stack
                flexGrow={1}
                sx={{ minWidth: 0 }}
              >
                {/* @ts-ignore */}
                <ChatMessageList conversation={selectedConversation} />

                {/* @ts-ignore */}
                <ChatMessageInput
                  conversationId={activeConversationId}
                  onSend={handleSendMessage}
                  onSendFile={handleSendMessageFile}
                  disabled={
                    !activeConversationId || chat.recipients.length === 0
                  }
                />
              </Stack>

              {/* {detailView && (
              <ChatRoom
                conversation={selectedConversation}
                participants={displayParticipants}
              />
            )} */}
            </Stack>
          </Stack>
        </Card>
      )}
    </Page>
  );
}
