const order = {
  root: "commande",
  root_plural: "commandes",
  location: "Lieu",
  customer: "Client",
  salon: "Salon",
  payment_type: "Type de paiement",
  payment_types: "Types de paiement",
  no_data: "Pas de commande",
};

export default order;
