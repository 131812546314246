import orderBy from "lodash/orderBy";
import PropTypes from "prop-types";
// @mui
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
// utils
import { fDateTime } from "@/core/utils/formatTime";
// components
import { ColorMultiPicker } from "@/core/components/color-utils";
import Iconify from "@/core/components/iconify";
import Scrollbar from "@/core/components/scrollbar";
import useTranslation from "@/core/hooks/useTranslation";
import moment from "moment";

// ----------------------------------------------------------------------

CalendarFilterDrawer.propTypes = {
  events: PropTypes.array,
  picker: PropTypes.object,
  openFilter: PropTypes.bool,
  onCloseFilter: PropTypes.func,
  onResetFilter: PropTypes.func,
  onSelectEvent: PropTypes.func,
  onFilterEventColor: PropTypes.func,
  colorOptions: PropTypes.arrayOf(PropTypes.string),
  filterEventColor: PropTypes.arrayOf(PropTypes.string),
};

export default function CalendarFilterDrawer({
  events,
  picker,
  openFilter,
  colorOptions,
  onCloseFilter,
  onResetFilter,
  onSelectEvent,
  filterEventColor,
  onFilterEventColor,
}) {
  const notDefault =
    (picker.startDate && picker.endDate) || !!filterEventColor.length;

  const t = useTranslation();

  return (
    <Drawer
      anchor="right"
      open={openFilter}
      onClose={onCloseFilter}
      BackdropProps={{
        invisible: true,
      }}
      PaperProps={{
        sx: { width: 320 },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pl: 2, pr: 1, py: 2 }}
      >
        <Typography variant="subtitle1">{t("app.filters")}</Typography>

        <Tooltip title={t("app.reset")}>
          <Box sx={{ position: "relative" }}>
            <IconButton onClick={onResetFilter}>
              <Iconify icon="ic:round-refresh" />
            </IconButton>

            {notDefault && (
              <Box
                sx={{
                  top: 6,
                  right: 4,
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  position: "absolute",
                  bgcolor: "error.main",
                }}
              />
            )}
          </Box>
        </Tooltip>
      </Stack>

      <Divider />

      {/*
<Typography
        variant="caption"
        sx={{
          color: "text.secondary",
          fontWeight: "fontWeightMedium",
          p: (theme) => theme.spacing(2, 2, 1, 2),
        }}
      >
        {t("app.colors")}
      </Typography>
*/}

      {/*
      <ColorMultiPicker
        colors={colorOptions}
        selected={filterEventColor}
        onChangeColor={onFilterEventColor}
        sx={{ mx: 2 }}
      />
*/}

      <Typography
        variant="caption"
        sx={{
          p: 2,
          color: "text.secondary",
          fontWeight: "fontWeightMedium",
        }}
      >
        {t("app.range")}
      </Typography>

      <Stack
        spacing={2}
        sx={{ px: 2 }}
      >
        <DatePicker
          label={t("label.start")}
          value={picker.startDate}
          onChange={picker.onChangeStartDate}
          renderInput={(params) => (
            <TextField
              size="small"
              {...params}
            />
          )}
        />

        <DatePicker
          label={t("label.end")}
          value={picker.endDate}
          onChange={picker.onChangeEndDate}
          renderInput={(params) => (
            <TextField
              size="small"
              {...params}
              error={picker.isError}
              helperText={
                picker.isError && t("error.start_date_greather_than_end_date")
              }
            />
          )}
        />
      </Stack>

      <Typography
        variant="caption"
        sx={{
          p: 2,
          color: "text.secondary",
          fontWeight: "fontWeightMedium",
        }}
      >
        {t("app.events")} ({events.length})
      </Typography>

      <Scrollbar sx={{ height: 1 }}>
        {orderBy(events, ["end"], ["desc"]).map((event) => (
          <ListItemButton
            key={event.id}
            onClick={() => onSelectEvent(event.id)}
            sx={{
              py: 1.5,
              borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
            }}
          >
            <Box
              sx={{
                top: 16,
                left: 0,
                width: 0,
                height: 0,
                position: "absolute",
                borderRight: "10px solid transparent",
                borderTop: `10px solid ${event.color}`,
              }}
            />

            <ListItemText
              disableTypography
              primary={
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: 13, mt: 0.5 }}
                >
                  {event.title}
                </Typography>
              }
              secondary={
                <Typography
                  variant="caption"
                  component="div"
                  sx={{ fontSize: 11, color: "text.disabled" }}
                >
                  {event.allDay ? (
                    moment(event.start).format("ll")
                  ) : (
                    <>
                      {`${moment(event.start).format("ll")} - ${moment(
                        event.end
                      ).format("ll")}`}
                    </>
                  )}
                </Typography>
              }
              sx={{ display: "flex", flexDirection: "column-reverse" }}
            />
          </ListItemButton>
        ))}
      </Scrollbar>
    </Drawer>
  );
}
