import PropTypes from "prop-types";
// @mui
import useTranslation from "@/core/hooks/useTranslation";
import { MenuItem, TextField } from "@mui/material";

// ----------------------------------------------------------------------

BlogPostsSort.propTypes = {
  onSort: PropTypes.func,
  sortBy: PropTypes.string,
  sortOptions: PropTypes.array,
};

export default function BlogPostsSort({ sortBy, sortOptions, onSort }) {
  const t = useTranslation();
  return (
    <TextField
      select
      size="small"
      value={sortBy}
      onChange={(_, { props: { value } }) => onSort(value)}
      SelectProps={{
        sx: { typography: "body2" },
      }}
    >
      {sortOptions.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          selected={option.value === sortBy}
          sx={{
            mx: 1,
            borderRadius: 0.75,
            typography: "body2",
            textTransform: "capitalize",
          }}
        >
          {t(option.label)}
        </MenuItem>
      ))}
    </TextField>
  );
}
