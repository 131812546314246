import { IApplicationState, Intent } from "@/app/types";
import useTranslation from "@/core/hooks/useTranslation";
import { MenuItem } from "@mui/material";
import React from "react";

import IntentsService from "@/app/services/api/IntentsService";
import ConfirmDialog from "@/core/components/confirm-dialog/ConfirmDialog";
import Iconify from "@/core/components/iconify/Iconify";
import intentEffects from "@/redux/slices/intent/effects";
import { dispatch } from "@/redux/store";
import { green } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

const useStyles: any = makeStyles((theme: any) => ({
  root: {},
  error: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.main,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
    height: "auto",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface AlertDialogDeleteIntentProps {
  info: Intent;
  lang: string;
  handleClosePopover: Function;
}

export default function AlertDialogDeleteIntent(
  props: AlertDialogDeleteIntentProps
) {
  const { info } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const deleting_request = false;
  const {
    intent: { range },
  } = useSelector((state: IApplicationState) => state);
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteSubmit = (info: Intent) => {
    setLoading(true);
    IntentsService.delete_intent(
      info.name.split("/")[info.name.split("/").length - 1]
    )
      .then(async (response: any) => {
        const data = await response.json();
        console.log({ response });
        if (response.status === 200) {
          dispatch(intentEffects.paginate({ range, lang: props.lang }));
          // dispatch(
          //   slice.actions.paginate({
          //     intents: intent.intents.filter((item) => item.name != info.name)
          //   })
          // );
          enqueueSnackbar("Question supprimée avec succès", {
            variant: "success",
          });
          handleClose();
        } else {
          enqueueSnackbar(data.detail, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("shared.internet_connexion_error"), {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleClose = () => {
    setOpen(false);
    props.handleClosePopover();
  };
  const t = useTranslation();
  return (
    <>
      <MenuItem
        onClick={() => {
          handleClickOpen();
        }}
        sx={{ color: "error.main" }}
      >
        <Iconify icon="eva:trash-2-outline" />
        {t("app.delete")}
      </MenuItem>
      {open && (
        <ConfirmDialog
          open={true}
          onClose={handleClose}
          disabled={loading}
          title={t("app.delete")}
          content={t(`app.delete_model_confirm`, { model: `chatBot.root` })}
          onConfirm={() => {
            handleDeleteSubmit(info);
          }}
          isConfirmLoading={loading}
          confirmLabel={t("app.delete")}
          action={null}
        />
      )}
    </>
  );
}
