import { IOrderCreate, IOrderRange, IOrderUpdate } from "@/app/types";
import BaseService from "./BaseService";
import { OrderUrls } from "./urls";

class OrderService {
  static get = (uuid: string) => {
    return BaseService.getRequest(OrderUrls.GET(uuid), true);
  };
  static paginate = (query: IOrderRange) => {
    return BaseService.getRequest(OrderUrls.PAGINATE(query), true);
  };
  static create = (data: IOrderCreate) => {
    return BaseService.postRequest(OrderUrls.CREATE, data, true);
  };
  static update = ({ uuid, ...rest }: IOrderUpdate) => {
    return BaseService.putRequest(OrderUrls.UPDATE(uuid), rest, true);
  };
  static delete = (uuid: string) => {
    return BaseService.deleteRequest(OrderUrls.DELETE(uuid), {}, true);
  };
}

export default OrderService;
