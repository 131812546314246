import { IAutoFormState } from ".";

type IAutoFormReducer = (state: IAutoFormState, action: any) => void;

const reducers: Record<string, IAutoFormReducer> = {
  setValuesToHold: (state, action) => {
    state.valuesToHold = action.payload;
  },
};

export default reducers;
