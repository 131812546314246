import { useLocales } from "@/app/locales";
import { IApplicationState, IAppLocale } from "@/app/types";
import useFilters from "@/core/hooks/useFilters";
import useTranslation from "@/core/hooks/useTranslation";
import TablePage from "@/presentation/components/TablePage";
import administratorEffects from "@/redux/slices/administrator/effects";
import React from "react";
import { useSelector } from "react-redux";
import administratorColumns from "./data/columns";
import {
  administratorTableTopOptions,
  administratorToolbarOptions,
} from "./data/constants";
import administratorFields from "./data/fields";

const AdministratorsPage: React.FC = () => {
  // Store
  const { administrator, globalData } = useSelector(
    (state: IApplicationState) => state
  );

  // Hooks
  const { currentLang } = useLocales();
  const t = useTranslation();
  const {
    getData,
    handleKeyword,
    handleRole_id,
    handleStatus,
    handleOrder,
    handleOrder_field,
    handlePage,
    handlePer_page,
    keyword,
    role_id,
    status,
    order,
    order_field,
  } = useFilters({
    effects: administratorEffects,
    initialState: {
      page: administrator.list.current_page,
      per_page: administrator.list.per_page,
      keyword: "",
      role_id: "",
      status: "",
      order: "",
      order_field: "",
    },
  });

  // Constants
  const formattedRoles = globalData.data.roles
    .filter((role) => [1, 5, 6].includes(role.id))
    .map((role) => ({
      label: role[`title_${currentLang.value as IAppLocale}`],
      value: role.id,
    }));
  console.log(formattedRoles);
  return (
    <TablePage
      model="administrator"
      noCheckBoxes
      // ** Genre **
      // female
      // ** Toolbar **
      tableTopOptions={administratorTableTopOptions}
      toolbarOptionChange={handleStatus}
      currentToolbarOption={status}
      toolbarOptions={administratorToolbarOptions(
        formattedRoles,
        {
          value: keyword,
          onChange: handleKeyword,
        },
        {
          value: role_id,
          onChange: handleRole_id,
        }
      )}
      // ** On Select rows **
      idProperty="public_id"
      // ** Table CRUD **
      fields={administratorFields(t, formattedRoles)}
      stateData={administrator}
      columns={administratorColumns(currentLang.value as IAppLocale)}
      effects={administratorEffects}
      getData={getData}
      // ** Filter options **
      isNotFound={(data) =>
        (!data.length && !!keyword) ||
        (!data.length && !!role_id) ||
        (!data.length && !!status)
      }
      isFiltered={(data) => keyword !== "" || role_id !== "" || status !== ""}
      onReset={() => {
        handleKeyword("");
        handleRole_id("");
        handleStatus("");
      }}
      // ** Sorting **
      handleFilterOrder={handleOrder}
      handleFilterOrderField={handleOrder_field}
      order={order}
      orderField={order_field}
      // ** Pagination **
      onChangePage={handlePage}
      onChangeRowsPerPage={handlePer_page}
    />
  );
};

export default AdministratorsPage;
