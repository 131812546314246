import { IHairdressingSalon } from "@/app/types";
import { IBaseCrudReducer } from "@/app/types/BaseReduxTypes";
import BaseCrudReducer from "@/redux/base/crud-slice-helpers/reducers";
import { resetStateKeys } from "@/redux/common/functions";
import { hairdressingSalonInitialState, IHairdressingSalonState } from ".";

export interface IHairdressingSalonReducer
  extends IBaseCrudReducer<IHairdressingSalonState, IHairdressingSalon> {
  resetState: (state: IHairdressingSalonState) => void;
}

class HairdressingSalonReducer
  extends BaseCrudReducer<IHairdressingSalonState, IHairdressingSalon>
  implements IHairdressingSalonReducer
{
  resetState = (state: IHairdressingSalonState) =>
    resetStateKeys(state, hairdressingSalonInitialState);
}

const reducers: IHairdressingSalonReducer = new HairdressingSalonReducer();

export default reducers;
