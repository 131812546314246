import {
  ITablePageOptionListItem,
  IToolbarOption,
} from "@/presentation/components/TablePage";

export const administratorTableTopOptions = [
  {
    label: "status.all",
    value: "",
  },
  {
    label: "status.active",
    value: "ACTIVED",
  },
  {
    label: "status.inactive",
    value: "UNACTIVED",
  },
];

export const administratorToolbarOptions = (
  roles: ITablePageOptionListItem[],
  keyword: {
    value: string;
    onChange: (value: string) => void;
  },
  role: {
    value: string;
    onChange: (value: string) => void;
  }
): IToolbarOption[] => [
  {
    name: "admin_roles",
    type: "select",
    options: roles,
    placeholder: "app.role",
    ...role,
  },
  {
    name: "admin_search",
    placeholder: "placeholder.search",
    type: "input",
    ...keyword,
  },
];
