const professional = {
  root: "professionel",
  root_plural: "professionnels",
  website: "Site web",
  details: "Details du professionnel",
  no_hairdresser: "Pas de coiffeur",
  not_open_this_day: "Le salon n'est pas ouvert ce jour",
};

export default professional;
