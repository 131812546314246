import { createSlice, ThunkAction } from "@reduxjs/toolkit";
import keyBy from "lodash/keyBy";
// utils
import { DEFAULT_RANGE } from "@/app/config-global";
import { ChatService } from "@/app/services/api/ChatService";
import CustomerService from "@/app/services/api/CustomerService";
import {
  IChatConversation,
  IChatMessage,
  IChatMessageCreate,
  IChatMessageFileCreate,
  IChatUser,
  ICustomer,
  IRequestRange,
} from "@/app/types";
import {
  IBaseCrudError,
  ISimpleReduxCallback,
} from "@/app/types/BaseReduxTypes";
import axios from "@/core/utils/axios";
import { execute } from "../common/functions";
import sliceNames from "../names";

export interface IChatState {
  isLoading: boolean;
  isLoadingConversations: boolean;
  isLoadingSendMessage: boolean;
  error: IBaseCrudError | null;
  contacts: { byId: Record<string, ICustomer>; allIds: string[] };
  conversations: { byId: Record<string, IChatConversation>; allIds: string[] };
  messages: IChatMessage[];
  activeConversationId: string | null;
  participants: IChatUser[];
  recipients: IChatUser[];
}

// ----------------------------------------------------------------------

const initialState: IChatState = {
  isLoading: false,
  isLoadingConversations: false,
  isLoadingSendMessage: false,
  error: null,
  contacts: { byId: {}, allIds: [] },
  conversations: { byId: {}, allIds: [] },
  messages: [],
  activeConversationId: null,
  participants: [],
  recipients: [],
};

const slice = createSlice({
  name: sliceNames.chat,
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // START LOADING
    startLoadingConversations(state) {
      state.isLoadingConversations = true;
    },

    // STOP LOADING
    stopLoadingConversations(state) {
      state.isLoadingConversations = false;
    },

    // START LOADING
    startLoadingSendMessage(state) {
      state.isLoadingSendMessage = true;
    },

    // STOP LOADING
    stopLoadingSendMessage(state) {
      state.isLoadingSendMessage = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONTACT SSUCCESS
    getContactsSuccess(state, action) {
      const contacts = action.payload.data;

      state.contacts.byId = keyBy(contacts, "public_id");
      state.contacts.allIds = Object.keys(state.contacts.byId);
    },

    // GET CONVERSATIONS
    getConversationsSuccess(state, action) {
      const conversations = action.payload;

      state.conversations.byId = keyBy(conversations, "id");
      state.conversations.allIds = Object.keys(state.conversations.byId);
    },

    // GET CONVERSATIONS
    getConversationMessagesSuccess(state, action) {
      state.messages = action.payload;
    },

    // GET CONVERSATION
    getConversationSuccess(state, action) {
      const conversation = action.payload;

      if (conversation) {
        state.conversations.byId[conversation.id] = conversation;
        state.activeConversationId = conversation.id;
        if (!state.conversations.allIds.includes(conversation.id)) {
          state.conversations.allIds.push(conversation.id);
        }
      } else {
        state.activeConversationId = null;
      }
    },

    // ON SEND MESSAGE
    sendMessage(state, action) {
      // const conversation = action.payload;
      // const {
      //   conversationId,
      //   messageId,
      //   message,
      //   contentType,
      //   attachments,
      //   createdAt,
      //   senderId,
      // } = conversation;
      // const newMessage = {
      //   id: messageId,
      //   body: message,
      //   contentType,
      //   attachments,
      //   createdAt,
      //   senderId,
      // };
      // state.conversations.byId[conversationId].messages.push(newMessage);
    },

    // ON SEND MESSAGE FILE
    sendMessageFile(state, action) {
      // const conversation = action.payload;
      // const {
      //   conversationId,
      //   messageId,
      //   message,
      //   contentType,
      //   attachments,
      //   createdAt,
      //   senderId,
      // } = conversation;
      // const newMessage = {
      //   id: messageId,
      //   body: message,
      //   contentType,
      //   attachments,
      //   createdAt,
      //   senderId,
      // };
      // state.conversations.byId[conversationId].messages.push(newMessage);
    },

    markConversationAsReadSuccess(state, action) {
      const { conversationId } = action.payload;
      const conversation = state.conversations.byId[conversationId];
      if (conversation) {
        conversation.is_read = true;
      }
    },

    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      const participants = action.payload;
      state.participants = participants;
    },

    // SET ACTIVE CONVERSATION
    setActiveConversation(state, action) {
      state.activeConversationId = action.payload;
      const conversation = state.conversations.byId[action.payload];
      state.recipients = conversation ? [conversation.receiver!] : [];
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
    },

    addRecipients(state, action) {
      const recipients = action.payload;
      state.recipients = recipients;
      state.messages = [];
      state.activeConversationId = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { addRecipients, sendMessage, resetActiveConversation } =
  slice.actions;

// ----------------------------------------------------------------------

export function getContacts(
  range: IRequestRange,
  callback?: ISimpleReduxCallback
) {
  return execute({
    slice,
    serviceFn: CustomerService.paginate,
    inputProps: { ...DEFAULT_RANGE, ...(range ?? {}) },
    actionToDispatch: slice.actions.getContactsSuccess,
    callback,
  });
}

// ----------------------------------------------------------------------

export function getConversations(
  public_id?: string,
  callback?: ISimpleReduxCallback
) {
  return execute({
    slice,
    serviceFn: ChatService.listUserConversations,
    inputProps: public_id,
    actionToDispatch: slice.actions.getConversationsSuccess,
    startLoadingAction: slice.actions.startLoadingConversations,
    stopLoadingAction: slice.actions.stopLoadingConversations,
    callback,
  });
}

// ----------------------------------------------------------------------
export function getConversationMessages(
  id: string,
  callback?: ISimpleReduxCallback
) {
  return execute({
    slice,
    serviceFn: ChatService.listConversationMessages,
    inputProps: id,
    actionToDispatch: slice.actions.getConversationMessagesSuccess,
    callback: (data, dispatch) => {
      callback && callback(data);
      dispatch(slice.actions.setActiveConversation(id));
    },
  });
}

// ----------------------------------------------------------------------

export function getConversation(id: string, callback?: ISimpleReduxCallback) {
  return execute({
    slice,
    serviceFn: ChatService.listConversationMessages,
    inputProps: id,
    actionToDispatch: slice.actions.getConversationSuccess,
    callback,
  });
}

// ----------------------------------------------------------------------
export function setActiveConversation(id: string) {
  return (async (dispatch) => {
    dispatch(slice.actions.setActiveConversation(id));
  }) as ThunkAction<any, any, any, any>;
}
// ----------------------------------------------------------------------

export function markConversationAsRead(conversationId: string) {
  return (async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.get("/api/chat/conversation/mark-as-seen", {
        params: { conversationId },
      });
      dispatch(slice.actions.markConversationAsReadSuccess({ conversationId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }) as ThunkAction<any, any, any, any>;
}

// ----------------------------------------------------------------------

export function getParticipants(conversationKey: string) {
  return (async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/chat/participants", {
        params: { conversationKey },
      });
      dispatch(
        slice.actions.getParticipantsSuccess(response.data.participants)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }) as ThunkAction<any, any, any, any>;
}

// ----------------------------------------------------------------------

export function createMessage(
  message: IChatMessageCreate,
  callback?: ISimpleReduxCallback
) {
  return execute({
    slice,
    serviceFn: ChatService.createMessage,
    inputProps: message,
    actionToDispatch: slice.actions.sendMessage,
    startLoadingAction: slice.actions.startLoadingSendMessage,
    stopLoadingAction: slice.actions.stopLoadingSendMessage,
    callback,
  });
}

// ----------------------------------------------------------------------

export function createMessageFile(
  messageFile: IChatMessageFileCreate,
  callback?: ISimpleReduxCallback
) {
  return execute({
    slice,
    serviceFn: ChatService.createMessageFile,
    inputProps: messageFile,
    actionToDispatch: slice.actions.sendMessageFile,
    startLoadingAction: slice.actions.startLoadingSendMessage,
    stopLoadingAction: slice.actions.stopLoadingSendMessage,
    callback,
  });
}
