import { IBlogCategory } from "@/app/types";
import { IBaseCrudReducer } from "@/app/types/BaseReduxTypes";
import BaseCrudReducer from "@/redux/base/crud-slice-helpers/reducers";
import { resetStateKeys } from "@/redux/common/functions";
import { blogCategoryInitialState, IBlogCategoryState } from ".";

export interface IBlogCategoryReducer
  extends IBaseCrudReducer<IBlogCategoryState, IBlogCategory> {
  resetState: (state: IBlogCategoryState) => void;
}

class BlogCategoryReducer
  extends BaseCrudReducer<IBlogCategoryState, IBlogCategory>
  implements IBlogCategoryReducer
{
  resetState = (state: IBlogCategoryState) =>
    resetStateKeys(state, blogCategoryInitialState);
}

const reducers: IBlogCategoryReducer = new BlogCategoryReducer();

export default reducers;
