import { ACCEPTED_FILE_TYPES, DEFAULT_RANGE } from "@/app/config-global";
import BlogCategoryService from "@/app/services/api/BlogCategoryService";
import { IAppLocale, IBlog, IBlogCategory } from "@/app/types";
import Iconify from "@/core/components/iconify/Iconify";
import buildValidators from "@/core/utils/buildValidators";
import getVideoImage from "@/core/utils/getFrameVideo";
import { IAutoFormFields } from "@/presentation/components/AutoForm";
import { InputAdornment } from "@mui/material";

export const blogFields: (
  t: any,
  currentLocaleTab: string,
  lang: IAppLocale
) => IAutoFormFields<IBlog> = (t, currentLocaleTab) => {
  const v = buildValidators(t);
  return {
    title_fr: {
      initialValue: (row) => row?.title_fr,
      label: "label.title",
      hide: currentLocaleTab != "fr",
      placeholder: "placeholder.title",
      title: "title.title",
      validation: v.string,
    },
    title_en: {
      initialValue: (row) => row?.title_en,
      label: "label.title",
      hide: currentLocaleTab != "en",
      placeholder: "placeholder.title",
      title: "title.title",
      validation: v.string_nr,
    },
    owner_firstname: {
      initialValue: (row) => row?.owner_firstname,
      label: "label.nom_auteur",
      // hide: currentLocaleTab != "en",
      placeholder: "placeholder.nom_auteur",
      title: "title.nom_auteur",
      validation: v.string_nr,
    },
    owner_lastname: {
      initialValue: (row) => row?.owner_lastname,
      label: "label.prenom_auteur",
      placeholder: "placeholder.prenom_auteur",
      title: "title.prenom_auteur",
      validation: v.string_nr,
    },
    owner_job: {
      initialValue: (row) => row?.owner_job,
      label: "label.fonction_auteur",
      hide: currentLocaleTab != "fr",
      placeholder: "placeholder.fonction_auteur",
      title: "title.fonction_auteur",
      validation: v.string_nr,
    },
    owner_job_en: {
      initialValue: (row) => row?.owner_job_en,
      label: "label.fonction_auteur",
      hide: currentLocaleTab != "en",
      placeholder: "placeholder.fonction_auteur",
      title: "title.fonction_auteur",
      validation: v.string_nr,
    },
    content_fr: {
      type: "editor",
      initialValue: (row) => row?.content_fr,
      label: "label.content",
      hide: currentLocaleTab != "fr",
      placeholder: "placeholder.content",
      title: "title.content",
      validation: v.string,
    },
    content_en: {
      type: "editor",
      initialValue: (row) => row?.content_en,
      label: "label.content",
      hide: currentLocaleTab != "en",
      placeholder: "placeholder.content",
      title: "title.content",
      validation: v.string_nr,
    },
    category_uuid: {
      type: "autocomplete",
      initialOptions: (row) => row?.category,
      getOptions: (initialOption) => (initialOption ? [initialOption] : []),
      loadOptions: async (inputValue) => {
        const res = await BlogCategoryService.paginate({
          ...DEFAULT_RANGE,
          keyword: inputValue,
        });
        const data = await res.json();
        return data.data;
      },
      getOptionLabel: (option: IBlogCategory) =>
        option?.[`title_${currentLocaleTab as "fr" | "en"}`],
      getOptionValue: (option: IBlogCategory) => option.uuid,
      initialValue: (row) => row?.category?.uuid,
      label: "label.category",
      placeholder: "placeholder.category",
      title: "title.category",
      props: {},
      validation: v.string,
    },
    owner_image_uuid: {
      type: "uploader",
      fileType: ACCEPTED_FILE_TYPES.image,
      initialValue: (row) => row?.owner_image?.uuid ?? "",
      initialImage: (row) => row?.owner_image?.medium?.url,
      label: "label.avatar_auteur",
      placeholder: "placeholder.image",
      title: "title.image",
      validation: v.string_nr,
    },
    // image_uuid: {
    //   type: "uploader",
    //   fileType: ACCEPTED_FILE_TYPES.image,
    //   hide: currentLocaleTab != "fr",
    //   initialValue: (row) => row?.image?.uuid ?? "",
    //   initialImage: (row) => row?.image?.medium?.url,
    //   label: "label.image",
    //   placeholder: "placeholder.image",
    //   title: "title.image",
    //   validation: v.string_nr,
    // },
    // image_en_uuid: {
    //   type: "uploader",
    //   fileType: ACCEPTED_FILE_TYPES.image,
    //   hide: currentLocaleTab != "en",
    //   initialValue: (row) => row?.image_en?.uuid ?? "",
    //   initialImage: (row) => row?.image_en?.medium?.url,
    //   label: "label.image",
    //   placeholder: "placeholder.image",
    //   title: "title.image",
    //   validation: v.string_nr,
    // },
    cover_image_uuid: {
      type: "uploader",
      hide: currentLocaleTab != "fr",
      fileType: ACCEPTED_FILE_TYPES.image,
      initialValue: (row) => row?.cover_image?.uuid ?? "",
      initialImage: (row) => row?.cover_image?.medium?.url,
      label: "label.cover",
      placeholder: "placeholder.cover",
      title: "title.cover",
      validation: v.string_nr,
    },
    cover_image_en_uuid: {
      type: "uploader",
      hide: currentLocaleTab != "en",
      fileType: ACCEPTED_FILE_TYPES.image,
      initialValue: (row) => row?.cover_image_en?.uuid ?? "",
      initialImage: (row) => row?.cover_image_en?.medium?.url,
      label: "label.cover",
      placeholder: "placeholder.cover",
      title: "title.cover",
      validation: v.string_nr,
    },
    // video_uuid: {
    //   type: "uploader",
    //   fileType: ACCEPTED_FILE_TYPES.video,
    //   hide: currentLocaleTab != "fr",
    //   initialValue: (row) => row?.video?.uuid ?? "",
    //   initialImage: (row) => row?.video?.url,
    //   label: "label.video",
    //   placeholder: "placeholder.video",
    //   title: "title.video",
    //   onChangeCallback: (formik: any, files: any[]) => {
    //     window.URL = window.URL || window.webkitURL;
    //     let video = document.createElement("video");
    //     video.preload = "metadata";
    //     video.onloadedmetadata = function () {
    //       window.URL.revokeObjectURL(video.src);
    //       formik.setFieldValue(
    //         "video_duration",
    //         Number(video.duration).toFixed(0)
    //       );
    //     };
    //     video.src = URL.createObjectURL(files[0]);

    //     getVideoImage(
    //       video.src,
    //       (img: any, time: any) => {
    //         console.log("formik: ", formik);
    //         console.log(img, time);
    //         video.currentTime = 0;
    //         if (!formik.values.cover_image_uuid) {
    //           formik.setFieldValue("cover_image_uuid", img);
    //         }
    //       },
    //       1
    //     );
    //   },
    //   validation: v.string_nr,
    // },
    // video_en_uuid: {
    //   type: "uploader",
    //   fileType: ACCEPTED_FILE_TYPES.video,
    //   hide: currentLocaleTab != "en",
    //   initialValue: (row) => row?.video_en?.uuid ?? "",
    //   initialImage: (row) => row?.video_en?.url,
    //   label: "label.video",
    //   placeholder: "placeholder.video",
    //   title: "title.video",
    //   onChangeCallback: (formik: any, files: any[]) => {
    //     window.URL = window.URL || window.webkitURL;
    //     let video = document.createElement("video");
    //     video.preload = "metadata";
    //     video.onloadedmetadata = function () {
    //       window.URL.revokeObjectURL(video.src);
    //       formik.setFieldValue(
    //         "video_duration_en",
    //         Number(video.duration).toFixed(0)
    //       );
    //     };
    //     video.src = URL.createObjectURL(files[0]);

    //     getVideoImage(
    //       video.src,
    //       (img: any, time: any) => {
    //         console.log("formik: ", formik);
    //         console.log(img, time);
    //         video.currentTime = 0;
    //         if (!formik.values.cover_image_uuid_en) {
    //           formik.setFieldValue("cover_image_en_uuid", img);
    //         }
    //       },
    //       1
    //     );
    //   },
    //   validation: v.string_nr,
    // },
    // video_duration: {
    //   type: "number",
    //   initialValue: (row) => row?.video_duration ?? 0,
    //   hide: currentLocaleTab != "fr",
    //   label: "label.video_duration",
    //   placeholder: "placeholder.video_duration",
    //   title: "label.video_duration",
    //   props: {
    //     disabled: true,
    //     InputProps: {
    //       startAdornment: (
    //         <InputAdornment position="start">
    //           <Iconify
    //             icon="mdi:clock-time-eight-outline"
    //             sx={{ color: "text.disabled" }}
    //           />
    //         </InputAdornment>
    //       ),
    //       endAdornment: <span>s</span>,
    //     },
    //   },
    //   validation: v.number_nr,
    // },
    // video_duration_en: {
    //   type: "number",
    //   initialValue: (row) => row?.video_duration_en ?? 0,
    //   hide: currentLocaleTab != "en",
    //   label: "label.video_duration",
    //   placeholder: "placeholder.video_duration",
    //   title: "label.video_duration",
    //   props: {
    //     disabled: true,
    //     InputProps: {
    //       startAdornment: (
    //         <InputAdornment position="start">
    //           <Iconify
    //             icon="mdi:clock-time-eight-outline"
    //             sx={{ color: "text.disabled" }}
    //           />
    //         </InputAdornment>
    //       ),
    //       endAdornment: <span>s</span>,
    //     },
    //   },
    //   validation: v.number_nr,
    // },
    read_duration: {
      type: "number",
      initialValue: (row) => row?.read_duration ?? 0,
      // hide: currentLocaleTab != "en",
      label: "label.read_duration",
      placeholder: "placeholder.read_duration",
      title: "label.read_duration",
      props: {
        disabled: true,
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon="mdi:clock-time-eight-outline"
                sx={{ color: "text.disabled" }}
              />
            </InputAdornment>
          ),
          endAdornment: <span>s</span>,
        },
      },
      validation: v.number_nr,
    },
    meta_title: {
      initialValue: (row) => row?.meta_title,
      label: "label.meta_title",
      placeholder: "placeholder.meta_title",
      title: "title.meta_title",
      validation: v.string_nr,
    },
    meta_description: {
      initialValue: (row) => row?.meta_description,
      label: "label.meta_description",
      placeholder: "placeholder.meta_description",
      title: "title.meta_description",
      validation: v.string_nr,
    }
  };
};
