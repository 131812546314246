import { IVideo } from "@/app/types";
import { ITranslateFunction } from "@/core/hooks/useTranslation";
import buildValidators from "@/core/utils/buildValidators";
import { IAutoFormFields } from "@/presentation/components/AutoForm";
import { ITablePageOptionListItem } from "@/presentation/components/TablePage";

const videoFields = (
  t: ITranslateFunction,
  presentations: ITablePageOptionListItem[],
  langue: any[]
): IAutoFormFields<IVideo> => {
  const v = buildValidators(t);

  return {
    is_child: {
      initialValue: (row) => {
        return row?.is_child ?? false;
      },
      switchValues: [true, false],
      type: "switch",
      checkFn: (value) => {
        return value;
      },
      label: "label.is_kid",
      placeholder: "placeholder.is_kid",
      title: "title.is_kid",
      props: {},
      validation: v.boolean,
    },
    is_kid_video: {
      initialValue: (row) => {
        return row?.is_kid_video ?? false;
      },
      switchValues: [true, false],
      type: "switch",
      checkFn: (check) => {
        // console.log('check: ', check)
        return check;
      },
      label: "label.is_kid_video",
      placeholder: "placeholder.is_kid_video",
      title: "title.is_kid_video",
      hidden: (initialValues: any, value: any) => {
        console.log("initialValues: ", initialValues);
        console.log("values: ", value);
        if (initialValues?.is_kid_video) return false;
        return !value?.is_child ?? true;
      },
      props: {},
      validation: v.boolean,
    },
    name: {
      initialValue: (row) => {
        return row?.name;
      },
      label: "label.name",
      placeholder: "placeholder.name",
      title: "title.name",
      props: {},
      validation: v.string,
    },
    name_en: {
      initialValue: (row) => row?.name_en,
      label: "label.name_en",
      placeholder: "placeholder.name_en",
      title: "title.name_en",
      props: {},
      validation: v.string,
    },
    video_link: {
      initialValue: (row) => row?.video_link,
      label: "label.video_link",
      placeholder: "placeholder.video_link",
      title: "title.video_link",
      props: {},
      validation: v.youtube_link,
    },
    image_link: {
      initialValue: (row) => row?.image_link,
      type: "select",
      getOptions: () => langue,
      getOptionLabel: (option) => option.label,
      getOptionValue: (option) => option.value,
      label: "langue",
      placeholder: "langue",
      title: "langue",
      props: {},
      validation: v.string,
    },
    begin_duration: {
      type: "time",
      initialValue: (row) => row?.begin_duration,
      label: "label.begin_duration",
      placeholder: "placeholder.begin_duration",
      title: "title.begin_duration",
      props: {},
      validation: v.string,
    },
    prestation_id: {
      initialValue: (row) => row?.prestation?.id,
      type: "autocomplete",
      getOptions: () => presentations,
      getOptionLabel: (option) => option.label,
      getOptionValue: (option) => option.value,
      label: "label.prestation",
      placeholder: "placeholder.prestation",
      title: "title.prestation",
      props: {},
      hidden: (_, value: any) => {
        return value?.is_kid_video ?? false;
      },
      validation: v.number,
    },
  };
};

export default videoFields;
