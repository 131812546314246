import React from "react";
import { useAuthContext } from "../useAuthContext";

export interface IAccessGuardProps {
  children: any;
  roles?: number[];
}

const AccessGuard: React.FC<IAccessGuardProps> = ({ children, roles }) => {
  const { user } = useAuthContext();
  return roles?.includes(user?.role?.id) ? children : null;
};
export default AccessGuard;
