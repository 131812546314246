import BlogCategoryService from "@/app/services/api/BlogCategoryService";
import {
  IBlogCategoryCreate,
  IBlogCategoryRange,
  IBlogCategoryUpdate,
} from "@/app/types";
import { IReduxBaseCrudEffect } from "@/app/types/BaseReduxTypes";
import BaseReduxCrudEffects from "@/redux/base/crud-slice-helpers/effects";
import { slice } from ".";

export interface IBlogCategoryEffects
  extends IReduxBaseCrudEffect<
    IBlogCategoryRange,
    IBlogCategoryCreate,
    IBlogCategoryUpdate,
    BlogCategoryService
  > {}

class BlogCategoryEffects
  extends BaseReduxCrudEffects<
    IBlogCategoryRange,
    IBlogCategoryCreate,
    IBlogCategoryUpdate
  >
  implements IBlogCategoryEffects {}

const blogCategoryEffects = new BlogCategoryEffects(
  slice,
  BlogCategoryService
);

export default blogCategoryEffects;
