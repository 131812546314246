import { useLocales } from "@/app/locales";
import { PATH_DASHBOARD } from "@/app/routes/paths";
import { IApplicationState, IAppLocale, IProductRange } from "@/app/types";
import useCurrency from "@/core/hooks/useCurrency";
import useFilters from "@/core/hooks/useFilters";
import useTranslation from "@/core/hooks/useTranslation";
import TablePage, {
  ICustomBreadcrumbsLink,
} from "@/presentation/components/TablePage";
import { slice } from "@/redux/slices/product";
import productEffects from "@/redux/slices/product/effects";
import React from "react";
import { useSelector } from "react-redux";
import productColumns from "./data/columns";
import {
  productTableTopOptions,
  productToolbarOptions,
} from "./data/constants";
import productFields from "./data/fields";

export interface IProductsPageProps {
  additionalFilters?: Record<string, string | undefined>;
  breadcrumbs?: null | ICustomBreadcrumbsLink[];
  noPage?: boolean;
  noSalonFiler?: boolean;
}

const ProductsPage: React.FC<IProductsPageProps> = ({
  additionalFilters,
  breadcrumbs,
  noPage,
  noSalonFiler,
}) => {
  // Store
  const { product, globalData } = useSelector(
    (state: IApplicationState) => state
  );

  // Hooks
  const t = useTranslation();
  const {
    getData,
    handleKeyword,
    handleCategory_ids,
    handleSub_category_id,
    handleIs_online,
    handleOrder,
    handleOrder_field,
    handlePage,
    handlePer_page,
    handleHairdressing_salon_uuid,
    keyword,
    category_ids,
    sub_category_id,
    hairdressing_salon_uuid,
    is_online,
    order,
    order_field,
  } = useFilters({
    effects: productEffects,
    initialState: {
      page: product.list.current_page,
      per_page: product.list.per_page,
      keyword: "",
      category_ids: "",
      sub_category_id: "",
      hairdressing_salon_uuid: "",
      is_online: "",
      order: "",
      order_field: "",
      ...(additionalFilters ?? {}),
    },
  });
  const { currentLang } = useLocales();
  const fCurrency = useCurrency();
  const subCategories =
    globalData.data.prestation_with_sub_prestations
      .find((cat) => String(cat.prestation.id) === String(category_ids))
      ?.subs.map((subCat) => {
        return {
          label: subCat[`title_${currentLang.value as IAppLocale}`],
          value: subCat.id,
        };
      }) ?? [];
  const allSubCats = globalData.data.categories
    .map((cat) => cat.subs)
    .reduce((a, c) => [...a, ...c], [])
    .map((subCat) => {
      return {
        label: subCat[`title_${currentLang.value as IAppLocale}`],
        value: subCat.id,
      };
    });

  const categories = globalData.data.prestation_with_sub_prestations.map(
    (cat) => ({
      label: cat.prestation[`title_${currentLang.value as IAppLocale}`],
      value: cat.prestation.id,
    })
  );

  const hairStyles = globalData.data.hair_types;

  return (
    <TablePage
      model="product"
      noCheckBoxes
      // ** Genre **
      // female
      // ** Toolbar **
      tableTopOptions={productTableTopOptions}
      toolbarOptionChange={handleIs_online}
      currentToolbarOption={is_online}
      toolbarOptions={productToolbarOptions(
        {
          value: keyword ?? "",
          onChange: handleKeyword,
        },
        {
          value: sub_category_id ?? "",
          onChange: handleSub_category_id,
        },
        {
          value: category_ids ?? "",
          onChange: handleCategory_ids,
        },
        noSalonFiler
          ? null
          : {
              value: hairdressing_salon_uuid,
              onChange: handleHairdressing_salon_uuid,
            },
        categories,
        subCategories
      )}
      // ** On Select rows **
      idProperty="uuid"
      // ** Table CRUD **
      fields={productFields(t, currentLang.value, allSubCats, hairStyles)}
      stateData={product}
      slice={slice}
      columns={productColumns(fCurrency)}
      effects={productEffects}
      getData={getData}
      // ** Filter options **
      isNotFound={(data) =>
        (!data.length && !!keyword) ||
        (!data.length && !!category_ids) ||
        (!data.length && !!hairdressing_salon_uuid) ||
        (!data.length && !!is_online)
      }
      isFiltered={(data) =>
        keyword !== "" ||
        category_ids !== "" ||
        is_online !== "" ||
        (!additionalFilters?.hairdressing_salon_uuid &&
          hairdressing_salon_uuid !== "") ||
        (!additionalFilters?.hairdressing_salon_uuid &&
          hairdressing_salon_uuid !== "")
      }
      onReset={() => {
        handleKeyword("");
        handleCategory_ids("");
        handleIs_online("");
        if (!additionalFilters?.hairdressing_salon_uuid) {
          handleHairdressing_salon_uuid("");
        }
      }}
      // ** Sorting **
      handleFilterOrder={handleOrder}
      handleFilterOrderField={handleOrder_field}
      order={order}
      orderField={order_field}
      // ** Pagination **
      onChangePage={handlePage}
      onChangeRowsPerPage={handlePer_page}
      // ** Table Row Actions **
      showDetailsUrl={(row) => PATH_DASHBOARD.products.view(row?.uuid ?? "")}
      breadcrumbLinks={breadcrumbs}
      noPage={noPage}
    />
  );
};

export default ProductsPage;
