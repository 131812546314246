import { ICustomer } from "@/app/types";
import { ITableRowColumn } from "@/core/components/table/AppTableRow";
import moment from "moment";

const customerColumns: (locale: string) => ITableRowColumn<ICustomer>[] = (
  locale: string
) => [
  {
    header: { id: "name", label: "administrator.name", align: "left" },
    type: "avatar",
    title: (row) => {
      const profile = row?.profiles?.find((p) => p.is_parent);
      return profile
        ? profile.email ??
            `${profile.firstname ?? ""} ${profile.lastname ?? ""}`
        : "";
    },
    avatarUrl: (row) => {
      const profile = row?.profiles?.find((p) => p.is_parent);
      return profile ? profile.avatar?.url ?? "" : "";
    },
  },
  {
    header: {
      id: "email",
      label: "label.email",
      align: "left",
    },
    title: (row) => row?.email ?? "",
    align: "left",
  },
  {
    header: {
      id: "profile_count",
      label: "label.profile_count",
      align: "center",
    },
    title: (row) => row?.profiles?.length ?? "0",
    align: "center",
  },
  {
    header: { id: "status", label: "administrator.status", align: "left" },
    type: "badge",
    title: (row) => `status.${row?.status ?? ""}`,
    badgeColor: (row) => (row?.status === "ACTIVED" ? "success" : "error"),
    sx: { textTransform: "capitalize" },
  },
  {
    header: {
      id: "register_date",
      label: "label.register_date",
      align: "left",
    },
    title: (row) =>
      row?.date_added ? moment(row?.date_added).format("ll") : "",
  },
];

export default customerColumns;
